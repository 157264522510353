import React, { useEffect, useState } from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import { withI18n } from 'libs/support/i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as itemAddOnsActions from 'bundles/vendor/actions/itemAddOnsActions';
import * as serviceAddOnsActions from 'bundles/vendor/actions/serviceAddOnsActions';
import * as customisationsActions from 'bundles/vendor/actions/customisationsActions';
import {
  SectionDescription,
  SectionTitle,
} from 'bundles/vendor/components/Shared';
import CustomisationsList from 'bundles/vendor/components/Item/Customisations/CustomisationsList';
import { SecondaryButton } from 'bundles/common/components/Buttons';

const Container = styled.div`

`;

const ButtonContainer = styled.div`
  margin-top: 1rem;
`;

const CustomisationsSection = ({
  fetchItemAddOns,
  fetchServiceAddOns,
  openCustomisationsModal,
}) => {
  const [isInitialized, setInitialize] = useState(false);

  useEffect(() => {
    if (!isInitialized) {
      setInitialize(true);
      fetchItemAddOns();
      fetchServiceAddOns();
    }
  });

  return (
    <Container className="customisations-section">
      <SectionTitle>Customisations</SectionTitle>
      <SectionDescription>
        Add item add-ons or service add-ons for this item.
      </SectionDescription>

      <CustomisationsList />

      <ButtonContainer>
        <SecondaryButton
          name="+ Add customisations"
          onClick={openCustomisationsModal}
        />
      </ButtonContainer>
    </Container>
  );
};

CustomisationsSection.propTypes = {
  fetchItemAddOns: func.isRequired,
  fetchServiceAddOns: func.isRequired,
  openCustomisationsModal: func.isRequired,
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchItemAddOns: itemAddOnsActions.fetchItemAddOns,
  fetchServiceAddOns: serviceAddOnsActions.fetchServiceAddOns,
  openCustomisationsModal: customisationsActions.openCustomisationsModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withI18n(CustomisationsSection));
