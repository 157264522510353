import Immutable from 'immutable';

export const $$initialState = Immutable.fromJS({
  currentOutlet: Immutable.fromJS({}),
  vendors: [],
});

export default function localeReducer($$state = $$initialState, action = null) {
  const { type } = action;

  switch (type) {
    default: {
      return $$state;
    }
  }
}
