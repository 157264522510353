/* eslint camelcase: 0, max-len: 0 */

import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';

import loggerMiddleware from 'libs/middlewares/loggerMiddleware';

import reducers, { initialStates } from '../reducers';

export default (props) => {
  const {
    $$outletSelectorState,
    $$dashboardState,
    $$localeState,
  } = initialStates;

  const initialState = {
    $$outletSelectorStore: $$outletSelectorState.merge({
      outletsSelectedIds: props.outletsSelectedIds,
      outletsSelected: props.outletsSelected,
      vendorId: props.vendorId,
      outlets: props.outlets,
      vendors: props.vendors,
      mobile: props.mobile,
      vendorSelected: props.vendorSelected,
      controllerName: props.controllerName,
      orderId: props.orderId,
      isDraftOrder: props.isDraftOrder,
    }),
    $$dashboardStore: $$dashboardState,
    $$localeStore: $$localeState.merge({
      locale: props.locale,
    }),
  };

  const reducer = combineReducers(reducers);
  const composedStore = composeWithDevTools(applyMiddleware(thunkMiddleware, loggerMiddleware));

  return composedStore(createStore)(reducer, initialState);
};
