/* eslint no-return-assign: 0 */

import React from 'react';
import { number, object, string } from 'prop-types';
import styled from 'styled-components';
import {
  InputField,
} from 'libs/components/Forms';
import {
  DeleteButton,
} from 'bundles/common/components/Buttons';

const Row = styled.tr`
  border-bottom: 1px solid ${props => props.theme.colors.gray200};

  :nth-child(even) {
    background-color: ${props => props.theme.colors.white};
  }
`;

const Cell = styled.td`
  padding: 0.5rem 0.3rem;
  vertical-align: top;
`;

const InvisibleButton = styled.button`
  display: none;
`;

const Subitem = ({
  categoryName,
  includedItem,
  index,
}) => {
  let deletedAtRef = null;
  let rowRef = null;

  const handleDelete = () => {
    if (includedItem.get('uuid')) {
      deletedAtRef.value = new Date();
      rowRef.style.display = 'none';
    } else {
      rowRef.parentNode.removeChild(rowRef);
    }
  };

  return (
    <Row ref={el => rowRef = el}>
      <Cell>
        <InputField
          id={`menu_item_subitems_attributes_${categoryName}_${index}_id`}
          name="menu_item[subitems_attributes][][id]"
          value={includedItem.get('uuid')}
          type="hidden"
        />
        <InputField
          inputRef={el => deletedAtRef = el}
          id={`menu_item_subitems_attributes_${categoryName}_${index}_deleted_at`}
          name="menu_item[subitems_attributes][][deleted_at]"
          value={includedItem.get('deleted_at')}
          type="hidden"
        />
        <InputField
          id={`menu_item_subitems_attributes_${categoryName}_${index}_category_name`}
          name="menu_item[subitems_attributes][][category_name]"
          value={categoryName}
          type="hidden"
        />
      </Cell>
      <Cell>
        <InputField
          id={`menu_item_subitems_attributes_${categoryName}_${index}_name`}
          name="menu_item[subitems_attributes][][name]"
          value={includedItem.get('name')}
        />
      </Cell>
      <Cell>
        <InvisibleButton />
        <DeleteButton onClick={handleDelete} />
      </Cell>
    </Row>
  );
};

Subitem.propTypes = {
  categoryName: string.isRequired,
  index: number.isRequired,
  includedItem: object.isRequired,
};

export default Subitem;
