import React, { Fragment } from 'react';
import { object } from 'prop-types';

const MobileHeader = ({ children }) => (
  <Fragment>
    <div className="mobile-header">
      {children}
    </div>
  </Fragment>
);

MobileHeader.propTypes = {
  children: object,
};

export default MobileHeader;

