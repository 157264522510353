import requestsManager from './requestsManager';

function processAction(type, response) {
  return { type, response };
}

function perform(types, method, params, endpoint, id) {
  const [actionType, successType, failureType] = types;

  return dispatch => {
    if (actionType !== null && actionType !== undefined) {
      dispatch(processAction(actionType, { id, params }));
    }

    return requestsManager[method](params, endpoint, id)
      .then(res => dispatch(processAction(successType, res.data)))
      .catch(res => dispatch(processAction(failureType, res.response.data)));
  };
}

export function newInstance(args) {
  const { types, endpoint, params } = args;

  return perform(types, 'fetchEntities', params, endpoint);
}

export function get(args) {
  const {
    types, endpoint, params, id,
  } = args;
  const url = id ? `${endpoint}/${id}` : endpoint;

  return perform(types, 'fetchEntities', params, url);
}

export function save(args) {
  const {
    types, endpoint, params, id, forceUpdate,
  } = args;

  if (id || forceUpdate) return perform(types, 'updateEntity', params, endpoint, id);

  return perform(types, 'createEntity', params, endpoint);
}

export function create(args) {
  const {
    types, endpoint, params, id,
  } = args;

  return perform(types, 'createEntity', params, endpoint, id);
}

export function destroy(args) {
  const {
    types, endpoint, params, id,
  } = args;

  return perform(types, 'deleteEntity', params, endpoint, id);
}
