import React from 'react';
import { bool, object } from 'prop-types';
import { connect } from 'react-redux';
import { blankish } from 'libs/support/string';
import {
  Table,
  HeaderRow,
  Header,
  TBody,
} from 'libs/support/sharedComponents';
import {
  TYPE_ITEM_ADD_ON,
  TYPE_SERVICE_ADD_ON,
} from 'bundles/vendor/constants/customisationsConstants';
import Customisation from './Customisation';

const CustomisationsList = ({
  isItemAddOnsFetched,
  isServiceAddOnsFetched,
  itemAddOns,
  menuItem,
  serviceAddOns,
}) => {
  if (blankish(menuItem) || !isItemAddOnsFetched || !isServiceAddOnsFetched) {
    return <div>Loading...</div>;
  }

  const selectedItemAddons = itemAddOns.filter(itemAddOn => (
    menuItem.get('menu_item_item_add_ons') && menuItem.get('menu_item_item_add_ons').some(selectedItemAddOn => (
      selectedItemAddOn.get('add_onable_id') === itemAddOn.get('uuid')
    ))
  ));

  const selectedServiceAddons = serviceAddOns.filter(serviceAddOn => (
    menuItem.get('menu_item_service_add_ons') && menuItem.get('menu_item_service_add_ons').some(selectedServiceAddOn => (
      selectedServiceAddOn.get('add_onable_id') === serviceAddOn.get('uuid')
    ))
  ));

  return (
    <Table>
      <thead>
        <HeaderRow>
          <Header key="position" width="50px" />
          <Header key="question" >Question</Header>
          <Header key="nickname" >Nickname</Header>
          <Header key="type" >Type</Header>
          <Header key="actions" />
        </HeaderRow>
      </thead>
      <TBody>
        {
          selectedItemAddons.size > 0 &&
            selectedItemAddons.map(customisation => (
              <Customisation
                customisation={customisation}
                type={TYPE_ITEM_ADD_ON}
                menuItemId={menuItem.get('uuid')}
              />
            ))
        }
        {
          selectedItemAddons.size === 0 &&
            <input
              type="hidden"
              name="menu_item[menu_item_item_add_ons_attributes][][item_add_on_id]"
              value=""
            />
        }
        {
          selectedServiceAddons.size > 0 &&
            selectedServiceAddons.map(customisation => (
              <Customisation
                customisation={customisation}
                type={TYPE_SERVICE_ADD_ON}
                menuItemId={menuItem.get('uuid')}
              />
            ))
        }
        {
          selectedServiceAddons.size === 0 &&
            <input
              type="hidden"
              name="menu_item[menu_item_service_add_ons_attributes][][service_add_on_id]"
              value=""
            />
        }
      </TBody>
    </Table>
  );
};

CustomisationsList.propTypes = {
  isItemAddOnsFetched: bool,
  isServiceAddOnsFetched: bool,
  itemAddOns: object,
  menuItem: object,
  serviceAddOns: object,
};

const mapStateToProps = ({
  $$itemAddOnsStore,
  $$menuItemsStore,
  $$serviceAddOnsStore,
}) => ({
  isItemAddOnsFetched: $$itemAddOnsStore.get('isItemAddOnsFetched'),
  isServiceAddOnsFetched: $$serviceAddOnsStore.get('isServiceAddOnsFetched'),
  itemAddOns: $$itemAddOnsStore.get('itemAddOns'),
  menuItem: $$menuItemsStore.get('menuItem'),
  serviceAddOns: $$serviceAddOnsStore.get('serviceAddOns'),
});

export default connect(mapStateToProps)(CustomisationsList);
