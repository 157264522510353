/* eslint max-len: 0 */
import React from 'react';

const SearchIcon = (_props) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M11 16.4681C14.0475 16.4681 16.5179 13.9977 16.5179 10.9502C16.5179 7.90272 14.0475 5.43226 11 5.43226C7.95253 5.43226 5.48206 7.90272 5.48206 10.9502C5.48206 13.9977 7.95253 16.4681 11 16.4681ZM11 18.9502C15.4183 18.9502 19 15.3685 19 10.9502C19 6.53192 15.4183 2.9502 11 2.9502C6.58172 2.9502 3 6.53192 3 10.9502C3 15.3685 6.58172 18.9502 11 18.9502Z" fill="#141415" />
    <path fillRule="evenodd" clipRule="evenodd" d="M19.0424 22.9777L14.3104 17.0626L16.2679 15.4966L21 21.4116L19.0424 22.9777Z" fill="#141415" />
  </svg>

);
export default SearchIcon;
