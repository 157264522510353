/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable quotes */
import React, { Fragment } from "react";
import { withAppContext } from 'libs/support/appContext';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OrderDetails from "bundles/v2/orders/components/OrderDetails/OrderDetails";
import { withQueryClient } from 'libs/support/withQueryClient';
import { IntlProvider } from 'react-intl';
import { translations } from 'libs/i18n/translations';
import { object, string } from 'prop-types';

import * as ordersActions from "../actions/ordersActions";

const OrderDetailsContainer = ({
  localeStore,
  orderId,
  isDraftOrder,
}) => {
  const locale = localeStore.get('locale');
  const messages = translations[locale];

  return (
    <Fragment>
      <IntlProvider locale={locale} key={locale} messages={messages}>
        <OrderDetails
          {
            ...{
              orderId,
              isDraftOrder,
            }
          }
        />
      </IntlProvider>
    </Fragment>
  );
};

const mapStateToProps = ({
  $$localeStore,
  $$outletSelectorStore,
}) => ({
  localeStore: $$localeStore,
  orderId: $$outletSelectorStore.get('orderId'),
  isDraftOrder: $$outletSelectorStore.get('isDraftOrder'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchOrders: ordersActions.fetchOrders,
}, dispatch);

OrderDetailsContainer.propTypes = {
  localeStore: object.isRequired,
  orderId: string.isRequired,
  isDraftOrder: string.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withAppContext(withQueryClient(OrderDetailsContainer)));
