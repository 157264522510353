import styled from 'styled-components';

export const Pill = styled.span`
  align-items: center;
  border-radius: 20px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  font-size: 14px;
  height: 32px;
  justify-content: center;
  padding: 0 1.5rem;
  top: 345px;
  text-transform: uppercase;
`;

export const PrimaryPill = styled(Pill)`
  background-color: ${props => props.theme.colors.snowFlurry};
  color: ${props => props.theme.colors.lima};
`;

export const SecondaryPill = styled(Pill)`
  background-color: ${props => props.theme.colors.gray200};
  color: ${props => props.theme.colors.greyChateau};
`;

export const AlertPill = styled(Pill)`
  background-color: ${props => props.theme.colors.lavenderBlush};
  color: ${props => props.theme.colors.mediumRedViolet};
`;
