/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Modal from 'libs/components/ModalComponent';
import ModalHeader from 'libs/components/ModalHeaderComponent';
import ModalBody from 'libs/components/ModalBodyComponent';
import ModalFooter from 'libs/components/ModalFooterComponent';
import {
  FieldGroup,
  InputField,
} from 'libs/components/Forms';
import { Spacer } from 'libs/support/sharedComponents';
import { SubmitButton } from 'bundles/common/components/Buttons';
import { SecondaryButton } from 'bundles/common/components/Buttons';
import { Creatable } from 'react-select';
import { customStyles } from 'bundles/v2/orders/utils/driverSelectCustomStyle';

const OrderAcceptModal = ({
  show,
  onHide,
  onSubmit,
  driver,
  translate,
  vendorDrivers,
}) => {
  const formId = 'accept-order-form';
  const SINGAPORE_NUMBER_PATTERN = '[8-9]{1}[0-9]{7}';
  const initialDriverPhoneNumber = driver ? driver.phoneNumber : '';
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [driverOption, setDriverOption] = useState(null);
  const [errors, setErrors] = useState('');

  const isValidSingaporeNumber = (number) => {
    const pattern = /^[89]\d{7}$/;
    return pattern.test(number);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = document.getElementById(formId);
    const params = new FormData(form);
    const driverId = params.get('driver_id');
    const driverPhoneNumber = params.get('driver_phone_number');
    if (!driverId) {
      setErrors('Please select driver');
      return;
    }
    if (driverPhoneNumber === '' || !isValidSingaporeNumber(driverPhoneNumber)) {
      setErrors('Please enter a valid Singapore mobile number');
      return;
    }
    onSubmit(params);
    onHide();
  };

  const handleSubmitWithoutDriver = () => {
    const params = new FormData();

    onSubmit(params);
    onHide();
  };

  const handleSaveButtonDisabled = () => {
    const form = document.getElementById(formId);

    setSubmitButtonDisabled(!form.checkValidity());
  };

  useEffect(() => handleSaveButtonDisabled(), []);

  const driversOptions = vendorDrivers
                          ?.map((d) => ({
                            value: d.uuid,
                            label: d.name,
                          }))
                          ?.sort((a, b) => a.label?.localeCompare(b.label, undefined, { sensitivity: 'base' }));

  const onSelectDriver = (selectedOption, actionMeta) => {
    const driverOnSelect = vendorDrivers.find((d) => d.uuid === selectedOption?.value);
    const phoneNumber = driverOnSelect?.phoneNumber || '';
    const driverNumberInput = document.getElementById('driver_phone_number');
    driverNumberInput.value = phoneNumber;
    if (phoneNumber === '' && actionMeta.action !== 'create-option') {
      driverNumberInput.disabled = true;
    } else {
      driverNumberInput.disabled = false;
    }
    setDriverOption(selectedOption);
    handleSaveButtonDisabled();
  };

  useEffect(() => {
    const selectedDriverOption = driversOptions?.find((option) => option.value === driver?.uuid);
    setDriverOption(selectedDriverOption);
  }, [vendorDrivers]);

  useEffect(() => {
    if (errors) {
      setTimeout(() => {
        const successAlert = document.getElementById('success-alert');
        if (successAlert) {
          successAlert.style.display = 'none';
        }
      }, 3000);
    }
  }, [errors]);

  return (
    <Modal
      show={show}
      className="modal-lg modal-decline-form"
      onHide={onHide}
    >
      <ModalHeader enableClose>
        <h5 className="modal-title">{translate('ordersShowDetailsAcceptModalHeader')}</h5>
      </ModalHeader>

      <ModalBody>
        <div className="modal-body-content container-fluid">
          <p>{translate('ordersShowDetailsAcceptModalBody')}</p>
          <Spacer />
          {errors && errors.length > 0 && (
            <div
              id="success-alert"
              className="tw-border-red tw-bg-red-light-6 tw-flex tw-w-full tw-rounded-lg tw-border-l-[6px] tw-px-7 tw-py-4 tw-shadow-[0px_2px_10px_0px_rgba(0,0,0,0.08)] md:tw-p-4"
            >
              <div
                className="tw-bg-red tw-mr-5 tw-flex tw-h-[34px] tw-w-full tw-max-w-[34px] tw-items-center tw-justify-center tw-rounded-lg"
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_961_15645)">
                    <path
                      d="M9 0.506256C4.30313 0.506256 0.50625 4.30313 0.50625 9.00001C0.50625 13.6969 4.30313 17.5219 9 17.5219C13.6969 17.5219 17.5219 13.6969 17.5219 9.00001C17.5219 4.30313 13.6969 0.506256 9 0.506256ZM9 16.2563C5.00625 16.2563 1.77188 12.9938 1.77188 9.00001C1.77188 5.00626 5.00625 1.77188 9 1.77188C12.9938 1.77188 16.2563 5.03438 16.2563 9.02813C16.2563 12.9938 12.9938 16.2563 9 16.2563Z"
                      fill="white"
                    />
                    <path
                      d="M11.5875 6.38438C11.3344 6.13125 10.9406 6.13125 10.6875 6.38438L9 8.1L7.28438 6.38438C7.03125 6.13125 6.6375 6.13125 6.38438 6.38438C6.13125 6.6375 6.13125 7.03125 6.38438 7.28438L8.1 9L6.38438 10.7156C6.13125 10.9688 6.13125 11.3625 6.38438 11.6156C6.49688 11.7281 6.66563 11.8125 6.83438 11.8125C7.00313 11.8125 7.17188 11.7563 7.28438 11.6156L9 9.9L10.7156 11.6156C10.8281 11.7281 10.9969 11.8125 11.1656 11.8125C11.3344 11.8125 11.5031 11.7563 11.6156 11.6156C11.8688 11.3625 11.8688 10.9688 11.6156 10.7156L9.9 9L11.6156 7.28438C11.8406 7.03125 11.8406 6.6375 11.5875 6.38438Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_961_15645">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="tw-w-full">
                <ul className="tw-list-inside tw-list-disc">
                  <li className="ttw-ext-red-light tw-text-base tw-leading-relaxed">
                    {errors}
                  </li>
                </ul>
              </div>
            </div>
          )}
          <form id={formId}>
            <FieldGroup
              label={translate('ordersShowDetailsAcceptModalDriverName')}
            >
              <Creatable
                isClearable
                options={driversOptions}
                name="driver_id"
                id="driver_id"
                styles={customStyles}
                onChange={onSelectDriver}
                value={driverOption}
              />

            </FieldGroup>

            <FieldGroup
              label={translate('ordersShowDetailsAcceptModalDriverPhoneNumber')}
            >
              <InputField
                placeholder={translate('ordersShowDetailsAcceptModalDriverPhoneNumberPlaceholder')}
                name="driver_phone_number"
                id="driver_phone_number"
                onChange={handleSaveButtonDisabled}
                value={initialDriverPhoneNumber}
                pattern={SINGAPORE_NUMBER_PATTERN}
                inputStyle={{ height: '37px' }}
                isRequired
              />
            </FieldGroup>
          </form>
        </div>
      </ModalBody>

      <ModalFooter>
        <SecondaryButton
          onClick={handleSubmitWithoutDriver}
          name={translate('ordersShowDetailsAcceptModalSkipButton')}
          form={formId}
          className="mr-1"
        />
        <SubmitButton
          onClick={handleSubmit}
          name={translate('ordersShowDetailsAcceptModalSaveButton')}
          form={formId}
          disabled={submitButtonDisabled}
        />
      </ModalFooter>
    </Modal>
  );
};

OrderAcceptModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  driver: PropTypes.object,
  translate: PropTypes.func.isRequired,
  vendorDrivers: PropTypes.array,
};

export default OrderAcceptModal;
