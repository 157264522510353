import React from 'react';
import { any } from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  height: 100%;
`;

const DisplayField = ({
  children,
}) => (
  <Container>
    { children }
  </Container>
);

DisplayField.propTypes = {
  children: any,
};

export default DisplayField;
