import React, { useEffect, useState } from 'react';
import { bool, number, oneOfType, string } from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

const Page = ({
  currentPage,
  label,
  isActive,
  isDisabled,
  page,
  className = '',
}) => {
  const [isClicked, setClick] = useState(false);
  const url = new URL(window.location);
  url.searchParams.set('page', page);
  const newClassName = classnames(className, {
    '--active': isActive,
  });
  const to = `${url.pathname}${url.search}`;
  const handleClick = () => {
    setClick(true);
  };
  useEffect(() => {
    setClick(false);
  }, [currentPage]);

  return (
    <Link
      className={newClassName}
      to={to}
      isDisabled={isDisabled}
      onClick={handleClick}
      disabled={isActive}
    >
      {
        isClicked ?
          <i className="fa fa-refresh fa-spin a-fw" /> :
          label
      }

    </Link>
  );
};

Page.propTypes = {
  currentPage: number,
  label: oneOfType([number, string]),
  isActive: bool,
  isDisabled: bool,
  page: number,
  className: string,
};

export default Page;
