/* eslint react/no-array-index-key: 0, max-len: 0 */

import React from 'react';
import { func, object, string } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as customisationsActions from 'bundles/vendor/actions/customisationsActions';
import snakeCase from 'lodash/snakeCase';
import { blankish } from 'libs/support/string';
import PhotoUpload from 'bundles/vendor/components/PhotoUploads';

const ItemAddOnOptionPhotoUpload = ({
  currentType,
  photo,
  optionId,
  onDropCustomisationOptionPhoto,
  onDeleteCustomisationOptionPhoto,
}) => {
  const snakeCaseType = snakeCase(currentType);

  return (
    <div>
      {
        photo &&
          <div
            key="photo-1"
          >
            <input
              type="hidden"
              name={`${snakeCaseType}[${snakeCaseType}_options_attributes][][photos][][id]`}
              value={photo.get('id') || 'new'}
            />
            {
              blankish(photo.get('id')) &&
                <input
                  type="hidden"
                  name={`${snakeCaseType}[${snakeCaseType}_options_attributes][][photos][][hash]`}
                  value={photo.get('hash')}
                />
            }
            <PhotoUpload
              isTextOnly
              details={{ index: 0, optionId }}
              photo={photo}
              preload
              onDrop={onDropCustomisationOptionPhoto}
              onDelete={onDeleteCustomisationOptionPhoto}
            />
          </div>
      }

      {
        !photo &&
          <div>
            <PhotoUpload
              isTextOnly
              multiple
              preload
              details={{ index: 0, optionId }}
              onDrop={onDropCustomisationOptionPhoto}
              onDelete={onDeleteCustomisationOptionPhoto}
            />
          </div>
      }
    </div>
  );
};

ItemAddOnOptionPhotoUpload.propTypes = {
  currentType: string,
  onDropCustomisationOptionPhoto: func.isRequired,
  onDeleteCustomisationOptionPhoto: func.isRequired,
  optionId: string,
  photo: object,
};

const mapStateToProps = ({
  $$customisationsStore,
}) => ({
  currentType: $$customisationsStore.get('currentType'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onDropCustomisationOptionPhoto: customisationsActions.addTemporaryCustomisationOptionPhoto,
  onDeleteCustomisationOptionPhoto: customisationsActions.deleteCustomisationOptionPhoto,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ItemAddOnOptionPhotoUpload);
