/* eslint no-return-assign: 0 */

import React, { useState } from 'react';
import { func, number, object } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import uppercamelcase from 'uppercamelcase';
import moment from 'moment';
import {
  StripedRow,
  Cell,
  CenteredCell,
} from 'libs/support/sharedComponents';
import {
  InputField,
  SelectField,
  DateTimeField,
} from 'libs/components/Forms';
import {
  DeleteButton,
} from 'bundles/common/components/Buttons';
import { notBlank } from 'libs/support/string';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as categoriesActions from 'bundles/vendor/actions/categoriesActions';
import { useSelectInput } from 'libs/support/selectInput';

export const AVAILABILITIES = [
  'available',
  'unavailable',
];

const year = (new Date()).getFullYear();

const CategoryAvailabilityFields = ({
  index,
  categoryAvailability,
  onDelete,
  translate,
}) => {
  const style = { display: `${notBlank(categoryAvailability.get('destroy')) ? 'none' : 'table-row'}` };
  const tempId = categoryAvailability.get('tempId');
  const handleDelete = () => (
    onDelete && onDelete(tempId)
  );
  const availabilityOptions = AVAILABILITIES.map(availability => (
    {
      label: translate(`vendorCategoryAvailabilities${uppercamelcase(availability)}`),
      value: availability,
    }
  ));
  const [selectedAvailabilityOption, handleAvailabilityChange] = useSelectInput(
    availabilityOptions,
    categoryAvailability.get('availability_type'),
  );

  const [startDate, setStartDate]
    = useState(categoryAvailability.get('start_date') && moment(categoryAvailability.get('start_date')));
  const [endDate, setEndDate]
    = useState(categoryAvailability.get('end_date') && moment(categoryAvailability.get('end_date')));

  const isValidStartDate = (current) => {
    if (endDate) {
      return current.isSameOrBefore(endDate);
    }

    return true;
  };

  const isValidEndDate = (current) => {
    if (startDate) {
      return current.isSameOrAfter(startDate);
    }

    return true;
  };

  return (
    <StripedRow style={style}>
      <Cell>
        <InputField
          id={`category_category_availabilities_${index}_id`}
          name="category[category_availabilities_attributes][][id]"
          value={categoryAvailability.get('uuid')}
          type="hidden"
        />
        <InputField
          id={`category_category_availabilities_${index}__destroy`}
          name="category[category_availabilities_attributes][][_destroy]"
          value={categoryAvailability.get('destroy')}
          type="hidden"
        />
        <DateTimeField
          id={`category_category_availabilities_${index}_start_date`}
          name="category[category_availabilities_attributes][][start_date]"
          initialValue={startDate}
          placeholder={`01 Jan ${year}`}
          isRequired
          autoComplete="off"
          onChange={setStartDate}
          isValidDate={isValidStartDate}
        />
      </Cell>
      <Cell>
        <DateTimeField
          id={`category_category_availabilities_${index}_end_date`}
          name="category[category_availabilities_attributes][][end_date]"
          initialValue={endDate}
          placeholder={`01 Jan ${year}`}
          isRequired
          autoComplete="off"
          onChange={setEndDate}
          isValidDate={isValidEndDate}
        />
      </Cell>
      <Cell >
        <SelectField
          id={`category_category_availabilities_${index}_availability_type`}
          name="category[category_availabilities_attributes][][availability_type]"
          value={[selectedAvailabilityOption]}
          options={availabilityOptions}
          onChange={handleAvailabilityChange}
          isRequired
        />
      </Cell>
      <CenteredCell>
        <DeleteButton
          onClick={handleDelete}
        />
      </CenteredCell>
    </StripedRow>
  );
};

CategoryAvailabilityFields.propTypes = {
  index: number,
  categoryAvailability: object,
  onDelete: func.isRequired,
  translate: func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onDelete: categoriesActions.deleteCategoryAvailability,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withI18n(CategoryAvailabilityFields));
