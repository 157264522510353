export const FETCHING_CATEGORY = 'FETCHING_CATEGORY';
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS';
export const FETCH_CATEGORY_FAILURE = 'FETCH_CATEGORY_FAILURE';

export const FETCHING_CATEGORIES = 'FETCHING_CATEGORIES';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';

export const SAVING_CATEGORY = 'SAVING_CATEGORY';
export const SAVE_CATEGORY_SUCCESS = 'SAVE_CATEGORY_SUCCESS';
export const SAVE_CATEGORY_FAILURE = 'SAVE_CATEGORY_FAILURE';

export const TOGGLE_CATEGORY_ACTIVE_FAILURE = 'TOGGLE_CATEGORY_ACTIVE_FAILURE';
export const TOGGLE_CATEGORY_ACTIVE_SUCCESS = 'TOGGLE_CATEGORY_ACTIVE_SUCCESS';
export const TOGGLING_CATEGORY_ACTIVE = 'TOGGLING_CATEGORY_ACTIVE';

export const REMOVE_NOTICE_FLASH = 'REMOVE_NOTICE_FLASH';
export const RESET_FLASH = 'RESET_FLASH';

export const CREATE_EMPTY_CATEGORY = 'CREATE_EMPTY_CATEGORY';

export const DELETING_CATEGORY = 'DELETING_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE';

export const SAVING_CATEGORIES = 'SAVING_CATEGORIES';
export const SAVE_CATEGORIES_SUCCESS = 'SAVE_CATEGORIES_SUCCESS';
export const SAVE_CATEGORIES_FAILURE = 'SAVE_CATEGORIES_FAILURE';

export const ADD_CATEGORY_AVAILABILITY = 'ADD_CATEGORY_AVAILABILITY';
export const DELETE_CATEGORY_AVAILABILITY = 'DELETE_CATEGORY_AVAILABILITY';
