/* eslint react/no-array-index-key: 0 */

import React, { useState } from 'react';
import { func, object } from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { withI18n } from 'libs/support/i18n';
import { SecondaryButton } from 'bundles/common/components/Buttons';
import {
  FieldGroup,
} from 'libs/components/Forms';
import uppercamelcase from 'uppercamelcase';
import {
  INCLUDED_ITEMS_HEADERS,
} from 'bundles/vendor/constants/menuItemsConstants';
import {
  Table,
  HeaderRow,
  Header,
  TBody,
  ScrollableShadow,
  ScrollableShadowContainer,
  OptionalFont,
} from 'libs/support/sharedComponents';
import IncludedItem from './IncludedItem';

const TableContainer = styled.div`
  overflow-x: auto;
`;

const ResponsiveScrollableShadow = styled(ScrollableShadow)`
  @media (min-width: ${props => props.theme.breakPoints.md}) {
    opacity: 0;
  }
`;

const Optional = styled(OptionalFont)`
  margin-left: 0.3rem;
  text-transform: none;
`;

const IncludedItemsFieldGroup = ({
  menuItem,
  translate,
}) => {
  const menuItemIncludedItems = menuItem.get('included_items');
  const defaultIncludedItems = () => {
    if (menuItemIncludedItems.size === 0) {
      return menuItemIncludedItems.push(Immutable.fromJS({}));
    }

    return menuItemIncludedItems;
  };
  const [includedItems, setIncludeItems] = useState(defaultIncludedItems());
  const handleAddIncludedItem = () => {
    setIncludeItems(includedItems.push(Immutable.fromJS({})));
  };


  return (
    <FieldGroup
      label="What's included"
      hintText="This section allows you to give a detailed breakdown of what is included in the item, set or package."
      optionalText="optional"
      className="included_items_field_group"
    >
      <ScrollableShadowContainer>
        <TableContainer>
          <Table>
            <thead>
              <HeaderRow>
                {
                  INCLUDED_ITEMS_HEADERS.map((header, index) => (
                    <Header
                      key={`${header.key}-${index}`}
                      style={{ minWidth: header.width }}
                    >
                      { translate(`vendor${uppercamelcase(header.key)}`) }
                      { header.isOptional && <Optional>(Optional)</Optional> }
                    </Header>
                  ))
                }
              </HeaderRow>
            </thead>
            <TBody>
              {
                includedItems.map((includedItem, index) => (
                  <IncludedItem
                    key={`included-item-${index}`}
                    includedItem={includedItem}
                    index={index}
                  />
                ))
              }
            </TBody>
          </Table>
        </TableContainer>
        <ResponsiveScrollableShadow />
      </ScrollableShadowContainer>
      <SecondaryButton
        name="Add Another"
        onClick={handleAddIncludedItem}
      />
    </FieldGroup>
  );
};

IncludedItemsFieldGroup.propTypes = {
  menuItem: object,
  translate: func.isRequired,
};

const mapStateToProps = (state) => ({
  menuItem: state.$$menuItemsStore.get('menuItem'),
});

export default connect(mapStateToProps)(withI18n(IncludedItemsFieldGroup));
