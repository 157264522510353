import React from 'react';
import LargeBasket from 'libs/components/Icons/v2/LargeBasket';
import { withI18n } from 'libs/support/i18n';
import { object, func, array, number, string } from 'prop-types';
import { withAppContext } from 'libs/support/appContext';

import BasketBody from './BasketBody';
import KeepSampleMessageBanner from './KeepSampleMessageBanner';

const OrderCarts = ({
  order,
  totalQuantity,
  orderableType,
  orderableVariations,
  translate,
  customMenuItems,
  flagIsEnabled,
}) => (
  <div className="order-details-basket-box order-details-basket-box-carts-desktop">
    <div className="order-details-basket-box-header tw-justify-between tw-gap-8">
      <div className="tw-flex tw-items-center">
        <LargeBasket />
        <span className="order-details-basket-text-header">{translate('ordersShowDetailsOrderCartsBasket')}</span>
      </div>
      { flagIsEnabled('food_safety_enabled') && order.keepSampleUntil &&
        <KeepSampleMessageBanner order={order} />
      }
    </div>
    <div className="basket-header">
      <span className="basket-header-qty">Qty</span>
      <span className="basket-header-item">Item</span>
      { order.groupOrder &&
        <span className="basket-header-label">Label</span>
      }
      <span className="basket-header-price">Price</span>
    </div>
    <div className="basket-body">
      <BasketBody
        {
            ...{
              order,
              orderableType,
              orderableVariations,
              customMenuItems,
              totalQuantity,
            }
          }
      />
    </div>
  </div>
);

OrderCarts.propTypes = {
  order: object,
  totalQuantity: number,
  orderableType: string,
  orderableVariations: array,
  translate: func,
  customMenuItems: array,
  flagIsEnabled: func,
};

export default withAppContext(withI18n(OrderCarts));
