/* eslint camelcase: 0 */

import Immutable from 'immutable';
import snakeCase from 'lodash/snakeCase';
import { notBlank } from 'libs/support/string';
import * as constants from '../constants/customisationsConstants';

export const $$initialState = Immutable.fromJS({
  isShowForm: false,
  isFormSaving: false,
  isShowModal: false,
  currentType: constants.TYPE_ITEM_ADD_ON,
  selections: {
    itemAddOns: [],
    serviceAddOns: [],
  },
  customisation: {},
  flash: Immutable.fromJS({}),
  keyword: '',
});

export default function customisationsReducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  switch (type) {
    case constants.OPEN_CUSTOMISATIONS_MODAL: {
      return $$state.withMutations(state => (
        state
          .set('isShowModal', true)
      ));
    }

    case constants.CLOSE_CUSTOMISATIONS_MODAL: {
      return $$state.withMutations(state => (
        state
          .set('isShowModal', false)
          .set('isShowForm', false)
          .set('customisation', Immutable.fromJS({}))
          .set('currentType', constants.TYPE_ITEM_ADD_ON)
      ));
    }

    case constants.CHANGE_CURRENT_TYPE: {
      return $$state.withMutations(state => (
        state
          .set('currentType', response)
      ));
    }

    case constants.EDIT_CUSTOMISATION: {
      return $$state.withMutations(state => (
        state
          .set('currentType', response.type)
          .set('isShowModal', true)
          .set('isShowForm', true)
          .set('flash', Immutable.fromJS({}))
          .set('customisation', response.customisation)
      ));
    }

    case constants.NEW_CUSTOMISATION: {
      return $$state.withMutations(state => (
        state
          .set('isShowForm', true)
          .set('flash', Immutable.fromJS({}))
          .set('customisation', Immutable.fromJS({
            photos: [],
            [`${snakeCase(response)}_options`]: [],
          }))
      ));
    }

    case constants.CLOSE_CUSTOMISATION: {
      return $$state.withMutations(state => (
        state
          .set('isShowForm', false)
          .set('flash', Immutable.fromJS({}))
      ));
    }

    case constants.SAVING_ITEM_ADD_ON: {
      return $$state.withMutations(state => (
        state
          .set('isFormSaving', true)
          .set('flash', Immutable.fromJS({}))
      ));
    }

    case constants.SAVE_ITEM_ADD_ON_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .set('isFormSaving', false)
          .set('customisation', Immutable.fromJS(response.item_add_on))
          .set('flash', Immutable.fromJS(response.flash))
      ));
    }

    case constants.SAVE_ITEM_ADD_ON_FAILURE: {
      return $$state.withMutations(state => (
        state
          .set('isFormSaving', false)
          .set('flash', Immutable.fromJS(response.flash))
      ));
    }

    case constants.DELETE_ITEM_ADD_ON_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .set('isFormSaving', false)
          .set('isShowForm', false)
          .set('customisation', Immutable.fromJS({}))
          .set('flash', Immutable.fromJS(response.flash))
      ));
    }

    case constants.DELETE_ITEM_ADD_ON_FAILURE: {
      return $$state.withMutations(state => (
        state
          .set('isFormSaving', false)
          .set('flash', Immutable.fromJS(response.errors))
      ));
    }

    case constants.SAVING_SERVICE_ADD_ON: {
      return $$state.withMutations(state => (
        state
          .set('isFormSaving', true)
          .set('flash', Immutable.fromJS({}))
      ));
    }

    case constants.SAVE_SERVICE_ADD_ON_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .set('isFormSaving', false)
          .set('customisation', Immutable.fromJS(response.service_add_on))
          .set('flash', Immutable.fromJS(response.flash))
      ));
    }

    case constants.SAVE_SERVICE_ADD_ON_FAILURE: {
      return $$state.withMutations(state => (
        state
          .set('isFormSaving', false)
          .set('flash', Immutable.fromJS(response.flash))
      ));
    }

    case constants.DELETE_SERVICE_ADD_ON_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .set('isFormSaving', false)
          .set('isShowForm', false)
          .set('customisation', Immutable.fromJS({}))
          .set('flash', Immutable.fromJS(response.flash))
      ));
    }

    case constants.DELETE_SERVICE_ADD_ON_FAILURE: {
      return $$state.withMutations(state => (
        state
          .set('isFormSaving', false)
          .set('flash', Immutable.fromJS(response.errors))
      ));
    }

    case constants.REMOVE_NOTICE_FLASH: {
      return $$state.withMutations(state => (
        state
          .setIn(['flash', 'notice'], '')
      ));
    }

    case constants.ADD_TEMPORARY_CUSTOMISATION_PHOTO: {
      const { params } = response;

      return $$state.withMutations(state => (
        state
          .updateIn(['customisation', 'photos'], photos => {
            let newPhotos = photos;
            params.forEach((param) => {
              const { file, hash, index } = param;
              const data = Immutable.fromJS({
                id: null,
                thumb: file.preview,
                temporaryDetails: file,
                hash,
              });

              if (newPhotos.get(index)) {
                newPhotos = newPhotos.update(index, photo => photo.merge(data));
              } else {
                newPhotos = newPhotos.push(data);
              }
            });

            return newPhotos;
          })
      ));
    }

    case constants.DELETE_CUSTOMISATION_PHOTO: {
      return $$state.withMutations(state => (
        state.setIn(['customisation', 'photos'], Immutable.fromJS([]))
      ));
    }

    case constants.ADD_TEMPORARY_CUSTOMISATION_OPTION_PHOTO: {
      const param = response.params[0];
      const {
        file,
        hash,
        index,
        tempId,
      } = param;
      const data = Immutable.fromJS({
        id: null,
        thumb: file.preview,
        temporaryDetails: file,
        hash,
      });

      return $$state.withMutations(state => (
        state
          .updateIn(['customisation', 'item_add_on_options'], itemAddOnOptions => {
            let itemAddOnOptionIndex = -1;

            if (tempId && tempId === 'new') {
              itemAddOnOptionIndex = itemAddOnOptions.size - 1;
            } else {
              itemAddOnOptionIndex = itemAddOnOptions.findIndex(d => (
                d.get('tempId') === param.optionId ||
                  d.get('uuid') === param.optionId
              ));
            }


            if (itemAddOnOptionIndex >= 0) {
              return itemAddOnOptions.updateIn([itemAddOnOptionIndex, 'photos'], photos => {
                let newPhotos = photos;

                if (newPhotos.get(index)) {
                  newPhotos = newPhotos.update(index, photo => photo.merge(data));
                } else {
                  newPhotos = newPhotos.push(data);
                }

                return newPhotos;
              });
            }

            const newData = data.set('tempId', 'new');

            return itemAddOnOptions.push(Immutable.fromJS({
              photos: [newData],
            }));
          })
      ));
    }

    case constants.DELETE_CUSTOMISATION_OPTION_PHOTO: {
      const { optionId } = response.details;

      return $$state.withMutations(state => (
        state
          .updateIn(['customisation', 'item_add_on_options'], itemAddOnOptions => {
            const itemAddOnOptionIndex = itemAddOnOptions.findIndex(d => (
              d.get('uuid') === optionId || d.get('tempId') === optionId
            ));

            if (itemAddOnOptionIndex >= 0) {
              return itemAddOnOptions.setIn([itemAddOnOptionIndex, 'photos'], Immutable.fromJS([]));
            }

            return itemAddOnOptions;
          })
      ));
    }

    case constants.ADD_CUSTOMISATION_OPTION: {
      return $$state.withMutations(state => (
        state
          .updateIn(['customisation', 'item_add_on_options'], itemAddOnOptions => (
            itemAddOnOptions.push(Immutable.fromJS({
              tempId: (new Date()).getTime(),
              photos: [],
            }))
          ))
      ));
    }

    case constants.DELETE_CUSTOMISATION_OPTION: {
      return $$state.withMutations(state => (
        state
          .updateIn(['customisation', 'item_add_on_options'], itemAddOnOptions => {
            const index = itemAddOnOptions.findIndex(option => (
              option.get('uuid') === response || option.get('tempId') === response
            ));

            if (notBlank(itemAddOnOptions.getIn([index, 'uuid']))) {
              return itemAddOnOptions.setIn([index, 'deleted_at'], new Date());
            }

            return itemAddOnOptions.delete(index);
          })
      ));
    }

    case constants.CLONE_ITEM_ADD_ON_SUCCESS:
    case constants.CLONE_SERVICE_ADD_ON_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .set('flash', Immutable.fromJS(response.flash))
      ));
    }

    case constants.SET_CUSTOMISATIONS_KEYWORD: {
      return $$state.withMutations(state => (
        state
          .set('keyword', response)
      ));
    }

    default: {
      return $$state;
    }
  }
}
