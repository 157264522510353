import React, { useState } from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { StoreIcon } from 'libs/components/Icons';
import VendorSelection from './VendorSelection';
import OutletSelection from './OutletSelection';

const mapStatesToProps = (state) => {
  const currentOutlet = state.$$vendorsStore.get('currentOutlet');
  const vendors = state.$$vendorsStore.get('vendors');

  return {
    currentOutlet,
    vendors,
  };
};

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  position: relative;
  width: 100%;

  @media (min-width: ${props => props.theme.breakPoints.md}) {
    align-items: flex-end;
    flex-direction: row;
  }
`;

const IconContainer = styled.div`
  display: none;
  margin-bottom: 0.27rem;
  margin-right: 0.5rem;

  @media (min-width: ${props => props.theme.breakPoints.md}) {
    display: flex;
  }
`;

const OutletNavigation = ({
  currentOutlet,
  vendors,
}) => {
  const matchingVendor = vendors.find((v) => v.get('uuid') === currentOutlet.get('vendorId'));
  const [selectedVendor, setVendor] = useState(matchingVendor);
  const matchingOutlet = selectedVendor &&
    selectedVendor.get('outlets').find((o) => o.get('uuid') === currentOutlet.get('uuid'));
  const [selectedOutlet, setOutlet] = useState(matchingOutlet);
  const handleSelectVendor = (vendor) => {
    setVendor(vendor);
  };
  const handleSelectOutlet = (outlet) => {
    setOutlet(outlet);
    window.location = `/outlets/${outlet.get('uuid')}`;
  };

  if (!selectedVendor) return null;

  return (
    <Container className="outlet-navigation">
      <IconContainer>
        <StoreIcon />
      </IconContainer>
      <VendorSelection
        onSelectVendor={handleSelectVendor}
        {
          ...{
            selectedVendor,
          }
        }
      />
      <OutletSelection
        onSelectOutlet={handleSelectOutlet}
        {
          ...{
            selectedOutlet,
            selectedVendor,
          }
        }
      />
    </Container>
  );
};

OutletNavigation.propTypes = {
  currentOutlet: object.isRequired,
  vendors: object.isRequired,
};

export default connect(mapStatesToProps)(OutletNavigation);
