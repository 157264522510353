/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable quotes */
import React, { useState } from "react";
import { bool, func, object, string } from 'prop-types';
import Selector from "libs/components/Icons/v2/Selector";
import LocationPin from "libs/components/Icons/v2/LocationPin";
import { withAppContext } from 'libs/support/appContext';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as outletSelectorActions from "../actions/outletSelectorActions";
import OutletSelectorModal from "../components/OutletSelectorModal";

const reloadEnableControllers = [
  'menus',
  'billings',
];

const OutletSelectorContainer = ({
  selectOutlets,
  outlets,
  vendors,
  outletsSelectedIds,
  vendorSelected,
  mobile,
  controllerName,
}) => {
  const [isShowOutletSelectorModal, setIsShowOutletSelectorModal] = useState(false);

  const onOpenOutletSelectorModal = () => {
    setIsShowOutletSelectorModal(true);
    document.querySelector('footer').style.display = 'none';
  };

  const onSaveOutletsSelected = async (params) => {
    await selectOutlets(params);
    if (reloadEnableControllers.includes(controllerName)) {
      window.location.reload();
    }
  };

  const displayName = (name) => {
    if (name.length > 40) {
      return `${name.substring(0, 39)}...`;
    }
    return name;
  };

  return (
    <>
      { mobile ? (
        <div className="card">
          <div className="card-block">
            <div
              className="outlet-selector-container"
              onClick={() => onOpenOutletSelectorModal()}
            >
              <div className="icons-group d-flex align-items-center">
                <LocationPin />
                <div className="text-group">
                  <span className="text-vendor"> {displayName(vendorSelected?.getIn(['table', 'name']))}</span>
                  <span className="text-outlets">{outletsSelectedIds.size}  Outlets </span>
                </div>
              </div>
              <Selector />
            </div>
          </div>
        </div>
      ) :
        <div className="outlet-selector-container">
          <div className="card">
            <div className="card-block" style={{ padding: '0px' }}>
              <div
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }}
                onClick={() => onOpenOutletSelectorModal()}
              >
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                  <LocationPin />
                  <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }} >
                    <span style={{ color: '#95989D', fontSize: '14px' }}>
                      {displayName(vendorSelected?.getIn(['table', 'name']))}
                    </span>
                    <span>{outletsSelectedIds.size} Outlets </span>
                  </div>
                </div>
                <Selector />
              </div>
            </div>
          </div>
        </div>
      }
      <OutletSelectorModal
        {
          ...{
            isShowOutletSelectorModal,
            setIsShowOutletSelectorModal,
            outlets,
            vendors,
            mobile,
            outletsSelectedIds,
            vendorSelected,
            onSaveOutletsSelected,
          }
        }
      />
    </>
  );
};

const mapStateToProps = ({
  $$outletSelectorStore,
}) => ({
  outletsSelected: $$outletSelectorStore.get('outletsSelected'),
  outletsSelectedIds: $$outletSelectorStore.get('outletsSelectedIds'),
  outlets: $$outletSelectorStore.get('outlets'),
  vendors: $$outletSelectorStore.get('vendors'),
  vendorSelected: $$outletSelectorStore.get('vendorSelected'),
  controllerName: $$outletSelectorStore.get('controllerName'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  actions: outletSelectorActions,
  selectOutlets: outletSelectorActions.selectOutlets,
}, dispatch);

OutletSelectorContainer.propTypes = {
  selectOutlets: func,
  outlets: object,
  vendors: object,
  outletsSelectedIds: object,
  vendorSelected: object,
  mobile: bool,
  controllerName: string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withAppContext(OutletSelectorContainer));
