/* eslint no-alert: 0 */

import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import styled from 'styled-components';
import {
  Link,
  EditButton,
} from 'bundles/common/components/Buttons';
import {
  StripedRow,
  Cell,
  Centered,
  CenteredCell,
} from 'libs/support/sharedComponents';
import { CircleCheckMarkIcon } from 'libs/components/Icons';
import { CheckBox, DropdownActions } from 'libs/components/Forms';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as customisationsActions from 'bundles/vendor/actions/customisationsActions';
import CloneCustomisationAction from './CloneCustomisationAction';
import DeleteCustomisationAction from './DeleteCustomisationAction';

const ActionsCell = styled(CenteredCell)`
  button:not(first-child) {
    margin-left: 0.5rem;
  }
`;

const Customisation = ({
  currentMenuItemId,
  currentType,
  customisation,
  editCustomisation,
  id,
  isSelected,
  name,
  question,
}) => {
  const value = customisation.get('uuid');
  const [isChecked, setCheck] = useState(isSelected);
  const handleOnChange = () => setCheck(!isChecked);
  const handleOnShow = (e) => {
    e && e.preventDefault(); /* eslint no-unused-expressions: 0 */

    editCustomisation(currentType, customisation);
  };

  const otherMenuItemIds = customisation.get('menu_item_ids').filter(menuItemId => (menuItemId !== currentMenuItemId));

  return (
    <StripedRow>
      <Cell>
        <CheckBox
          onChange={handleOnChange}
          checked={isChecked}
          {
            ...{
              id,
              name,
              value,
            }
          }
        />
      </Cell>
      <Cell>
        <Link
          href="#"
          name={question}
          onClick={handleOnShow}
        />
      </Cell>
      <Cell>
        {customisation.get('nickname')}
      </Cell>
      <Cell>
        <Centered>
          { otherMenuItemIds.size > 0 && <CircleCheckMarkIcon /> }
        </Centered>
      </Cell>
      <ActionsCell>
        <EditButton
          onClick={handleOnShow}
        />
        <DropdownActions>
          <DeleteCustomisationAction
            uuid={customisation.get('uuid')}
          />
          <CloneCustomisationAction
            uuid={customisation.get('uuid')}
          />
        </DropdownActions>
      </ActionsCell>
    </StripedRow>
  );
};

Customisation.propTypes = {
  currentMenuItemId: string,
  currentType: string,
  editCustomisation: func.isRequired,
  customisation: object,
  id: string,
  isSelected: bool,
  name: string,
  question: string.isRequired,
};

const mapStateToProps = ({
  $$customisationsStore,
  $$menuItemsStore,
}) => ({
  currentMenuItemId: $$menuItemsStore.getIn(['menuItem', 'uuid']),
  currentType: $$customisationsStore.get('currentType'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  editCustomisation: customisationsActions.editCustomisation,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Customisation);
