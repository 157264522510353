import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ImageModal from 'bundles/common/components/Images/ImageModal';
import * as imageActions from 'bundles/common/actions/imageActions';

const ImageModalContainer = ({
  details,
  isShow,
  photo,
  onCrop,
  onHide,
  title,
}) => (
  <ImageModal
    {
      ...{
        details,
        isShow,
        photo,
        onCrop,
        onHide,
        title,
      }
    }
  />
);

ImageModalContainer.propTypes = ({
  details: object,
  isShow: bool.isRequired,
  photo: object,
  onCrop: func,
  onHide: func,
  title: string,
});

const mapStateToProps = ({
  $$imageStore,
}) => ({
  details: $$imageStore.getIn(['imageModal', 'details']),
  isShow: $$imageStore.getIn(['imageModal', 'isShow']),
  photo: $$imageStore.getIn(['imageModal', 'photo']),
  title: $$imageStore.getIn(['imageModal', 'title']),
  onCrop: $$imageStore.getIn(['imageModal', 'onCrop']),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onHide: imageActions.hideImageModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ImageModalContainer);
