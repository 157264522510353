/* eslint max-len: 0, no-global-assign: 0, no-restricted-globals: 0 */

import React from 'react';
import { array, bool, object } from 'prop-types';
import Sheet from '@caterspot/react-label-sheet';

/**
 * LeftPanel
 */
const LeftPanel = ({
  labels,
  template,
  isGroupOrder,
}) => {
  const { settings } = template;
  const getLabelTag = isGroupOrder ? 'meal' : 'planned_meal';
  return (
    <div className="container">
      <Sheet
        labels={labels}
        editable={false}
        labelTag={getLabelTag}
        setting={{
          meal: {
            labelPerRow: settings.label_per_row,
            labelPerCol: settings.label_per_col,
          },
          page: settings.page_size,
          labelHeight: settings.label_height,
          labelWidth: settings.label_width,
          labelPaddingRight: settings.label_margin_left_right,
          labelPaddingLeft: settings.label_margin_left_right,
          labelPaddingTop: settings.label_margin_top_bottom,
          labelPaddingBottom: settings.label_margin_top_bottom,
          sheetPaddingLeft: settings.sheet_margin_left_right,
          sheetPaddingRight: settings.sheet_margin_left_right,
          sheetPaddingTop: settings.sheet_margin_top_bottom,
          sheetPaddingBottom: settings.sheet_margin_top_bottom,
        }}
      />
    </div>
  );
};

LeftPanel.propTypes = {
  labels: array.isRequired,
  template: object,
  isGroupOrder: bool,
};

export default LeftPanel;
