import * as api from 'libs/middlewares/api';

import * as actionTypes from '../constants/vendorBillingConstants';

const apiUrl = '/billings/vendor_billings';

export const findVendorBilling = () => {
  const {
    FINDING_VENDOR_BILLING,
    FIND_VENDOR_BILLING_SUCCESS,
    FIND_VENDOR_BILLING_FAILURE,
  } = actionTypes;

  return api.get({
    types: [
      FINDING_VENDOR_BILLING,
      FIND_VENDOR_BILLING_SUCCESS,
      FIND_VENDOR_BILLING_FAILURE,
    ],
    endpoint: apiUrl,
    params: null,
    id: null,
  });
};

export const saveVendorBilling = (forceUpdate, params) => {
  const {
    SAVING_VENDOR_BILLING,
    SAVE_VENDOR_BILLING_SUCCESS,
    SAVE_VENDOR_BILLING_FAILURE,
  } = actionTypes;

  return api.save({
    types: [
      SAVING_VENDOR_BILLING,
      SAVE_VENDOR_BILLING_SUCCESS,
      SAVE_VENDOR_BILLING_FAILURE,
    ],
    endpoint: apiUrl,
    params,
    forceUpdate,
  });
};

export const showOutletBillingDetailModal = (outlet, outletBillingDetail) => (
  { type: actionTypes.SHOW_OUTLET_BILLING_DETAIL_MODAL, response: { outlet, outletBillingDetail } }
);

export const hideOutletBillingDetailModal = () => (
  { type: actionTypes.HIDE_OUTLET_BILLING_DETAIL_MODAL }
);

export const saveOutletBillingDetail = (outletId, params) => (
  { type: actionTypes.SAVE_OUTLET_BILLING_DETAIL, response: { outletId, params } }
);

export const removeNoticeFlash = () => (
  { type: actionTypes.REMOVE_NOTICE_FLASH }
);

export const resetFlash = () => (
  { type: actionTypes.RESET_FLASH }
);
