import React, { Fragment } from 'react';
import { bool, func, object } from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';
import { blankish, notBlank } from 'libs/support/string';
import { CloseIcon } from 'libs/components/Icons';

const Container = styled.div`
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;

  &.--sticky {
    position: sticky;
    top: 60px;
    z-index: 100;
  }

  &.--notice {
    background-color: ${props => props.theme.colors.riceFlower};
    border: 1px solid ${props => props.theme.colors.tusk};
  }

  &.--error {
    background-color: ${props => props.theme.colors.tutu};
    border: 1px solid ${props => props.theme.colors.beautyBush};
  }
`;

const Flash = ({
  flash,
  isSticky,
  removeNoticeFlash,
}) => {
  if (flash.size === 0 || (blankish(flash.get('notice')) && blankish(flash.get('errors')))) {
    return null;
  }

  const className = classnames('flash', {
    '--error': notBlank(flash.get('errors')),
    '--notice': notBlank(flash.get('notice')),
    '--sticky': isSticky,
  });

  const handleNoticeClick = () => (
    removeNoticeFlash && removeNoticeFlash()
  );

  return (
    <Fragment>
      <Container
        className={className}
        onClick={handleNoticeClick}
      >
        { flash.get('notice') }

        {
          notBlank(flash.get('errors')) &&
            flash.get('errors').map(error => (
              <div>{ error }</div>
            ))
        }

        { removeNoticeFlash && <CloseIcon /> }
      </Container>
    </Fragment>
  );
};

Flash.propTypes = {
  flash: object,
  isSticky: bool,
  removeNoticeFlash: func,
};

export default Flash;
