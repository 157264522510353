/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable quotes */
import React, { Fragment } from "react";
import { array, bool, func } from "prop-types";
import { withI18n } from 'libs/support/i18n';
import { trackClickedLoadMoreOrders } from "packs/events";
import OrderCard from "./OrderCard";
import MobileOrderListSkeleton from "./MobileOrderListSkeleton";

const MobileOrderList = ({
  mobileOrders,
  isFetching,
  redirectToOrderDetails,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
}) => {
  const handleOnClick = () => {
    trackClickedLoadMoreOrders();
    fetchNextPage();
  };

  return (
    <Fragment>
      {isFetching ? (
        <Fragment>
          {[...Array(3)].map((_, i) => (
            <MobileOrderListSkeleton key={i} />
          ))}
        </Fragment>
      ) :
        <Fragment>
          {mobileOrders?.map((orderGroup, groupIndex) => (
            <Fragment key={groupIndex}>
              {orderGroup?.orders?.map((order) => (
                <OrderCard
                  onClickCard={redirectToOrderDetails}
                  key={order.uuid}
                  {...{
                    order,
                    full: true,
                  }}
                />
              ))}
            </Fragment>
          ))}
        </Fragment>
      }
      <span
        className="load-more-button"
        onClick={handleOnClick}
      >
        { isFetchingNextPage ? <span className="loader" /> : ''}
        { !isFetchingNextPage && hasNextPage ? 'Load More' : ''}
      </span>
    </Fragment>
  );
};

MobileOrderList.propTypes = {
  mobileOrders: array,
  isFetching: bool,
  redirectToOrderDetails: func,
  hasNextPage: bool,
  isFetchingNextPage: bool,
  fetchNextPage: func,
};

export default withI18n(MobileOrderList);
