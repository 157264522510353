/* eslint max-len: 0 */

import React from 'react';

const ChevronLeft = (_props) => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M15.5335 6.3506C15.8682 6.68533 15.8682 7.22805 15.5335 7.56278L10.9967 12.0995L15.5335 16.6363C15.8682 16.971 15.8682 17.5138 15.5335 17.8485C15.1987 18.1832 14.656 18.1832 14.3213 17.8485L9.17842 12.7056C8.84368 12.3709 8.84368 11.8282 9.17842 11.4935L14.3213 6.3506C14.656 6.01586 15.1987 6.01586 15.5335 6.3506Z" fill="#95989D" />
  </svg>
);
export default ChevronLeft;
