/* eslint camelcase: 0, max-len: 0 */

import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';

import loggerMiddleware from 'libs/middlewares/loggerMiddleware';

import reducers, { initialStates } from '../reducers';

export default (props) => {
  const {
    vendor_admins,
    outlets,
    caterer_id,
  } = props;

  const {
    $$vendorAdminState,
  } = initialStates;

  const initialState = {
    $$vendorAdminStore: $$vendorAdminState.merge({
      vendorAdmins: vendor_admins,
      outlets,
      caterer_id,
    }),
  };

  const reducer = combineReducers(reducers);
  const composedStore = composeWithDevTools(applyMiddleware(thunkMiddleware, loggerMiddleware));

  return composedStore(createStore)(reducer, initialState);
};
