import styled from 'styled-components';

export const ScrollableOuterContainer = styled.div`
  overflow-x: scroll;
  position: relative;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ScrollableInnerContainer = styled.div`
  overflow-x: scroll;
`;

export const ScrollableShadowContainer = styled.div`
  position: relative;
  margin-bottom: 1rem;
  overflow-x: hidden;
`;

export const ScrollableShadow = styled.div`
  background: linear-gradient(0.75turn, rgba(6, 6, 6, 0.06) 14%, rgba(60, 60, 60, 0) 70%);
  bottom: 0;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
`;
