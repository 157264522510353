/* eslint max-len: 0 */
import React from 'react';
import styled from 'styled-components';
import { bool, func, string } from 'prop-types';
import classNames from 'classnames';

const Container = styled.div`
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;

  &:checked~label::before {
    background-color: ${props => props.theme.colors.lima};
  }

  &:checked~label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }
  }
`;

const Label = styled.label`
  color: ${props => props.theme.colors.gunPowder};
  align-items: center;
  display: flex;
  font-size: 14px;
  height: 20px;
  margin-bottom: 0;
  padding-left: 1.7rem;
  position: relative;

  &.disabled {
    &::before {
      background-color: ${props => props.theme.colors.gainsboro};
    }

    &::after {
      cursor: not-allowed;
    }
  }

  &::before {
    background-color: ${props => props.theme.colors.white};
    border-radius: 0.25rem;
    border: 1px solid ${props => props.theme.colors.gainsboro};
    box-shadow: 0px 1px 0px rgb(0 0 0 / 8%);
    content: "";
    display: block;
    height: 20px !important;
    left: 0;
    margin-top: -3px;
    pointer-events: none;
    position: absolute;
    user-select: none;
    width: 20px !important;
  }

  &::after {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 50% 50%;
    content: "";
    display: block;
    height: 20px !important;
    left: 0;
    margin-top: -3px;
    position: absolute;
    width: 20px !important;
  }
`;

const CheckBox = ({
  checked,
  disabled,
  id,
  name,
  onChange,
  text,
  value = 'on',
}) => {
  const handleChange = () => (onChange && onChange());

  return (
    <Container>
      <Input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
        {...{ id, name, value }}
      />
      <Label htmlFor={id} className={classNames({ disabled })}>
        {text}
      </Label>
    </Container>
  );
};

CheckBox.propTypes = {
  checked: bool,
  disabled: bool,
  id: string,
  name: string,
  onChange: func,
  text: string,
  value: string,
};

export default CheckBox;
