import React from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as customisationsActions from 'bundles/vendor/actions/customisationsActions';
import {
  Flash as FlashComponent,
} from 'libs/components/Forms';

const Flash = ({
  flash,
  removeNoticeFlash,
}) => (
  <FlashComponent
    {
      ...{
        flash,
        removeNoticeFlash,
      }
    }
  />
);

Flash.propTypes = {
  flash: object,
  removeNoticeFlash: func.isRequired,
};

const mapStateToProps = ({ $$customisationsStore }) => ({
  flash: $$customisationsStore.get('flash'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  removeNoticeFlash: customisationsActions.removeNoticeFlash,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Flash);
