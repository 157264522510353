/* eslint import/no-named-default: 0 */
import React, { Fragment, useState, useEffect } from 'react';
import { bool, object, func, integer, string } from 'prop-types';
import styled from 'styled-components';
import { default as MainHeader } from 'bundles/billings/components/Billings/Header';
import Tabs from 'bundles/billings/components/Billings/Tabs';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as vendorStatementsActions from 'bundles/billings/actions/vendorStatementsActions';
import * as catererActions from 'bundles/common/actions/catererActions';
import Pagination from 'libs/components/Pagination';
import {
  Spacer,
} from 'libs/support/sharedComponents';
import { withAppContext } from 'libs/support/appContext';
import { getLastMonthYear } from 'libs/support/date';
import List from './List';
import Actions from './Actions';

const Container = styled.div`
  padding: 1.5rem;
`;

const VendorStatementsContainer = ({
  caterer,
  catererId,
  fetchCaterer,
  fetchVendorStatements,
  isVendorStatementsFetched,
  selectedYear,
  metadata,
}) => {
  const [isInitialized, setInitialize] = useState(false);

  useEffect(() => {
    if (!isInitialized) {
      setInitialize(true);

      if (caterer.size === 0) {
        fetchCaterer(catererId);
      }

      if (!isVendorStatementsFetched) {
        fetchVendorStatements({ page: 1, year: selectedYear });
      }
    }
  }, [isInitialized]);

  return (
    <Fragment>
      <MainHeader />
      <Tabs />
      <Container>
        <Spacer />
        <Actions />
        <List />
        <Spacer />
        {
          isVendorStatementsFetched && metadata && metadata.size > 0 &&
            <Pagination
              currentPage={metadata.get('page')}
              totalPages={metadata.get('total_pages')}
            />
        }
      </Container>
    </Fragment>
  );
};

VendorStatementsContainer.propTypes = {
  catererId: string.isRequired,
  caterer: object,
  fetchCaterer: func.isRequired,
  fetchVendorStatements: func.isRequired,
  isVendorStatementsFetched: bool,
  selectedYear: integer,
  metadata: object,
};

const mapStateToProps = ({
  $$catererStore,
  $$vendorStatementsStore,
}) => ({
  catererId: $$catererStore.get('catererId'),
  caterer: $$catererStore.get('caterer'),
  isVendorStatementsFetched: $$vendorStatementsStore.get('isVendorStatementsFetched'),
  selectedYear: $$vendorStatementsStore.selectedYear || getLastMonthYear(),
  vendorStatements: $$vendorStatementsStore.get('vendorStatements'),
  metadata: $$vendorStatementsStore.get('metadata'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCaterer: catererActions.fetchCaterer,
  fetchVendorStatements: vendorStatementsActions.fetchVendorStatements,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withAppContext(VendorStatementsContainer));
