import React from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import MenuItemNestedAttributeFieldGroup from './MenuItemNestedAttributeFieldGroup';

const DurationFieldGroup = ({
  menuItem,
  durations,
}) => (
  <MenuItemNestedAttributeFieldGroup
    label="Duration"
    options={durations}
    optionKey="duration"
    item={menuItem.get('menu_item_duration')}
    hasValue
    isInteger
    isRequired
  />
);

DurationFieldGroup.propTypes = {
  menuItem: object.isRequired,
  durations: object.isRequired,
};

const mapStateToProps = (state) => ({
  menuItem: state.$$menuItemsStore.get('menuItem'),
  durations: state.$$filtersStore.get('durations'),
});

export default connect(mapStateToProps)(DurationFieldGroup);
