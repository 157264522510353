import React from 'react';
import { func, integer } from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { SelectField } from 'libs/components/Forms';
import { useSelectInput } from 'libs/support/selectInput';
import { bindActionCreators } from 'redux';
import * as vendorStatementsActions from 'bundles/billings/actions/vendorStatementsActions';
import { CUTOFF_YEAR } from 'bundles/billings/constants/vendorStatementsConstants';

const Container = styled.div`
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

const RightContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  margin-right: 0.5rem;
  flex: 1;
`;

const StyledSelectField = styled(SelectField)`
  min-width: 100px;
`;

const generateYears = (startYear = CUTOFF_YEAR) => {
  const maxYear = new Date().getFullYear();
  const years = [];
  let currentYear = startYear;

  while (currentYear <= maxYear) {
    years.push(currentYear);
    currentYear += 1;
  }

  return years;
};

const Actions = ({
  fetchVendorStatements,
  selectedYear,
  setSelectedYear,
}) => {
  const years = generateYears();

  const options = years.map((year) => (
    {
      label: year,
      value: year,
    }
  ));
  const [selectedOption, handleOptionChange] = useSelectInput(
    options,
    selectedYear,
  );

  const handleYearChange = (data) => {
    fetchVendorStatements({ page: 1, year: data.value });
    handleOptionChange(data);
    setSelectedYear(data.value);
  };

  return (
    <Container className="vendor-statements-actions">
      <LeftContainer />

      <RightContainer>
        <StyledSelectField
          id="vendor_statements_year"
          name="vendor_statements[year]"
          label="Year"
          value={[selectedOption]}
          options={options}
          onChange={handleYearChange}
        />
      </RightContainer>
    </Container>
  );
};

Actions.propTypes = {
  fetchVendorStatements: func.isRequired,
  selectedYear: integer,
  setSelectedYear: func.isRequired,
};

const mapStateToProps = ({ $$vendorStatementsStore }) => ({
  selectedYear: $$vendorStatementsStore.get('selectedYear'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchVendorStatements: vendorStatementsActions.fetchVendorStatements,
  setSelectedYear: vendorStatementsActions.setSelectedYear,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Actions);
