import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { DeleteButton } from 'bundles/common/components/Buttons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as categoriesActions from 'bundles/vendor/actions/categoriesActions';
import { blankish } from 'libs/support/string';
import { withRouter } from 'react-router';

const DeleteCategoryButton = ({
  deleteCategory,
  isCategoryDeleting,
  uuid,
  history,
}) => {
  const handleOnDelete = () => {
    if (window.confirm('Delete menu item?')) { /* eslint no-alert: 0 */
      deleteCategory(uuid)
        .then(() => {
          history.push('/menus/categories');
        });
    }
  };

  if (blankish(uuid)) return null;

  return (
    <DeleteButton
      className="delete-category-button"
      onClick={handleOnDelete}
      isDeleting={isCategoryDeleting}
    />
  );
};

DeleteCategoryButton.propTypes = {
  deleteCategory: func.isRequired,
  history: object,
  isCategoryDeleting: bool,
  uuid: string,
};

const mapStateToProps = ({
  $$categoriesStore,
}) => ({
  uuid: $$categoriesStore.getIn(['category', 'uuid']),
  isCategoryDeleting: $$categoriesStore.get('isCategoryDeleting'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  deleteCategory: categoriesActions.deleteCategory,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeleteCategoryButton));
