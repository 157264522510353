import React from 'react';
import { func, string } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withI18n } from 'libs/support/i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SearchComponent from 'libs/components/Search';
import * as customisationsActions from 'bundles/vendor/actions/customisationsActions';

const Search = ({
  keyword,
  onSearch,
}) => (
  <SearchComponent
    className="customisations-search"
    {
        ...{
          keyword,
          onSearch,
        }
      }
  />
);

Search.propTypes = {
  keyword: string,
  onSearch: func,
};

const mapStateToProps = ({
  $$customisationsStore,
}) => ({
  keyword: $$customisationsStore.get('keyword'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onSearch: customisationsActions.setCustomisationsKeyword,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withI18n(withRouter(Search)));
