import React from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import MenuItemNestedAttributeFieldGroup from './MenuItemNestedAttributeFieldGroup';

const MeasurementUnitFieldGroup = ({
  menuItem,
  packagingUnits,
}) => (
  <MenuItemNestedAttributeFieldGroup
    label="Unit"
    options={packagingUnits}
    optionKey="packaging_unit"
    item={menuItem.get('menu_item_packaging_unit')}
    hasValue
    isInteger
  />
);

MeasurementUnitFieldGroup.propTypes = {
  menuItem: object.isRequired,
  packagingUnits: object.isRequired,
};

const mapStateToProps = (state) => ({
  menuItem: state.$$menuItemsStore.get('menuItem'),
  packagingUnits: state.$$filtersStore.get('packagingUnits'),
});

export default connect(mapStateToProps)(MeasurementUnitFieldGroup);
