import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { blankish, notBlank } from 'libs/support/string';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as vendorBillingActions from 'bundles/billings/actions/vendorBillingActions';
import { bindActionCreators } from 'redux';
import BillingDetail from './BillingDetail';
import OutletModal from './OutletBillingDetail/OutletModal';

const Submit = styled.input`
  display: none;
`;

const Form = ({
  catererId,
  isFindSuccess,
  saveVendorBilling,
  vendorBilling,
}) => {
  if (!isFindSuccess) return null;

  const formUrl = '/billings/vendor_billing';
  const uuid = vendorBilling.get('uuid');
  const formMethod = blankish(uuid) ? 'post' : 'put';
  const forceUpdate = notBlank(uuid);
  const onSubmit = (e) => {
    e.preventDefault();
    const form = document.getElementById('vendor_billing_form');
    const params = new FormData(form);

    saveVendorBilling(forceUpdate, params);
  };
  // const outlets = outletsStore.get('outlets');

  return (
    <div>
      <form
        key={`vendor_billing_form_${catererId}`}
        id="vendor_billing_form"
        action={formUrl}
        method={formMethod}
        onSubmit={onSubmit}
      >
        <input
          type="hidden"
          name="vendor_billing[vendor_attributes][id]"
          value={catererId}
        />

        <BillingDetail />

        <Submit type="submit" />
      </form>
      <OutletModal />
    </div>
  );
};

Form.propTypes = {
  catererId: string.isRequired,
  isFindSuccess: bool,
  saveVendorBilling: func.isRequired,
  vendorBilling: object,
};

const mapStateToProps = ({
  $$catererStore,
  $$vendorBillingStore,
}) => ({
  catererId: $$catererStore.get('catererId'),
  isFindSuccess: $$vendorBillingStore.get('isFindSuccess'),
  vendorBilling: $$vendorBillingStore.get('vendorBilling'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  saveVendorBilling: vendorBillingActions.saveVendorBilling,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Form);
