/* eslint max-len: 0 */

import {
  HIDE_IMAGE_MODAL,
  SHOW_IMAGE_MODAL,
} from 'bundles/common/constants/imageConstants';

export function showImageModal(params) {
  return { type: SHOW_IMAGE_MODAL, response: params };
}

export function hideImageModal() {
  return { type: HIDE_IMAGE_MODAL };
}
