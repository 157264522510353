/* eslint camelcase: 0 */

import Immutable from 'immutable';
import * as actionTypes from '../constants/serviceAddOnsConstants';
import * as customisationsActionTypes from '../constants/customisationsConstants';

export const $$initialState = Immutable.fromJS({
  isServiceAddOnsFetched: false,
  serviceAddOn: Immutable.fromJS({}),
  serviceAddOns: Immutable.fromJS([]),
});

export default function serviceAddOnsReducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  switch (type) {
    case actionTypes.FETCH_SERVICE_ADD_ONS_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .set('isServiceAddOnsFetched', true)
          .set('serviceAddOns', Immutable.fromJS(response.service_add_ons))
      ));
    }

    case customisationsActionTypes.SAVE_SERVICE_ADD_ON_SUCCESS: {
      const { service_add_on } = response;

      return $$state.withMutations(state => (
        state.update('serviceAddOns', serviceAddOns => {
          const index = serviceAddOns.findIndex(d => d.get('uuid') === service_add_on.uuid);

          const newItemAddOn = Immutable.fromJS(service_add_on);

          if (index < 0) return serviceAddOns.push(newItemAddOn);

          return serviceAddOns.update(index, existingItemAddOn => existingItemAddOn.merge(newItemAddOn));
        })
      ));
    }

    case customisationsActionTypes.DELETE_SERVICE_ADD_ON_SUCCESS: {
      const { service_add_on } = response;

      return $$state.withMutations(state => (
        state.update('serviceAddOns', serviceAddOns => {
          const index = serviceAddOns.findIndex(d => d.get('uuid') === service_add_on.uuid);

          if (index >= 0) return serviceAddOns.delete(index);

          return serviceAddOns;
        })
      ));
    }

    case customisationsActionTypes.CLONE_SERVICE_ADD_ON_SUCCESS: {
      const { service_add_on } = response;

      return $$state.withMutations(state => (
        state.update('serviceAddOns', serviceAddOns => {
          const newServiceAddOn = Immutable.fromJS(service_add_on);

          return serviceAddOns.push(newServiceAddOn);
        })
      ));
    }

    default: {
      return $$state;
    }
  }
}
