/* eslint max-len: 0 */
import React from 'react';

const LargeNote = (_props) => (
  <svg width="43" height="44" viewBox="0 0 43 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M34.625 31C33.7275 31 33 31.7275 33 32.625V38.5C33 40.9853 35.0147 43 37.5 43C39.9853 43 42 40.9853 42 38.5V32.625C42 31.7275 41.2725 31 40.375 31H34.625Z" fill="white" stroke="#141415" strokeWidth="2" />
    <path d="M32 43H33V42V4C33 2.34315 31.6569 1 30 1H4C2.34315 1 1 2.34315 1 4V38C1 40.7614 3.23858 43 6 43H32Z" fill="white" stroke="#141415" strokeWidth="2" />
    <line x1="26.4453" y1="10.6084" x2="7.82369" y2="10.6084" stroke="#141415" strokeWidth="3" strokeLinecap="round" />
    <line x1="26.4453" y1="19.2568" x2="7.82369" y2="19.2568" stroke="#141415" strokeWidth="3" strokeLinecap="round" />
    <line x1="19.959" y1="27.9043" x2="7.82385" y2="27.9043" stroke="#141415" strokeWidth="3" strokeLinecap="round" />
    <line x1="37" y1="43" x2="29" y2="43" stroke="#141415" strokeWidth="2" />
  </svg>
);
export default LargeNote;
