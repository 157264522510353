import React from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import MenuItemNestedAttributeFieldGroup from './MenuItemNestedAttributeFieldGroup';

const MeasurementUnitFieldGroup = ({
  menuItem,
  measurementUnits,
}) => (
  <MenuItemNestedAttributeFieldGroup
    label="Size"
    options={measurementUnits}
    optionKey="measurement_unit"
    item={menuItem.get('menu_item_measurement_unit')}
    hasValue
    isInteger
  />
);

MeasurementUnitFieldGroup.propTypes = {
  menuItem: object.isRequired,
  measurementUnits: object.isRequired,
};

const mapStateToProps = (state) => ({
  menuItem: state.$$menuItemsStore.get('menuItem'),
  measurementUnits: state.$$filtersStore.get('measurementUnits'),
});

export default connect(mapStateToProps)(MeasurementUnitFieldGroup);
