import styled from 'styled-components';

export const TitleFont = styled.span`
  color: ${props => props.theme.colors.blackRussian};
  font-family: ${props => props.theme.fonts.geomanistbook};
  font-size: 17px;
  line-height: 21px;
`;

export const PrimaryTitle = styled(TitleFont)`
  font-size: 18px;
  line-height: 32px;
`;

export const SecondaryTitle = styled.span`
  color: ${props => props.theme.colors.blackRussian};
  font-family: ${props => props.theme.fonts.geomanistbook};
  font-size: 14px;
  line-height: 21px;
`;

export const Hint = styled.span`
  font-size: 14px;
  color: ${props => props.theme.colors.greyChateau};
  line-height: 21px;
`;

export const HintItalic = styled(Hint)`
  font-style: italic;
`;

export const NormalFont = styled.span`
  color: ${props => props.theme.colors.blackRussian};
  font-family: ${props => props.theme.fonts.proxima};
  font-size: 14px;
  line-height: 21px;
`;

export const GreyFont = styled(NormalFont)`
  color: ${props => props.theme.colors.greyChateau};
`;

export const OptionalFont = styled.span`
  color: ${props => props.theme.colors.greyChateau};
  font-size: 12px;
  line-height: 19px;
  margin-left: 0.5rem;
`;

export const RequiredFont = styled.span`
  color: ${props => props.theme.colors.red};
  font-size: 14px;
  font-weight: normal;
`;

export const SecondaryFont = styled.span`
  color: ${props => props.theme.colors.nobel};
`;
