import React from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import { withI18n } from 'libs/support/i18n';

const Container = styled.div`
  color: ${props => props.theme.colors.blackRussian};
  font-family: ${props => props.theme.fonts.geomanistbook};
  font-size: 18px;
  line-height: 32px;
  padding: 1rem 1.5rem;
`;

const Header = ({
  translate,
}) => (
  <Container>
    { translate('vendorMenu') }
  </Container>
);

Header.propTypes = {
  translate: func.isRequired,
};

export default withI18n(Header);
