/* eslint max-len: 0 */
import React from 'react';
import { object } from 'prop-types';

const External = ({ style = {} }) => (
  <svg style={style} width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.9687 8.625H10.1562C10.0485 8.625 9.94517 8.6678 9.86899 8.74399C9.7928 8.82017 9.75 8.92351 9.75 9.03125V11.875H1.625V3.75H5.28125C5.38899 3.75 5.49232 3.7072 5.56851 3.63101C5.6447 3.55483 5.6875 3.45149 5.6875 3.34375V2.53125C5.6875 2.42351 5.6447 2.32017 5.56851 2.24399C5.49232 2.1678 5.38899 2.125 5.28125 2.125H1.21875C0.895517 2.125 0.585524 2.2534 0.356964 2.48196C0.128404 2.71052 0 3.02052 0 3.34375L0 12.2812C0 12.6045 0.128404 12.9145 0.356964 13.143C0.585524 13.3716 0.895517 13.5 1.21875 13.5H10.1562C10.4795 13.5 10.7895 13.3716 11.018 13.143C11.2466 12.9145 11.375 12.6045 11.375 12.2812V9.03125C11.375 8.92351 11.3322 8.82017 11.256 8.74399C11.1798 8.6678 11.0765 8.625 10.9687 8.625ZM12.3906 0.5H9.14062C8.59803 0.5 8.32685 1.15787 8.70898 1.54102L9.61619 2.44822L3.42773 8.63439C3.37092 8.69101 3.32585 8.75828 3.29509 8.83235C3.26434 8.90642 3.2485 8.98583 3.2485 9.06604C3.2485 9.14624 3.26434 9.22565 3.29509 9.29972C3.32585 9.37379 3.37092 9.44106 3.42773 9.49768L4.00334 10.0723C4.05995 10.1291 4.12722 10.1742 4.20129 10.2049C4.27536 10.2357 4.35478 10.2515 4.43498 10.2515C4.51518 10.2515 4.5946 10.2357 4.66867 10.2049C4.74274 10.1742 4.81001 10.1291 4.86662 10.0723L11.052 3.88508L11.959 4.79102C12.3398 5.17188 13 4.90527 13 4.35938V1.10938C13 0.947759 12.9358 0.792762 12.8215 0.678482C12.7072 0.564202 12.5522 0.5 12.3906 0.5Z" fill="#7D9E1D" />
  </svg>
);

External.propTypes = {
  style: object,
};

export default External;
