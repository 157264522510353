import React from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as menuItemsActions from 'bundles/vendor/actions/menuItemsActions';
import { CheckBox } from 'libs/components/Forms';
import { usePolicies } from 'bundles/common/queries/policies';

const SelectItemCheckbox = ({
  menuItem,
  selectedIds,
  toggleSelectMenuItem,
}) => {
  const policiesResponse = usePolicies();
  const { policies } = policiesResponse.isSuccess ? policiesResponse.data : {};
  const uuid = menuItem.get('uuid');
  const handleOnToggle = () => {
    toggleSelectMenuItem(uuid);
  };
  const isChecked = selectedIds.findIndex(id => id === uuid) >= 0;
  const disabled = !policies?.menuItem?.create;

  return (
    <CheckBox
      id={`menu_item_${uuid}`}
      name="menu_item[id][]"
      disabled={disabled}
      onChange={handleOnToggle}
      checked={isChecked}
    />
  );
};

SelectItemCheckbox.propTypes = {
  menuItem: object.isRequired,
  selectedIds: object.isRequired,
  toggleSelectMenuItem: func.isRequired,
};

const mapStateToProps = ({
  $$menuItemsStore,
}) => ({
  selectedIds: $$menuItemsStore.get('selectedIds'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  toggleSelectMenuItem: menuItemsActions.toggleSelectMenuItem,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SelectItemCheckbox);
