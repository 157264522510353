import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';
import {
  HintItalic,
} from 'libs/support/sharedComponents';

const Container = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  height: 100%;
`;

const Hint = styled(HintItalic)`
  margin-left: 0.5rem;
`;

const TextField = ({
  value,
  hint,
}) => (
  <Container>
    <span>
      { value }

      { hint && <Hint>{ hint }</Hint> }
    </span>
  </Container>
);

TextField.propTypes = {
  value: string,
  hint: string,
};

export default TextField;
