/* eslint max-len:0 */

import React from 'react';

const Check = (_props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 4C1 2.34315 2.34315 1 4 1H16C17.6569 1 19 2.34315 19 4V16C19 17.6569 17.6569 19 16 19H4C2.34315 19 1 17.6569 1 16V4Z"
      fill="white"
      stroke="#7D9E1D"
      strokeWidth="2"
    />
    <path
      d="M5 9.5L8.5 13L14.5 7"
      stroke="#7D9E1D"
      strokeWidth="2"
    />
  </svg>
);

export default Check;
