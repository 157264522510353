/* eslint no-unused-expressions: 0 */
/* eslint react/no-danger: 0 */

import React, { Fragment, useState, useRef, useLayoutEffect } from 'react';
import { bool, func, object, string } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import Modal from 'libs/components/ModalComponent';
import ModalHeader from 'libs/components/ModalHeaderComponent';
import ModalBody from 'libs/components/ModalBodyComponent';
import ModalFooter from 'libs/components/ModalFooterComponent';
import { SecondaryButton, SaveButton } from 'bundles/common/components/Buttons';
import {
  ModalContainer,
  Spacer,
} from 'libs/support/sharedComponents';
import Cropper from 'cropperjs';
import { notBlank, blankish } from 'libs/support/string';
import { createTempFile } from 'libs/services/tempFilesApi';
import styled from 'styled-components';
import { Nbsp } from 'libs/support/sharedComponents';
import {
  SectionError,
} from 'bundles/vendor/components/Shared';
import { getBlob } from 'libs/support/image';
import ImageContainer from './ImageContainer';

const StyledModalBody = styled(ModalBody)`
  zoom: 0.5;

  @media (min-width: ${props => props.theme.breakPoints.xxs}) {
    zoom: 0.7;
  }

  @media (min-width: ${props => props.theme.breakPoints.md}) {
    zoom: 1;
  }
`;

const BodyContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ImageModal = ({
  details,
  isShow,
  photo,
  onCrop,
  onHide,
  title,
}) => {
  const imageRef = useRef(null);
  const hashOrThumb = notBlank(photo) && (photo.get('hash') || photo.get('thumb'));
  const fileName = notBlank(photo) && hashOrThumb.split('/').pop().replace(/^thumb_/, '');
  const [cropper, setCropper] = useState(null);
  const [isCropping, setIsCropping] = useState(false);
  const [error, setError] = useState(null);

  useLayoutEffect(() => {
    if (blankish(photo)) return;

    const autoCropArea = photo.get('orientation') === 'portrait' ? 10 : 1;

    const engine = new Cropper(imageRef.current, {
      aspectRatio: 5 / 3,
      autoCropArea,
      minContainerWidth: 500,
      minContainerHeight: 300,
      zoomOnTouch: false,
      zoomOnWheel: false,
    });

    imageRef.current.onload = () => {
      setError(null);
      setCropper(engine);
    };
  });

  const handleSubmit = () => {
    setError(null);
    setIsCropping(true);

    cropper.cropped && cropper.getCroppedCanvas({
      fillColor: '#fff',
    }).toBlob((blob) => {
      const blobUrl = URL.createObjectURL(blob);

      const image = new Image();
      image.src = blobUrl;

      image.onload = () => {
        const qualityPercentage = blob.size > 4000000 ? 0.9 : 1;

        getBlob(image, qualityPercentage)
          .then((resizedBlob) => {
            const resizedBlobUrl = URL.createObjectURL(resizedBlob);
            const formData = new FormData();
            formData.append('file', resizedBlob, fileName);

            createTempFile(
              formData,
              (res) => {
                Promise.resolve(onCrop([{ file: { preview: resizedBlobUrl }, ...res, ...details.toJS() }]))
                  .then(() => {
                    setIsCropping(false);
                    onHide();
                  });
              },
              (res) => {
                setError(res.errors[0]);
                setIsCropping(false);
              },
            );
          });
      };
    });
  };

  const handleReset = () => {
    cropper.reset();
    setError(null);
  };

  return (
    <ModalContainer>
      <Modal
        show={isShow}
        className="modal-md modal-alert"
        onHide={onHide}
      >
        <ModalHeader enableClose>
          { title }
        </ModalHeader>

        <StyledModalBody>
          <BodyContainer>
            {
              notBlank(error) &&
                <Fragment>
                  <SectionError>{ error }</SectionError>
                  <Spacer />
                </Fragment>
            }

            { photo && <ImageContainer {...{ photo, imageRef }} /> }
          </BodyContainer>
        </StyledModalBody>

        <ModalFooter>
          <SecondaryButton
            onClick={handleReset}
            className="btn-sm"
            name="Reset"
          />
          <Nbsp />
          <SaveButton
            onClick={handleSubmit}
            isSaving={isCropping}
            className="btn-sm"
            name="Crop"
            savingName="Cropping..."
          />
        </ModalFooter>
      </Modal>
    </ModalContainer>
  );
};

ImageModal.propTypes = {
  details: object,
  isShow: bool.isRequired,
  photo: object,
  onCrop: func,
  onHide: func,
  title: string,
};

export default withI18n(ImageModal);
