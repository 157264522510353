/* eslint max-len: 0 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IntlProvider } from 'react-intl';
import Intl from 'intl';
import styled from 'styled-components';

import { translations } from 'libs/i18n/translations';
import { defaultLocale } from 'libs/i18n/default';
import { trackViewedV2UsersPage } from 'packs/events';

import * as adminsActions from '../actions/adminsActions';

import AdminList from '../components/AdminList/AdminList';

global.Intl = Intl;

function mapStatesToProps(state) {
  return {
    vendorAdminStore: state.$$vendorAdminStore,
  };
}

const Container = styled.div`
  background: ${props => props.theme.colors.white};
  border-radius: 4px
  box-shadow: 0px 2px 4px rgba(20, 20, 21, 0.1);
  width: 100%;
`;

const CatererContainer = ({
  dispatch,
  vendorAdminStore,
}) => {
  const actions = bindActionCreators({
    ...adminsActions,
  }, dispatch);
  const locale = defaultLocale;
  const messages = translations[locale];

  useEffect(() => trackViewedV2UsersPage(), []);

  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      <Container className="container px-0">
        <AdminList
          {...{
            actions,
            vendorAdminStore,
          }}
        />
      </Container>
    </IntlProvider>
  );
};

CatererContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  vendorAdminStore: PropTypes.object.isRequired,
};

// Don't forget to actually use connect!
export default connect(mapStatesToProps)(CatererContainer);
