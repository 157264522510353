import $ from 'jquery';
import request from 'axios';

import metaTagsManager from './metaTagsManager';

const paramSerializer = params => $.param(params);

export default {
  fetchEntities(entity, apiUrl) {
    return request({
      method: 'GET',
      url: `${apiUrl}.json`,
      responseType: 'json',
      params: entity,
      paramsSerializer: paramSerializer,
    });
  },

  createEntity(entity, apiUrl) {
    return request({
      method: 'POST',
      url: `${apiUrl}.json`,
      responseType: 'json',
      headers: {
        'X-CSRF-Token': metaTagsManager.getCSRFToken(),
      },
      data: entity,
      paramsSerializer: paramSerializer,
    });
  },

  updateEntity(entity, apiUrl, entityId) {
    let url = `${apiUrl}/${entityId}.json`;

    if (entityId == null) url = `${apiUrl}.json`;

    return request({
      method: 'PUT',
      url,
      responseType: 'json',
      headers: {
        'X-CSRF-Token': metaTagsManager.getCSRFToken(),
      },
      data: entity,
      paramsSerializer: paramSerializer,
    });
  },

  deleteEntity(entity, apiUrl, entityId) {
    let url = `${apiUrl}/${entityId}.json`;

    if (entityId == null) url = `${apiUrl}.json`;

    return request({
      method: 'DELETE',
      url,
      responseType: 'json',
      headers: {
        'X-CSRF-Token': metaTagsManager.getCSRFToken(),
      },
      data: entity,
      paramsSerializer: paramSerializer,
    });
  },

  createMultiPart(dataForm, apiUrl) {
    const url = `${apiUrl}.json`;

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-CSRF-Token': metaTagsManager.getCSRFToken(),
      },
    };

    return request.post(url, dataForm, config);
  },
};
