import React from 'react';
import { bool, func, string } from 'prop-types';
import { SecondaryButton as Button } from 'libs/support/sharedComponents';

const SecondaryButton = ({
  name,
  onClick,
  isDisabled,
  className = '',
}) => {
  const handleOnClick = (e) => {
    e.preventDefault();

    return onClick && onClick();
  };

  return (
    <Button
      onClick={handleOnClick}
      disabled={isDisabled}
      className={className}
    >
      {name}
    </Button>
  );
};

SecondaryButton.propTypes = {
  isDisabled: bool,
  name: string.isRequired,
  onClick: func,
  className: string,
};

export default SecondaryButton;
