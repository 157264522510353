/* eslint max-len: 0 */

import React from 'react';

const IconClose = (_props) => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.85938 1.22524L5.90359 5.26945L10.1401 1.03242C10.5655 0.607031 11.2556 0.607031 11.681 1.03242C12.1063 1.45781 12.1063 2.14792 11.681 2.57331L7.44393 6.80979L11.4881 10.854C11.9135 11.2794 11.9135 11.9689 11.4881 12.3943C11.0628 12.8197 10.3732 12.8197 9.9478 12.3943L5.90359 8.35013L2.0522 12.2015C1.62681 12.6269 0.937249 12.6269 0.511313 12.2015C0.0859222 11.7761 0.0859221 11.0866 0.511313 10.6612L4.36325 6.80979L0.319042 2.76558C-0.106349 2.34019 -0.106349 1.65063 0.319042 1.22524C0.744433 0.799847 1.43399 0.799847 1.85938 1.22524Z" fill="#515154" />
  </svg>
);
export default IconClose;
