/* eslint react/no-danger: 0 */

import React from 'react';
import { func, string } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import styled from 'styled-components';
import uppercamelcase from 'uppercamelcase';
import { Link } from 'react-router-dom';
import { ExternalIcon } from 'libs/components/Icons';

const Description = styled.div`
  font-size: 14px;
  line-height: 24px;
`;

const IconContainer = styled.span`
  margin-left: 0.5rem;
`;

const GSTInfo = styled.span`
  strong {
    font-weight: 600;
  }
`;


const Subheader = ({
  taxType,
  translate,
}) => (
  <Description>
    <GSTInfo>
      <span
        dangerouslySetInnerHTML={
          {
            __html: translate(`vendorPricesTaxTypes${uppercamelcase(taxType)}Html`),
          }
        }
      />
      &nbsp;
    </GSTInfo>
    { translate('vendorPricesSettingsLinkHtml') }
    <Link to="/menus/settings" target="_blank" rel="noopener noreferrer">
      { translate('vendorMenuSettings') }
      <IconContainer>
        <ExternalIcon />
      </IconContainer>
    </Link>
  </Description>
);

Subheader.propTypes = {
  taxType: string,
  translate: func.isRequired,
};

export default withI18n(Subheader);
