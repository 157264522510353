import React from 'react';
import { func, string } from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as customisationsActions from 'bundles/vendor/actions/customisationsActions';
import {
  TYPE_ITEM_ADD_ON,
  TYPE_SERVICE_ADD_ON,
} from 'bundles/vendor/constants/customisationsConstants';

const Container = styled.div`
  align-items: center;
  color: ${props => props.theme.colors.gunPowder};
  display: flex;
  font-size: 14px;
  line-height: 24px;
  height: 32px;

  .tab:first-child {
    border-radius: 3px 0 0 3px;
  }

  .tab:last-child {
    border-radius: 0 3px 3px 0;
  }
`;

const Tab = styled.div`
  align-items: center;
  display: flex;
  font-family: ${props => props.theme.fonts.proxima};
  height: 32px;
  padding: 0.5rem 1rem;
  border: 1px solid ${props => props.theme.colors.gainsboro};

  &.--active {
    background-color: ${props => props.theme.colors.lima};
    border-color: ${props => props.theme.colors.lima};
    color: ${props => props.theme.colors.white};
  }
`;

const Tabs = ({
  changeCurrentType,
  currentType,
}) => {
  const handleOnClick = (value) => () => {
    changeCurrentType(value);
  };

  return (
    <Container className="tabs">
      <Tab
        className={`tab ${currentType === TYPE_ITEM_ADD_ON ? '--active' : ''}`}
        onClick={handleOnClick(TYPE_ITEM_ADD_ON)}
      >
        Item Add-Ons
      </Tab>
      <Tab
        className={`tab ${currentType === TYPE_SERVICE_ADD_ON ? '--active' : ''}`}
        onClick={handleOnClick(TYPE_SERVICE_ADD_ON)}
      >
        Service Add-Ons
      </Tab>
    </Container>
  );
};

Tabs.propTypes = {
  changeCurrentType: func.isRequired,
  currentType: string,
};

const mapStateToProps = ({
  $$customisationsStore,
}) => ({
  currentType: $$customisationsStore.get('currentType'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  changeCurrentType: customisationsActions.changeCurrentType,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
