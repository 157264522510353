import React from 'react';
import styled from 'styled-components';
import { withI18n } from 'libs/support/i18n';
import { string } from 'prop-types';

const Container = styled.div`
  color: ${props => props.theme.colors.blackRussian};
  font-family: ${props => props.theme.fonts.geomanistbook};
  font-size: 18px;
  line-height: 32px;
  padding: 1rem 1.5rem;
  box-shadow: 0px -1px 0px #eaeaeb, 0px 1px 0px #eaeaeb;
`;

const Header = ({
  label,
}) => (
  <Container>
    { label }
  </Container>
);

Header.propTypes = {
  label: string.isRequired,
};

export default withI18n(Header);
