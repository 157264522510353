import Immutable from 'immutable';

const is = Immutable.is.bind(Immutable);

export default function shallowEqualImmutable(objA, objB) {
  if (objA === objB || is(objA, objB)) return true;

  let key;

  // Test for A's keys different from B.
  for (key in objA) {
    let objectA = objA[key];
    let objectB = objB[key];

    if (typeof (objA[key]) === 'object' && !Immutable.Map.isMap(objA[key])) {
      objectA = Immutable.fromJS(objA[key]);
    }

    if (typeof (objB[key]) === 'object' && !Immutable.Map.isMap(objB[key])) {
      objectB = Immutable.fromJS(objB[key]);
    }

    if (objA.hasOwnProperty(key) && (key !== 'actions') && (key !== 'intl') && (typeof objectA !== 'function') &&
      (!objB.hasOwnProperty(key) || !Immutable.is(objectA, objectB))) {
      return false;
    }
  }

  // Test for B's keys missing from A.
  for (key in objB) {
    if (objB.hasOwnProperty(key) && !objA.hasOwnProperty(key)) return false;
  }

  return true;
}
