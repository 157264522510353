import React from 'react';
import { bool, func, string } from 'prop-types';
import styled from 'styled-components';
import { PrimaryButton } from 'libs/support/sharedComponents';

const Button = styled(PrimaryButton)`
`;

const SubmitButton = ({
  form,
  isSaving,
  name = 'Save',
  onClick,
  savingName = 'Saving...',
  disabled = false,
}) => {
  const handleOnClick = (e) => (
    onClick && onClick(e)
  );

  const saveButtonText = isSaving ? savingName : name;
  const buttonDisabled = isSaving || disabled;

  return (
    <Button
      as="input"
      disabled={buttonDisabled}
      form={form}
      onClick={handleOnClick}
      type="submit"
      value={saveButtonText}
    />
  );
};

SubmitButton.propTypes = {
  form: string,
  isSaving: bool,
  name: string,
  onClick: func,
  savingName: string,
  disabled: bool,
};

export default SubmitButton;
