import React from 'react';
import { bool, string } from 'prop-types';
import { SecondaryButton } from 'libs/support/sharedComponents';
import BaseComponent from 'libs/components/BaseComponent';

class CloseButton extends BaseComponent {
  static propTypes = {
    hide: bool,
    disabled: bool,
    name: string,
  };

  render = () => {
    const {
      disabled,
      hide,
      name = 'Close',
    } = this.props;

    if (hide) return null;

    return (
      <SecondaryButton
        type="button"
        data-dismiss="modal"
        disabled={disabled}
      >
        { name }
      </SecondaryButton>
    );
  }
}

export default CloseButton;
