/* eslint import/no-named-default: 0 */

import React, { Fragment, useEffect, useState } from 'react';
import { func, object, string } from 'prop-types';
import styled from 'styled-components';
import { default as MainHeader } from 'bundles/vendor/components/Vendor/Header';
import Tabs from 'bundles/vendor/components/Vendor/Tabs';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as catererActions from 'bundles/common/actions/catererActions';
import Form from './Form';

const Container = styled.div`
  padding: 1.5rem;
`;

const SettingsContainer = ({
  catererId,
  caterer,
  fetchCaterer,
}) => {
  const [isInitialized, setInitialize] = useState(false);

  useEffect(() => {
    if (!isInitialized) {
      setInitialize(true);

      if (caterer.size === 0) {
        fetchCaterer(catererId);
      }
    }
  });

  if (!isInitialized || caterer.size === 0) {
    return (
      <Fragment>
        <MainHeader />
        <Tabs />
        <Container>
          <div>Loading...</div>
        </Container>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <MainHeader />
      <Tabs />
      <Container>
        <Form />
      </Container>
    </Fragment>
  );
};

SettingsContainer.propTypes = {
  catererId: string.isRequired,
  caterer: object,
  fetchCaterer: func.isRequired,
};

const mapStateToProps = ({
  $$catererStore,
}) => ({
  catererId: $$catererStore.get('catererId'),
  caterer: $$catererStore.get('caterer'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCaterer: catererActions.fetchCaterer,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);
