import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';

export function notEmpty(str) {
  return str !== null && str !== undefined;
}

export function notBlank(str) {
  return str !== null && str !== undefined && str !== '';
}

export function blankish(str) {
  return str === null || str === undefined || str === '';
}

export function truncate(str, num, omission) {
  const om = (blankish(omission)) ? '...' : omission;

  if (num >= str.length) return str;

  str.slice(num);
  return `${str}${om}`;
}

export function parameterize(str) {
  return str.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '');
}

// Input: newly_placed
// Output: NewlyPlaced
export function pascalCase(string) {
  const updateString =
    startCase(camelCase(string)).replace(/\s/g, '');

  return updateString;
}

export const abbreviateMiddle = (str, limit = 35) => {
  if (str.length > limit) {
    const suffixLength = str.length - 14 > 20 ? 20 : 0;

    return `${str.substr(0, suffixLength)}...${str.substr(str.length - 10, str.length)}`;
  }
  return str;
};
