import React from 'react';
import { bool, func, string } from 'prop-types';
import styled from 'styled-components';
import { PrimaryButton } from 'libs/support/sharedComponents';

const Button = styled(PrimaryButton)`
`;

const SaveButton = ({
  style,
  className = '',
  isSaving,
  name = 'Save',
  onClick,
  savingName = 'Saving...',
}) => {
  const handleOnClick = (e) => {
    e.preventDefault();

    return onClick && onClick();
  };

  const saveButtonText = isSaving ? savingName : name;

  return (
    <Button
      onClick={handleOnClick}
      disabled={isSaving}
      className={className}
      style={style}
    >
      {saveButtonText}
    </Button>
  );
};

SaveButton.propTypes = {
  style: string,
  className: string,
  isSaving: bool,
  name: string,
  onClick: func,
  savingName: string,
};

export default SaveButton;
