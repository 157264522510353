import React from 'react';
import { bool, func, object, oneOfType, string } from 'prop-types';
import DateTime from 'react-datetime';
import {
  Input,
} from 'libs/support/sharedComponents';

const DateTimeField = ({
  autoComplete,
  value,
  name,
  id,
  initialValue,
  isRequired = false,
  placeholder,
  onChange,
  isValidDate,
}) => (
  <DateTime
    inputProps={{
      autoComplete,
      className: '',
      id,
      name,
      required: isRequired,
      placeholder,
    }}
    initialValue={initialValue}
    value={value}
    dateFormat="DD MMM YYYY"
    timeFormat={false}
    closeOnSelect
    renderInput={(props) => <Input {...props} />}
    onChange={onChange}
    isValidDate={isValidDate}
  />
);

DateTimeField.propTypes = {
  autoComplete: string,
  value: oneOfType([object, string]),
  id: string,
  initialValue: oneOfType([object, string]),
  isRequired: bool,
  isValidDate: func,
  name: string,
  onChange: func,
  placeholder: string,
};

export default DateTimeField;
