/* eslint max-len: 0 */

import React, { Fragment } from 'react';
import { object, string } from 'prop-types';
import isForWideView from 'bundles/orders/support/isForWideView';
import styled from 'styled-components';
import classNames from 'classnames';

const SecondaryText = styled.div`
  color: ${props => props.theme.colors.gray800};
`;

const HistoryOrderOption = ({
  options,
  title,
  type,
}) => (
  options.size > 0 &&
    <Fragment>
      <div className="row mt-1 no-gutters">
        <div className={isForWideView(type) ? 'font-weight-bold offset-md-1 col-md-11' : 'font-weight-bold offset-md-2 col-md-10'}>
          {title}
        </div>
      </div>

      <div className="row no-gutters">
        <div className={isForWideView(type) ? 'offset-md-1 col-md-11' : 'offset-md-2 col-md-10'}>
          {options.map((option, index) => (
            <div
              key={index} // eslint-disable-line react/no-array-index-key
            >
              <span className={classNames('font-weight-bold option-qty', { changed: option.get('changed') })}>
                {option.get('quantity')}x
              </span>
              <span className={classNames('ml-2 option-title', { changed: option.get('changed') })}>
                {option.get('name')}
              </span>
              {
                option.get('photo') &&
                  <SecondaryText className="ml-2">
                    <a
                      href={option.get('photo')}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {option.get('photo').split('/').pop()}
                    </a>
                  </SecondaryText>
              }
              {
                option.get('text') &&
                  <SecondaryText className="ml-2">
                    {option.get('text')}
                  </SecondaryText>
              }
            </div>
          ))}
        </div>
      </div>
    </Fragment>
);

HistoryOrderOption.propTypes = {
  options: object.isRequired,
  title: string.isRequired,
  type: string,
};

export default HistoryOrderOption;
