/* eslint react/no-array-index-key: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import BaseComponent from 'libs/components/BaseComponent';
import { defaultMessages } from 'libs/i18n/default';

class ErrorComponent extends BaseComponent {
  static propTypes = {
    errors: PropTypes.object.isRequired,
    intl: intlShape.isRequired,
  };

  render() {
    const { errors, intl } = this.props;
    const { formatMessage } = intl;

    return (
      <div className="alert alert-danger">
        <h6>{formatMessage(defaultMessages.errorsMessagesNotSaved)}</h6>

        <ul>
          {
            errors.map((message, index) => <li key={`error-${index}`}>{message}</li>)
          }
        </ul>
      </div>
    );
  }
}

export default injectIntl(ErrorComponent);
