/* eslint max-len: 0 */
import React from 'react';

const Plus = (_props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7 16L7 0L9 8.74227e-08L9 16H7Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M1.74846e-07 7L16 7V9L0 9L1.74846e-07 7Z" fill="white" />
  </svg>

);
export default Plus;
