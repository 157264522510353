/* eslint max-len: 0 */
import React from 'react';

const ArrowLeft = (_props) => (
  <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.1775 1.20119C7.51224 1.53592 7.51224 2.07863 7.1775 2.41337L2.64074 6.95013L7.1775 11.4869C7.51224 11.8216 7.51224 12.3643 7.1775 12.6991C6.84277 13.0338 6.30005 13.0338 5.96532 12.6991L0.822463 7.55623C0.487727 7.22149 0.487727 6.67878 0.822463 6.34404L5.96532 1.20119C6.30006 0.86645 6.84277 0.866451 7.1775 1.20119Z" fill="#141415" />
  </svg>

);
export default ArrowLeft;
