/* eslint no-alert: 0 */

import React from 'react';
import { func, string } from 'prop-types';
import { blankish } from 'libs/support/string';
import {
  DropdownItem,
} from 'libs/support/sharedComponents';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as customisationsActions from 'bundles/vendor/actions/customisationsActions';

const CloneCustomisationAction = ({
  currentType,
  cloneCustomisation,
  uuid,
}) => {
  if (blankish(uuid)) return null;

  const handleClone = (e) => {
    e.preventDefault();

    cloneCustomisation(currentType, uuid);
  };

  return (
    <DropdownItem
      className="dropdown-item clone-customisation"
      onClick={handleClone}
      href="#"
    >
      Duplicate
    </DropdownItem>
  );
};

CloneCustomisationAction.propTypes = {
  currentType: string,
  cloneCustomisation: func.isRequired,
  uuid: string,
};

const mapStateToProps = ({
  $$customisationsStore,
}) => ({
  currentType: $$customisationsStore.get('currentType'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  cloneCustomisation: customisationsActions.cloneCustomisation,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CloneCustomisationAction);
