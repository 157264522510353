/* eslint max-len: 0 */

import localeReducer, { $$initialState as $$localeState } from './localeReducer';
import vendorsReducer, { $$initialState as $$vendorsState } from './vendorsReducer';

export default {
  $$localeStore: localeReducer,
  $$vendorsStore: vendorsReducer,
};

export const initialStates = {
  $$localeState,
  $$vendorsState,
};
