import React from 'react';
import { bool, func, string } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AlertModal from 'bundles/common/components/Messages/AlertModal';
import * as messagesActions from 'bundles/common/actions/messagesActions';

const AlertModalContainer = ({
  submit,
  isShow,
  message,
  onHide,
  title,
}) => (
  <AlertModal
    {
      ...{
        submit,
        isShow,
        message,
        onHide,
        title,
      }
    }
  />
);

AlertModalContainer.propTypes = ({
  submit: func,
  isShow: bool.isRequired,
  message: string,
  onHide: func,
  title: string,
});

const mapStateToProps = ({
  $$messagesStore,
}) => ({
  submit: $$messagesStore.getIn(['alert', 'submit']),
  isShow: $$messagesStore.getIn(['alert', 'isShow']),
  message: $$messagesStore.getIn(['alert', 'message']),
  title: $$messagesStore.getIn(['alert', 'title']),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onHide: messagesActions.hideAlertMessage,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AlertModalContainer);
