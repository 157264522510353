import React, { Fragment } from 'react';
import { array, bool, object, string, func } from 'prop-types';
import moment from 'moment';
import { withI18n } from 'libs/support/i18n';
import { ORDER_ACTIONS } from 'bundles/v2/orders/constants/orderConstants';
import OrderNoticeChangeRequested from './OrderNoticeChangeRequested';
import ConfirmOrderReminder from './ConfirmOrderReminder';

const OrderNotice = ({
  orderable,
  errorMsg,
  translate,
  onChangeAction,
  isLoadingAction,
  isAcceptOrderSuccess,
  isDeclineOrderSuccess,
  showDeclineModal,
  showAcceptModal,
  isAcceptChangesOrderSuccess,
  isDeclineChangesOrderSuccess,
  isConfirmOrderReminderSuccess,
  onClickChangeRequestDetails,
  orderHistories,
}) => (
  <Fragment>
    { orderable.status === 'newly_placed' &&
      <div className="notice notice-purple">
        { isLoadingAction ?
          <div className="loader-container">
            <span className="loader" />
          </div>
        :
          <Fragment>
            { isAcceptOrderSuccess &&
              <span className="notice-text-header notice-text-header-success">
                {translate('ordersShowDetailsOrderNoticeAcceptSuccess')}
              </span> }
            { isDeclineOrderSuccess &&
              <span className="notice-text-header notice-text-header-success">
                {translate('ordersShowDetailsOrderNoticeDeclineSuccess')}
              </span> }
            { !isAcceptOrderSuccess && !isDeclineOrderSuccess &&
              <Fragment>
                <span className="notice-text-header">{translate('ordersShowDetailsOrderNoticeNewOrder')}</span>
                <div className="notice-button-group">
                  <button
                    className="btn btn-decline"
                    onClick={(e) => showDeclineModal(e, ORDER_ACTIONS.decline)}
                  >
                    {translate('ordersShowDetailsOrderNoticeDecline')}
                  </button>
                  <button
                    className="btn btn-accept"
                    onClick={(e) => showAcceptModal(e, ORDER_ACTIONS.accept)}
                  >
                    {translate('ordersShowDetailsOrderNoticeAccept')}
                  </button>
                </div>
              </Fragment>
            }
          </Fragment>
        }
      </div>
    }
    { (orderable.status === 'new_changes_requested' || orderable.status === 'changes_requested') &&
      <OrderNoticeChangeRequested
        {
          ...{
            isLoadingAction,
            isAcceptChangesOrderSuccess,
            isDeclineChangesOrderSuccess,
            translate,
            onChangeAction,
            showAcceptModal,
            onClickChangeRequestDetails,
            orderHistories,
            status: orderable.status,
          }
        }
      />
    }
    { orderable.status === 'draft' &&
      <div className="notice-draft-order-container">
        <div className="notice notice-draft-order">
          <div className="notice-draft-order-message">
            <span className="notice-text-header">{translate('ordersShowDetailsOrderNoticeOngoingCafeteriaOrder')}</span>
            <div>
              <span>
                {translate('ordersShowDetailsOrderNoticeOngoingCafeteriaOrderDescription')}
              </span>
            </div>
          </div>
          <div className="notice-draft-order-dates">
            <span className="notice-draft-order-dates-text">
              {translate('ordersShowDetailsOrderNoticeCustomerToFinalize')}
            </span>
            <span className="notice-draft-order-dates-value">
              {moment(orderable.deadlineAt).utc().format('ddd D MMM YYYY h:mm A') }
            </span>
          </div>
        </div>
      </div>
    }
    { (orderable.orderReminderConfirmed || orderable.confirmOrderReminder) &&
      <ConfirmOrderReminder
        {
          ...{
            isLoadingAction,
            isReminderConfirmed: orderable.orderReminderConfirmed,
            confirmOrderReminder: orderable.confirmOrderReminder,
            deliveryType: orderable.deliveryType,
            hasDriver: orderable.drivers?.length > 0,
            isConfirmOrderReminderSuccess,
            translate,
            onChangeAction,
            onClickChangeRequestDetails,
          }
        }
      />
    }
    {
      errorMsg &&
      <div className="notice notice-error mt-2">
        <span className="notice-text-header">{translate('ordersShowDetailsOrderNoticeErrorHeader')}</span>
        <span className="notice-text-subheader">{errorMsg}</span>
      </div>
    }
  </Fragment>
);

OrderNotice.propTypes = {
  orderable: object.isRequired,
  errorMsg: string,
  translate: func,
  onChangeAction: func,
  isLoadingAction: bool,
  isAcceptOrderSuccess: bool,
  isDeclineOrderSuccess: bool,
  showDeclineModal: func,
  showAcceptModal: func,
  isAcceptChangesOrderSuccess: bool,
  isDeclineChangesOrderSuccess: bool,
  isConfirmOrderReminderSuccess: bool,
  onClickChangeRequestDetails: func,
  orderHistories: array,
};

export default withI18n(OrderNotice);
