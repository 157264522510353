/* eslint max-len: 0, no-global-assign: 0, no-restricted-globals: 0 */

import React from 'react';
import styled from 'styled-components';
import { bool, func, object, string } from 'prop-types';

import { withI18n } from 'libs/support/i18n';
import FieldComponent from 'libs/components/FieldComponent';
import { blankish, notBlank } from 'libs/support/string';

const PAGES = [{ value: 'A4', label: 'A4' }];
const Container = styled.div`
  border: 1px solid #DBDBDB;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 26px 14px;
`;


const Link = styled.a`
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;

  > * {
    color: #7D9E1D;
    vertical-align: middle;
  }
`;

const TemplateSettings = ({
  isEditTemplateMode,
  catererId,
  saveLabelTemplate,
  setEditTemplateMode,
  successMsg,
  template,
  updateTempTemplate,
  translate,
}) => {
  let form;

  const handleOnSave = () => {
    const params = new FormData(form);
    saveLabelTemplate(catererId, template.uuid, params);
  };

  const renderEditModeActions = () => (
    <div>
      {
        !template.new &&
          <button className="btn btn-md btn-light mr-2" onClick={_ => setEditTemplateMode(false)}>
            { translate('buttonsCancel') }
          </button>
      }

      <button className="btn btn-md btn-primary" onClick={handleOnSave}>
        { translate('buttonsSave') }
      </button>
    </div>
  );

  const renderNonEditModeActions = () => {
    if (blankish(template.uuid)) return null;

    return (
      <Link onClick={_ => setEditTemplateMode(true)}>
        <span>
          { translate('mealLabelsEditPanelTemplateButtonsEdit') }
        </span>
      </Link>
    );
  };

  return (
    <Container id="template-settings">
      <div className="row">
        <div className="col-md-12 mb-4 mt-2 d-flex justify-content-between">
          <div>
            <h5 className="align-middle">
              { translate('mealLabelsEditPanelTemplateTitle') }
            </h5>
          </div>

          <div className="align-middle">
            {
              isEditTemplateMode ? renderEditModeActions() : renderNonEditModeActions()
            }
          </div>
        </div>
      </div>

      {
        notBlank(successMsg) &&
          <div className="alert alert-primary" role="alert">
            <div className="text-center" style={{ color: '#141415' }}>
              { successMsg }
            </div>
          </div>
      }

      <form ref={(node) => { form = node; }}>
        <div className="row">
          <div className="col-md-12">
            <FieldComponent
              key={`template-name-${template.value}`}
              isRequired
              label="mealLabelsEditPanelTemplateFieldsName"
              type="text"
              name="label_template[name]"
              value={template.name}
              isDisabled={!isEditTemplateMode}
              labelClassName="col-sm-2 col-form-label"
              inputClassName="col-sm-10"
            />
            <FieldComponent
              label="mealLabelsEditPanelTemplateFieldsPageSize"
              type="react-select"
              name="label_template[settings][page_size]"
              labelClassName="col-sm-2 col-form-label"
              inputClassName="col-sm-10"
              value={{ value: template.settings.page_size, label: template.settings.page_size }}
              isDisabled={!isEditTemplateMode}
              onChange={target => updateTempTemplate({ page_size: target.value })}
              options={PAGES}
            />

            <div className="row">
              <label className="col-sm-2 col-form-label">
                { translate('mealLabelsEditPanelTemplateFieldsSheetMargin') }
              </label>

              <div className="col-sm-5">
                <FieldComponent
                  label="mealLabelsEditPanelTemplateFieldsMarginTopBottom"
                  type="text"
                  name="label_template[settings][sheet_margin_top_bottom]"
                  value={template.settings.sheet_margin_top_bottom}
                  onChange={e => updateTempTemplate({
                    sheet_margin_top_bottom: e.target.value,
                  })}
                  isDisabled={!isEditTemplateMode}
                  labelClassName="col-sm-6 col-form-label"
                  inputClassName="col-sm-6"
                />
              </div>
              <div className="col-sm-5">
                <FieldComponent
                  label="mealLabelsEditPanelTemplateFieldsMarginLeftRight"
                  type="text"
                  name="label_template[settings][sheet_margin_left_right]"
                  value={template.settings.sheet_margin_left_right}
                  onChange={e => updateTempTemplate({
                    sheet_margin_left_right: e.target.value,
                  })}
                  isDisabled={!isEditTemplateMode}
                  labelClassName="col-sm-6 col-form-label"
                  inputClassName="col-sm-6"
                />
              </div>
            </div>

            <div className="row">
              <label className="col-sm-2 col-form-label">
                { translate('mealLabelsEditPanelTemplateFieldsLabelMargin') }
              </label>

              <div className="col-sm-5">
                <FieldComponent
                  label="mealLabelsEditPanelTemplateFieldsMarginTopBottom"
                  type="text"
                  name="label_template[settings][label_margin_top_bottom]"
                  value={template.settings.label_margin_top_bottom}
                  onChange={e => updateTempTemplate({
                    label_margin_top_bottom: e.target.value,
                  })}
                  isDisabled={!isEditTemplateMode}
                  labelClassName="col-sm-6 col-form-label"
                  inputClassName="col-sm-6"
                />
              </div>
              <div className="col-sm-5">
                <FieldComponent
                  label="mealLabelsEditPanelTemplateFieldsMarginLeftRight"
                  type="text"
                  name="label_template[settings][label_margin_left_right]"
                  value={template.settings.label_margin_left_right}
                  onChange={e => updateTempTemplate({
                    label_margin_left_right: e.target.value,
                  })}
                  isDisabled={!isEditTemplateMode}
                  labelClassName="col-sm-6 col-form-label"
                  inputClassName="col-sm-6"
                />
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-sm-6">
                <FieldComponent
                  label="mealLabelsEditPanelTemplateFieldsLabelWidth"
                  type="text"
                  name="label_template[settings][label_width]"
                  value={template.settings.label_width}
                  onChange={e => updateTempTemplate({ label_width: e.target.value })}
                  isDisabled={!isEditTemplateMode}
                  labelClassName="col-sm-4 col-form-label"
                  inputClassName="col-sm-8"
                />
              </div>
              <div className="col-sm-6">
                <FieldComponent
                  label="mealLabelsEditPanelTemplateFieldsLabelHeight"
                  type="text"
                  name="label_template[settings][label_height]"
                  value={template.settings.label_height}
                  onChange={e => updateTempTemplate({ label_height: e.target.value })}
                  isDisabled={!isEditTemplateMode}
                  labelClassName="col-sm-4 col-form-label"
                  inputClassName="col-sm-8"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <FieldComponent
                  label="mealLabelsEditPanelTemplateFieldsNumberAcross"
                  type="number"
                  name="label_template[settings][label_per_row]"
                  value={template.settings.label_per_row}
                  onChange={e => updateTempTemplate({ label_per_row: parseInt(e.target.value, 10) })}
                  isDisabled={!isEditTemplateMode}
                  labelClassName="col-sm-4 col-form-label"
                  inputClassName="col-sm-8"
                />
              </div>
              <div className="col-sm-6">
                <FieldComponent
                  label="mealLabelsEditPanelTemplateFieldsNumberDown"
                  type="number"
                  name="label_template[settings][label_per_col]"
                  value={template.settings.label_per_col}
                  onChange={e => updateTempTemplate({ label_per_col: parseInt(e.target.value, 10) })}
                  isDisabled={!isEditTemplateMode}
                  labelClassName="col-sm-4 col-form-label"
                  inputClassName="col-sm-8"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </Container>
  );
};

TemplateSettings.propTypes = {
  isEditTemplateMode: bool,
  catererId: string.isRequired,
  saveLabelTemplate: func.isRequired,
  setEditTemplateMode: func.isRequired,
  successMsg: string,
  template: object.isRequired,
  updateTempTemplate: func.isRequired,
  translate: func.isRequired,
};

TemplateSettings.defaultProps = {
  isEditTemplateMode: true,
};

export default withI18n(TemplateSettings);
