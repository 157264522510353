/* eslint max-len: 0 */
import React from 'react';

const SortChevron = (_props) => (
  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 2318">
      <path id="Vector (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M6.87447 4.82816C6.70711 5.02342 6.43575 5.02342 6.26838 4.82816L4 2.18172L1.73162 4.82816C1.56425 5.02342 1.29289 5.02342 1.12553 4.82816C0.958158 4.6329 0.958158 4.31632 1.12553 4.12106L3.69695 1.12106C3.86432 0.925794 4.13568 0.925794 4.30305 1.12106L6.87447 4.12106C7.04184 4.31632 7.04184 4.6329 6.87447 4.82816Z" fill="#515154" stroke="#515154" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
      <path id="Vector (Stroke)_2" fillRule="evenodd" clipRule="evenodd" d="M1.12553 9.17184C1.29289 8.97658 1.56425 8.97658 1.73162 9.17184L4 11.8183L6.26838 9.17184C6.43575 8.97658 6.70711 8.97658 6.87447 9.17184C7.04184 9.3671 7.04184 9.68368 6.87447 9.87894L4.30305 12.8789C4.13568 13.0742 3.86432 13.0742 3.69695 12.8789L1.12553 9.87894C0.958158 9.68368 0.958158 9.3671 1.12553 9.17184Z" fill="#515154" stroke="#515154" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </svg>
);
export default SortChevron;
