import React, { Fragment, useState } from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import {
  Link,
} from 'libs/support/sharedComponents';
import { ExportCsvIcon } from 'libs/components/Icons';
import ExportModal from 'bundles/vendor/components/Items/ExportModal';
import { withI18n } from 'libs/support/i18n';

const IconContainer = styled.span`
  display: flex;
  margin-right: 0.5rem;
`;

const ExportButton = ({
  translate,
}) => {
  const [isShowModal, setShowModal] = useState(false);
  const handleOnExport = () => setShowModal(true);
  const handleOnHide = () => setShowModal(false);

  return (
    <Fragment>
      <Link
        onClick={handleOnExport}
      >
        <IconContainer>
          <ExportCsvIcon />
        </IconContainer>
        { translate('menuItemsActionsExportCsv') }
      </Link>
      <ExportModal
        isShow={isShowModal}
        onHide={handleOnHide}
      />
    </Fragment>
  );
};

ExportButton.propTypes = {
  translate: func.isRequired,
};

export default withI18n(ExportButton);
