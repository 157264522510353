export const SELECT_VENDOR_ADMIN = 'SELECT_VENDOR_ADMIN';
export const SHOW_VENDOR_ADMIN_FORM = 'SHOW_VENDOR_ADMIN_FORM';
export const HIDE_VENDOR_ADMIN_FORM = 'HIDE_VENDOR_ADMIN_FORM';

export const DELETE_VENDOR_ADMIN_FAILURE = 'DELETE_VENDOR_ADMIN_FAILURE';
export const DELETE_VENDOR_ADMIN_SUCCESS = 'DELETE_VENDOR_ADMIN_SUCCESS';
export const DELETING_VENDOR_ADMIN = 'DELETING_VENDOR_ADMIN';

export const SAVE_VENDOR_ADMIN_FAILURE = 'SAVE_VENDOR_ADMIN_FAILURE';
export const SAVE_VENDOR_ADMIN_SUCCESS = 'SAVE_VENDOR_ADMIN_SUCCESS';
export const SAVING_VENDOR_ADMIN = 'SAVING_VENDOR_ADMIN';

export const FIND_VENDOR_ADMINS_FAILURE = 'FIND_VENDOR_ADMINS_FAILURE';
export const FIND_VENDOR_ADMINS_SUCCESS = 'FIND_VENDOR_ADMINS_SUCCESS';
export const FINDING_VENDOR_ADMINS = 'FINDING_VENDOR_ADMINS';
