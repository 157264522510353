import React from 'react';
import { any, bool, string } from 'prop-types';
import styled from 'styled-components';
import {
  Hint,
  OptionalFont,
  RequiredFont,
} from 'libs/support/sharedComponents';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
`;

const LabelContainer = styled.div`
  margin: 0;
  width: 100%;

  @media (min-width: ${props => props.theme.breakPoints.md}) {
    padding-right: 0.5rem
    width: 30%;
  }
`;

const Label = styled.label`
  color: ${props => props.theme.colors.gunPowder};
  font-size: 14px;
  line-height: 1.2;
  padding: 0.5rem 0;
  margin-bottom: 0;
`;

const InputContainer = styled.div`
  width: 100%;

  @media (min-width: ${props => props.theme.breakPoints.md}) {
    width: 70%;
  }
`;

const HintText = styled(Hint)`
  display: ${props => (props.isInline ? 'inline' : 'block')};
  margin-left: ${props => (props.isInline ? '0.5rem' : '0')};
`;

const Required = styled(RequiredFont)`
  padding-left: 0.5rem;
`;

const FieldGroup = ({
  label,
  forHtml,
  children,
  hintText,
  isInlineHint = false,
  isRequired,
  className = '',
  optionalText,
}) => (
  <Container className={className}>
    <LabelContainer>
      <Label forHtml={forHtml}>
        {label}

        { isRequired && <Required>*</Required> }

        { optionalText && <OptionalFont>({optionalText})</OptionalFont> }
      </Label>

      { hintText && <HintText isInline={isInlineHint}>{hintText}</HintText> }
    </LabelContainer>
    <InputContainer>{ children }</InputContainer>
  </Container>
);

FieldGroup.propTypes = {
  children: any,
  className: string,
  forHtml: string,
  hintText: string,
  isInlineHint: bool,
  isRequired: bool,
  label: string,
  optionalText: string,
};

export default FieldGroup;
