import React from 'react';
import { bool, func } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import Modal from 'libs/components/ModalComponent';
import ModalHeader from 'libs/components/ModalHeaderComponent';
import ModalBody from 'libs/components/ModalBodyComponent';
import ModalFooter from 'libs/components/ModalFooterComponent';
import * as customisationsActions from 'bundles/vendor/actions/customisationsActions';
import {
  ModalContainer,
} from 'libs/support/sharedComponents';
import CustomisationsList from './CustomisationsList';
import CustomisationsListFooter from './CustomisationsList/Footer';
import CustomisationForm from './CustomisationForm';

const VerticallyScrollable = styled.div`
  height: 60vh;
  overflow-y: scroll;
`;

const CustomisationModal = ({
  isShowForm,
  isShowModal,
  onHide,
}) => {
  const BodyComponent = isShowForm ? CustomisationForm : CustomisationsList;
  const FooterComponent = isShowForm ? null : CustomisationsListFooter;

  return (
    <ModalContainer>
      <Modal
        show={isShowModal}
        className="modal-xlg"
        onHide={onHide}
      >
        <ModalHeader enableClose>
          Choose Customisations
        </ModalHeader>
        <ModalBody>
          <VerticallyScrollable>
            <BodyComponent />
          </VerticallyScrollable>
        </ModalBody>
        {
          FooterComponent &&
            <ModalFooter>
              <FooterComponent />
            </ModalFooter>
        }
      </Modal>
    </ModalContainer>
  );
};

CustomisationModal.propTypes = {
  isShowForm: bool.isRequired,
  isShowModal: bool.isRequired,
  onHide: func.isRequired,
};

const mapStateToProps = ({
  $$customisationsStore,
}) => ({
  isShowForm: $$customisationsStore.get('isShowForm'),
  isShowModal: $$customisationsStore.get('isShowModal'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onHide: customisationsActions.closeCustomisationsModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomisationModal);
