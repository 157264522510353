import React from 'react';
import { any, string } from 'prop-types';

const ModalBodyComponent = ({
  className = '',
  children,
}) => (
  <div className={`modal-body ${className}`}>
    { children }
  </div>
);

ModalBodyComponent.propTypes = {
  className: string,
  children: any,
};

export default ModalBodyComponent;
