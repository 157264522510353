/* eslint max-len: 0 */

import React from 'react';

const ChevronUp = (_props) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icons/chevron-down">
      <rect x="24.5" y="24" width="24" height="24" rx="12" transform="rotate(-180 24.5 24)" fill="#EAEAEB" />
      <path id="Vector (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M18.2489 15.1774C17.9142 15.5122 17.3715 15.5122 17.0368 15.1774L12.5 10.6406L7.96323 15.1774C7.6285 15.5121 7.08579 15.5121 6.75105 15.1774C6.41632 14.8427 6.41632 14.3 6.75105 13.9652L11.8939 8.82237C12.2286 8.48764 12.7714 8.48764 13.1061 8.82237L18.2489 13.9652C18.5837 14.3 18.5837 14.8427 18.2489 15.1774Z" fill="#515154" />
    </g>
  </svg>
);
export default ChevronUp;
