export const FIND_VENDOR_BILLING_FAILURE = 'FIND_VENDOR_BILLING_FAILURE';
export const FIND_VENDOR_BILLING_SUCCESS = 'FIND_VENDOR_BILLING_SUCCESS';
export const FINDING_VENDOR_BILLING = 'FINDING_VENDOR_BILLING';

export const SAVE_VENDOR_BILLING_FAILURE = 'SAVE_VENDOR_BILLING_FAILURE';
export const SAVE_VENDOR_BILLING_SUCCESS = 'SAVE_VENDOR_BILLING_SUCCESS';
export const SAVING_VENDOR_BILLING = 'SAVING_VENDOR_BILLING';

export const SHOW_OUTLET_BILLING_DETAIL_MODAL = 'SHOW_BILLING_DETAIL_MODAL';
export const HIDE_OUTLET_BILLING_DETAIL_MODAL = 'HIDE_BILLING_DETAIL_MODAL';
export const SAVE_OUTLET_BILLING_DETAIL = 'SAVE_OUTLET_BILLING_DETAIL';

export const REMOVE_NOTICE_FLASH = 'REMOVE_NOTICE_FLASH';
export const RESET_FLASH = 'RESET_FLASH';
