/* eslint max-len: 0 */
import React from 'react';

const Store = (_props) => (
  <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.9237 4.95868L17.6336 0.450789C17.4809 0.150263 17.1756 0 16.8702 0H3.12977C2.82443 0 2.51908 0.150263 2.36641 0.450789L0.0763359 4.95868C0 5.10894 0 5.18407 0 5.33433V6.08565C0 7.13749 0.610687 8.18933 1.52672 8.71525V15.8527C1.52672 17.13 2.59542 18.1818 3.89313 18.1818H16.1069C17.4046 18.1818 18.4733 17.13 18.4733 15.8527V8.71525C19.3893 8.18933 20 7.13749 20 6.08565V5.33433C20 5.2592 20 5.10894 19.9237 4.95868ZM6.25954 12.8475C6.25954 12.4718 6.56489 12.1713 6.94657 12.1713H7.70992C8.0916 12.1713 8.39695 12.4718 8.39695 12.8475V16.5289H6.25954V12.8475ZM7.70992 10.5184H6.94657C5.64886 10.5184 4.58015 11.5702 4.58015 12.8475V16.5289H3.89313C3.51145 16.5289 3.20611 16.2284 3.20611 15.8527V9.16604C4.0458 9.16604 4.80916 8.79038 5.41985 8.18933C6.03053 8.79038 6.87023 9.16604 7.70992 9.16604C8.54962 9.16604 9.38931 8.79038 10 8.18933C10.6107 8.79038 11.4504 9.16604 12.2901 9.16604C13.1298 9.16604 13.9695 8.79038 14.5802 8.18933C15.1908 8.79038 15.9542 9.16604 16.7939 9.16604V15.8527C16.7939 16.2284 16.4886 16.5289 16.1069 16.5289H10.0763V12.8475C10.0763 11.5702 9.00763 10.5184 7.70992 10.5184ZM14.5802 5.2592C14.1221 5.2592 13.7405 5.63486 13.7405 6.08565C13.7405 6.83696 13.1298 7.51315 12.2901 7.51315C11.4504 7.51315 10.8397 6.9121 10.8397 6.08565C10.8397 5.63486 10.458 5.2592 10 5.2592C9.54198 5.2592 9.16031 5.63486 9.16031 6.08565C9.16031 6.83696 8.54962 7.51315 7.70992 7.51315C6.87023 7.51315 6.25954 6.9121 6.25954 6.08565C6.25954 5.63486 5.87786 5.2592 5.41985 5.2592C4.96183 5.2592 4.58015 5.63486 4.58015 6.08565C4.58015 6.83696 3.96947 7.51315 3.12977 7.51315C2.29008 7.51315 1.67939 6.9121 1.67939 6.08565V5.55973L3.66412 1.72802H16.3359L18.3206 5.55973V6.08565C18.3206 6.83696 17.7099 7.51315 16.8702 7.51315C16.0305 7.51315 15.4198 6.9121 15.4198 6.08565C15.4198 5.63486 15.0382 5.2592 14.5802 5.2592Z" fill="#A6889F" />
  </svg>
);

export default Store;
