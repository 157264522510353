/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo, Fragment, useRef, useState, useEffect } from 'react';
import { array, object, func, string } from 'prop-types';
import CalendarThick from 'libs/components/Icons/v2/CalendarThick';
import CloseIcon from 'libs/components/Icons/v2/CloseIcon';
import StatusIcon from 'libs/components/Icons/v2/StatusIcon';
import DatePickerRange from 'libs/components/Forms/DatePickerRange';
import 'flatpickr/dist/themes/light.css';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { withI18n } from 'libs/support/i18n';
import uppercamelcase from 'uppercamelcase';
import { trackOrderDateChange, trackDeliveryDateChange, trackStatusState } from 'packs/events';
import { withAppContext } from 'libs/support/appContext';

const DesktopFilterSection = ({
  orderDates,
  setOrderDates,
  deliveryDates,
  setDeliveryDates,
  sampleDates,
  setSampleDates,
  query,
  onChangeSearchInput,
  ordersCountResponse,
  setStatus,
  status,
  statusesCategories,
  dateDisplayText,
  translate,
  flagIsEnabled,
}) => {
  const divRef = useRef(null);
  const buttonRef = useRef(null);
  const isOpenStatesRef = useRef(false);
  const [isOpenStates, setIsOpenStates] = useState(false);
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);

  const clearFilters = () => {
    setOrderDates([]);
    setDeliveryDates([]);
    setStatus([]);
    setSampleDates([]);
    const form = document.getElementById('order_status_form');
    const statusInputs = form.querySelectorAll('[name="statuses[]"]');
    statusInputs.forEach((input) => {
      input.checked = false;
    });
  };

  const onChangeDate = (date, type) => {
    if (type === 'order') {
      trackOrderDateChange();
      setOrderDates(date);
    }

    if (type === 'delivery') {
      trackDeliveryDateChange();
      setDeliveryDates(date);
    }

    if (type === 'sample') {
      setSampleDates(date);
    }
  };

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target) &&
      buttonRef.current && !buttonRef.current.contains(event.target) && isOpenStatesRef.current) {
      const form = document.getElementById('order_status_form');
      const params = new FormData(form);
      const checkedStatus = params.getAll('statuses[]');
      trackStatusState(checkedStatus);
      setStatus(checkedStatus);
      setIsOpenStates(false);
      isOpenStatesRef.current = false;
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const parentDiv = document.getElementsByClassName('content orders index')[0];

    if (parentDiv && isOpenStates) {
      parentDiv.style.overflow = 'hidden';
    } else {
      parentDiv.style.removeProperty('overflow');
    }
  }, [isOpenStates]);

  const removeLabel = (state) => {
    setStatus(status.filter(e => e !== state));
    const stateInput = document.getElementById(`checkbox-${state}`);
    stateInput.checked = false;
  };

  return (
    <Fragment>
      <div className="order-filters">
        <div className="order-date-filters">
          <span className="filters-text">Filter</span>
          <div className="filters-date-group">
            { (isOpenStates || isOpenDatePicker) &&
              <div className="modal-backdrop fade in status-backdrop" />
            }
            <div className="order-status-filters status-dropdown mr-1">
              <div
                className={
                  classNames('order-date pointer', {
                    'order-date-filter--active': isOpenStates,
                  })}
                onClick={() => {
                    setIsOpenStates(!isOpenStates);
                    isOpenStatesRef.current = !isOpenStates;
                  }
                }
                ref={buttonRef}
              >
                <StatusIcon />
                <span>Status</span>
              </div>

              <div
                className={
                  classNames('status-dropdown-content', {
                    show: isOpenStates,
                    'fade in': isOpenStates,
                  })}
                onScroll={(e) => e.stopPropagation()}
                ref={divRef}
              >
                <form
                  id="order_status_form"
                >
                  { statusesCategories.map((category, i) => (
                    <div className="status-dropdown-category">
                      <span
                        className={classNames('status-dropdown-category-text', {
                          'status-dropdown-category-text-title': i !== 0,
                        })}
                      >
                        {category.label}
                      </span>
                      {category.children.map((child) => (
                        <div className="status-dropdown-item">
                          <input
                            type="checkbox"
                            className="custom-checkbox-v2"
                            id={`checkbox-${child.value}`}
                            value={child.value}
                            name="statuses[]"
                            defaultChecked={status.includes(child.value)}
                          />
                          <label className="status-dropdown-item-label" htmlFor={`checkbox-${child.value}`}>
                            {child.label}
                            <span className="status-tag-number">
                              {ordersCountResponse?.[`total${child.key}OrdersCount`] || 0}
                            </span>
                          </label>
                        </div>
                      ))}
                    </div>
                  ))}
                </form>
              </div>
            </div>

            <div className={
              classNames('order-filters mr-1', {
                'order-date-filter--active': orderDates.length > 0,
                'fade in': isOpenDatePicker,
              })}
            >
              <DatePickerRange
                wrap
                onChangeDate={(date) => onChangeDate(date, 'order')}
                startDate={orderDates[0]}
                endDate={orderDates[1]}
                onOpen={() => setIsOpenDatePicker(true)}
                onClose={() => setIsOpenDatePicker(false)}
              >
                <div className="order-date" data-input>
                  <input />
                  <CalendarThick />
                  <span>{translate('ordersFiltersOrderDate')}</span>
                </div>
              </DatePickerRange>
            </div>
            <div
              className={
              classNames('order-filters mr-1', {
                'order-date-filter--active': deliveryDates.length > 0,
                'fade in': isOpenDatePicker,
              })}
            >
              <DatePickerRange
                wrap
                onChangeDate={(date) => onChangeDate(date, 'delivery')}
                startDate={deliveryDates[0]}
                endDate={deliveryDates[1]}
                enable={
                  orderDates.length > 0
                    ? [
                      (date) => date > orderDates[0],
                    ]
                    : []
                }
                onOpen={() => setIsOpenDatePicker(true)}
                onClose={() => setIsOpenDatePicker(false)}
              >
                <div className="order-date" data-input>
                  <input />
                  <CalendarThick />
                  <span>{translate('ordersFiltersDeliveryDate')}</span>
                </div>
              </DatePickerRange>
            </div>
            { flagIsEnabled('food_safety_enabled') &&
              <div
                className={
                classNames('order-filters', {
                  'order-date-filter--active': sampleDates.length > 0,
                  'fade in': isOpenDatePicker,
                })}
              >
                <DatePickerRange
                  wrap
                  onChangeDate={(date) => onChangeDate(date, 'sample')}
                  startDate={sampleDates[0]}
                  endDate={sampleDates[1]}
                  onOpen={() => setIsOpenDatePicker(true)}
                  onClose={() => setIsOpenDatePicker(false)}
                >
                  <div className="order-date" data-input>
                    <input />
                    <CalendarThick />
                    <span>{translate('ordersFiltersSampleDate')}</span>
                  </div>
                </DatePickerRange>
              </div>
            }
          </div>
        </div>
        <div className="order-search-filter">
          <span className="search-text">{translate('ordersFiltersSearch')}</span>
          <input
            type="text"
            className="order-search"
            placeholder="Order ID, Customer or Outlet"
            onChange={(e) => onChangeSearchInput(e)}
            defaultValue={query}
          />
        </div>
      </div>
      <div
        className={
          classNames('active-tags', {
            'empty-active-tags': (orderDates.length === 0 || deliveryDates.length === 0 || status.length === 0 || sampleDates.length === 0),
          })}
      >
        { (orderDates.length > 0 || deliveryDates.length > 0 || status.length > 0 || sampleDates.length > 0) &&
          <div
            className="clear-filters"
            onClick={() => clearFilters()}
          >
            {translate('ordersFiltersClearAll')}
          </div>
        }
        { orderDates.length > 0 &&
          <div className="filter-tag">
            <div className="filter-tag-text">{translate('ordersFiltersOrderDate')}: {dateDisplayText(orderDates)}</div>
            <div
              className="d-flex pointer"
              onClick={() => setOrderDates([])}
            >
              <CloseIcon />
            </div>
          </div>
        }
        { deliveryDates.length > 0 &&
          <div className="filter-tag">
            <div className="filter-tag-text">{translate('ordersFiltersDeliveryDate')}: {dateDisplayText(deliveryDates)}</div>
            <div
              className="d-flex pointer"
              onClick={() => setDeliveryDates([])}
            >
              <CloseIcon />
            </div>
          </div>
        }
        { sampleDates.length > 0 &&
          <div className="filter-tag">
            <div className="filter-tag-text">{translate('ordersFiltersSampleDate')}: {dateDisplayText(sampleDates)}</div>
            <div
              className="d-flex pointer"
              onClick={() => setSampleDates([])}
            >
              <CloseIcon />
            </div>
          </div>
        }
        { status.length > 0 && status.map((state) => (
          <div className="filter-tag">
            <div className="filter-tag-text">
              {translate(`ordersFiltersStates${uppercamelcase(state)}`)}
            </div>
            <div
              className="d-flex pointer"
              onClick={() => {
                removeLabel(state);
              }}
            >
              <CloseIcon />
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

DesktopFilterSection.propTypes = {
  orderDates: array,
  setOrderDates: func,
  deliveryDates: array,
  setDeliveryDates: func,
  sampleDates: array,
  setSampleDates: func,
  query: string,
  onChangeSearchInput: func,
  ordersCountResponse: object,
  setStatus: func,
  status: array,
  statusesCategories: array,
  dateDisplayText: string,
  translate: func,
  flagIsEnabled: func,
};

export default memo(withRouter(withAppContext(withI18n(DesktopFilterSection))));
