import React from 'react';
import PropTypes from 'prop-types';

const Tooltip = ({ children, classes, placement, title }) => (
  <a
    data-toggle="tooltip"
    data-placement={placement || 'top'}
    data-original-title={title}
    data-template={`<div class="tooltip ${classes}" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>`} // eslint-disable-line
  >
    {children}
  </a>
);

Tooltip.propTypes = {
  children: PropTypes.object,
  classes: PropTypes.string,
  placement: PropTypes.string,
  title: PropTypes.string,
};

export default Tooltip;
