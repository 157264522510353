/* eslint react/no-array-index-key: 0 */

import React, { useState } from 'react';
import { func, object, string } from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { withI18n } from 'libs/support/i18n';
import { SecondaryButton } from 'bundles/common/components/Buttons';
import {
  FieldGroup,
} from 'libs/components/Forms';
import uppercamelcase from 'uppercamelcase';
import {
  SUBITEMS_HEADERS,
} from 'bundles/vendor/constants/menuItemsConstants';
import {
  ScrollableShadow,
  ScrollableShadowContainer,
} from 'libs/support/sharedComponents';
import Subitem from './Subitem';

const TableContainer = styled.div`
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
`;

const HeaderRow = styled.tr`
  height: 35px;
  box-shadow: 0px 1px 0px ${props => props.theme.colors.gray200};
`;

const Header = styled.th`
  font-family: ${props => props.theme.fonts.proxima};
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  padding: 0.5rem 0.75rem 0.5rem;
  text-transform: uppercase;
  vertical-align: bottom;
`;

const TBody = styled.tbody`
  background-color: ${props => props.theme.colors.azure};
  box-shadow: 0px -1px 0px ${props => props.theme.colors.gray200}, 0px 1px 0px ${props => props.theme.colors.gray200};
`;

const ResponsiveScrollableShadow = styled(ScrollableShadow)`
  @media (min-width: ${props => props.theme.breakPoints.md}) {
    opacity: 0;
  }
`;

const SubitemsFieldGroup = ({
  categoryName,
  hintText,
  label,
  menuItem,
  translate,
}) => {
  const menuItemSubitems = menuItem.get('subitems')
    .filter((item) => item.get('category_name') === categoryName);
  const defaultSubitems = () => {
    if (menuItemSubitems.size === 0) {
      return menuItemSubitems.push(Immutable.fromJS({}));
    }

    return menuItemSubitems;
  };
  const [includedItems, setIncludeItems] = useState(defaultSubitems());
  const handleAddSubitem = () => {
    setIncludeItems(includedItems.push(Immutable.fromJS({})));
  };

  return (
    <FieldGroup
      label={label}
      className={`subitems_${categoryName}_field_group`}
      hintText={hintText}
      optionalText="optional"
    >
      <ScrollableShadowContainer>
        <TableContainer>
          <Table>
            <thead>
              <HeaderRow>
                {
                  SUBITEMS_HEADERS.map((header, index) => (
                    <Header
                      key={`${header.key}-${index}`}
                      style={{ minWidth: header.width }}
                    >
                      { translate(`vendor${uppercamelcase(header.key)}`) }
                    </Header>
                  ))
                }
              </HeaderRow>
            </thead>
            <TBody>
              {
                includedItems.map((includedItem, index) => (
                  <Subitem
                    key={`included-item-${index}`}
                    categoryName={categoryName}
                    includedItem={includedItem}
                    index={index}
                  />
                ))
              }
            </TBody>
          </Table>
        </TableContainer>
        <ResponsiveScrollableShadow />
      </ScrollableShadowContainer>
      <SecondaryButton
        name="Add Another"
        onClick={handleAddSubitem}
      />
    </FieldGroup>
  );
};

SubitemsFieldGroup.propTypes = {
  categoryName: string,
  hintText: string,
  label: string,
  menuItem: object,
  translate: func.isRequired,
};

const mapStateToProps = (state) => ({
  menuItem: state.$$menuItemsStore.get('menuItem'),
});

export default connect(mapStateToProps)(withI18n(SubitemsFieldGroup));
