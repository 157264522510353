/* eslint react/no-danger: 0 */

import React, { useRef, useState, useEffect, useCallback } from 'react';
import { bool, func, object, string } from 'prop-types';
import Immutable from 'immutable';

import { connect } from 'react-redux';
import { withAppContext } from 'libs/support/appContext';
import { withI18n } from 'libs/support/i18n';
import { notBlank } from 'libs/support/string';
import Modal from 'libs/components/ModalComponent';
import ModalHeader from 'libs/components/ModalHeaderComponent';
import ModalBody from 'libs/components/ModalBodyComponent';
import ModalFooter from 'libs/components/ModalFooterComponent';
import { CloseButton } from 'bundles/common/components/Buttons';
import { createMenuItemExport } from 'libs/services/menuItemsApi';
import {
  ModalContainer,
  Spacer,
} from 'libs/support/sharedComponents';
import { Flash } from 'libs/components/Forms';
import { trackClickedExportCsvButton } from 'packs/events';

const CHANNEL = 'menuItemExportChannel';

const ExportModal = ({
  catererId,
  isShow,
  onHide,
  cable,
  selectedIds,
  translate,
}) => {
  const [flash, setFlash] = useState(Immutable.fromJS({ notice: translate('menuItemsExportModalExporting') }));
  const [link, setLink] = useState();
  const channel = useRef(null);

  const subscribeChannel = useCallback(() => {
    channel.current = cable.subscribe({
      channel: CHANNEL,
      receivedCallback: (data) => {
        if (!data) return;
        if (data.msg) setFlash(Immutable.fromJS({ notice: data.msg }));
        if (data.errors) setFlash(Immutable.fromJS({ errors: data.errors }));
        if (data.link) {
          trackClickedExportCsvButton();
          setLink(data.link);
          setFlash(Immutable.fromJS({ notice: translate('menuItemsExportModalAlertsSuccess') }));
        }
      },
    });
  }, []);

  useEffect(() => {
    if (!channel.current) subscribeChannel();

    return () => {
      if (channel.current) { return cable.unsubscribe(channel.current); }

      return false;
    };
  }, []);

  useEffect(() => {
    if (isShow) {
      setFlash(Immutable.fromJS({}));
      setLink(null);
      createMenuItemExport({
        vendor_id: catererId,
        sid: cable.socketId,
        uuid: selectedIds,
      });
    }
  }, [isShow]);

  return (
    <ModalContainer>
      <Modal
        show={isShow}
        className="modal-lg"
        onHide={onHide}
      >
        <ModalHeader>
          {translate('menuItemsExportModalSubject')}
        </ModalHeader>

        <ModalBody>
          <Flash flash={flash} />
          <Spacer />

          {
            notBlank(link) &&
              <div dangerouslySetInnerHTML={{ __html: translate('menuItemsExportModalDownloadHere', { link }) }} />
          }
        </ModalBody>

        <ModalFooter>
          <CloseButton />
        </ModalFooter>
      </Modal>
    </ModalContainer>
  );
};

ExportModal.propTypes = {
  catererId: string.isRequired,
  isShow: bool.isRequired,
  onHide: func.isRequired,
  cable: object.isRequired,
  selectedIds: object,
  translate: func.isRequired,
};

const mapStateToProps = ({
  $$catererStore,
  $$menuItemsStore,
}) => ({
  catererId: $$catererStore.get('catererId'),
  selectedIds: $$menuItemsStore.get('selectedIds'),
});

export default connect(mapStateToProps)(withAppContext(withI18n(ExportModal)));
