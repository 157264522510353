import React from 'react';
import { func, object } from 'prop-types';
import styled from 'styled-components';
import uppercamelcase from 'uppercamelcase';
import { withI18n } from 'libs/support/i18n';
import {
  DisplayField,
  FieldGroup,
  InputField,
  SelectField,
  TextField,
  ToggleField,
} from 'libs/components/Forms';
import {
  Link,
  Nbsp,
  PrimaryPill,
  SecondaryPill,
  AlertPill,
  Separator,
} from 'libs/support/sharedComponents';
import { connect } from 'react-redux';
import { ExternalIcon } from 'libs/components/Icons';
import { useSelectInput } from 'libs/support/selectInput';
import Header from './Header';

const Container = styled.div`
`;

const IconContainer = styled.span`
  display: flex;
  margin-right: 0.5rem;
`;

const LinkContainer = styled.span`
  align-items: center;
  display: flex;
`;

const VENDOR_PATHS = {
  catering: 'caterers',
  pantry: 'pantry-suppliers',
  gifts: 'corporate-gifts',
  activities: 'corporate-activities',
  ecommerce: 'office-supplies',
};

export const DELIVERY_TYPES = [
  'caterspot',
  'own',
];

export const TAX_TYPES = [
  'no',
  'inclusive',
  'exclusive',
];

export const PILLS = {
  online: PrimaryPill,
  closed: SecondaryPill,
  not_available: SecondaryPill,
  deleted: SecondaryPill,
  preview: AlertPill,
  coming_soon: AlertPill,
  menu_change: AlertPill,
};

const Form = ({
  caterer,
  translate,
}) => {
  const countryCode = caterer.get('country');
  const kind = caterer.get('kind');
  const path = VENDOR_PATHS[kind];
  const slug = caterer.get('slug');
  const transitionState = caterer.get('transition_state');
  const link = `https://caterspot.${countryCode}/${path}/${slug}`;
  const type = uppercamelcase(kind);

  const deliveryTypeOptions = DELIVERY_TYPES.map(deliveryType => (
    {
      label: translate(`vendorDeliveryTypesSelections${uppercamelcase(deliveryType)}`),
      value: deliveryType,
    }
  ));
  const [selectedDeliveryTypeOption, handleDeliveryTypeChange] = useSelectInput(
    deliveryTypeOptions,
    caterer.get('delivery_type'),
  );

  const taxTypeOptions = TAX_TYPES.map(taxType => (
    {
      label: translate(`vendorTaxTypesSelections${uppercamelcase(taxType)}`),
      value: taxType,
    }
  ));
  const [selectedTaxTypeOption, handleTaxTypeChange, taxTypeValue] = useSelectInput(
    taxTypeOptions,
    caterer.get('tax_type'),
  );

  const Pill = PILLS[transitionState] || SecondaryPill;
  const transitionStateLabel = translate(`vendorTransitionStates${uppercamelcase(transitionState)}`).toUpperCase();

  return (
    <Container>
      <form
        id="settings_form"
      >
        <Header
          value={translate('vendorYourMenuSettings')}
        />

        <FieldGroup
          label="Menu status"
        >
          <DisplayField>
            <Pill>{transitionStateLabel}</Pill>
          </DisplayField>
        </FieldGroup>

        <FieldGroup
          label="Link"
        >
          <DisplayField>
            <Link target="_blank" href={link}>
              <LinkContainer>
                <IconContainer><ExternalIcon /></IconContainer>
                {link}
              </LinkContainer>
            </Link>
          </DisplayField>
        </FieldGroup>

        <FieldGroup
          label="Vendor Type"
        >
          <TextField
            value={type}
          />
        </FieldGroup>

        <FieldGroup
          label="Who does delivery"
        >
          <SelectField
            id="vendor_delivery_type"
            name="vendor[delivery_type]"
            value={[selectedDeliveryTypeOption]}
            options={deliveryTypeOptions}
            onChange={handleDeliveryTypeChange}
            isSearchable={false}
            isDisabled
            menuIsOpen={false}
            width="275px"
          />
        </FieldGroup>

        <Separator />

        <Header
          value={translate('vendorPrice')}
        />

        <FieldGroup
          label="Price input as"
        >
          <SelectField
            id="vendor_tax_type"
            name="vendor[tax_type]"
            value={[selectedTaxTypeOption]}
            options={taxTypeOptions}
            onChange={handleTaxTypeChange}
            isSearchable={false}
            isDisabled
            menuIsOpen={false}
            hint={translate(`vendorTaxTypesHints${uppercamelcase(taxTypeValue)}`)}
            width="275px"
          />
        </FieldGroup>

        <FieldGroup
          label="Price markup"
        >
          <DisplayField>
            <ToggleField
              id="vendor_markup_price_enabled"
              defaultChecked={caterer.get('markup_price_enabled') || false}
              name="vendor[markup_price_enabled]"
              isDisabled
            />
            <Nbsp />
            <InputField
              id="vendor_markup_price_percent"
              name="vendor[markup_price_percent]"
              value={caterer.get('markup_price_percent')}
              addOn="(%) markup"
              isDisabled
              inputStyle={{ width: '100px' }}
              rightAligned
            />
          </DisplayField>
        </FieldGroup>
      </form>
    </Container>
  );
};

Form.propTypes = {
  caterer: object,
  translate: func,
};

const mapStateToProps = ({
  $$catererStore,
}) => ({
  caterer: $$catererStore.get('caterer'),
});

export default connect(mapStateToProps)(withI18n(Form));
