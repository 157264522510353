import React from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import * as customisationsActions from 'bundles/vendor/actions/customisationsActions';
import {
  SecondaryButton,
  SaveButton,
} from 'bundles/common/components/Buttons';

const Container = styled.div`
  button:not(first-child) {
    margin-right: 0.5rem;
  }
`;

const fetchSelectedData = (type) => {
  const data = new FormData(document.getElementById(`${type}_add_ons_list`));
  const ids = [];

  for (const [, value] of data.entries()) { // eslint-disable-line
    ids.push(value);
  }

  return ids;
};

const Footer = ({
  applyCustomisations,
  closeModal,
}) => {
  const handleApply = () => {
    const itemAddOnIds = fetchSelectedData('item');
    const serviceAddOnIds = fetchSelectedData('service');

    Promise
      .resolve(applyCustomisations(itemAddOnIds, serviceAddOnIds))
      .then(closeModal());
  };

  return (
    <Container>
      <SecondaryButton
        name="Cancel"
        onClick={closeModal}
      />
      <SaveButton
        name="Apply"
        onClick={handleApply}
      />
    </Container>
  );
};

Footer.propTypes = {
  applyCustomisations: func.isRequired,
  closeModal: func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  applyCustomisations: customisationsActions.applyCustomisations,
  closeModal: customisationsActions.closeCustomisationsModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
