import React from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as categoriesActions from 'bundles/vendor/actions/categoriesActions';
import { ToggleField } from 'libs/components/Forms';
import { blankish } from 'libs/support/string';
import { usePolicies } from 'bundles/common/queries/policies';

const ActiveToggle = ({
  category,
  toggleCategoryActive,
}) => {
  const policiesResponse = usePolicies();
  const { policies } = policiesResponse.isSuccess ? policiesResponse.data : {};
  const handleOnActive = () => {
    toggleCategoryActive(category.get('uuid'));
  };
  const isDisabled = blankish(category.get('uuid')) || !policies?.category?.update;

  return (
    <ToggleField
      id={`category_active_${category.get('uuid')}`}
      defaultChecked={category.get('active')}
      onChange={handleOnActive}
      isDisabled={isDisabled}
    />
  );
};

ActiveToggle.propTypes = {
  category: object.isRequired,
  toggleCategoryActive: func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  toggleCategoryActive: categoriesActions.toggleCategoryActive,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ActiveToggle);
