import React from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import MenuItemNestedAttributeFieldGroup from './MenuItemNestedAttributeFieldGroup';

const OriginFieldGroup = ({
  menuItem,
  origins,
}) => (
  <MenuItemNestedAttributeFieldGroup
    label="Country of Origin"
    optionalText="optional"
    options={origins}
    optionKey="origin"
    item={menuItem.get('menu_item_origin')}
    isCreatable
  />
);

OriginFieldGroup.propTypes = {
  menuItem: object.isRequired,
  origins: object.isRequired,
};

const mapStateToProps = (state) => ({
  menuItem: state.$$menuItemsStore.get('menuItem'),
  origins: state.$$filtersStore.get('origins'),
});

export default connect(mapStateToProps)(OriginFieldGroup);
