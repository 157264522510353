/* eslint react/no-array-index-key: 0 */
/* eslint react/no-danger: 0 */

import React from 'react';
import styled from 'styled-components';
import { withI18n } from 'libs/support/i18n';
import TagsFieldGroup from 'bundles/vendor/components/Item/FieldGroups/TagsFieldGroup';
import FormatsFieldGroup from 'bundles/vendor/components/Item/FieldGroups/FormatsFieldGroup';
import SectionTitle from 'bundles/vendor/components/Shared/SectionTitle';

const Container = styled.div`

`;

const GiftsTagsSection = () => (
  <Container className="tags-section">
    <SectionTitle>Classification Tags</SectionTitle>

    <FormatsFieldGroup isRequired />
    <TagsFieldGroup kind="type" limit={2} isRequired />
    <TagsFieldGroup kind="occasion" limit={4} isRequired />
    <TagsFieldGroup kind="recipient_type" isRequired />
    <TagsFieldGroup kind="brand" limit={1} />
    <TagsFieldGroup kind="feature" />
  </Container>
);

export default withI18n(GiftsTagsSection);
