import React, { Fragment } from 'react';
import { bool, func, object } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { BackIcon } from 'libs/components/Icons';
import { withI18n } from 'libs/support/i18n';
import { SaveButton } from 'bundles/common/components/Buttons';
import {
  ActionBar,
  ActionBarLeftSection,
  ActionBarRightSection,
  BackButton,
  PrimaryTitle,
} from 'libs/support/sharedComponents';
import { bindActionCreators } from 'redux';
import * as categoriesActions from 'bundles/vendor/actions/categoriesActions';
import { trackClickedSaveForAddCategory, trackClickedSaveForEditCategory } from 'packs/events';
import DeleteCategoryButton from './DeleteCategoryButton';
import ActiveToggle from './ActiveToggle';

const Actions = ({
  isCategorySaving,
  category,
  resetFlash,
  translate,
}) => {
  const handleSave = () => {
    if (category.get('uuid')) {
      trackClickedSaveForEditCategory();
    } else {
      trackClickedSaveForAddCategory();
    }

    document
      .getElementById('category_form')
      .querySelector('input[type="submit"]')
      .click();
  };

  const handleBack = (e) => {
    if (isCategorySaving) {
      e.preventDefault();
    } else {
      resetFlash();
    }
  };

  const uuid = category.get('uuid');

  const titleKey = uuid ? 'vendorEditCategory' : 'vendorAddCategory';

  const inUseMealPlans = category.get('in_use_meal_plans');

  return (
    <ActionBar className="category-actions">
      <ActionBarLeftSection>
        <BackButton>
          <Link
            className="back-link"
            to="/menus/categories"
            onClick={handleBack}
          >
            <BackIcon />
          </Link>
        </BackButton>
        <PrimaryTitle>
          { translate(titleKey) }
        </PrimaryTitle>
      </ActionBarLeftSection>
      <ActionBarRightSection>
        {
          inUseMealPlans.size === 0 &&
            <Fragment>
              <ActiveToggle />
              <DeleteCategoryButton />
              <SaveButton isSaving={isCategorySaving} onClick={handleSave} />
            </Fragment>
        }
      </ActionBarRightSection>
    </ActionBar>
  );
};

Actions.propTypes = {
  isCategorySaving: bool.isRequired,
  category: object,
  resetFlash: func.isRequired,
  translate: func.isRequired,
};

const mapStateToProps = ({
  $$categoriesStore,
}) => ({
  category: $$categoriesStore.get('category'),
  isCategorySaving: $$categoriesStore.get('isCategorySaving'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  resetFlash: categoriesActions.resetFlash,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withI18n(Actions));
