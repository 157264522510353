/* eslint max-len: 0 */
import React from 'react';

const ExportCsv = () => (
  <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1757:7250)">
      <path d="M5.89997 9.90005C6.29997 10.3 6.89997 10.3 7.29997 9.90005L10.2 7.00005C11.1 6.10005 9.69997 4.70005 8.79997 5.60005L7.59997 6.80005V1.5C7.59997 0.9 7.19997 0.5 6.59997 0.5C5.99997 0.5 5.59997 0.9 5.59997 1.5V6.70005L4.49997 5.60005C3.59997 4.60005 2.09997 6.10005 3.09997 7.00005L5.89997 9.90005Z" fill="#7D9E1D" />
      <path d="M18.7 2.40015H12.3C11.8 2.40015 11.4 2.80015 11.4 3.30015C11.4 3.80015 11.8 4.20015 12.3 4.20015H18.5V19.2001H6.89996C5.09996 19.1001 4.99996 21.1001 6.69996 21.1001H18.8C19.7 21.1001 20.4 20.3001 20.4 19.4001V4.10015C20.4 3.10015 19.6 2.40015 18.7 2.40015Z" fill="#7D9E1D" />
      <path d="M16.4 16.3V12.3C16.4 11.7 15.9 11.2 15.2 11.2H1.79998C1.09998 11.2 0.599976 11.7 0.599976 12.3V16.3C0.599976 16.9 1.09998 17.4 1.79998 17.4H15.1C15.8 17.4 16.4 16.9 16.4 16.3ZM6.59998 15.3C6.09998 16.3 4.49998 16.4 3.69998 15.6C2.69998 14.5 3.39998 12.3 4.99998 12.4C5.59998 12.4 6.49998 12.8 6.59998 13.5C6.59998 13.9 5.99998 13.9 5.89998 13.5C4.89998 12.1 3.49998 13.7 4.09998 15C4.49998 15.9 5.79998 15.6 5.99998 14.7C6.49998 14.4 6.79998 15 6.59998 15.3ZM10.1 15.6C9.49998 16.4 7.99998 16.3 7.39998 15.6C7.09998 15.3 7.29998 14.6 7.79998 14.9C7.99998 15.1 7.99998 15.4 8.29998 15.6C8.69998 15.8 9.39997 15.7 9.49998 15.1C9.49998 14.6 8.99998 14.6 8.59998 14.5C7.49998 14.5 6.69998 13.1 7.99998 12.6C8.69998 12.3 10.5 12.6 9.99998 13.6C9.59998 14 9.39997 13.3 9.19998 13.1C8.89997 12.9 8.09998 12.9 8.09998 13.4C8.19998 14 9.09998 13.8 9.49998 14.1C10.1 14.2 10.5 15 10.1 15.6ZM14 12.9C14 13 13 15.4 13 15.5C12.9 15.8 12.7 16.2 12.4 16.2C12 16.2 11.8 15.8 11.7 15.5C11.7 15.4 10.7 12.9 10.7 12.9C10.6 12.5 11.1 12.3 11.3 12.6C11.4 12.8 12.2 15.1 12.3 15.4C12.4 15.3 13.2 12.7 13.3 12.7C13.5 12.3 14.1 12.5 14 12.9Z" fill="#7D9E1D" />
    </g>
    <defs>
      <clipPath id="clip0_1757:7250">
        <rect width="21" height="21" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
export default ExportCsv;
