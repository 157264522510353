/* eslint-disable no-undef */
/* eslint-disable quotes */
import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import { formattedPrice } from 'libs/support/price';
import OverviewCard from "./OverviewCard";

const Overviews = ({
  isLoadingPerformance,
  dashboardPerformance,
}) => (
  <Fragment>
    <div className="overview-cards mt-2 d-flex">
      <OverviewCard
        overviewText="Total Order Revenue"
        overviewValue={formattedPrice(dashboardPerformance?.totalRevenue, { precision: 2 })}
        isFetchingDashboardData={isLoadingPerformance}
        overviewActiveOrder={formattedPrice(dashboardPerformance?.totalRevenueActiveOrder, { precision: 2 })}
        overviewCompletedOrder={formattedPrice(dashboardPerformance?.totalRevenueCompletedOrder, { precision: 2 })}
      />
      <OverviewCard
        overviewText="Total Orders"
        overviewValue={dashboardPerformance?.totalOrders}
        isFetchingDashboardData={isLoadingPerformance}
        overviewActiveOrder={dashboardPerformance?.totalActiveOrders}
        overviewCompletedOrder={dashboardPerformance?.completedOrders}
      />
    </div>
  </Fragment>
);

Overviews.propTypes = {
  isLoadingPerformance: PropTypes.bool,
  dashboardPerformance: PropTypes.object,
};

export default Overviews;
