/* eslint consistent-return: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import uppercamelcase from 'uppercamelcase';
import { withI18n } from 'libs/support/i18n';

import BaseComponent from 'libs/components/BaseComponent';
import SuccessComponent from 'libs/components/SuccessComponent';

import Admin from './Admin/Admin';
import Form from './Form/Form';
import Header from './Header';

const TAB = 'admins';
const HEADERS = ['email', 'first_name', 'last_name', 'outlets', 'role'];

class CatererAdminList extends BaseComponent {
  static propTypes = {
    isFindSuccessOutlets: PropTypes.bool,
    isFindingOutlets: PropTypes.bool,
    activeTab: PropTypes.string.isRequired,
    catererId: PropTypes.string,
    store: PropTypes.object.isRequired,
    outlets: PropTypes.object,
    onFind: PropTypes.func.isRequired,
    onFindOutlets: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onHideForm: PropTypes.func.isRequired,
    actions: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
  };

  handleOnClickAdd = (e) => {
    e.preventDefault();

    this.props.actions.showVendorAdminForm();
  }

  // eslint-disable-next-line
  UNSAFE_componentWillUpdate(nextProps) {
    const {
      isFindSuccessOutlets, isFindingOutlets, activeTab, catererId, onFind,
      onFindOutlets,
    } = nextProps;
    const { vendorAdminStore } = nextProps;
    const isFindSuccess = vendorAdminStore.get('isFindSuccess');
    const isFinding = vendorAdminStore.get('isFinding');

    if (activeTab !== TAB) return false;
    if (!isFindSuccess && !isFinding) onFind(catererId);
    if (!isFindSuccessOutlets && !isFindingOutlets) onFindOutlets(catererId);
  }

  handleOnDelete(admin) {
    const { translate } = this.props;
    const { deleteVendorAdmin } = this.props.actions;
    const catererId = this.props.vendorAdminStore.get('caterer_id');
    // const { translate } = intl;
    if (confirm(translate('vendorAdminsButtonsDeleteThis'))) { // eslint-disable-line
      deleteVendorAdmin(admin.get('uuid'), catererId);
    }
  }

  render() {
    const { vendorAdminStore, translate } = this.props;
    const outlets = vendorAdminStore.get('outlets');
    const catererId = vendorAdminStore.get('caterer_id');

    const { deleteVendorAdmin, hideVendorAdminForm, saveVendorAdmin, selectVendorAdmin } = this.props.actions;
    const adminList = vendorAdminStore.get('vendorAdmins').filter(e => !e.get('email').includes('caterspot.com'));

    if (vendorAdminStore.get('isFinding')) {
      return (
        <div className="content-box text-center m-50">
          <i className="fa fa-refresh fa-spin fa-5x fa-fw" />
        </div>
      );
    }

    if (vendorAdminStore.get('showForm')) {
      return (
        <Form
          isSaving={vendorAdminStore.get('isSaving')}
          isSaveSuccess={vendorAdminStore.get('isSaveSuccess')}
          isDeleting={vendorAdminStore.get('isDeleting')}
          catererId={catererId}
          admin={vendorAdminStore.get('admin')}
          outlets={outlets}
          errors={vendorAdminStore.get('errors')}
          onSave={saveVendorAdmin}
          onDelete={deleteVendorAdmin}
          onHideForm={hideVendorAdminForm}
        />
      );
    }

    return (
      <div>
        <Header label={translate('vendorAdminsList')} />
        <div className="group-actions group-actions-desktop">
          <div className="right-actions pull-right">
            <nav className="nav nav-inline">
              <a
                className="nav-link btn"
                href="#"
                onClick={this.handleOnClickAdd}
              >
                <i className="fa fa-plus fa-lg" />
                {translate('vendorAdminsButtonsAdd')}
              </a>
            </nav>
          </div>
        </div>
        <div className="group-actions-responsive" style={{ display: 'none' }}>
          <a
            className="nav-link btn btn-primary btn-block"
            href="#"
            onClick={this.handleOnClickAdd}
          >
            {translate('vendorAdminsButtonsAdd')}
          </a>
        </div>

        <div className="content-box table-responsive">
          {
            (vendorAdminStore.get('isDeleteSuccess')) &&
            <SuccessComponent
              subject={translate('vendorAdminsFormHeader')}
              message={translate('termsHasBeenDeleted')}
            />
          }

          <table className="table table-striped admin-table">
            <thead>
              <tr>
                {
                  HEADERS.map(item => (
                    <th key={`header-${item}`}>
                      {translate([`vendorAdminsTableHeading${uppercamelcase(item)}`])}
                    </th>
                  ))
                }
                <th />
              </tr>
            </thead>

            <tbody>
              {
                adminList.map(item => (
                  <Admin
                    key={`vendor-admin-${item.get('uuid')}`}
                    outlets={outlets}
                    admin={item}
                    onClick={selectVendorAdmin}
                    handleOnDelete={() => this.handleOnDelete(item)}
                  />
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default withI18n(CatererAdminList);
