import * as api from 'libs/middlewares/api';
import * as actionTypes from '../constants/baseDeliveryFeesConstants';

export const fetchBaseDeliveryFees = () => {
  const types = [
    actionTypes.FETCHING_BASE_DELIVERY_FEES,
    actionTypes.FETCH_BASE_DELIVERY_FEES_SUCCESS,
    actionTypes.FETCH_BASE_DELIVERY_FEES_FAILURE,
  ];

  return api.get({
    types,
    endpoint: '/menus/base_delivery_fees',
    params: {},
  });
};
