/* eslint no-param-reassign: 0 */

export const getBlob = (img, quality) => {
  const canvas = document.createElement('canvas');
  const { width, height } = img;

  canvas.width = width;
  canvas.height = height;

  const context = canvas.getContext('2d');
  context.drawImage(img, 0, 0, width, height);

  return new Promise((resolve, _reject) => {
    canvas.toBlob((blob) => (
      setTimeout(() => resolve(blob), 200)
    ), 'image/jpeg', quality);
  });
};
