/* eslint max-len: 0 */

import React from 'react';

const CloseIcon = (_props) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icons/close">
      <rect x="13.5" y="13.5" width="13" height="13" rx="6.5" transform="rotate(-180 13.5 13.5)" stroke="#515154" />
      <path id="icon-close" fillRule="evenodd" clipRule="evenodd" d="M9.07031 9.74407L7.0482 7.72197L4.92996 9.84048C4.71727 10.0532 4.37222 10.0532 4.15952 9.84048C3.94683 9.62778 3.94683 9.28273 4.15952 9.07004L6.27803 6.9518L4.25593 4.92969C4.04323 4.717 4.04323 4.37222 4.25593 4.15952C4.46862 3.94683 4.8134 3.94683 5.0261 4.15952L7.0482 6.18163L8.9739 4.25593C9.1866 4.04323 9.53138 4.04323 9.74434 4.25593C9.95704 4.46862 9.95704 4.8134 9.74434 5.0261L7.81837 6.9518L9.84048 8.9739C10.0532 9.1866 10.0532 9.53138 9.84048 9.74407C9.62778 9.95677 9.283 9.95677 9.07031 9.74407Z" fill="#515154" />
    </g>
  </svg>
);

export default CloseIcon;
