import React from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { notBlank } from 'libs/support/string';
import { bindActionCreators } from 'redux';
import * as filtersActions from 'bundles/vendor/actions/filtersActions';
import * as categoriesActions from 'bundles/vendor/actions/categoriesActions';
import Flash from 'bundles/vendor/components/Category/Flash';
import FieldSets from './FieldSets';
import Actions from '../Actions';

const Container = styled.div`
`;

const FieldSetsContainer = styled.div`
  padding: 1rem 1.5rem;
`;

const Submit = styled.input`
  display: none;
`;

const Form = ({
  category,
  saveCategory,
}) => {
  const formUrl = notBlank(category.get('uuid')) ?
    `/menus/categories/${category.get('uuid')}` : '/menus/categories';
  const formMethod = notBlank(category.get('uuid')) ? 'put' : 'post';
  const onSubmit = (e) => {
    e.preventDefault();
    const form = document.getElementById('category_form');
    const params = new FormData(form);

    saveCategory(category.get('uuid'), params);
  };

  return (
    <Container>
      <form
        id="category_form"
        action={formUrl}
        method={formMethod}
        onSubmit={onSubmit}
      >
        <Actions />

        <FieldSetsContainer>
          <Flash isSticky />

          <FieldSets />
        </FieldSetsContainer>

        <Submit type="submit" />
      </form>
    </Container>
  );
};

Form.propTypes = {
  category: object.isRequired,
  saveCategory: func.isRequired,
};

const mapStateToProps = (state) => ({
  category: state.$$categoriesStore.get('category'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  saveCategory: categoriesActions.saveCategory,
  fetchFilters: filtersActions.fetchFilters,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Form);
