import React from 'react';
import { string } from 'prop-types';
import {
  SubmitButton,
} from 'bundles/common/components/Buttons';
import snakeCase from 'lodash/snakeCase';
import { connect } from 'react-redux';

const SaveCustomisationButton = ({
  currentType,
}) => {
  const type = snakeCase(currentType);

  const handleSave = () => {
    const submitButton = document
      .getElementById(`${type}_form`)
      .querySelector('input[type="submit"]');

    return submitButton && submitButton.click();
  };

  return (
    <SubmitButton
      name="Save"
      onClick={handleSave}
      form={`${type}_form`}
    />
  );
};

SaveCustomisationButton.propTypes = {
  currentType: string,
};

const mapStateToProps = ({
  $$customisationsStore,
}) => ({
  currentType: $$customisationsStore.get('currentType'),
});

export default connect(mapStateToProps)(SaveCustomisationButton);
