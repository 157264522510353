import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import HistoryWithDiffV2 from 'bundles/orders/components/Order/OrderHistories/HistoryWithDiffV2.jsx';
import Immutable from 'immutable';
import { decamelize } from 'libs/support/object';
import uppercamelcase from 'uppercamelcase';
import { ORDER_ACTIONS } from 'bundles/v2/orders/constants/orderConstants';

const OrderNoticeChangeRequested = ({
  isLoadingAction,
  isAcceptChangesOrderSuccess,
  isDeclineChangesOrderSuccess,
  translate,
  onChangeAction,
  showAcceptModal,
  orderHistories,
  status,
}) => {
  if (!orderHistories) return null;

  const sortedByDateOrderHistories = orderHistories?.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

  const orderHistory = sortedByDateOrderHistories[0];
  const immutableOrderHistory = Immutable.fromJS(decamelize(orderHistory));

  return (
    <div className="notice notice-purple notice-purple-changes mb-1">
      { isLoadingAction ? <div className="loader-container"><span className="loader" /></div> :
      <Fragment>
        { isAcceptChangesOrderSuccess &&
          <span className="notice-text-header notice-text-header-success">
            {translate(`ordersShowDetailsOrderNoticeAccept${uppercamelcase(status)}`)}
          </span>
        }
        { isDeclineChangesOrderSuccess &&
          <span className="notice-text-header notice-text-header-success">
            {translate(`ordersShowDetailsOrderNoticeDecline${uppercamelcase(status)}`)}
          </span> }
        { !isAcceptChangesOrderSuccess && !isDeclineChangesOrderSuccess &&
          <Fragment>
            <div className="notice-text-group">
              <span className="notice-text-header">
                {translate(`ordersShowDetailsOrderNotice${uppercamelcase(status)}Order`)}
              </span>
              <span className="notice-text-subheader mt-8">
                {translate(`ordersShowDetailsOrderNotice${uppercamelcase(status)}OrderDescription`)}
              </span>
            </div>
            <div>
              {
                immutableOrderHistory.get('data_diff') !== null &&
                <HistoryWithDiffV2 diff={immutableOrderHistory.get('data_diff')} />
              }
            </div>
            <div className="notice-button-group notice-button-group-change-requested">
              <button
                className="btn btn-decline"
                onClick={(e) => onChangeAction(e, ORDER_ACTIONS.decline_changes)}
              >
                {translate('ordersShowDetailsOrderNoticeDecline')}
              </button>
              <button
                className="btn btn-accept"
                onClick={(e) => showAcceptModal(e, ORDER_ACTIONS.accept_changes)}
              >
                {translate('ordersShowDetailsOrderNoticeAccept')}
              </button>
            </div>

          </Fragment>
        }
      </Fragment>
      }
    </div>
  );
};

OrderNoticeChangeRequested.propTypes = {
  isLoadingAction: PropTypes.bool.isRequired,
  isAcceptChangesOrderSuccess: PropTypes.bool.isRequired,
  isDeclineChangesOrderSuccess: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  onChangeAction: PropTypes.func.isRequired,
  showAcceptModal: PropTypes.func.isRequired,
  orderHistories: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
};

export default OrderNoticeChangeRequested;
