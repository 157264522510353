/* eslint camelcase: 0 */

import Immutable from 'immutable';
import * as actionTypes from '../constants/filtersConstants';

export const $$initialState = Immutable.fromJS({
  isFiltersFetched: false,
  brands: Immutable.fromJS([]),
  delivery_options: Immutable.fromJS([]),
  courses: Immutable.fromJS([]),
  dietaries: Immutable.fromJS([]),
  durations: Immutable.fromJS([]),
  features: Immutable.fromJS([]),
  foodAttributes: Immutable.fromJS([]),
  foodTypes: Immutable.fromJS([]),
  expirations: Immutable.fromJS([]),
  ingredients: Immutable.fromJS([]),
  languages: Immutable.fromJS([]),
  measurementUnits: Immutable.fromJS([]),
  occasions: Immutable.fromJS([]),
  origins: Immutable.fromJS([]),
  packagingUnits: Immutable.fromJS([]),
  presentations: Immutable.fromJS([]),
  recipientTypes: Immutable.fromJS([]),
  requirements: Immutable.fromJS([]),
  sizeRanges: Immutable.fromJS([]),
  temperatures: Immutable.fromJS([]),
  types: Immutable.fromJS([]),
});

export default function filtersReducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  switch (type) {
    case actionTypes.FETCH_FILTERS_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .set('isFiltersFetched', true)
          .set('brands', Immutable.fromJS(response.brands))
          .set('courses', Immutable.fromJS(response.courses || []))
          .set('deliveryOptions', Immutable.fromJS(response.delivery_options))
          .set('dietaries', Immutable.fromJS(response.dietaries))
          .set('durations', Immutable.fromJS(response.durations))
          .set('features', Immutable.fromJS(response.features))
          .set('foodAttributes', Immutable.fromJS(response.food_attributes))
          .set('foodTypes', Immutable.fromJS(response.food_types))
          .set('expirations', Immutable.fromJS(response.expirations))
          .set('ingredients', Immutable.fromJS(response.ingredients))
          .set('languages', Immutable.fromJS(response.languages))
          .set('measurementUnits', Immutable.fromJS(response.measurement_units))
          .set('occasions', Immutable.fromJS(response.occasions))
          .set('origins', Immutable.fromJS(response.origins))
          .set('packagingUnits', Immutable.fromJS(response.packaging_units))
          .set('presentations', Immutable.fromJS(response.presentations))
          .set('recipientTypes', Immutable.fromJS(response.recipient_types))
          .set('requirements', Immutable.fromJS(response.requirements))
          .set('sizeRanges', Immutable.fromJS(response.size_ranges))
          .set('temperatures', Immutable.fromJS(response.temperatures))
          .set('types', Immutable.fromJS(response.types))
      ));
    }

    default: {
      return $$state;
    }
  }
}
