/* eslint react/no-array-index-key:0 */

import React from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withI18n } from 'libs/support/i18n';
import * as categoriesActions from 'bundles/vendor/actions/categoriesActions';
import {
  Table,
  HeaderRow,
  Header,
  TBody,
  Spacer,
} from 'libs/support/sharedComponents';
import styled from 'styled-components';
import {
  SecondaryButton,
} from 'bundles/common/components/Buttons';
import {
  SectionDescription,
  SectionTitle,
} from 'bundles/vendor/components/Shared';
import CategoryAvailabilityFields from './CategoryAvailabilityFields';

const Container = styled.div`
`;

const CategoryAvailabilitiesList = ({
  addCategoryAvailability,
  categoryAvailabilities,
  translate,
}) => {
  const handleAddCategoryAvailability = () => {
    addCategoryAvailability();
  };

  return (
    <Container
      className="category-availabilities-list"
    >
      <SectionTitle>
        { translate('vendorCategoryAvailabilitiesFormTitle') }
      </SectionTitle>
      <SectionDescription>
        { translate('vendorCategoryAvailabilitiesFormDescription') }
      </SectionDescription>

      <Spacer />

      <Table>
        <thead>
          <HeaderRow>
            <Header key="start date" width="33%">Start Date</Header>
            <Header key="end date" width="33%">End Date</Header>
            <Header key="availability" width="33%" >Availability</Header>
            <Header key="actions" />
          </HeaderRow>
        </thead>
        <TBody>
          {
            categoryAvailabilities.size > 0 &&
              categoryAvailabilities.map((availability, index) => (
                <CategoryAvailabilityFields
                  key={`option_${availability.get('uuid') || availability.get('tempId')}_${index}`}
                  categoryAvailability={availability}
                  index={index}
                />
              ))
          }
        </TBody>
      </Table>

      <Spacer />

      <SecondaryButton
        name={translate('vendorCategoryAvailabilitiesFormAddDate')}
        onClick={handleAddCategoryAvailability}
      />
    </Container>
  );
};
CategoryAvailabilitiesList.propTypes = {
  addCategoryAvailability: func.isRequired,
  categoryAvailabilities: object,
  translate: func.isRequired,
};

const mapStateToProps = ({
  $$categoriesStore,
}) => ({
  categoryAvailabilities: $$categoriesStore.getIn(['category', 'category_availabilities']),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  addCategoryAvailability: categoriesActions.addCategoryAvailability,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withI18n(CategoryAvailabilitiesList));
