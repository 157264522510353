import React, { Children, cloneElement, useRef, useState, useEffect } from 'react';
import { array, bool, func, number, object, string } from 'prop-types';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/light.css';
import { createPortal } from 'react-dom';

function DatePickerRange({
  startDate,
  endDate,
  onChangeDate,
  children,
  enable = [],
  inline = false,
  showMonths = 2,
  onOpen = () => {},
  onClose = () => {},
}) {
  const flatpickrRef = useRef(null);
  const [dates, setDates] = useState([startDate, endDate]);

  useEffect(() => {
    setDates([startDate, endDate]);
  }, [startDate, endDate]);

  const options = {
    mode: 'range',
    enableTime: false,
    dateFormat: 'd M Y',
    showMonths,
    locale: {
      firstDayOfWeek: 1,
    },
    closeOnSelect: false,
  };

  if (enable.length > 0) {
    options.enable = enable;
  }

  if (inline) {
    options.inline = true;
  }

  const element = Children.only(children);

  return (
    <Flatpickr
      data-enable-time
      ref={flatpickrRef}
      value={[dates[0], dates[1]]}
      options={options}
      onChange={(date) => {
        if (date.length === 2) {
          setDates(date);
        }
      }}
      onOpen={onOpen}
      onClose={onClose}
      render={({ _ }, ref) => {
        const childWithRef = cloneElement(element, { ref });

        return (
          <div>
            {childWithRef}
            {flatpickrRef.current &&
              createPortal(
                <div className="flex justify-center gap-3 py-2 datepicker-cta">
                  <button
                    className="btn btn-default"
                    onClick={() => {
                      flatpickrRef.current.flatpickr.close();
                    }}
                  >Cancel
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      onChangeDate(dates);
                      flatpickrRef.current.flatpickr.close();
                    }}
                  >Apply
                  </button>
                </div>,
                flatpickrRef.current.flatpickr.calendarContainer,
              )}
          </div>
        );
      }}
    />
  );
}

DatePickerRange.propTypes = {
  startDate: string,
  endDate: string,
  onChangeDate: func,
  children: object,
  enable: array,
  inline: bool,
  showMonths: number,
  onOpen: func,
  onClose: func,
};

export default DatePickerRange;
