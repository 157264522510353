/* eslint no-return-assign: 0 */

import React, { useState, useLayoutEffect } from 'react';
import { bool, string, object } from 'prop-types';
import { blankish } from 'libs/support/string';
import styled from 'styled-components';
import Immutable from 'immutable';
import {
  FieldGroup,
  InputField,
  SelectField,
} from 'libs/components/Forms';

const InputsContainer = styled.div`
  display: flex;
  margin-right: -1rem;

  > * {
    flex: 1;
    margin-right: 1rem;
  }
`;

const RangeContainer = styled.span`
  display: flex;
`;

const ToText = styled.span`
  align-items: center;
  display: flex;
  padding: 0 0.5rem;
`;

const MenuItemNestedAttributeFieldGroup = ({
  hasRange,
  hasValue,
  hintText,
  isCreatable,
  isInteger,
  isRequired,
  label,
  optionKey,
  optionalText,
  options,
  item = Immutable.fromJS({}),
}) => {
  if (options.size === 0) return null;

  let valueRef = null;
  let minRef = null;
  let maxRef = null;
  let menuItemIdRef = null;
  let idRef = null;

  const selectOptions = options.map((option) => (
    {
      label: option.get('name'),
      value: option.get('uuid'),
    }
  ));
  const selectedOptionId = item.get(`${optionKey}_id`);
  const initialSelectedOption = selectOptions.find(data => data.value === selectedOptionId);
  const [selectedOption, setSelectedOption] = useState(initialSelectedOption);
  const [optionRef, setOptionRef] = useState('');
  const id = (item && blankish(item.get('deleted_at')) && item.get('uuid')) || '';

  const isFixedOption = isRequired && options.size === 1;
  const unsetOptionAttributes = () => {
    setOptionRef('');
    if (idRef) idRef.name = '';
    if (menuItemIdRef) menuItemIdRef.name = '';
  };
  const setOptionAttributes = () => {
    setOptionRef(`menu_item[menu_item_${optionKey}_attributes][${optionKey}_id]`);
    if (idRef) idRef.name = `menu_item[menu_item_${optionKey}_attributes][id]`;
    if (menuItemIdRef) menuItemIdRef.name = `menu_item[menu_item_${optionKey}_attributes][menu_item_id]`;
  };
  const unsetValueAttributes = () => {
    valueRef.name = '';
    unsetOptionAttributes();
  };
  const setValueAttributes = () => {
    valueRef.name = `menu_item[menu_item_${optionKey}_attributes][value]`;
    setOptionAttributes();
  };
  const unsetRangeAttributes = () => {
    minRef.name = '';
    maxRef.name = '';
    unsetOptionAttributes();
  };
  const setRangeAttributes = () => {
    minRef.name = `menu_item[menu_item_${optionKey}_attributes][min]`;
    maxRef.name = `menu_item[menu_item_${optionKey}_attributes][max]`;
    setOptionAttributes();
  };
  const handleOnChange = () => {
    if (hasValue) {
      if (blankish(id) && blankish(valueRef.value) && blankish(selectedOption)) {
        unsetValueAttributes();
      } else {
        setValueAttributes();
      }
    } else if (hasRange) {
      if (blankish(id) && blankish(minRef.value) && blankish(maxRef.value) && blankish(selectedOption)) {
        unsetRangeAttributes();
      } else {
        setRangeAttributes();
      }
    } else if (blankish(id) && blankish(selectedOption)) {
      unsetOptionAttributes();
    } else {
      setOptionAttributes();
    }
  };
  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const parseValue = (value) => {
    if (isInteger && !Number.isNaN(parseInt(value, 10))) {
      return parseInt(value, 10);
    }

    return value;
  };

  useLayoutEffect(() => {
    handleOnChange();
  }, [item, selectedOption]);

  return (
    <FieldGroup
      label={label}
      htmlFor={`menu_item[menu_item_${optionKey}_attributes][${optionKey}_id]`}
      hintText={hintText}
      optionalText={optionalText}
      isRequired={isRequired}
    >
      <InputsContainer>
        {
          hasValue &&
            <InputField
              inputRef={node => valueRef = node}
              type="number"
              id={`menu_item_menu_item_${optionKey}_attributes_value`}
              name={`menu_item[menu_item_${optionKey}_attributes][value]`}
              className="form-control"
              value={item && parseValue(item.get('value'))}
              required={isRequired}
              onChange={handleOnChange}
            />
        }
        {
          hasRange &&
            <RangeContainer>
              <InputField
                inputRef={node => minRef = node}
                type="number"
                id={`menu_item_menu_item_${optionKey}_attributes_min`}
                name={`menu_item[menu_item_${optionKey}_attributes_min]`}
                className="form-control"
                value={item && parseValue(item.get('min'))}
                required={isRequired}
                placeholder="min"
              />

              <ToText>to</ToText>

              <InputField
                inputRef={node => maxRef = node}
                type="number"
                id={`menu_item_menu_item_${optionKey}_attributes_max`}
                name={`menu_item[menu_item_${optionKey}_attributes][max]`}
                className="form-control"
                value={item && parseValue(item.get('max'))}
                required={isRequired}
                placeholder="max"
              />
            </RangeContainer>
        }
        {
          item &&
            <input
              ref={node => idRef = node}
              type="hidden"
              id={`menu_item_menu_item_${optionKey}_attributes_id`}
              name={`menu_item[menu_item_${optionKey}_attributes][id]`}
              value={id}
            />
        }
        {
          item &&
            <input
              ref={node => menuItemIdRef = node}
              type="hidden"
              id={`menu_item_menu_item_${optionKey}_attributes_menu_item_id`}
              name={`menu_item[menu_item_${optionKey}_attributes][menu_item_id]`}
              value={item.get('menu_item_id')}
            />
        }
        <SelectField
          id={`menu_item_menu_item_${optionKey}_attributes_${optionKey}_id`}
          name={optionRef}
          value={[selectedOption]}
          options={selectOptions}
          onChange={handleOptionChange}
          isRequired={isRequired}
          isClearable={!isRequired && !isFixedOption}
          isCreatable={isCreatable}
        />
      </InputsContainer>
    </FieldGroup>
  );
};

MenuItemNestedAttributeFieldGroup.propTypes = {
  hasRange: bool,
  hasValue: bool,
  hintText: string,
  isCreatable: bool,
  isInteger: bool,
  isRequired: bool,
  label: string.isRequired,
  optionKey: string.isRequired,
  optionalText: string,
  options: object.isRequired,
  item: object,
};

export default MenuItemNestedAttributeFieldGroup;
