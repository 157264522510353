import React from 'react';
import { func } from 'prop-types';
import {
  DropdownItem,
} from 'libs/support/sharedComponents';
import { withI18n } from 'libs/support/i18n';

const ExportDropdownAction = ({
  onClick,
  translate,
}) => (
  <DropdownItem
    onClick={onClick}
  >
    { translate('menuItemsActionsExportCsv') }
  </DropdownItem>
);

ExportDropdownAction.propTypes = {
  onClick: func,
  translate: func.isRequired,
};

export default withI18n(ExportDropdownAction);
