/* eslint react/no-array-index-key: 0 */

import React from 'react';
import { bool, func, object } from 'prop-types';
import moment from 'moment';
import Immutable from 'immutable';
import { blankish } from 'libs/support/string';
import Modal from 'libs/components/ModalComponent';
import ModalHeader from 'libs/components/ModalHeaderComponent';
import ModalBody from 'libs/components/ModalBodyComponent';
import HistoryWithDiff from 'bundles/orders/components/Order/OrderHistories/HistoryWithDiff.jsx';
import HistoryWithoutDiff from 'bundles/orders/components/Order/OrderHistories/HistoryWithoutDiff.jsx';
import HistoryWithoutDiffV2 from 'bundles/orders/components/Order/OrderHistories/HistoryWithoutDiffV2.jsx';
import DeclineReason from 'bundles/orders/components/Order/OrderHistories/DeclineReason.jsx';
import { decamelize } from 'libs/support/object';

const OrderHistoryModal = ({ order = {}, show, orderHistory, onHide }) => {
  const orderDeclined = order.status === 'declined';
  const immutableOrderHistory = Immutable.fromJS(decamelize(orderHistory));
  return (
    <Modal
      show={show}
      className="modal-lg modal-order-history"
      onHide={onHide}
    >
      <ModalHeader enableClose>
        <h5 className="modal-title">
          {
            `Order History - ${moment(orderHistory.createdAt)
              .format('DD MMM YYYY, h:mm A')}`
          }
        </h5>
      </ModalHeader>

      <ModalBody>
        {
          (orderHistory && orderHistory.data) &&
          <div className="modal-body-content container-fluid order-diff">
            {
              orderDeclined ?
                <DeclineReason
                  reasons={order.declineReasons}
                  detail={order.declineDetail}
                /> :
                <div>
                  {
                    blankish(orderHistory.data.versi) &&
                    orderHistory.dataDiff === null &&
                    <HistoryWithoutDiff orderHistory={immutableOrderHistory} />
                  }

                  {
                    orderHistory.data.versi > 1 &&
                    orderHistory.dataDiff === null &&
                    <HistoryWithoutDiffV2 orderHistory={immutableOrderHistory} />
                  }

                  {
                    immutableOrderHistory.get('data_diff') !== null &&
                    <HistoryWithDiff diff={immutableOrderHistory.get('data_diff')} />
                  }
                </div>
            }
          </div>
        }
      </ModalBody>
    </Modal>
  );
};

OrderHistoryModal.propTypes = {
  onHide: func.isRequired,
  orderHistory: object,
  order: object,
  show: bool,
};

export default OrderHistoryModal;
