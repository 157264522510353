import React from 'react';
import styled from 'styled-components';
import Tab from './Tab';

const List = styled.ul`
  box-shadow: 0px -1px 0px #EAEAEB, 0px 1px 0px #EAEAEB;
  display: flex;
  list-style: none;
  padding: 0;
  margin-bottom: 0;

  li {
    border-right: 1px solid  ${props => props.theme.colors.gray200};
  }
`;

const Tabs = () => (
  <List>
    <Tab label="statements" link="/billings/vendor_statements" alias="/billings" />
    <Tab label="billing_details" link="/billings/vendor_billings" />
  </List>
);

export default Tabs;
