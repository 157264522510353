/* eslint max-len: 0 */

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { bool, func, object, string } from 'prop-types';

import * as mealLabelActions from '../../actions/mealLabelActions';
import TemplateSettings from '../../components/TemplateSettings';

const TemplateSettingsContainer = ({
  isEditTemplateMode,
  catererId,
  saveLabelTemplate,
  setEditTemplateMode,
  successMsg,
  template,
  updateTempTemplate,
}) => (
  <TemplateSettings
    {
        ...{
          isEditTemplateMode,
          catererId,
          saveLabelTemplate,
          setEditTemplateMode,
          successMsg,
          template,
          updateTempTemplate,
        }
      }
  />
);

TemplateSettingsContainer.propTypes = {
  isEditTemplateMode: bool.isRequired,
  catererId: string.isRequired,
  saveLabelTemplate: func.isRequired,
  setEditTemplateMode: func.isRequired,
  successMsg: string,
  template: object.isRequired,
  updateTempTemplate: func.isRequired,
};

const mapStateToProps = ({
  $$mealLabelStore,
}) => ({
  isEditTemplateMode: $$mealLabelStore.get('isEditTemplateMode'),
  catererId: $$mealLabelStore.get('catererId'),
  template: $$mealLabelStore.get('selectedTemplate').toJS(),
  successMsg: $$mealLabelStore.get('successMsg'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  ...mealLabelActions,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)((TemplateSettingsContainer));
