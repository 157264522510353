import React from 'react';
import { formattedPrice, formatDisplayPrice } from 'libs/support/price';
import LargeBilling from 'libs/components/Icons/v2/LargeBilling';
import ArrowDown from 'libs/components/Icons/v2/ArrowDown';
import ArrowUp from 'libs/components/Icons/v2/ArrowUp';
import { withI18n } from 'libs/support/i18n';
import { object, bool, func } from 'prop-types';

const BillingDetails = ({
  orderBilling,
  showMoreBilling,
  setShowMoreBilling,
  translate,
}) => {
  const commissionKind = orderBilling?.customCommission ? 'Custom' : 'Standard';
  const commissionPercentage = parseFloat(orderBilling?.commissionPercentage);

  return (
    <div className="order-details-billing-box">
      <div className="order-details-billing-group-header">
        <div className="order-details-basket-box-header">
          <LargeBilling />
          <div className="order-details-group-text">
            <span className="order-details-basket-text-header">{translate('ordersShowDetailsBillingDetailsBilling')}</span>
            <span className="order-details-basket-text-subheader">
              {translate('ordersShowDetailsBillingDetailsBillingDescription')}
            </span>
          </div>
        </div>
        <div // eslint-disable-line
          className="order-details-billing-box-toggle"
          onClick={() => setShowMoreBilling(!showMoreBilling)}
        >
          <span className="order-details-show-more">{translate('ordersShowDetailsShowMore')}</span>
          <span className="order-details-toggle-icon">
            { showMoreBilling ? <ArrowUp /> : <ArrowDown /> }
          </span>
        </div>
      </div>
      { showMoreBilling && (
        <div className="order-details-group-body">
          <div className="billing-details-item">
            <span className="billing-details-item-label">
              {translate('ordersShowDetailsBillingDetailsCommission')} ({commissionKind}) - {commissionPercentage}%
            </span>
            <span className="billing-details-item-value">{formatDisplayPrice(orderBilling?.commissionAmount)}</span>
          </div>
          <div className="billing-details-item">
            <span className="billing-details-item-label">{translate('ordersShowDetailsBillingDetailsGst')}</span>
            <span className="billing-details-item-value">{formatDisplayPrice(orderBilling?.taxAmount)}</span>
          </div>
          <div className="billing-details-item">
            <span className="billing-details-item-label">{translate('ordersShowDetailsBillingDetailsOwedToCaterspot')}</span>
            <span className="billing-details-item-value">{formatDisplayPrice(orderBilling?.ownedCaterspotAmount)}</span>
          </div>
          <div className="billing-details-item">
            <span className="billing-details-item-label">{translate('ordersShowDetailsBillingDetailsOwedToVendor')}</span>
            <span className="billing-details-item-value">{formatDisplayPrice(orderBilling?.ownedVendorAmount)}</span>
          </div>
          <div className="billing-details-item billing-details-item-total">
            <span className="billing-details-item-label">{translate('ordersShowDetailsBillingDetailsTotalReceived')}</span>
            <span className="billing-details-item-value">{formattedPrice(orderBilling?.toBePaidAmount)}</span>
          </div>
        </div>
        )
      }

    </div>
  );
};

BillingDetails.propTypes = {
  orderBilling: object,
  showMoreBilling: bool,
  setShowMoreBilling: func,
  translate: func,
};

export default withI18n(BillingDetails);
