/* eslint camelcase: 0, max-len: 0 */

import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import humps from 'lodash-humps';
import Immutable from 'immutable';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';

import loggerMiddleware from 'libs/middlewares/loggerMiddleware';

import reducers, { initialStates } from '../reducers';

export default (props) => {
  const {
    current_outlet,
    locale,
    vendors,
  } = props;
  const {
    $$localeState,
    $$vendorsState,
  } = initialStates;

  const initialState = {
    $$localeStore: $$localeState.merge({
      locale,
    }),

    $$vendorsStore: $$vendorsState.merge({
      vendors: Immutable.fromJS(vendors.map((v) => humps(v))),
      currentOutlet: Immutable.fromJS({ ...humps(current_outlet) }),
    }),
  };

  const reducer = combineReducers(reducers);
  const composedStore = composeWithDevTools(applyMiddleware(thunkMiddleware, loggerMiddleware));

  return composedStore(createStore)(reducer, initialState);
};
