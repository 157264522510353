import React from 'react';
import { func, object } from 'prop-types';
import uppercamelcase from 'uppercamelcase';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withI18n } from 'libs/support/i18n';
import { SelectField } from 'libs/components/Forms';
import { useSelectInput } from 'libs/support/selectInput';
import { bindActionCreators } from 'redux';
import * as menuItemsActions from 'bundles/vendor/actions/menuItemsActions';
import { withRouter } from 'react-router';

const OPTIONS = [
  'all',
  'true',
  'false',
];

const Container = styled.div`
  min-width: 50%;

  @media (min-width: ${props => props.theme.breakPoints.lg}) {
    min-width: 150px;
  }
`;

const ActiveFilter = ({
  filterMenuItemsByActive,
  history,
  translate,
}) => {
  const url = new URL(window.location);
  const initialValue = url.searchParams.get('filter_active') || 'all';
  const options = OPTIONS.map((option) => (
    {
      label: translate(`vendorMenuItemActiveFilter${uppercamelcase(option)}`),
      value: option,
    }
  ));
  const [selectedOption, handleOptionChange] = useSelectInput(
    options,
    initialValue,
  );
  filterMenuItemsByActive(initialValue);
  const handleChange = (data) => {
    const newUrl = new URL(window.location);
    handleOptionChange(data);
    filterMenuItemsByActive(data.value);

    if (data.value === 'all') {
      newUrl.searchParams.delete('filter_active');
    } else {
      newUrl.searchParams.set('filter_active', data.value);
    }
    history.push(`${newUrl.pathname}${newUrl.search}`);
  };

  return (
    <Container>
      <SelectField
        id="menu_item_active"
        name="menu_item[active]"
        value={[selectedOption]}
        options={options}
        onChange={handleChange}
        isRequired
        isLefty
      />
    </Container>
  );
};

ActiveFilter.propTypes = {
  filterMenuItemsByActive: func.isRequired,
  history: object.isRequired,
  translate: func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  filterMenuItemsByActive: menuItemsActions.filterMenuItemsByActive,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withI18n(ActiveFilter)));
