/* eslint max-len: 0 */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { notBlank } from 'libs/support/string';
import ArrowRight from 'libs/components/Icons/v2/ArrowRight';
import { withI18n } from 'libs/support/i18n';

import HistoryOrderVariation from './HistoryOrderVariation';

const HistoryWithDiffV2 = ({ diff, translate }) => {
  const showHeaderChangesSection = !(notBlank(diff.getIn(['date', 'before'])) && notBlank(diff.getIn(['date', 'after'])));
  const isMobile = window.innerWidth <= 1200;
  const isChangedPresent = diff.get('changed').size > 0;
  return (
    <Fragment>
      {
        (notBlank(diff.get('date')) || (diff.get('changed').size > 0)) &&
          <Fragment>
            <div className="row mb-4">
              <div className="col-xs-12">
                <div className="order-diff__badge">
                  {translate('ordersShowDetailsOrderNoticeChangeRequestNoticeItemsChanged')}
                </div>
              </div>
            </div>

            {
              notBlank(diff.getIn(['date', 'before'])) &&
              notBlank(diff.getIn(['date', 'after'])) &&
              <div
                className={`history-with-diff-card-container ${isChangedPresent ? 'history-with-diff-card-container-changes changed-container' : ''}`}
              >
                <div className="history-with-diff-card">
                  <div className="history-with-diff-card-header">
                    <div className="history-with-diff-card-header-title">{translate('ordersShowDetailsOrderNoticeChangeRequestNoticeOriginalItem')}</div>
                  </div>
                  <div className="history-with-diff-card-body history-with-diff-card-body-delivery-changes">
                    <div className="order-diff__table-content">
                      <span className="diff-text-delivery-label font-weight-bold">{translate('ordersShowDetailsOrderNoticeChangeRequestNoticeDeliveryDate')}</span>
                      <br />
                      <div className="diff-text-delivery-changes">{moment(diff.getIn(['date', 'before'])).format('D MMMM YYYY, h:mma')}</div>
                    </div>
                  </div>
                </div>
                <ArrowRight />
                <div className="history-with-diff-card">
                  <div className="history-with-diff-card-header">
                    <div className="history-with-diff-card-header-title">{translate('ordersShowDetailsOrderNoticeChangeRequestNoticeChangedItem')}</div>
                  </div>
                  <div className="history-with-diff-card-body history-with-diff-card-body-delivery-changes">
                    <div className="order-diff__table-content">
                      <span className="diff-text-delivery-label font-weight-bold changed">{translate('ordersShowDetailsOrderNoticeChangeRequestNoticeDeliveryDate')}</span>
                      <br />
                      <div className="diff-text-delivery-changes changed">{moment(diff.getIn(['date', 'after'])).format('D MMMM YYYY, h:mma')}</div>
                    </div>
                  </div>
                </div>
              </div>
            }

            {
              diff.get('changed').map((item, index) => (
                <div
                  key={index} // eslint-disable-line react/no-array-index-key
                  className={`history-with-diff-card-container history-with-diff-card-container-changes changed-container ${diff.get('changed').size === (index + 1) ? 'last-changed-container' : ''}`}
                >
                  <div className="history-with-diff-card history-with-diff-card-changes">
                    { ((showHeaderChangesSection && index === 0) || isMobile) &&
                      <div className="history-with-diff-card-header">
                        <div className="history-with-diff-card-header-title">{translate('ordersShowDetailsOrderNoticeChangeRequestNoticeOriginalItem')}</div>
                      </div>
                    }
                    <div className="history-with-diff-card-body">
                      <HistoryOrderVariation
                        item={item.get('before')}
                      />
                    </div>
                  </div>
                  <ArrowRight />
                  <div className="history-with-diff-card history-with-diff-card-changes">
                    { ((showHeaderChangesSection && index === 0) || isMobile) &&
                      <div className="history-with-diff-card-header">
                        <div className="history-with-diff-card-header-title">{translate('ordersShowDetailsOrderNoticeChangeRequestNoticeChangedItem')}</div>
                      </div>
                    }
                    <div className="history-with-diff-card-body">
                      <HistoryOrderVariation
                        item={item.get('after')}
                        after
                      />
                    </div>
                  </div>
                </div>
              ))
            }
          </Fragment>
      }

      {
        diff.get('added').size > 0 &&
          <Fragment>
            <div className="row">
              <div className="col-xs-12">
                <div className="order-diff__badge">
                  {translate('ordersShowDetailsOrderNoticeChangeRequestNoticeItemsAdded')}
                </div>
              </div>
            </div>

            <div className="history-with-diff-card-container history-with-diff-card-container-changes">
              <div className="history-with-diff-card history-with-diff-card-changes-added">
                <div className="history-with-diff-card-body">
                  {
                    diff.get('added').map((item, index) => (
                      <HistoryOrderVariation
                        type="wide"
                        key={index} // eslint-disable-line react/no-array-index-key
                        item={item}
                      />
                    ))
                  }
                </div>
              </div>
            </div>
          </Fragment>
      }

      {
        diff.get('removed').size > 0 &&
          <Fragment>
            <div className="row">
              <div className="col-xs-12">
                <div className="order-diff__badge">
                  {translate('ordersShowDetailsOrderNoticeChangeRequestNoticeItemsRemoved')}
                </div>
              </div>
            </div>

            <div className="history-with-diff-card-container history-with-diff-card-container-changes">
              <div className="history-with-diff-card history-with-diff-card-changes-removed">
                <div className="history-with-diff-card-body">
                  {
                    diff.get('removed').map((item, index) => (
                      <HistoryOrderVariation
                        type="wide"
                        key={index} // eslint-disable-line react/no-array-index-key
                        item={item}
                      />
                    ))
                  }
                </div>
              </div>
            </div>
          </Fragment>
      }
    </Fragment>
  );
};

HistoryWithDiffV2.propTypes = {
  diff: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

export default withI18n(HistoryWithDiffV2);
