export const ADD_LABEL = 'ADD_LABEL';
export const REMOVE_LABEL = 'REMOVE_LABEL';
export const UPDATE_LABEL = 'UPDATE_LABEL';
export const DUPLICATE_LABEL = 'DUPLICATE_LABEL';
export const SELECT_TEMPLATE = 'SELECT_TEMPLATE';
export const UPDATE_TEMP_TEMPLATE = 'UPDATE_TEMP_TEMPLATE';
export const NEW_TEMPLATE = 'NEW_TEMPLATE';
export const SET_EDIT_TEMPLATE_MODE = 'SET_EDIT_TEMPLATE_MODE';

export const SAVING_LABEL_TEMPLATE = 'SAVING_LABEL_TEMPLATE';
export const SAVE_LABEL_TEMPLATE_SUCCESS = 'SAVE_LABEL_TEMPLATE_SUCCESS';
export const SAVE_LABEL_TEMPLATE_FAILURE = 'SAVE_LABEL_TEMPLATE_FAILURE';

export const FETCHING_LABEL_TEMPLATES = 'FETCHING_LABEL_TEMPLATES';
export const FETCH_LABEL_TEMPLATES_SUCCESS = 'FETCH_LABEL_TEMPLATES_SUCCESS';
export const FETCH_LABEL_TEMPLATES_FAILURE = 'FETCH_LABEL_TEMPLATES_FAILURE';

export const DELETING_LABEL_TEMPLATE = 'DELETING_LABEL_TEMPLATE';
export const DELETE_LABEL_TEMPLATE_SUCCESS = 'DELETE_LABEL_TEMPLATE_SUCCESS';
export const DELETE_LABEL_TEMPLATE_FAILURE = 'DELETE_LABEL_TEMPLATE_FAILURE';
