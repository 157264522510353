/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment } from 'react';
import LargeHistory from 'libs/components/Icons/v2/LargeHistory';
import ArrowDown from 'libs/components/Icons/v2/ArrowDown';
import ArrowUp from 'libs/components/Icons/v2/ArrowUp';
import ChevronRight from 'libs/components/Icons/v2/ChevronRight';
import uppercamelcase from 'uppercamelcase';
import moment from 'moment';
import { withI18n } from 'libs/support/i18n';
import { array, bool, func, object } from 'prop-types';

import OrderHistoryModal from './OrderHistoryModal';

const OrderHistory = ({
  orderHistories,
  showMoreHistory,
  setShowMoreHistory,
  translate,
  order,
  openModalHistory,
  setOpenModalHistory,
  selectedOrderHistory,
  onClickSetHistory,
}) => {
  const showName = (orderHistory) => {
    if (orderHistory.userEmail.includes('caterspot.com')) {
      return 'Caterspot';
    }
    return orderHistory.userName;
  };

  return (
    <Fragment>
      <div className="order-details-history-box">
        <div className="order-details-billing-group-header">
          <div className="order-details-basket-box-header">
            <LargeHistory />
            <div className="order-details-group-text">
              <span className="order-details-basket-text-header">{translate('ordersShowDetailsOrderHistoryHistory')}</span>
              <span className="order-details-basket-text-subheader">
                All order and delivery updates from the customer, CaterSpot or yourself will be stored here.
              </span>
            </div>
          </div>
          <div
            className="order-details-billing-box-toggle"
            onClick={() => setShowMoreHistory(!showMoreHistory)}
          >
            <span className="order-details-show-more">{translate('ordersShowDetailsShowMore')}</span>
            <span className="order-details-toggle-icon">
              { showMoreHistory ? <ArrowUp /> : <ArrowDown /> }
            </span>
          </div>
        </div>
        { showMoreHistory && (
          <Fragment>
            <div className="order-details-group-body order-details-group-body-desktop">
              <div className="history-details-header">
                <span className="history-details-header-date">{translate('ordersShowDetailsOrderHistoryDateTime')}</span>
                <span className="history-details-header-actions">{translate('ordersShowDetailsOrderHistoryAction')}</span>
                <span className="history-details-header-user">{translate('ordersShowDetailsOrderHistoryUser')}</span>
                <span className="history-details-header-view" />
              </div>
              { orderHistories && orderHistories.map((orderHistory) => (
                <div className="history-details-body mt-2">
                  <span className="history-details-body-date">
                    {moment(orderHistory.createdAt).format('DD MMM YYYY h:mm A')}
                  </span>
                  <span className="history-details-body-actions">
                    {translate(`ordersStates${uppercamelcase(orderHistory.state)}`)}
                  </span>
                  <span className="history-details-body-user">
                    {showName(orderHistory)}
                  </span>
                  <span className="history-details-body-view">
                    <a
                      href="#"
                      onClick={(e) => onClickSetHistory(e, orderHistory)}
                    >
                      View
                    </a>
                  </span>
                </div>
              ))}
            </div>
            <div className="order-details-group-body order-details-group-body-mobile">
              { orderHistories && orderHistories.map((orderHistory) => (
                <div className="history-details-body mt-2">
                  <span className="history-details-body-date">
                    {moment(orderHistory.createdAt).format('DD MMM YYYY h:mm A')} • By {showName(orderHistory)}
                  </span>
                  <span className="history-details-body-actions">
                    {translate(`ordersStates${uppercamelcase(orderHistory.state)}`)}
                    <a
                      href="#"
                      onClick={(e) => onClickSetHistory(e, orderHistory)}
                    >
                      <ChevronRight />
                    </a>
                  </span>
                </div>
              ))}
            </div>
          </Fragment>
          )
        }
      </div>
      <OrderHistoryModal
        orderHistory={selectedOrderHistory}
        onHide={() => setOpenModalHistory(false)}
        show={openModalHistory}
        order={order}
      />
    </Fragment>
  );
};

OrderHistory.propTypes = {
  orderHistories: array,
  showMoreHistory: bool,
  setShowMoreHistory: func,
  translate: func,
  order: object,
  openModalHistory: bool,
  setOpenModalHistory: func,
  selectedOrderHistory: object,
  onClickSetHistory: func,
};

export default withI18n(OrderHistory);
