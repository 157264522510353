/* eslint max-len: 0 */

import React from 'react';

const ChevronDown = (_props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icons/chevron-down">
      <rect width="24" height="24" rx="12" fill="#EAEAEB" />
      <path id="Vector (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M6.25105 8.82258C6.58579 8.48785 7.1285 8.48785 7.46323 8.82258L12 13.3594L16.5368 8.82258C16.8715 8.48785 17.4142 8.48785 17.7489 8.82258C18.0837 9.15732 18.0837 9.70003 17.7489 10.0348L12.6061 15.1776C12.2714 15.5124 11.7286 15.5124 11.3939 15.1776L6.25105 10.0348C5.91632 9.70003 5.91632 9.15732 6.25105 8.82258Z" fill="#515154" />
    </g>
  </svg>
);
export default ChevronDown;
