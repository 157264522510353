import React from 'react';
import { oneOfType, bool, func, object, string, number } from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import {
  Input as StyledInput,
} from 'libs/support/sharedComponents';
import { FieldGroup } from 'libs/components/Forms';

const Input = styled(StyledInput)`
  &.--hide-stepper {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }
  }

  &.--right-aligned {
    text-align: right;
  }

  &.--add-on {
    border-radius: 4px 0 0 4px;
  }
`;

const InputGroup = styled.div`
  display: flex;
  white-space: nowrap;
  align-items: center;
`;

const AddOn = styled.span`
  align-items: center;
  border: 1px solid ${props => props.theme.colors.gainsboro};
  border-radius: 0 4px 4px 0;
  box-shadow: inset 0px 1px 1px ${props => props.theme.colors.gray200};
  display: flex;
  height: 32px;
  padding: 0 1rem;
`;

const InputField = ({
  accept,
  addOn,
  id,
  inputRef,
  inputStyle,
  isDisabled,
  isRequired,
  label,
  name,
  onBlur,
  onChange,
  optionalText,
  placeholder,
  rightAligned = false,
  step,
  type = 'text',
  hideStepper = false,
  value,
  pattern,
}) => {
  const className = classnames({
    '--hide-stepper': hideStepper,
    '--right-aligned': rightAligned,
    '--add-on': addOn,
  });

  const renderInput = () => (
    <InputGroup>
      <Input
        {
          ...{
            accept,
            disabled: isDisabled,
            id,
            onBlur,
            onChange,
            placeholder,
            step,
            type,
            name,
            style: inputStyle,
          }
        }
        ref={inputRef}
        defaultValue={value}
        required={isRequired}
        className={className}
        pattern={pattern}
      />
      { addOn && <AddOn>{addOn}</AddOn> }
    </InputGroup>
  );

  if (label) {
    return (
      <FieldGroup
        label={label}
        htmlFor={id}
        isRequired={isRequired}
        optionalText={optionalText}
      >
        {renderInput()}
      </FieldGroup>
    );
  }

  return renderInput();
};

InputField.propTypes = {
  accept: string,
  addOn: string,
  hideStepper: bool,
  id: string,
  inputRef: func,
  inputStyle: object,
  isDisabled: bool,
  isRequired: bool,
  label: string,
  name: string,
  onBlur: func,
  onChange: func,
  optionalText: string,
  placeholder: string,
  rightAligned: bool,
  step: string,
  type: string,
  value: oneOfType([string, number]),
  pattern: string,
};

export default InputField;
