/* eslint import/no-named-default: 0 */
import React, { Fragment, useState, useEffect } from 'react';
import { bool, object, func, string } from 'prop-types';
import styled from 'styled-components';
import { default as MainHeader } from 'bundles/billings/components/Billings/Header';
import Tabs from 'bundles/billings/components/Billings/Tabs';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as vendorBillingActions from 'bundles/billings/actions/vendorBillingActions';
import * as catererActions from 'bundles/common/actions/catererActions';
import * as outletsActions from 'bundles/common/actions/outletsActions';
import {
  Spacer,
} from 'libs/support/sharedComponents';
import { withAppContext } from 'libs/support/appContext';
import Form from './Form';
import Actions from './Actions';
import Flash from './Flash';

const Container = styled.div`
  padding: 1.5rem;
`;

const BillingDetailsContainer = ({
  caterer,
  catererId,
  fetchCaterer,
  fetchOutlets,
  fetchVendorBilling,
  isFindSuccess,
  isOutletsFindSuccess,
}) => {
  const [isInitialized, setInitialize] = useState(false);

  useEffect(() => {
    if (!isInitialized) {
      setInitialize(true);

      if (caterer.size === 0) {
        fetchCaterer(catererId);
      }

      if (!isFindSuccess) {
        fetchVendorBilling();
      }

      if (!isOutletsFindSuccess) {
        fetchOutlets();
      }
    }
  }, [isInitialized]);

  return (
    <Fragment>
      <MainHeader />
      <Tabs />
      <Container>
        <Flash />
        <Actions />
        <hr />
        <Form />
        <Spacer />
      </Container>
    </Fragment>
  );
};

BillingDetailsContainer.propTypes = {
  catererId: string.isRequired,
  caterer: object,
  fetchCaterer: func.isRequired,
  fetchOutlets: func.isRequired,
  fetchVendorBilling: func.isRequired,
  isFindSuccess: bool,
  isOutletsFindSuccess: bool,
};

const mapStateToProps = ({
  $$catererStore,
  $$vendorBillingStore,
  $$outletsStore,
}) => ({
  catererId: $$catererStore.get('catererId'),
  caterer: $$catererStore.get('caterer'),
  isOutletsFindSuccess: $$outletsStore.get('isFindSuccess'),
  isFindSuccess: $$vendorBillingStore.get('isFindSuccess'),
  vendorBilling: $$vendorBillingStore.get('vendorBilling'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCaterer: catererActions.fetchCaterer,
  fetchOutlets: outletsActions.fetchOutlets,
  fetchVendorBilling: vendorBillingActions.findVendorBilling,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withAppContext(BillingDetailsContainer));
