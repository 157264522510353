/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-restricted-globals */
/* eslint-disable quotes */
import React, { Fragment } from 'react';
import classnames from 'classnames';
import { array, func, string } from 'prop-types';
import { trackClickedTodayFilter, trackClickedTomorrowFilter } from "packs/events";

const MobileOrderListHeader = ({
  orders,
  tomorrowOrders,
  tab,
  setTab,
  tabTypes,
}) => {
  const handleTodayTabOnClick = () => {
    trackClickedTodayFilter();
    setTab(tabTypes.today);
  };

  const handleTomorrowTabOnClick = () => {
    trackClickedTomorrowFilter();
    setTab(tabTypes.tomorrow);
  };

  return (
    <Fragment>
      <div className="mt-2 header">
        <span className="order-text">Orders</span>
        <div className="d-flex tabs mt-2">
          <div
            className={classnames('d-flex pointer', {
                'other-tab': tab !== tabTypes.today,
                'active-tab': tab === tabTypes.today,
              })}
            onClick={handleTodayTabOnClick}
          >
            <span
              className="tab-text"
            >
              Today
            </span>
            <span
              className={classnames({
                  'active-tab-value': tab === tabTypes.today,
                  'other-tab-value': tab !== tabTypes.today,
                })}
            >
              {orders?.length}
            </span>
          </div>
          <div
            className={classnames('d-flex pointer', {
                'active-tab': tab === tabTypes.tomorrow,
                'other-tab': tab !== tabTypes.tomorrow,
              })}
            onClick={handleTomorrowTabOnClick}
          >
            <span
              className="tab-text"
            >
              Tomorrow
            </span>
            <span
              className={classnames({
                  'active-tab-value': tab === tabTypes.tomorrow,
                  'other-tab-value': tab !== tabTypes.tomorrow,
                })}
            >
              {tomorrowOrders?.length}
            </span>
          </div>
        </div>
        <span />
      </div>
    </Fragment>
  );
};

MobileOrderListHeader.propTypes = {
  orders: array,
  tomorrowOrders: array,
  tab: string,
  setTab: func,
  tabTypes: string,
};

export default MobileOrderListHeader;
