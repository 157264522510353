import React from 'react';
import { func, object } from 'prop-types';
import {
  GreyFont,
  Row,
  Cell,
  VerticalRule,
} from 'libs/support/sharedComponents';
import {
  EditButton,
} from 'bundles/common/components/Buttons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as vendorBillingActions from 'bundles/billings/actions/vendorBillingActions';
import VendorBillingDetail from '../VendorBillingDetail';

const Outlet = ({
  outlet,
  outletBillingDetail,
  showOutletBillingDetailModal,
}) => {
  const handleOnClick = () => showOutletBillingDetailModal(outlet, outletBillingDetail);
  const displayEntity = () => (
    <div>
      <div>{outletBillingDetail.get('billingName')}</div>
      <div>{outletBillingDetail.get('businessRegistrationNumber')}</div>
      <div>{outletBillingDetail.get('taxRegistrationNumber')}</div>
    </div>
  );
  const displayPayment = () => (
    <div>
      <div>
        <span>{outletBillingDetail.get('bankName')}</span>
        <VerticalRule size="sm" />
        <span>{outletBillingDetail.get('swiftCode')}</span>
        <VerticalRule size="sm" />
        <span>{outletBillingDetail.get('bankCode')}</span>
      </div>
      <div>
        {outletBillingDetail.get('bankAccountNumber')}
      </div>
      <div>
        {outletBillingDetail.get('bankAccountHolder')}
      </div>
    </div>
  );

  return (
    <Row verticalAlign="top">
      <Cell>{outlet.get('name')}</Cell>
      <Cell>
        <input
          type="hidden"
          name="vendor_billing[vendor_attributes][outlets_attributes][][id]"
          value={outlet.get('uuid')}
        />
        <VendorBillingDetail
          vendorBillingDetail={outletBillingDetail}
          billableId={outlet.get('uuid')}
          billableType="Outlet"
          isHidden
        />
        {
          outletBillingDetail.get('businessRegistrationNumber') ?
            displayEntity() :
            <i><GreyFont>Missing</GreyFont></i>
        }
      </Cell>
      <Cell>
        {
          outletBillingDetail.get('bankAccountNumber') ?
            displayPayment() :
            <i><GreyFont>Missing</GreyFont></i>
        }
      </Cell>
      <Cell>
        <EditButton
          onClick={handleOnClick}
          className="test-edit-outlet"
        />
      </Cell>
    </Row>
  );
};

Outlet.propTypes = {
  outlet: object.isRequired,
  outletBillingDetail: object.isRequired,
  showOutletBillingDetailModal: func.isRequired,
};

const mapStateToProps = () => {};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showOutletBillingDetailModal: vendorBillingActions.showOutletBillingDetailModal,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Outlet);
