/* eslint max-len: 0 */

import React from 'react';

const LargeBasket = (_props) => (
  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27 2L32 21" stroke="#141415" strokeWidth="3" strokeLinecap="round" />
    <path d="M17 2L12 21" stroke="#141415" strokeWidth="3" strokeLinecap="round" />
    <path d="M5.37785 19C3.51547 19 2.10311 20.6792 2.42221 22.514L5.72656 41.514C5.97647 42.951 7.22366 44 8.68219 44H35.3178C36.7763 44 38.0235 42.951 38.2734 41.514L41.5778 22.514C41.8969 20.6792 40.4845 19 38.6222 19H5.37785Z" fill="white" stroke="#141415" strokeWidth="2" />
    <rect x="1" y="17" width="43" height="8" rx="2" fill="white" stroke="#141415" strokeWidth="2" />
    <path d="M17 28L18 40" stroke="#141415" strokeWidth="2" strokeLinecap="round" />
    <path d="M27 28L26 40" stroke="#141415" strokeWidth="2" strokeLinecap="round" />
    <path d="M10 28L12 40" stroke="#141415" strokeWidth="2" strokeLinecap="round" />
    <path d="M34 28L32 40" stroke="#141415" strokeWidth="2" strokeLinecap="round" />
  </svg>

);
export default LargeBasket;
