import React from 'react';
import { bool, object } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withI18n } from 'libs/support/i18n';
import { connect } from 'react-redux';
import SearchComponent from 'libs/components/Search';
import styled from 'styled-components';
import { trackClickedSearchItemButton } from 'packs/events';

const Container = styled.div`
  margin-bottom: 1rem;

  width: 100%;

  @media (min-width: ${props => props.theme.breakPoints.lg}) {
    margin-right: 1rem;
    width: 366px;
  }
`;

const Search = ({
  history,
  isFetchingMenuItems,
}) => {
  const url = new URL(window.location);
  const keyword = url.searchParams.get('keyword');
  const onSearch = (newKeyword) => {
    if (newKeyword) {
      trackClickedSearchItemButton();
      url.searchParams.set('keyword', newKeyword);
    } else {
      url.searchParams.delete('keyword');
    }
    url.searchParams.delete('page');
    history.push(`${url.pathname}${url.search}`);
  };

  return (
    <Container>
      <SearchComponent
        className="items-search"
        isProcessing={isFetchingMenuItems}
        {
          ...{
            keyword,
            onSearch,
          }
        }
      />
    </Container>
  );
};

Search.propTypes = {
  history: object.isRequired,
  isFetchingMenuItems: bool,
};

const mapStateToProps = (state) => ({
  isFetchingMenuItems: state.$$menuItemsStore.get('isFetchingMenuItems'),
});

export default connect(mapStateToProps)(withI18n(withRouter(Search)));
