import { useQuery } from 'react-query';
import { httpClient } from 'libs/support/http-client';

export const useCart = (params) => (
  useQuery([
    'CART',
    params.orderableId,
  ], async () => {
    const { data } = await httpClient.get(`/carts/${params.orderableId}.json`);
    return data;
  }, {
    staleTime: Infinity,
    enabled: params.isDraftOrder,
  })
);
