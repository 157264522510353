/* eslint max-len: 0 */

import dashboardReducer, { $$initialState as $$dashboardState } from 'bundles/v2/dashboards/reducers/dashboardReducer';
import outletSelectorReducer, { $$initialState as $$outletSelectorState } from 'bundles/v2/outlet_selectors/reducers/outletSelectorReducer';
import orderReducer, { $$initialState as $$orderState } from 'bundles/v2/orders/reducers/orderReducer';
import localeReducer, { $$initialState as $$localeState } from 'bundles/common/reducers/localeReducer';

export default {
  $$outletSelectorStore: outletSelectorReducer,
  $$dashboardStore: dashboardReducer,
  $$orderStore: orderReducer,
  $$localeStore: localeReducer,
};

export const initialStates = {
  $$outletSelectorState,
  $$dashboardState,
  $$orderState,
  $$localeState,
};
