import React from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import MenuItemNestedAttributeFieldGroup from './MenuItemNestedAttributeFieldGroup';

const SizeRangeFieldGroup = ({
  menuItem,
  sizeRanges,
}) => (
  <MenuItemNestedAttributeFieldGroup
    label="Size Range"
    options={sizeRanges}
    optionKey="size_range"
    item={menuItem.get('menu_item_size_range')}
    hasRange
    isInteger
    isRequired
  />
);

SizeRangeFieldGroup.propTypes = {
  menuItem: object.isRequired,
  sizeRanges: object.isRequired,
};

const mapStateToProps = (state) => ({
  menuItem: state.$$menuItemsStore.get('menuItem'),
  sizeRanges: state.$$filtersStore.get('sizeRanges'),
});

export default connect(mapStateToProps)(SizeRangeFieldGroup);
