/* eslint import/no-named-default: 0 */
import React, { Fragment, useRef, useState, useEffect, useCallback } from 'react';
import { bool, object, func, string } from 'prop-types';
import styled from 'styled-components';
import { default as MainHeader } from 'bundles/vendor/components/Vendor/Header';
import Tabs from 'bundles/vendor/components/Vendor/Tabs';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as menuItemsActions from 'bundles/vendor/actions/menuItemsActions';
import * as catererActions from 'bundles/common/actions/catererActions';
import * as categoriesActions from 'bundles/vendor/actions/categoriesActions';
import * as messagesActions from 'bundles/common/actions/messagesActions';
import Flash from 'bundles/vendor/components/Item/Flash';
import Pagination from 'libs/components/Pagination';
import PaginationMetadata from 'libs/components/Pagination/Metadata';
import {
  Spacer,
} from 'libs/support/sharedComponents';
import { withAppContext } from 'libs/support/appContext';
import { withI18n } from 'libs/support/i18n';
import { trackViewedV2MenuPage } from 'packs/events';
import Header from './Header';
import Actions from './Actions';
import List from './List';

const Container = styled.div`
  padding: 1.5rem;
`;

const CHANNEL = 'menuItemExportChannel';

const ItemsContainer = ({
  cable,
  caterer,
  catererId,
  closeInUseMealPlanMessage,
  fetchCategories,
  fetchCaterer,
  fetchMenuItems,
  isCategoriesFetched,
  isMenuItemsFetched,
  menuItems,
  metadata,
  showAlertMessage,
  showInUseMealPlanMessage,
  translate,
}) => {
  const [isInitialized, setInitialize] = useState(false);
  const url = new URL(window.location);

  const page = url.searchParams.get('page') || 1;
  const keyword = url.searchParams.get('keyword');
  const active = url.searchParams.get('filter_active') || 'all';
  const categoryId = url.searchParams.get('filter_category_id') || 'all';
  const channel = useRef(null);

  const subscribeChannel = useCallback(() => {
    channel.current = cable.subscribe({
      channel: CHANNEL,
    });
  }, []);

  useEffect(() => {
    if (!isInitialized) {
      setInitialize(true);

      if (caterer.size === 0) {
        fetchCaterer(catererId);
      }

      if (!isCategoriesFetched) {
        fetchCategories();
      }
    }
  }, [isInitialized]);

  useEffect(() => {
    if (showInUseMealPlanMessage) {
      showAlertMessage({
        title: translate('vendorModalsEditItemWarningTitle'),
        message: translate('vendorModalsEditItemWarningMessage'),
        submit: () => closeInUseMealPlanMessage(),
      });
    }
  }, [showInUseMealPlanMessage]);

  useEffect(() => {
    trackViewedV2MenuPage();
    fetchMenuItems({ page, keyword, active, category_id: categoryId });
  }, [page, keyword, active, categoryId]);

  useEffect(() => {
    if (!channel.current) subscribeChannel();

    return () => {
      if (channel.current) { return cable.unsubscribe(channel.current); }

      return false;
    };
  }, []);

  return (
    <Fragment>
      <MainHeader />
      <Tabs />
      <Container>
        <Flash />
        <Spacer />
        <Header />
        <Actions />
        {
          isMenuItemsFetched && metadata && metadata.size > 0 &&
            <PaginationMetadata
              currentCount={menuItems.size}
              currentPage={metadata.get('page')}
              perPage={metadata.get('per_page')}
              totalCount={metadata.get('total_count')}
              totalPages={metadata.get('total_pages')}
            />
        }
        <List />
        <Spacer />
        {
          isMenuItemsFetched && metadata && metadata.size > 0 &&
            <Pagination
              currentPage={metadata.get('page')}
              totalPages={metadata.get('total_pages')}
            />
        }
      </Container>
    </Fragment>
  );
};

ItemsContainer.propTypes = {
  cable: object.isRequired,
  catererId: string.isRequired,
  caterer: object,
  closeInUseMealPlanMessage: func.isRequired,
  fetchCategories: func.isRequired,
  fetchCaterer: func.isRequired,
  fetchMenuItems: func.isRequired,
  isCategoriesFetched: bool,
  isMenuItemsFetched: bool,
  menuItems: object,
  metadata: object,
  showAlertMessage: func.isRequired,
  showInUseMealPlanMessage: bool,
  translate: func.isRequired,
};

const mapStateToProps = ({
  $$categoriesStore,
  $$catererStore,
  $$menuItemsStore,
}) => ({
  catererId: $$catererStore.get('catererId'),
  caterer: $$catererStore.get('caterer'),
  isCategoriesFetched: $$categoriesStore.get('isCategoriesFetched'),
  isMenuItemsFetched: $$menuItemsStore.get('isMenuItemsFetched'),
  menuItems: $$menuItemsStore.get('menuItems'),
  showInUseMealPlanMessage: $$menuItemsStore.get('showInUseMealPlanMessage'),
  metadata: $$menuItemsStore.get('metadata'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCategories: categoriesActions.fetchCategories,
  fetchCaterer: catererActions.fetchCaterer,
  fetchMenuItems: menuItemsActions.fetchMenuItems,
  closeInUseMealPlanMessage: menuItemsActions.closeInUseMealPlanMessage,
  showAlertMessage: messagesActions.showAlertMessage,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withAppContext(withI18n(ItemsContainer)));
