/* eslint max-len:0 */

import React from 'react';

const Upload = (_props) => (
  <svg width="21" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 0C8.91 0 6.48 2.3475 6.14025 5.34375C5.48673 5.44906 4.87385 5.7291 4.36646 6.15423C3.85908 6.57936 3.47608 7.13376 3.258 7.75875C1.413 8.2905 0 9.936 0 12C0 14.493 2.007 16.5 4.5 16.5H19.5C21.993 16.5 24 14.493 24 12C24 10.68 23.3587 9.498 22.4295 8.6715C22.2555 6.036 20.1532 3.933 17.508 3.7965C16.605 1.59975 14.5335 0 12 0ZM12 1.5C14.0715 1.5 15.7275 2.8275 16.3125 4.71L16.4775 5.25H17.25C19.3162 5.25 21 6.93375 21 9V9.375L21.3045 9.60975C21.672 9.8914 21.9706 10.253 22.1777 10.6671C22.3849 11.0812 22.4951 11.537 22.5 12C22.5 13.707 21.207 15 19.5 15H4.5C2.793 15 1.5 13.707 1.5 12C1.5 10.485 2.5875 9.309 3.96 9.0705L4.45275 8.97675L4.5465 8.48325C4.7715 7.473 5.667 6.75 6.75 6.75H7.5V6C7.5 3.4725 9.4725 1.5 12 1.5ZM12 5.6955L11.46 6.21075L8.46 9.21075L9.54 10.2908L11.25 8.57775V13.5H12.75V8.57775L14.46 10.2892L15.54 9.20925L12.54 6.20925L12 5.69475V5.6955Z" fill="#7D9E1D" />
  </svg>
);

export default Upload;
