import React, { Fragment } from 'react';
import { object } from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  Nbsp,
} from 'libs/support/sharedComponents';
import { usePolicies } from 'bundles/common/queries/policies';
import Search from './Search';
import ActiveFilter from './ActiveFilter';
import AddButton from './AddButton';
import ExportButton from './ExportButton';
import CategoryFilter from './CategoryFilter';
import DuplicateAction from './DuplicateAction';
import ImportButton from './ImportButton';
import SelectedMenuItems from './SelectedMenuItems';
import MobileDropdown from './MobileDropdown';

const Container = styled.div`
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  width: 100%;

  @media (min-width: ${props => props.theme.breakPoints.md}) {
    width: auto;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  flex: 1;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;

  @media (min-width: ${props => props.theme.breakPoints.lg}) {
    width: auto;
  }
`;

const DesktopOnly = styled.div`
  display: none;

  @media (min-width: ${props => props.theme.breakPoints.lg}) {
    display: flex;
  }
`;

const MobileOnly = styled.div`
  display: block;

  @media (min-width: ${props => props.theme.breakPoints.lg}) {
    display: none;
  }
`;

const Actions = ({
  selectedIds,
}) => {
  const policiesResponse = usePolicies();
  const { policies } = policiesResponse.isSuccess ? policiesResponse.data : {};

  return (
    <Container className="items-actions">
      <LeftContainer>
        <Search />

        <FilterContainer>
          <ActiveFilter />
          <CategoryFilter />
        </FilterContainer>
      </LeftContainer>

      <RightContainer>
        <SelectedMenuItems />
        { policies?.menuItem?.create && policies?.menuItem?.update &&
          <Fragment>
            <DesktopOnly>
              {
                selectedIds.size === 0 &&
                  <Fragment>
                    <ImportButton />
                    <Nbsp />
                  </Fragment>
              }
              <ExportButton />
              <Nbsp />
            </DesktopOnly>
            {
              selectedIds.size > 0 ?
                <DuplicateAction /> :
                <AddButton />
            }
            <MobileOnly>
              <Nbsp />
              <MobileDropdown />
            </MobileOnly>
          </Fragment>
        }
      </RightContainer>
    </Container>
  );
};

Actions.propTypes = {
  selectedIds: object,
};

const mapStateToProps = ({
  $$menuItemsStore,
}) => ({
  selectedIds: $$menuItemsStore.get('selectedIds'),
});

export default connect(mapStateToProps)(Actions);
