import { trackEvent } from 'libs/support/segment';

const urlSearchParams = new URLSearchParams(window.location.search);
const searchParamsObject = Object.fromEntries(urlSearchParams.entries());

const buildViewParameters = (params = {}) => ({
  referrer: document.referrer,
  pagetype: 'dashboard',
  country: window.CS.userTraits.country,
  url: window.location.href,
  search: searchParamsObject,
  ...params,
});

export const trackViewedV2Dashboard = () => {
  trackEvent('Viewed_V2_Dashboard', buildViewParameters());
};

export const trackViewedV2OrdersPage = () => {
  trackEvent('Viewed_V2_Orders_Page', buildViewParameters());
};

export const trackViewedV2MenuPage = () => {
  trackEvent('Viewed_V2_Menu_Page', buildViewParameters());
};

export const trackViewedV2BillingPage = () => {
  trackEvent('Viewed_V2_Billing_Page', buildViewParameters());
};

export const trackViewedV2UsersPage = () => {
  trackEvent('Viewed_V2_Users_Page', buildViewParameters());
};

export const trackClickedTodayFilter = () => {
  trackEvent('Clicked_Today_Filter', {});
};

export const trackClickedTomorrowFilter = () => {
  trackEvent('Clicked_Tomorrow_Filter', {});
};

export const trackClickedSeeAllOrdersButton = () => {
  trackEvent('Clicked_See_All_Orders_Button', {});
};

export const trackClickedShowMoreButton = () => {
  trackEvent('Clicked_Show_More_Button', {});
};

export const trackClickedLoadMoreOrders = () => {
  trackEvent('Clicked_Load_More_Orders', {});
};

export const trackOrderDateChange = () => {
  trackEvent('Order_Date_Change', {});
};

export const trackDeliveryDateChange = () => {
  trackEvent('Delivery_Date_Change', {});
};

export const trackSearchBarUsed = () => {
  trackEvent('Search_Bar_Used', {});
};

export const trackStatusState = (status) => {
  trackEvent('Set_Status_State', {
    statusState: status,
  });
};

export const trackSortState = (sort) => {
  trackEvent('Set_Sort_State', {
    sortState: sort,
  });
};

export const trackClickedSaveForAddItem = () => {
  trackEvent('Clicked_Save_For_Add_Item', {});
};

export const trackClickedSaveForEditItem = () => {
  trackEvent('Clicked_Save_For_Edit_Item', {});
};

export const trackClickedSaveForAddCategory = () => {
  trackEvent('Clicked_Save_For_Add_Category', {});
};

export const trackClickedSaveForEditCategory = () => {
  trackEvent('Clicked_Save_For_Edit_Category', {});
};

export const trackClickedSearchItemButton = () => {
  trackEvent('Clicked_Search_Item_Button', {});
};

export const trackClickedImportCsvButton = () => {
  trackEvent('Clicked_Import_CSV_Button', {});
};

export const trackClickedExportCsvButton = () => {
  trackEvent('Clicked_Export_CSV_Button', {});
};

export const trackClickedDownloadStatement = () => {
  trackEvent('Clicked_Download_Statement', {});
};

export const trackClickedSaveForBillingDetails = () => {
  trackEvent('Clicked_Save_For_Billing_Details', {});
};

export const trackClickedSaveForAddUser = () => {
  trackEvent('Clicked_Save_For_Add_User', {});
};

export const trackClickedSaveForEditUser = () => {
  trackEvent('Clicked_Save_For_Edit_User', {});
};

export const trackClickedDeleteUser = () => {
  trackEvent('Clicked_Delete_User', {});
};

export const trackClickedChangeOutletButton = () => {
  trackEvent('Clicked_Change_Outlet_Button', {});
};
