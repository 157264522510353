import React from 'react';
import { object, func, string } from 'prop-types';
import uppercamelcase from 'uppercamelcase';
import classnames from 'classnames';
import styled from 'styled-components';
import { withI18n } from 'libs/support/i18n';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

const Item = styled.li`
  font-family: ${props => props.theme.fonts.proxima};
  font-size: 14px;
  line-height: 24px;
  padding: 0.5rem 1.5rem;
  position: relative;

  a {
    color: ${props => props.theme.colors.blackRussian} !important;
  }
`;

const Highlight = styled.div`
  background-color: ${props => props.theme.colors.lima};
  bottom: 0;
  height: 2px;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;

  &.--active {
    opacity: 1;
  }
`;

const Tab = ({
  alias,
  label,
  link,
  location,
  translate,
}) => {
  const className = classnames({
    '--active': location.pathname.match(new RegExp(link)) || location.pathname === alias,
  });

  return (
    <Item>
      <Link to={link} >
        { translate(`vendor${uppercamelcase(label)}`) }
      </Link>
      <Highlight className={className} />
    </Item>
  );
};

Tab.propTypes = {
  alias: string,
  label: string.isRequired,
  link: string.isRequired,
  location: object.isRequired,
  translate: func.isRequired,
};

export default withRouter(withI18n(Tab));
