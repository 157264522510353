import React, { useEffect } from 'react';
import $ from 'jquery';
import { bool, func, object, string } from 'prop-types';
import { abbreviateMiddle, blankish, notBlank } from 'libs/support/string';
import DropZone from 'react-dropzone';
import { withI18n } from 'libs/support/i18n';
import styled from 'styled-components';
import {
  CloseIcon,
  UploadIcon,
  PhotoIcon,
} from 'libs/components/Icons';
import { Tooltip } from 'libs/components/Forms';

const Container = styled.div`
  display: flex;
  color: ${props => props.theme.colors.lima};
  cursor: pointer;
`;

const DropContainer = styled.div`
  display: flex;
`;

const LoadingContainer = styled.div`
  white-space: nowrap;
`;

const IconContainer = styled.span`
  align-items: center;
  display: flex;
  height: auto;
  margin-right: 0.5rem;
  width: 21px;
`;

const DeleteButton = styled.button`
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;

  path {
    fill: ${props => props.theme.colors.chestNut};
  }
`;

const TextPreview = ({
  backgroundColor = '',
  handleOnDelete,
  handleOnDrop,
  isRequired,
  multiple,
  photo,
  translate,
  uploading,
}) => {
  const dropzoneClass = notBlank(photo) ? 'dropzone-uploaded' : '';

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip({ html: true });
  });

  const thumb = notBlank(photo) && photo.get('thumb');
  const hashOrThumb = notBlank(photo) && (photo.get('hash') || photo.get('thumb'));
  const tooltipContent = notBlank(thumb) ? `<img src='${thumb}' width='180px' height='auto'/>` : '';

  return (
    <Container className="text-preview-container">
      <Tooltip
        title={tooltipContent}
      >
        <DropZone
          onDrop={handleOnDrop}
          multiple={multiple || false}
          accept="image/*"
          className={`dropzone ${dropzoneClass}`}
          style={{ backgroundColor }}
          inputProps={{
            required: isRequired,
          }}
        >
          <DropContainer>
            <IconContainer>
              { blankish(hashOrThumb) ? <UploadIcon /> : <PhotoIcon /> }
            </IconContainer >

            {
              blankish(hashOrThumb) &&
                <LoadingContainer>
                  {
                    uploading ?
                      `${translate('photoUploadUploading')}...` :
                      translate('photoUploadUploadPhoto')
                  }
                </LoadingContainer>
            }
            {
              notBlank(hashOrThumb) &&

                <div className="help-text text-nowrap">
                  { abbreviateMiddle(hashOrThumb.split('/').pop().replace(/^thumb_/, ''), 20) }
                </div>
            }
          </DropContainer>
        </DropZone>
      </Tooltip>

      {
        (notBlank(hashOrThumb)) &&
          <DeleteButton
            onClick={handleOnDelete}
          >
            <CloseIcon />
          </DeleteButton>
      }
    </Container>
  );
};

TextPreview.propTypes = {
  backgroundColor: string,
  handleOnDelete: func.isRequired,
  handleOnDrop: func.isRequired,
  isRequired: bool,
  multiple: bool,
  photo: object,
  translate: func.isRequired,
  uploading: bool,
};

export default withI18n(TextPreview);
