import React, { Fragment, useState } from 'react';
import { func, object } from 'prop-types';
import { ToggleField } from 'libs/components/Forms';
import styled from 'styled-components';
import { withI18n } from 'libs/support/i18n';
import { connect } from 'react-redux';

const StatusDisplay = styled.span`
  colors: ${props => props.theme.colors.blackRussian};
  font-size: 14px;
`;

const ActiveToggle = ({
  category,
  translate,
}) => {
  const [status, setStatus] = useState(category.get('active'));
  const statusKey = status ? 'vendorStatusesActive' : 'vendorStatusesInactive';
  const handleChange = (e) => setStatus(e.currentTarget.checked);

  return (
    <Fragment>
      <StatusDisplay>
        Status: { translate(statusKey) }
      </StatusDisplay>

      <ToggleField
        id="category_active"
        name="category[active]"
        defaultChecked={status}
        onChange={handleChange}
      />
    </Fragment>
  );
};

ActiveToggle.propTypes = {
  category: object.isRequired,
  translate: func.isRequired,
};

const mapStateToProps = ({
  $$categoriesStore,
}) => ({
  category: $$categoriesStore.get('category'),
});

export default connect(mapStateToProps)(withI18n(ActiveToggle));
