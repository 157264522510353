import React from 'react';
import { func, string } from 'prop-types';
import {
  SaveButton,
} from 'bundles/common/components/Buttons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as customisationsActions from 'bundles/vendor/actions/customisationsActions';

const AddButton = ({
  currentType,
  newCustomisation,
}) => {
  const handleOnClick = () => {
    newCustomisation(currentType);
  };

  return (
    <SaveButton
      name="+ Add New"
      onClick={handleOnClick}
    />
  );
};

AddButton.propTypes = {
  currentType: string,
  newCustomisation: func.isRequired,
};

const mapStateToProps = ({
  $$customisationsStore,
}) => ({
  currentType: $$customisationsStore.get('currentType'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  newCustomisation: customisationsActions.newCustomisation,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddButton);
