import React from 'react';
import Calendar from 'libs/components/Icons/v2/Calendar';
import Avatar from 'libs/components/Icons/v2/Avatar';

const MobileOrderListSkeleton = () => (
  <div className="mt-2">
    <div className="card mr-2" style={{ width: '100%' }}>
      <div className="card-header">
        <div className="d-flex skeleton" style={{ justifyContent: 'space-between' }}>
          <span className="line" style={{ width: '50px' }} />
          <b className="line" style={{ width: '50px' }} />
        </div>
      </div>
      <div className="card-block">
        <div className="d-flex">
          <Calendar />
          <div
            className="ml-1 d-flex skeleton"
            style={{ flexDirection: 'column', justifyContent: 'initial', alignItems: 'initial' }}
          >
            <span className="line" style={{ width: '150px' }} />
            <span className="line" style={{ width: '100px' }} />
          </div>
        </div>
        <div
          className="d-flex mt-1 skeleton"
          style={{ justifyContent: 'initial', alignItems: 'initial' }}
        >
          <span className="badge line" style={{ marginRight: '5px', width: '30px' }} />
          <span className="badge line" style={{ marginRight: '5px', width: '30px' }} />
          <span className="badge line" style={{ width: '30px' }} />
        </div>
        <div className="d-flex mt-1 skeleton" style={{ alignItems: 'center', justifyContent: 'initial' }}>
          <Avatar />
          <span className="ml-1 line" style={{ fontWeight: '600', width: '50px' }} />
        </div>
      </div>
    </div>
  </div>
);

export default MobileOrderListSkeleton;
