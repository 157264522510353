import React from 'react';
import { func, string } from 'prop-types';
import uppercamelcase from 'uppercamelcase';
import styled from 'styled-components';
import {
  PrimaryTitle,
  Spacer,
} from 'libs/support/sharedComponents';
import { connect } from 'react-redux';
import { withI18n } from 'libs/support/i18n';
import { notBlank } from 'libs/support/string';
import ItemAddOnForm from './ItemAddOnForm';
import SaveCustomisationButton from './SaveCustomisationButton';
import BackLink from './BackLink';
import DeleteCustomisationButton from './DeleteCustomisationButton';
import ServiceAddOnForm from './ServiceAddOnForm';
import Flash from '../Flash';

const Container = styled.div`

`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActionsContainer = styled.span`
  display: flex;
  align-items: center;

  > button:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const FORMS = {
  itemAddOn: ItemAddOnForm,
  serviceAddOn: ServiceAddOnForm,
};

const CustomisationForm = ({
  currentType,
  translate,
  uuid,
}) => {
  const Form = FORMS[currentType];
  const title = notBlank(uuid) ? 'Edit' : 'Add New';

  return (
    <Container>
      <Flash />
      <Spacer />
      <Header>
        <PrimaryTitle>
          {`${title} ${translate(`vendor${uppercamelcase(currentType)}`)}`}
        </PrimaryTitle>

        <ActionsContainer>
          <BackLink />
          <DeleteCustomisationButton />
          <SaveCustomisationButton />
        </ActionsContainer>
      </Header>
      <Form key={`item_add_on_form_${uuid || (new Date()).getTime()}`} />
    </Container>
  );
};

CustomisationForm.propTypes = {
  currentType: string,
  translate: func.isRequired,
  uuid: string,
};

const mapStateToProps = ({
  $$customisationsStore,
}) => ({
  currentType: $$customisationsStore.get('currentType'),
  uuid: $$customisationsStore.getIn(['customisation', 'uuid']),
});

export default connect(mapStateToProps)(withI18n(CustomisationForm));
