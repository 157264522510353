import * as ActionCable from '@rails/actioncable';

export default class APICable {
  constructor(socketId) {
    this.socketId = socketId;
    this.cable = ActionCable.createConsumer(`${window.apiCableUrl}?sid=${socketId}`);
  }

  subscribe({ channel, params, connectedCallback, disconnectedCallback, receivedCallback }) {
    return this.cable.subscriptions.create({ channel, ...params }, {
      connected: () => {
        if (connectedCallback) {
          connectedCallback();
        } else {
          console.log('connected ', channel); // eslint-disable-line
        }
      },
      disconnected: () => {
        if (disconnectedCallback) {
          disconnectedCallback();
        } else {
          console.log('disconnected ', channel); // eslint-disable-line
        }
      },
      received: (data) => {
        if (receivedCallback) {
          receivedCallback(data);
        } else {
          console.log('received ', data); // eslint-disable-line
        }
      },
      rejected: () => {
        console.log('rejected'); // eslint-disable-line
      },
    });
  }

  unsubscribe(channel) {
    if (this.cable) {
      channel.unsubscribe();
      this.cable.subscriptions.remove(channel);
    }
  }

  cleanup() {
    if (this.cable) {
      this.cable.unsubscribe();
    }
  }
}
