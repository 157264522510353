import React from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  FieldGroup,
  InputField,
} from 'libs/components/Forms';

const InputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
`;

const InputContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
  min-width: 150px;

  @media (min-width: ${props => props.theme.breakPoints.md}) {
    min-width: unset;
  }
`;

const Label = styled.label`
  color: ${props => props.theme.colors.gunPowder};
  font-size: 14px;
  margin: 0 0 0 0.5rem;
`;

const NUTRITIONS = [
  'calories',
  'fat',
  'protein',
  'carbs',
];

const NutritionsFieldGroup = ({
  menuItem,
}) => (
  <FieldGroup
    label="Nutrition"
    optionalText="optional"
  >
    <InputsContainer>
      {
        NUTRITIONS.map((key) => (
          <InputContainer
            key={`menu_item_container_${key}`}
          >
            <InputField
              id={`menu_item_${key}`}
              name={`menu_item[${key}]`}
              value={menuItem.get(key)}
            />
            <Label>{key}</Label>
          </InputContainer>
        ))
      }
    </InputsContainer>
  </FieldGroup>
);

NutritionsFieldGroup.propTypes = {
  menuItem: object.isRequired,
};

const mapStateToProps = (state) => ({
  menuItem: state.$$menuItemsStore.get('menuItem'),
});

export default connect(mapStateToProps)(NutritionsFieldGroup);
