import ReactOnRails from 'react-on-rails';

import 'jquery-ujs';

import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';

// assets
import '../assets/stylesheets/application.sass';
import '../assets/images/favicon.ico';
import '../assets/images/caterspot-logo.svg';
import '../assets/images/icon-cart.png';
import '../assets/images/nav-orders-icon.svg';
import '../assets/images/nav-vendors-icon.svg';
import '../assets/images/star-sm-rating-sprite.png';
import '../assets/images/vendor-graphic-icon.svg';
import '../assets/images/delete-photo.png';
import '../assets/images/nav-marketing-icon.svg';
import '../assets/images/nav-users-icon.svg';
import '../assets/images/nav-orders-icon.svg';
import '../assets/images/star-rating-sprite.png';
import '../assets/images/state-check-icon.svg';


// apps
import * as SharedApp from '../bundles/common/startup/SharedApp';
import BillingsApp from '../bundles/billings/startup/BillingsApp';
import OutletNavigationApp from '../bundles/common/startup/OutletNavigationApp';
import MealLabelApp from '../bundles/meal_labels/startup/MealLabelApp';
import DashboardApp from '../bundles/v2/dashboards/startup/DashboardApp';
import OrderAppV2 from '../bundles/v2/orders/startup/OrderAppV2';
import OrderDetailAppV2 from '../bundles/v2/orders/startup/OrderDetailAppV2';
import OutletSelectorApp from '../bundles/v2/outlet_selectors/startup/OutletSelectorApp';
import VendorApp from '../bundles/vendor/startup/VendorApp';
import VendorAdminApp from '../bundles/vendor_admins/startup/VendorAdminApp';
import TermsApp from '../bundles/terms/startups/TermsApp';

// stores
import billingsStore from '../bundles/billings/stores/billingsStore';
import navigationStore from '../bundles/common/stores/navigationStore';
import mealLabelStore from '../bundles/meal_labels/stores/mealLabelStore';
import vendorAppStore from '../bundles/v2/common/stores/vendorAppStore';
import vendorStore from '../bundles/vendor/stores/vendorStore';
import vendorAdminStore from '../bundles/vendor_admins/stores/vendorAdminStore';

addLocaleData([...en]);

ReactOnRails.setOptions({
  traceTurbolinks: process.env.TRACE_TURBOLINKS,
});

ReactOnRails.register({
  BillingsApp,
  OutletNavigationApp,
  MealLabelApp,
  DashboardApp,
  OrderAppV2,
  OrderDetailAppV2,
  OutletSelectorApp,
  VendorApp,
  VendorAdminApp,
  TermsApp,
});

ReactOnRails.registerStore({
  billingsStore,
  navigationStore,
  mealLabelStore,
  vendorAppStore,
  vendorStore,
  vendorAdminStore,
});

SharedApp.initialize();
