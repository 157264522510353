import styled from 'styled-components';

export const Link = styled.a`
  display: flex;
  color: ${props => props.theme.colors.lima} !important;
  font-size: 14px;
  line-height: 1.2;
  padding: 0.43rem 0.5rem;
  pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
`;

export const DropdownItem = styled(Link)`
  color: ${props => props.theme.colors.gunPowder} !important;
  padding: 0.5rem 1rem !important;

  &:hover {
    background-color: ${props => props.theme.colors.azure} !important;
  }
`;
