import React from 'react';
import { bool, func, number, string } from 'prop-types';
import styled from 'styled-components';
import { FieldGroup } from 'libs/components/Forms';

const TextArea = styled.textarea`
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.gainsboro};
  box-shadow: inset 0px 1px 1px ${props => props.theme.colors.gray200};
  box-sizing: border-box;
  font-size: 14px;
  padding: 0.5rem 1rem;
  width: 100%;
`;

const TextAreaField = ({
  hintText,
  id,
  inputRef,
  isRequired,
  label,
  name,
  onBlur,
  onChange,
  optionalText,
  placeholder,
  rows,
  value,
}) => {
  const renderInput = () => (
    <div>
      <TextArea
        {
          ...{
            id,
            onBlur,
            onChange,
            placeholder,
            rows,
            name,
          }
        }
        ref={inputRef}
        defaultValue={value}
        required={isRequired}
      />
    </div>
  );

  if (label) {
    return (
      <FieldGroup
        hintText={hintText}
        label={label}
        htmlFor={id}
        isRequired={isRequired}
        optionalText={optionalText}
      >
        {renderInput()}
      </FieldGroup>
    );
  }

  return renderInput();
};

TextAreaField.propTypes = {
  hintText: string,
  id: string,
  inputRef: func,
  isRequired: bool,
  label: string,
  name: string,
  onBlur: func,
  onChange: func,
  optionalText: string,
  placeholder: string,
  rows: number,
  value: string,
};

export default TextAreaField;
