import { useQuery } from 'react-query';
import { httpClient } from 'libs/support/http-client';

export const usePolicies = () => (
  useQuery([
    'policies',
    'all',
  ], async () => {
    const { data } = await httpClient.get('/policies.json');
    return data;
  }, {
    staleTime: Infinity,
    enabled: true,
  })
);

