/* eslint camelcase: 0 */

import Immutable from 'immutable';
import { camelize } from 'libs/support/object';

import * as actionTypes from '../constants/dashboardConstants';

export const $$initialState = Immutable.fromJS({
  totalRevenue: 0,
  averageOrderValue: 0,
  totalOrders: 0,
  completedOrders: 0,
  isFetchingDashboardData: false,
});

export default function dashboardReducer($$state = $$initialState, action = null) {
  const { type, response } = action;
  const defaultHash = {
    totalRevenue: 0,
    averageOrderValue: 0,
    totalOrders: 0,
    completedOrders: 0,
    isFetchingDashboardData: false,
  };

  switch (type) {
    case actionTypes.FETCHING_DASHBOARDS_DATA: {
      return $$state.withMutations(state => (
        state
          .merge(defaultHash)
          .set('isFetchingDashboardData', true)
      ));
    }
    case actionTypes.FETCH_DASHBOARDS_DATA_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .merge(defaultHash)
          .set('isFetchingDashboardData', false)
          .set('totalRevenue', response.sum)
          .set('averageOrderValue', response.avg)
          .set('totalOrders', response.total_orders)
          .set('completedOrders', response.completed_orders)
          .set('orderCount', response.order_count)
          .set('orders', camelize(response.orders))
          .set('actionRequiredOrders', camelize(response.action_required_orders))
          .set('tomorrowOrders', camelize(response.tomorrow_orders))
          .set('totalRevenueActiveOrder', response.total_revenue_active_order)
          .set('totalRevenueCompletedOrder', response.total_revenue_completed_order)
          .set('totalActiveOrders', response.total_active_orders)
      ));
    }

    default: {
      return $$state;
    }
  }
}
