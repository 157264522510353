/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable quotes */
/* eslint jsx-a11y/click-events-have-key-events: 0, jsx-a11y/no-static-element-interactions: 0 */
import React, { Fragment } from "react";
import { bool, func } from 'prop-types';
import ArrowRight from "libs/components/Icons/v2/ArrowRight";
import Calendar from "libs/components/Icons/v2/Calendar";
import Avatar from "libs/components/Icons/v2/Avatar";
import OrderCard from "bundles/v2/orders/components/OrderCard";
import { trackClickedShowMoreButton } from 'packs/events';

const ActionRequiredSection = ({
  actionRequiredOrders,
  isFetchingDashboardData,
}) => (
  <Fragment>
    <div className="action-required pl-20">
      <div className="d-flex align-items-center pr-20">
        <div>
          <span className="action-required-text">Action Required</span>
          <span className="action-required-value">
            {actionRequiredOrders?.orders?.length}
          </span>
        </div>
        <div
          className="d-flex pointer"
          onClick={() => {
            trackClickedShowMoreButton();
            window.location.href = '/orders?filter=action_required';
          }} // eslint-disable-line
        >
          <span className="action-required-more">More</span>
          <ArrowRight />
        </div>
      </div>
      <div className="d-flex mt-2 action-required-container">
        { isFetchingDashboardData ? (
          <Fragment>
            {[...Array(3)].map((_, index) => (
              <div className="mr-2" key={index}>
                <div className="card mr-2" style={{ width: '300px' }}>
                  <div className="card-header">
                    <div className="d-flex skeleton" style={{ justifyContent: 'space-between' }}>
                      <span className="line" style={{ width: '50px' }} />
                      <b className="line" style={{ width: '50px' }} />
                    </div>
                  </div>
                  <div className="card-block">
                    <div className="d-flex">
                      <Calendar />
                      <div
                        className="ml-1 d-flex skeleton"
                        style={{ flexDirection: 'column', justifyContent: 'initial', alignItems: 'initial' }}
                      >
                        <span className="line" style={{ width: '150px' }} />
                        <span className="line" style={{ width: '100px' }} />
                      </div>
                    </div>
                    <div
                      className="d-flex mt-1 skeleton"
                      style={{ justifyContent: 'initial', alignItems: 'initial' }}
                    >
                      <span className="badge line" style={{ marginRight: '5px', width: '30px' }} />
                      <span className="badge line" style={{ marginRight: '5px', width: '30px' }} />
                      <span className="badge line" style={{ width: '30px' }} />
                    </div>
                    <div className="d-flex mt-1 skeleton" style={{ alignItems: 'center', justifyContent: 'initial' }}>
                      <Avatar />
                      <span className="ml-1 line" style={{ fontWeight: '600', width: '50px' }} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Fragment>
        ) :
          actionRequiredOrders?.orders?.map((order) => (
            <OrderCard
              onClickCard={(uuid) => window.location.href = `/orders/${uuid}`} // eslint-disable-line
              order={order}
              className="action-required-card"
            />
          ))
        }
      </div>
    </div>
  </Fragment>
);

ActionRequiredSection.propTypes = {
  actionRequiredOrders: func,
  isFetchingDashboardData: bool,
};

export default ActionRequiredSection;
