/* eslint-disable quotes */
import React, { Fragment } from "react";
import { func, object, bool } from "prop-types";
import { withI18n } from 'libs/support/i18n';

import Overviews from "./Overviews";

const OverviewContainer = ({
  dashboardPerformance,
  isLoadingPerformance,
  translate,
}) => (
  <Fragment>
    <div className="overview d-flex" style={{ flexDirection: 'column', justifyContent: 'center' }} >
      <div className="d-flex overview-header">
        <div className="d-flex" style={{ flexDirection: 'column' }}>
          <span className="overview-title">{translate('titlesPerformance')}</span>
        </div>
      </div>
      <Overviews
        {
          ...{
            dashboardPerformance,
            isLoadingPerformance,
          }
        }
      />
    </div>
  </Fragment>
);

OverviewContainer.propTypes = {
  dashboardPerformance: object,
  isLoadingPerformance: bool,
  translate: func,
};

export default withI18n(OverviewContainer);
