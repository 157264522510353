import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import BaseComponent from 'libs/components/BaseComponent';
import DeleteButton from 'libs/components/Buttons/Delete';
import EditButton from 'libs/components/Buttons/Edit';
import { DropdownActions } from 'libs/components/Forms';
import { DropdownItem } from 'libs/support/sharedComponents';
import { trackClickedDeleteUser } from 'packs/events';

export default class CatererAdmin extends BaseComponent {
  static propTypes = {
    admin: PropTypes.object,
    onClick: PropTypes.func.isRequired,
    outlets: PropTypes.object,
  };

  handleOnClick = (e) => {
    if (e) {
      e.preventDefault();
    }

    const { admin, onClick } = this.props;

    onClick(admin);
  }

  render() {
    const { admin, handleOnDelete, outlets } = this.props;
    const adminRole = admin.get('role');
    const catererOutletIds = outlets.toArray().map(e => e.get('uuid'));
    const adminOutlets = admin.get('outlets').toArray();
    // only show outlets that is from catererOutletIds
    const outletNames = adminOutlets.filter(e => catererOutletIds.includes(e.get('uuid'))).map(e => e.get('name'));
    const badgeRoleClass = classnames(
      'badge',
      { 'badge-primary': adminRole === 'admin' },
      { 'badge-secondary': adminRole !== 'admin' },
    );

    return (
      <tr>
        <td className="td-email">
          <a
            href="#"
            onClick={this.handleOnClick}
          >
            {admin.get('email')}
          </a>
          <div className="elipses-email" style={{ display: 'none' }}>
            <DropdownActions>
              <DropdownItem
                onClick={this.handleOnClick}
              >
                Edit
              </DropdownItem>
              <DropdownItem
                onClick={handleOnDelete}
              >
                Delete
              </DropdownItem>
            </DropdownActions>
          </div>
        </td>

        <td>{admin.get('first_name')}</td>

        <td>{admin.get('last_name')}</td>

        <td>{outletNames.join(', ')}</td>

        <td>
          <span className={badgeRoleClass}>
            {adminRole && adminRole.replace('_', ' ').toUpperCase()}
          </span>
        </td>
        <td className="text-right no-wrap" width="200px">
          <DeleteButton
            className=""
            style={{ marginRight: '0.5rem' }}
            onClick={() => {
              handleOnDelete();
              trackClickedDeleteUser();
            }}
          />

          <EditButton
            onClick={this.handleOnClick}
          />
        </td>
      </tr>
    );
  }
}
