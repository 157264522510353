import React, { useState } from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import * as customisationsActions from 'bundles/vendor/actions/customisationsActions';
import {
  CheckBox,
  FieldGroup,
  InputField,
} from 'libs/components/Forms';
import {
  Spacer,
} from 'libs/support/sharedComponents';
import { notBlank } from 'libs/support/string';
import ServiceAddOnOptionsList from './ServiceAddOnOptionsList';

const Container = styled.div`

`;

const InputsContainer = styled.div`
  display: flex;

  > div:not(:first-child) {
    margin-left: 1rem;
  }
`;

const InputContainer = styled.div`
  display: flex;
`;

const Label = styled.label`
  align-items: center;
  display: flex;
  margin-bottom: 0 !important;
  margin-left: 0.5rem;
`;

const Submit = styled.input`
  display: none;
`;

const ServiceAddOnForm = ({
  serviceAddOn,
  saveServiceAddOn,
}) => {
  const [enableQuantity, setEnableQuantity] = useState(serviceAddOn.get('enable_quantity_option'));
  const handleEnableQuantityChange = () => {
    setEnableQuantity(!enableQuantity);
  };

  const formUrl = notBlank(serviceAddOn.get('uuid')) ?
    `/menus/service_add_ons/${serviceAddOn.get('uuid')}` : '/menus/service_add_ons';
  const formMethod = notBlank(serviceAddOn.get('uuid')) ? 'put' : 'post';
  const onSubmit = (e) => {
    e.preventDefault();
    const form = document.getElementById('service_add_on_form');
    const params = new FormData(form);

    saveServiceAddOn(serviceAddOn.get('uuid'), params);
  };

  return (
    <Container>
      <form
        id="service_add_on_form"
        action={formUrl}
        method={formMethod}
        onSubmit={onSubmit}
      >
        <FieldGroup
          label="Nickname"
          htmlFor="service_add_on_nickname"
        >
          <InputField
            id="service_add_on_nickname"
            name="service_add_on[nickname]"
            value={serviceAddOn.get('nickname')}
          />
        </FieldGroup>

        <FieldGroup
          label="Question"
          htmlFor="service_add_on_question"
        >
          <InputField
            id="service_add_on_question"
            isRequired
            name="service_add_on[question]"
            value={serviceAddOn.get('question')}
          />
        </FieldGroup>

        <FieldGroup
          label="More Info"
          htmlFor="service_add_on_description"
        >
          <InputField
            id="service_add_on_description"
            name="service_add_on[description]"
            value={serviceAddOn.get('description')}
          />
        </FieldGroup>

        <FieldGroup
          label="Conditions"
        >
          <InputsContainer>
            <InputContainer>
              <InputField
                id="service_add_on_min_order_quantity"
                name="service_add_on[min_order_quantity]"
                value={serviceAddOn.get('min_order_quantity')}
              />
              <Label>Choose max</Label>
            </InputContainer>
            <InputContainer>
              <InputField
                id="service_add_on_max_order_quantity"
                name="service_add_on[max_order_quantity]"
                value={serviceAddOn.get('max_order_quantity')}
              />
              <Label>Choose min</Label>
            </InputContainer>
          </InputsContainer>
        </FieldGroup>

        <FieldGroup
          label=" "
          htmlFor="service_add_on_enable_quantity_option"
        >
          <CheckBox
            onChange={handleEnableQuantityChange}
            checked={serviceAddOn.get('enable_quantity_option')}
            id="service_add_on_enable_quantity_option"
            name="service_add_on[enable_quantity_option]"
            value="true"
            text="Quantity Options"
          />
        </FieldGroup>

        <Spacer />

        <ServiceAddOnOptionsList />

        <Submit />
      </form>
    </Container>
  );
};

ServiceAddOnForm.propTypes = {
  serviceAddOn: object,
  saveServiceAddOn: func.isRequired,
};


const mapStateToProps = ({
  $$customisationsStore,
}) => ({
  serviceAddOn: $$customisationsStore.get('customisation'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  saveServiceAddOn: customisationsActions.saveServiceAddOn,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ServiceAddOnForm);
