/* eslint import/prefer-default-export: 0, no-undef: 0, no-unused-vars: 0 */
import $ from 'jquery';
import { initializeSegment, processSegment } from 'libs/support/segment';

export const initialize = () => {
  $(() => {
    initializeSegment();
    processSegment();
  });

  document.addEventListener('turbolinks:load', (event) => {
    initializeSegment();
    processSegment();
  });
};
