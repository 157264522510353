import styled from 'styled-components';

export const ModalContainer = styled.div`
  .modal-header {
    background-color: ${props => props.theme.colors.white} !important;
    font-size: 17px;
    font-family: ${props => props.theme.fonts.geomanistbook};
    line-height: 21px;
  }

  .modal-body {
    padding: 20px !important;
  }

  .modal-footer {
    background-color: ${props => props.theme.colors.gray200} !important;
    border-radius: 0 0 3px 3px;
    padding: 0.5rem 20px !important;
  }
`;
