import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { DeleteButton } from 'bundles/common/components/Buttons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as menuItemsActions from 'bundles/vendor/actions/menuItemsActions';
import { blankish } from 'libs/support/string';
import { withRouter } from 'react-router';

const DeleteMenuItemButton = ({
  deleteMenuItem,
  isMenuItemDeleting,
  uuid,
  history,
}) => {
  const handleOnDelete = () => {
    if (window.confirm('Delete menu item?')) { /* eslint no-alert: 0 */
      deleteMenuItem(uuid)
        .then(() => {
          history.push('/menus/items');
        });
    }
  };

  if (blankish(uuid)) return null;

  return (
    <DeleteButton
      className="delete-menu-item-button"
      onClick={handleOnDelete}
      isDeleting={isMenuItemDeleting}
    />
  );
};

DeleteMenuItemButton.propTypes = {
  deleteMenuItem: func.isRequired,
  history: object,
  isMenuItemDeleting: bool,
  uuid: string,
};

const mapStateToProps = ({
  $$menuItemsStore,
}) => ({
  uuid: $$menuItemsStore.getIn(['menuItem', 'uuid']),
  isMenuItemDeleting: $$menuItemsStore.get('isMenuItemDeleting'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  deleteMenuItem: menuItemsActions.deleteMenuItem,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeleteMenuItemButton));
