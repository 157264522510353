import React from 'react';
import { bool, func, object } from 'prop-types';
import styled from 'styled-components';
import { SaveButton } from 'bundles/common/components/Buttons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as menuItemsActions from 'bundles/vendor/actions/menuItemsActions';
import { withRouter } from 'react-router-dom';

const Container = styled.div`
  width: 100%;

  @media (min-width: ${props => props.theme.breakPoints.sm}) {
    width: auto;
  }
`;

const StyledButton = styled(SaveButton)`
  width: 100%;
`;

const DuplicateAction = ({
  cloneMenuItems,
  history,
  isMenuItemCloning,
  metadata,
  selectedIds,
}) => {
  const handleDuplicate = () => (
    selectedIds.size > 0 &&
      cloneMenuItems({ uuid: selectedIds })
        .then(_ => {
          const page = metadata.get('page');
          const lastPage = metadata.get('total_pages');

          if (page === lastPage) return;

          const url = new URL(window.location);
          url.searchParams.set('page', lastPage);
          const to = `${url.pathname}${url.search}`;
          history.push(to);
        })
  );

  return (
    <Container>
      <StyledButton
        name="Duplicate Item"
        isSaving={isMenuItemCloning}
        savingName="Duplicating..."
        onClick={handleDuplicate}
      />
    </Container>
  );
};

DuplicateAction.propTypes = {
  cloneMenuItems: func.isRequired,
  history: object.isRequired,
  isMenuItemCloning: bool,
  metadata: object,
  selectedIds: object,
};

const mapStateToProps = ({ $$menuItemsStore }) => ({
  isMenuItemCloning: $$menuItemsStore.get('isMenuItemCloning'),
  metadata: $$menuItemsStore.get('metadata'),
  selectedIds: $$menuItemsStore.get('selectedIds'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  cloneMenuItems: menuItemsActions.cloneMenuItems,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DuplicateAction));
