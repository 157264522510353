export const FETCHING_VENDOR_STATEMENT = 'FETCHING_VENDOR_STATEMENT';
export const FETCH_VENDOR_STATEMENT_SUCCESS = 'FETCH_VENDOR_STATEMENT_SUCCESS';
export const FETCH_VENDOR_STATEMENT_FAILURE = 'FETCH_VENDOR_STATEMENT_FAILURE';

export const FETCHING_VENDOR_STATEMENTS = 'FETCHING_VENDOR_STATEMENTS';
export const FETCH_VENDOR_STATEMENTS_SUCCESS = 'FETCH_VENDOR_STATEMENTS_SUCCESS';
export const FETCH_VENDOR_STATEMENTS_FAILURE = 'FETCH_VENDOR_STATEMENTS_FAILURE';

export const SET_SELECTED_YEAR = 'SET_SELECTED_YEAR';

export const CUTOFF_YEAR = 2022;
