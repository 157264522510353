import React from 'react';
import { func, bool, object } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withI18n } from 'libs/support/i18n';
import {
  CloseButton,
  SaveButton,
} from 'bundles/common/components/Buttons';
import {
  ModalContainer,
  Nbsp,
} from 'libs/support/sharedComponents';
import Modal from 'libs/components/ModalComponent';
import ModalHeader from 'libs/components/ModalHeaderComponent';
import ModalBody from 'libs/components/ModalBodyComponent';
import ModalFooter from 'libs/components/ModalFooterComponent';
import * as vendorBillingActions from 'bundles/billings/actions/vendorBillingActions';
import styled from 'styled-components';
import OutletFields from './OutletFields';

const Submit = styled.input`
  display: none;
`;

const OutletModal = ({
  outlet,
  outletBillingDetail,
  saveOutletBillingDetail,
  hideOutletBillingDetailModal,
  isShowOutletBillingDetailModal,
  translate,
}) => {
  const handleCancel = () => {
    hideOutletBillingDetailModal();
  };

  const handleUpdate = () => {
    document
      .getElementById('outlet_billing_detail_form')
      .querySelector('input[type="submit"]')
      .click();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = document.getElementById('outlet_billing_detail_form');
    const formData = new FormData(form);

    const params = {
      billableId: formData.get('billable_id'),
      billableType: formData.get('billable_type'),
      billingName: formData.get('billing_name'),
      businessRegistrationNumber: formData.get('business_registration_number'),
      taxRegistrationNumber: formData.get('tax_registration_number'),
      address1: formData.get('address1'),
      address2: formData.get('address2'),
      postcode: formData.get('postcode'),
      city: formData.get('city'),
      bankName: formData.get('bank_name'),
      bankCode: formData.get('bank_code'),
      swiftCode: formData.get('swift_code'),
      bankAccountNumber: formData.get('bank_account_number'),
      bankAccountHolder: formData.get('bank_account_holder'),
    };

    Promise.resolve(saveOutletBillingDetail(outlet.get('uuid'), params))
      .then(hideOutletBillingDetailModal());
  };

  return (
    <ModalContainer>
      <Modal
        elementId="modal_outlet_billing_detail"
        show={isShowOutletBillingDetailModal}
        className="modal-lg"
        onHide={hideOutletBillingDetailModal}
      >
        <ModalHeader enableClose>
          { translate('billingsTitlesEditOutlet') }
        </ModalHeader>
        <ModalBody>
          <form
            id="outlet_billing_detail_form"
            onSubmit={handleSubmit}
          >
            <OutletFields
              key={`outlet-form-${outlet.get('uuid')}`}
              outlet={outlet}
              outletBillingDetail={outletBillingDetail}
            />

            <Submit type="submit" />
          </form>
        </ModalBody>

        <ModalFooter>
          <CloseButton
            onClick={handleCancel}
            name={translate('buttonsCancel')}
            className="btn-white"
          />
          <Nbsp />
          <SaveButton
            onClick={handleUpdate}
            name={translate('buttonsUpdate')}
            savingName={translate('buttonsUpdating')}
            saveString="buttonsUpdate"
          />
        </ModalFooter>
      </Modal>
    </ModalContainer>
  );
};

OutletModal.propTypes = {
  outlet: object,
  outletBillingDetail: object,
  hideOutletBillingDetailModal: func.isRequired,
  saveOutletBillingDetail: func.isRequired,
  isShowOutletBillingDetailModal: bool.isRequired,
  translate: func.isRequired,
};

const mapStateToProps = ({ $$vendorBillingStore }) => {
  const outlet = $$vendorBillingStore.get('outlet');
  const outletBillingDetail = $$vendorBillingStore.get('outletBillingDetail');
  const isShowOutletBillingDetailModal = $$vendorBillingStore.get('isShowOutletBillingDetailModal');

  return {
    outlet,
    outletBillingDetail,
    isShowOutletBillingDetailModal,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  hideOutletBillingDetailModal: vendorBillingActions.hideOutletBillingDetailModal,
  saveOutletBillingDetail: vendorBillingActions.saveOutletBillingDetail,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withI18n(OutletModal));
