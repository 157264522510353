/* eslint max-len: 0 */

import imageReducer, { $$initialState as $$imageState } from 'bundles/common/reducers/imageReducer';
import catererReducer, { $$initialState as $$catererState } from 'bundles/common/reducers/catererReducer';
import messagesReducer, { $$initialState as $$messagesState } from 'bundles/common/reducers/messagesReducer';
import baseDeliveryFeesReducer, { $$initialState as $$baseDeliveryFeesState } from './baseDeliveryFeesReducer';
import categoriesReducer, { $$initialState as $$categoriesState } from './categoriesReducer';
import filtersReducer, { $$initialState as $$filtersState } from './filtersReducer';
import localeReducer, { $$initialState as $$localeState } from '../../common/reducers/localeReducer';
import menuItemsReducer, { $$initialState as $$menuItemsState } from './menuItemsReducer';
import itemAddOnsReducer, { $$initialState as $$itemAddOnsState } from './itemAddOnsReducer';
import serviceAddOnsReducer, { $$initialState as $$serviceAddOnsState } from './serviceAddOnsReducer';
import customisationsReducer, { $$initialState as $$customisationsState } from './customisationsReducer';
import orderNoticesReducer, { $$initialState as $$orderNoticesState } from './orderNoticesReducer';

export default {
  $$baseDeliveryFeesStore: baseDeliveryFeesReducer,
  $$categoriesStore: categoriesReducer,
  $$filtersStore: filtersReducer,
  $$localeStore: localeReducer,
  $$menuItemsStore: menuItemsReducer,
  $$catererStore: catererReducer,
  $$imageStore: imageReducer,
  $$itemAddOnsStore: itemAddOnsReducer,
  $$serviceAddOnsStore: serviceAddOnsReducer,
  $$customisationsStore: customisationsReducer,
  $$orderNoticesStore: orderNoticesReducer,
  $$messagesStore: messagesReducer,
};

export const initialStates = {
  $$baseDeliveryFeesState,
  $$categoriesState,
  $$filtersState,
  $$localeState,
  $$menuItemsState,
  $$catererState,
  $$imageState,
  $$itemAddOnsState,
  $$serviceAddOnsState,
  $$customisationsState,
  $$orderNoticesState,
  $$messagesState,
};
