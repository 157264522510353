import React, { useState } from 'react';
import { object } from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { processPage } from 'libs/support/segment';
import isEqual from 'lodash/isEqual';

const Container = styled.div`
  background: ${props => props.theme.colors.white};
  border-radius: 4px
  box-shadow: 0px 2px 4px rgba(20, 20, 21, 0.1);
  width: 100%;
`;

const BillingsRouterContainer = ({
  children,
  history,
}) => {
  const [pushedLocation, setPushedLocation] = useState(history.location);

  history.listen(() => {
    const pushedUrl = `${pushedLocation.pathname}${pushedLocation.search}`;
    const currentUrl = `${window.location.pathname}${window.location.search}`;

    if (!isEqual(pushedUrl, currentUrl)) {
      processPage();
      setPushedLocation(window.location);
    }
  });

  return (
    <Container>
      { children }
    </Container>
  );
};

BillingsRouterContainer.propTypes = {
  children: object,
  history: object,
};

export default withRouter(BillingsRouterContainer);
