import metaTagsManager from 'libs/middlewares/metaTagsManager';
import { camelize, decamelize } from 'libs/support/object';
import axios from 'axios';

export const httpClient = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

httpClient.interceptors.request.use((request) => {
  if (request.params) {
    request.params = decamelize(request.params);
  }

  if (request.data) {
    request.data = decamelize(request.data);
  }

  request.headers['X-CSRF-Token'] = metaTagsManager.getCSRFToken();

  return request;
});

httpClient.interceptors.response.use((response) => {
  if (response.data) {
    response.data = camelize(response.data);
  }

  return response;
});
