import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  font-family: ${props => props.theme.fonts.proxima};
  font-size: 14px;
  line-height: 24px;
`;


const SectionDescription = ({ children }) => (
  <Container>{children}</Container>
);

SectionDescription.propTypes = {
  children: string.isRequired,
};

export default SectionDescription;
