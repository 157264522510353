/* eslint react/no-array-index-key: 0 */
/* eslint react/no-danger: 0 */

import React from 'react';
import styled from 'styled-components';
import { withI18n } from 'libs/support/i18n';
import TagsFieldGroup from 'bundles/vendor/components/Item/FieldGroups/TagsFieldGroup';

const Container = styled.div`

`;

const CateringTagsSection = () => (
  <Container className="tags-section">
    <TagsFieldGroup kind="dietary" />
    <TagsFieldGroup kind="ingredient" />
  </Container>
);

export default withI18n(CateringTagsSection);
