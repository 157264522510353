/* eslint-disable quote-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Fragment, useState } from 'react';
import { array, func } from 'prop-types';
import ArrowLeft from 'libs/components/Icons/v2/ArrowLeft';
import SearchIcon from 'libs/components/Icons/v2/SearchIcon';
import Settings from 'libs/components/Icons/v2/Settings';
import Close from 'libs/components/Icons/Close';
import classNames from 'classnames';

const MobileOrderHeader = ({
  onChangeSearchInput,
  setOpenModal,
  deliveryDates,
  orderDates,
  status,
}) => {
  const [openSearch, setOpenSearch] = useState(false);
  const countFilters = [deliveryDates, orderDates].filter(n => n !== null && n.length > 0).length + status.length;

  const handleBack = () => {
    if (openSearch) {
      setOpenSearch(false);
      return;
    }
    window.location = '/dashboards';
  };

  const mobileSettingFilter = () => (
    <span
      className={
        classNames('mobile-header-icon', {
          'active': countFilters > 0,
        })
      }
      onClick={() => setOpenModal(true)}
    >
      <Settings />
      { countFilters > 0 && (
        <span className="status-tag-number active">{ countFilters }</span>
      )}
    </span>
  );

  return (
    <Fragment>
      <div className="d-flex mobile-order-header-titles">
        <span onClick={() => handleBack()}>
          <ArrowLeft />
        </span>
        { openSearch ? (
          <Fragment>
            <div className="input-with-icon">
              <input
                className="mobile-search-input"
                type="text"
                placeholder="Type here"
                onChange={(e) => onChangeSearchInput(e)}
              />
              <span className="icon" onClick={() => setOpenSearch(false)}>
                <Close />
              </span>
            </div>
            { mobileSettingFilter() }
          </Fragment>
          ) : (
            <Fragment>
              <span className="order-text">Orders</span>
              <div className="mobile-header-icon-container">
                <span className="mobile-header-icon" onClick={() => setOpenSearch(true)}>
                  <SearchIcon />
                </span>
                { mobileSettingFilter() }
              </div>
            </Fragment>
          )
        }
      </div>
    </Fragment>
  );
};

MobileOrderHeader.propTypes = {
  onChangeSearchInput: func,
  setOpenModal: func,
  deliveryDates: array,
  orderDates: array,
  status: array,
};

export default MobileOrderHeader;
