/* eslint max-len: 0 */

import React from 'react';
import { bool, string, object, func } from 'prop-types';

import BaseComponent from 'libs/components/BaseComponent';

export default class EditButtonComponent extends BaseComponent {
  static propTypes = {
    hide: bool,
    disabled: bool,
    id: string,
    elementId: string,
    className: string,
    params: object,
    onClick: func.isRequired,
  };

  handleOnClick = (e) => {
    e.preventDefault();

    const {
      disabled, id, params, onClick,
    } = this.props;

    if (disabled) return false;

    return onClick(id, params);
  }

  render = () => {
    const {
      className,
      disabled,
      elementId,
      hide,
      id,
    } = this.props;

    if (hide) return null;

    const finalElementId = elementId || `edit_${id}`;
    const opacity = disabled ? '0.4' : '1';
    const cursor = disabled ? 'not-allowed' : 'pointer';

    return (
      <a
        id={finalElementId}
        data-id="edit-button"
        href="#"
        className={`btn btn-light btn-small px-2 ${className}`}
        disabled={disabled}
        onClick={this.handleOnClick}
        style={{ opacity, cursor }}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 2C2.44772 2 2 2.44772 2 3V13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V8H16V13C16 14.6569 14.6569 16 13 16H3C1.34315 16 0 14.6569 0 13V3C0 1.34315 1.34315 0 3 0H8V2H3ZM11.5858 1C12.3668 0.218951 13.6332 0.218952 14.4142 1L15 1.58579C15.781 2.36684 15.781 3.63317 15 4.41421L10.041 9.37326C9.71163 9.70258 9.31015 9.95071 8.86832 10.098L7.26491 10.6325C6.09227 11.0233 4.97667 9.90773 5.36754 8.73509L5.90201 7.13168C6.04929 6.68985 6.29742 6.28837 6.62674 5.95904L11.5858 1ZM13 2.41421L8.04096 7.37326C7.93118 7.48303 7.84847 7.61686 7.79938 7.76414L7.58114 8.41886L8.23586 8.20062C8.38314 8.15153 8.51697 8.06882 8.62674 7.95904L13.5858 3L13 2.41421Z"
            fill="#141415"
          />
        </svg>
      </a>
    );
  }
}
