/* eslint no-unused-expressions: 0 */
/* eslint react/no-danger: 0 */

import React from 'react';
import { bool, func, string } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import Modal from 'libs/components/ModalComponent';
import ModalHeader from 'libs/components/ModalHeaderComponent';
import ModalBody from 'libs/components/ModalBodyComponent';
import ModalFooter from 'libs/components/ModalFooterComponent';
import { SaveButton } from 'bundles/common/components/Buttons';
import {
  ModalContainer,
} from 'libs/support/sharedComponents';

const AlertModal = ({
  enableClose,
  submit,
  isShow,
  message,
  onHide,
  title,
}) => {
  const handleSubmit = () => {
    (submit && Promise.resolve(submit()).then(() => {
      onHide();
    })) || onHide();
  };

  return (
    <ModalContainer>
      <Modal
        show={isShow}
        className="modal-md modal-alert"
        onHide={onHide}
        lockOverlay={!enableClose}
      >
        <ModalHeader enableClose={enableClose}>
          { title }
        </ModalHeader>

        <ModalBody>
          <span dangerouslySetInnerHTML={{ __html: message }} />
        </ModalBody>

        <ModalFooter>
          <SaveButton
            onClick={handleSubmit}
            className="btn-sm"
            name="Okay"
            savingName="Closing..."
          />
        </ModalFooter>
      </Modal>
    </ModalContainer>
  );
};

AlertModal.propTypes = {
  enableClose: bool,
  submit: func,
  isShow: bool.isRequired,
  message: string,
  onHide: func,
  title: string,
};

export default withI18n(AlertModal);
