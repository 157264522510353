import React from 'react';
import { object, string } from 'prop-types';
import { connect } from 'react-redux';
import {
  Table,
  HeaderRow,
  Header,
  TBody,
} from 'libs/support/sharedComponents';
import {
  TYPE_ITEM_ADD_ON,
} from 'bundles/vendor/constants/customisationsConstants';
import Customisation from './Customisation';

const List = ({
  currentType,
  itemAddOns,
  keyword,
  menuItem,
  serviceAddOns,
  type,
}) => {
  const customisations = type === TYPE_ITEM_ADD_ON ?
    itemAddOns : serviceAddOns;

  const selectedCustomisations = type === TYPE_ITEM_ADD_ON ?
    menuItem.get('menu_item_item_add_ons') : menuItem.get('menu_item_service_add_ons');

  const isSelected = (customisation) => (
    selectedCustomisations &&
      selectedCustomisations.some((selected) => selected.get('add_onable_id') === customisation.get('uuid'))
  );

  const filteredCustomisations = customisations.filter((customisation) => (
    customisation.get('question').search(new RegExp(keyword, 'i')) > -1
  ));

  return (
    <Table
      style={{ display: `${currentType === type ? 'table' : 'none'}` }}
    >
      <thead>
        <HeaderRow>
          <Header key="position" width="40px" />
          <Header key="question" >Question</Header>
          <Header key="nickname" >Nickname</Header>
          <Header key="linked_to_an_item" width="150px">Linked to An Item</Header>
          <Header key="actions" width="100px" />
        </HeaderRow>
      </thead>
      <TBody>
        {
          filteredCustomisations.size > 0 &&
            filteredCustomisations.map(customisation => (
              <Customisation
                key={`customisation_${customisation.get('uuid')}`}
                question={customisation.get('question')}
                isSelected={isSelected(customisation)}
                name="customisation"
                id={`customisation_${customisation.get('uuid')}`}
                type={type}
                customisation={customisation}
              />
            ))
        }
      </TBody>
    </Table>
  );
};

List.propTypes = {
  currentType: string,
  keyword: string,
  itemAddOns: object,
  menuItem: object,
  serviceAddOns: object,
  type: string,
};

const mapStateToProps = ({
  $$customisationsStore,
  $$itemAddOnsStore,
  $$menuItemsStore,
  $$serviceAddOnsStore,
}) => ({
  currentType: $$customisationsStore.get('currentType'),
  keyword: $$customisationsStore.get('keyword'),
  itemAddOns: $$itemAddOnsStore.get('itemAddOns'),
  menuItem: $$menuItemsStore.get('menuItem'),
  serviceAddOns: $$serviceAddOnsStore.get('serviceAddOns'),
});

export default connect(mapStateToProps)(List);
