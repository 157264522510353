/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable quotes */
import React, { Fragment, useEffect } from "react";
import { bool, func, object } from 'prop-types';
import { withAppContext } from 'libs/support/appContext';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OrderList from "bundles/v2/orders/components/OrderList";
import { BrowserRouter as Router } from 'react-router-dom';
import { withQueryClient } from 'libs/support/withQueryClient';
import { IntlProvider } from 'react-intl';
import { translations } from 'libs/i18n/translations';
import { trackViewedV2OrdersPage } from "packs/events";

import * as ordersActions from "../actions/ordersActions";

const OrderContainer = ({
  outletsSelectedIds,
  vendorSelected,
  orders,
  isFetching,
  fetchOrders,
  metadata,
  localeStore,
}) => {
  const locale = localeStore.get('locale');
  const messages = translations[locale];

  useEffect(() => trackViewedV2OrdersPage(), []);

  return (
    <Fragment>
      <IntlProvider locale={locale} key={locale} messages={messages}>
        <Router>
          <OrderList
            {
              ...{
                orders,
                isFetching,
                metadata,
                fetchOrders,
                outletsSelectedIds,
                vendorSelected,
              }
            }
          />
        </Router>
      </IntlProvider>
    </Fragment>
  );
};

const mapStateToProps = ({
  $$outletSelectorStore,
  $$orderStore,
  $$localeStore,
}) => ({
  outletsSelectedIds: $$outletSelectorStore.get('outletsSelectedIds'),
  vendorSelected: $$outletSelectorStore.get('vendorSelected'),
  isFetching: $$orderStore.get('isFetching'),
  orders: $$orderStore.get('orders'),
  metadata: $$orderStore.get('ordersMetadata'),
  localeStore: $$localeStore,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchOrders: ordersActions.fetchOrders,
}, dispatch);

OrderContainer.propTypes = {
  outletsSelectedIds: object,
  vendorSelected: object,
  orders: object,
  isFetching: bool,
  fetchOrders: func,
  metadata: object,
  localeStore: object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withAppContext(withQueryClient(OrderContainer)));
