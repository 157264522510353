import React, { Fragment } from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import {
  InputField,
} from 'libs/components/Forms';
import SecondarySection from 'bundles/vendor/components/Item/SecondarySection';
import VariationsSection from 'bundles/vendor/components/Item/Variations/VariationsSection';
import { Separator } from 'libs/support/sharedComponents';
import DurationFieldGroup from 'bundles/vendor/components/Item/FieldGroups/DurationFieldGroup';
import SizeRangeFieldGroup from 'bundles/vendor/components/Item/FieldGroups/SizeRangeFieldGroup';
import SubitemsFieldGroup from 'bundles/vendor/components/Item/Subitems/SubitemsFieldGroup';
import ActivitiesTagsSection from 'bundles/vendor/components/Item/Sections/ActivitiesTagsSection';
import PhotosSection from 'bundles/vendor/components/Item/Sections/PhotosSection';
import CustomisationsSection from 'bundles/vendor/components/Item/Sections/CustomisationsSection';
import PrimarySection from 'bundles/vendor/components/Item/Sections/PrimarySection';

const ActivitiesForm = ({
  menuItem,
}) => (
  <Fragment>
    <PrimarySection />

    <Separator />

    <VariationsSection />

    <Separator />

    <SecondarySection />

    <SizeRangeFieldGroup />
    <DurationFieldGroup />

    <InputField
      label="Location"
      id="menu_item_location"
      name="menu_item[location]"
      value={menuItem.get('location')}
      optionalText="if applicable"
    />

    <SubitemsFieldGroup
      categoryName="requirements"
      label="Requirements"
      hintText="List what are the requirements for your activity."
    />

    <SubitemsFieldGroup
      categoryName="what_to_bring"
      label="What to Bring"
      hintText="List items that the participants are required to bring for the activity."
      optionalText="optional"
    />

    <Separator />

    <ActivitiesTagsSection />

    <Separator />

    <PhotosSection isRequired />

    <Separator />

    <CustomisationsSection />
  </Fragment>
);

ActivitiesForm.propTypes = {
  menuItem: object.isRequired,
};

const mapStateToProps = (state) => ({
  menuItem: state.$$menuItemsStore.get('menuItem'),
});

export default connect(mapStateToProps)(ActivitiesForm);
