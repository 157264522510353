import React from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import { usePolicies } from 'bundles/common/queries/policies';
import { bindActionCreators } from 'redux';
import * as menuItemsActions from 'bundles/vendor/actions/menuItemsActions';
import { ToggleField } from 'libs/components/Forms';

const ActiveToggle = ({
  menuItem,
  toggleMenuItemActive,
}) => {
  const policiesResponse = usePolicies();
  const { policies } = policiesResponse.isSuccess ? policiesResponse.data : {};
  const handleOnActive = () => {
    toggleMenuItemActive(menuItem.get('uuid'));
  };
  const disabled = !policies?.menuItem?.update;

  return (
    <ToggleField
      id={`menu_item_active_${menuItem.get('uuid')}`}
      isDisabled={disabled}
      checked={menuItem.get('active')}
      onChange={handleOnActive}
    />
  );
};

ActiveToggle.propTypes = {
  menuItem: object.isRequired,
  toggleMenuItemActive: func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  toggleMenuItemActive: menuItemsActions.toggleMenuItemActive,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ActiveToggle);
