import styled from 'styled-components';

export const Separator = styled.hr`
  margin: 2rem 0;
`;

const SPACERS = {
  xs: 0.2,
  sm: 0.5,
  md: 1,
  lg: 2,
};

export const Spacer = styled.div`
  padding: ${props => SPACERS[props.size] || 0.5}rem 0;
`;

export const Centered = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const Nbsp = styled.span`
  padding: 0 ${props => SPACERS[props.size] || 0.5}rem;
`;

export const VerticalRule = styled.span`
  border-left: 1px solid  ${props => props.theme.colors.gainsboro};
  height: 100%;
  margin: 0 ${props => SPACERS[props.size] || 1}rem;
`;
