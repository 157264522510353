import Immutable from 'immutable';
import humps from 'lodash-humps';
import * as actionTypes from '../constants/vendorBillingConstants';

export const $$initialState = Immutable.fromJS({
  flash: {},
  isFinding: false,
  isFindSuccess: false,
  isSaving: false,
  isSaveSuccess: false,
  isShowOutletBillingDetailModal: false,
  vendorBilling: {},
  outlet: {},
  outletBillingDetail: {},
});

export default function vendorBillingReducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  const defaultHash = {
    isFinding: false,
    isSaving: false,
    isSaveSuccess: false,
  };

  switch (type) {
    case actionTypes.FIND_VENDOR_BILLING_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .merge(defaultHash)
          .set('vendorBilling', Immutable.fromJS(humps(response.vendor_billing)))
          .set('isFindSuccess', true)
      ));
    }

    case actionTypes.FINDING_VENDOR_BILLING: {
      return $$state.withMutations(state => (
        state
          .merge(defaultHash)
          .set('isFinding', true)
      ));
    }

    case actionTypes.SAVE_VENDOR_BILLING_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .merge(defaultHash)
          .set('vendorBilling', Immutable.fromJS(humps(response.vendor_billing)))
          .set('flash', Immutable.fromJS(response.flash))
          .set('isSaveSuccess', true)
      ));
    }

    case actionTypes.SAVE_VENDOR_BILLING_FAILURE: {
      return $$state.withMutations(state => (
        state
          .merge(defaultHash)
          .set('flash', Immutable.fromJS({ errors: response.errors }))
      ));
    }

    case actionTypes.SAVING_VENDOR_BILLING: {
      return $$state.withMutations(state => (
        state
          .merge(defaultHash)
          .set('isSaving', true)
          .set('flash', Immutable.fromJS({}))
      ));
    }

    case actionTypes.SHOW_OUTLET_BILLING_DETAIL_MODAL: {
      const { outlet, outletBillingDetail } = response;

      return $$state.withMutations(state => (
        state
          .merge(defaultHash)
          .set('outlet', Immutable.fromJS(humps(outlet)))
          .set('outletBillingDetail', Immutable.fromJS(humps(outletBillingDetail)))
          .set('isShowOutletBillingDetailModal', true)
      ));
    }

    case actionTypes.HIDE_OUTLET_BILLING_DETAIL_MODAL: {
      return $$state.withMutations(state => (
        state
          .merge(defaultHash)
          .set('outlet', Immutable.fromJS({}))
          .set('outletBillingDetail', Immutable.fromJS({}))
          .set('isShowOutletBillingDetailModal', false)
      ));
    }

    case actionTypes.SAVE_OUTLET_BILLING_DETAIL: {
      const { outletId, params } = response;
      const immutableParams = Immutable.fromJS({ ...params, updatedAt: new Date() });

      return $$state.withMutations(state => (
        state
          .merge(defaultHash)
          .updateIn(['vendorBilling', 'outletBillingDetails'], (outletBillingDetails) => {
            const index = outletBillingDetails.findIndex((outletBillingDetail) => (
              outletBillingDetail.get('billableId') === outletId
            ));

            if (index > -1) {
              return outletBillingDetails.set(index, immutableParams);
            }

            return outletBillingDetails.push(immutableParams);
          })
      ));
    }

    case actionTypes.REMOVE_NOTICE_FLASH: {
      return $$state.withMutations(state => (
        state
          .setIn(['flash', 'notice'], '')
      ));
    }

    case actionTypes.RESET_FLASH: {
      return $$state.withMutations(state => (
        state
          .set('flash', Immutable.fromJS({}))
      ));
    }

    default: {
      return $$state;
    }
  }
}
