import React from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  FieldGroup,
  InputField,
  SelectField,
} from 'libs/components/Forms';
import {
  QUANTITY_TYPES,
} from 'bundles/vendor/constants/menuItemsConstants';
import { useSelectInput } from 'libs/support/selectInput';

const QuantityFieldsContainer = styled.div`
  display: flex;
  margin-right: -1rem;

  > * {
    flex: 1;
    margin-right: 1rem;
  }
`;

const QuantityFieldGroup = ({
  menuItem,
}) => {
  const quantityTypeOptions = QUANTITY_TYPES.map((type) => (
    {
      label: type,
      value: type,
    }
  ));
  const [selectedQuantityTypeOption, handleQuantityTypeChange] = useSelectInput(
    quantityTypeOptions,
    menuItem.get('quantity_type'),
  );

  return (
    <FieldGroup
      label="Quantity Information"
      htmlFor="menu_item_quantity"
    >
      <QuantityFieldsContainer>
        <InputField
          id="menu_item_quantity"
          name="menu_item[quantity]"
          value={menuItem.get('quantity')}
        />

        <SelectField
          id="menu_item_quantity_type"
          name="menu_item[quantity_type]"
          value={[selectedQuantityTypeOption]}
          options={quantityTypeOptions}
          onChange={handleQuantityTypeChange}
        />
      </QuantityFieldsContainer>
    </FieldGroup>
  );
};

QuantityFieldGroup.propTypes = {
  menuItem: object.isRequired,
};


const mapStateToProps = (state) => ({
  menuItem: state.$$menuItemsStore.get('menuItem'),
});

export default connect(mapStateToProps)(QuantityFieldGroup);
