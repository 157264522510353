export const APPLY_CUSTOMISATIONS = 'APPLY_CUSTOMISATIONS';
export const OPEN_CUSTOMISATIONS_MODAL = 'OPEN_CUSTOMISATIONS_MODAL';
export const CLOSE_CUSTOMISATIONS_MODAL = 'CLOSE_CUSTOMISATIONS_MODAL';
export const CHANGE_CURRENT_TYPE = 'CHANGE_CURRENT_TYPE';
export const EDIT_CUSTOMISATION = 'EDIT_CUSTOMISATION';
export const NEW_CUSTOMISATION = 'NEW_CUSTOMISATION';
export const CLOSE_CUSTOMISATION = 'CLOSE_CUSTOMISATION';

export const TYPE_ITEM_ADD_ON = 'itemAddOn';
export const TYPE_SERVICE_ADD_ON = 'serviceAddOn';
export const TYPES = [TYPE_ITEM_ADD_ON, TYPE_SERVICE_ADD_ON];

export const SAVING_ITEM_ADD_ON = 'SAVING_ITEM_ADD_ON';
export const SAVE_ITEM_ADD_ON_SUCCESS = 'SAVE_ITEM_ADD_ON_SUCCESS';
export const SAVE_ITEM_ADD_ON_FAILURE = 'SAVE_ITEM_ADD_ON_FAILURE';

export const DELETING_ITEM_ADD_ON = 'DELETING_ITEM_ADD_ON';
export const DELETE_ITEM_ADD_ON_SUCCESS = 'DELETE_ITEM_ADD_ON_SUCCESS';
export const DELETE_ITEM_ADD_ON_FAILURE = 'DELETE_ITEM_ADD_ON_FAILURE';

export const SAVING_SERVICE_ADD_ON = 'SAVING_SERVICE_ADD_ON';
export const SAVE_SERVICE_ADD_ON_SUCCESS = 'SAVE_SERVICE_ADD_ON_SUCCESS';
export const SAVE_SERVICE_ADD_ON_FAILURE = 'SAVE_SERVICE_ADD_ON_FAILURE';

export const DELETING_SERVICE_ADD_ON = 'DELETING_SERVICE_ADD_ON';
export const DELETE_SERVICE_ADD_ON_SUCCESS = 'DELETE_SERVICE_ADD_ON_SUCCESS';
export const DELETE_SERVICE_ADD_ON_FAILURE = 'DELETE_SERVICE_ADD_ON_FAILURE';

export const CLONING_ITEM_ADD_ON = 'CLONING_ITEM_ADD_ON';
export const CLONE_ITEM_ADD_ON_SUCCESS = 'CLONE_ITEM_ADD_ON_SUCCESS';
export const CLONE_ITEM_ADD_ON_FAILURE = 'CLONE_ITEM_ADD_ON_FAILURE';

export const CLONING_SERVICE_ADD_ON = 'CLONING_SERVICE_ADD_ON';
export const CLONE_SERVICE_ADD_ON_SUCCESS = 'CLONE_SERVICE_ADD_ON_SUCCESS';
export const CLONE_SERVICE_ADD_ON_FAILURE = 'CLONE_ITEM_ADD_ON_FAILURE';

export const DETACH_CUSTOMISATION = 'DETACH_CUSTOMISATION';

export const REMOVE_NOTICE_FLASH = 'REMOVE_NOTICE_FLASH';

export const ADD_CUSTOMISATION_OPTION = 'ADD_CUSTOMISATION_OPTION';
export const DELETE_CUSTOMISATION_OPTION = 'DELETE_CUSTOMISATION_OPTION';

export const ADD_TEMPORARY_CUSTOMISATION_PHOTO = 'ADD_TEMPORARY_CUSTOMISATION_PHOTO';
export const DELETE_CUSTOMISATION_PHOTO = 'DELETE_CUSTOMISATION_PHOTO';

export const ADD_TEMPORARY_CUSTOMISATION_OPTION_PHOTO = 'ADD_TEMPORARY_CUSTOMISATION_OPTION_PHOTO';
export const DELETE_CUSTOMISATION_OPTION_PHOTO = 'DELETE_CUSTOMISATION_OPTION_PHOTO';

export const SET_CUSTOMISATIONS_KEYWORD = 'SET_CUSTOMISATIONS_KEYWORD';
