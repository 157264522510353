import * as api from 'libs/middlewares/api';
import * as actionTypes from '../constants/outletsConstants';

export const fetchOutlets = () => {
  const types = [
    actionTypes.FINDING_OUTLETS,
    actionTypes.FIND_OUTLETS_SUCCESS,
    actionTypes.FIND_OUTLETS_FAILURE,
  ];

  return api.get({
    types,
    endpoint: '/outlets',
    params: {},
    id: null,
  });
};
