/* eslint max-len: 0 */

import React from 'react';

const Handle = (_props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line y1="5.5" x2="16" y2="5.5" stroke="#95989D" />
    <line y1="9.5" x2="16" y2="9.5" stroke="#95989D" />
  </svg>
);

export default Handle;
