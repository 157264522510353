import * as api from 'libs/middlewares/api';

import * as actionTypes from '../constants/outletSelectorConstants';

const sessionsUrl = '/sessions';

export function selectOutlets(params = {}) {
  const types = [
    actionTypes.SELECT_OUTLETS,
    actionTypes.SELECT_OUTLETS_SUCCESS,
    actionTypes.SELECT_OUTLETS_FAILURE,
  ];
  return api.save({
    types,
    endpoint: `${sessionsUrl}/update_outlet_session`,
    params,
    forceUpdate: true,
  });
}
