import React, { Fragment } from 'react';
import SecondarySection from 'bundles/vendor/components/Item/SecondarySection';
import VariationsSection from 'bundles/vendor/components/Item/Variations/VariationsSection';
import { Separator } from 'libs/support/sharedComponents';
import QuantityFieldGroup from 'bundles/vendor/components/Item/FieldGroups/QuantityFieldGroup';
import EcommerceTagsSection from 'bundles/vendor/components/Item/Sections/EcommerceTagsSection';
import PhotosSection from 'bundles/vendor/components/Item/Sections/PhotosSection';
import CustomisationsSection from 'bundles/vendor/components/Item/Sections/CustomisationsSection';
import PrimarySection from 'bundles/vendor/components/Item/Sections/PrimarySection';

const EcommerceForm = () => (
  <Fragment>
    <PrimarySection />

    <Separator />

    <VariationsSection />

    <Separator />

    <SecondarySection />
    <QuantityFieldGroup />

    <Separator />

    <EcommerceTagsSection />

    <Separator />

    <PhotosSection isRequired />

    <Separator />

    <CustomisationsSection />
  </Fragment>
);

EcommerceForm.propTypes = {
};

export default EcommerceForm;
