import styled from 'styled-components';

export const Badge = styled.span`
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
  height: 20px;
  padding: 0 0.5rem;
  top: 345px;
  text-transform: uppercase;
`;

export const PrimaryBadge = styled(Badge)`
  background-color: ${props => props.theme.colors.snowFlurry};
  color: ${props => props.theme.colors.lima};
  border: 1px solid ${props => props.theme.colors.lima};
`;

export const SecondaryBadge = styled(Badge)`
  background-color: ${props => props.theme.colors.gray200};
  color: ${props => props.theme.colors.gunPowder};
  border: 1px solid ${props => props.theme.colors.greyChateau};
`;

export const AlertBadge = styled(Badge)`
  background-color: ${props => props.theme.colors.lavenderBlush};
  color: ${props => props.theme.colors.mediumRedViolet};
  border: 1px solid ${props => props.theme.colors.mediumRedViolet};
`;
