import React from 'react';
import { func, string } from 'prop-types';
import styled from 'styled-components';
import { SecondaryButton } from 'libs/support/sharedComponents';
import { EditIcon } from 'libs/components/Icons';

const Button = styled(SecondaryButton)`
  padding: 0.43rem 0.5rem;
  height: 32px;
`;

const EditButton = ({
  className = '',
  onClick,
}) => {
  const handleOnClick = (e) => {
    e.preventDefault();

    return onClick && onClick();
  };

  return (
    <Button
      className={className}
      onClick={handleOnClick}
    >
      <EditIcon />
    </Button>
  );
};

EditButton.propTypes = {
  className: string,
  onClick: func,
};

export default EditButton;
