/* eslint no-return-assign: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { withI18n } from 'libs/support/i18n';

import BaseComponent from 'libs/components/BaseComponent';
import ErrorComponent from 'libs/components/ErrorComponent';
import SuccessComponent from 'libs/components/SuccessComponent';
import FieldComponent from 'libs/components/FieldComponent';
import { blankish, notBlank } from 'libs/support/string';
import { getIndex } from 'libs/support/findUtil';
import {
  ActionBar,
  ActionBarLeftSection,
  ActionBarRightSection,
  BackButton,
  PrimaryTitle,
} from 'libs/support/sharedComponents';
import DeleteButton from 'libs/components/Buttons/Delete';
import { SaveButton } from 'bundles/common/components/Buttons';
import { BackIcon } from 'libs/components/Icons';
import { trackClickedSaveForAddUser, trackClickedSaveForEditUser } from 'packs/events';

import RoleSelection from './RoleSelection';

class CatererAdminForm extends BaseComponent {
  static propTypes = {
    isSaving: PropTypes.bool,
    isSaveSuccess: PropTypes.bool,
    isDeleting: PropTypes.bool,
    catererId: PropTypes.string.isRequired,
    outlets: PropTypes.object,
    admin: PropTypes.object,
    errors: PropTypes.object,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onHideForm: PropTypes.func.isRequired,
  };

  constructor(props) {
    super();
    this.state = { selectedRole: props.admin.get('role') || 'admin' };
  }

  handleOnClickBack = (e) => {
    e.preventDefault();

    this.props.onHideForm();
  }

  handleOnSave = (e) => {
    if (e) {
      e.preventDefault();
    }

    const { catererId, admin, onSave } = this.props;

    if (blankish(admin.get('uuid'))) {
      trackClickedSaveForAddUser();
    } else {
      trackClickedSaveForEditUser();
    }

    const params = new FormData(this.form);
    params.set('vendor_admin[vendor_id]', catererId);
    params.set('vendor_admin[role]', this.state.selectedRole);
    onSave(admin.get('uuid'), catererId, params);
  }

  handleOnDelete = (e) => {
    if (e) {
      e.preventDefault();
    }

    const {
      catererId, admin, onDelete, translate,
    } = this.props;

    if (confirm(translate('vendorAdminsButtonsDeleteThis'))) { // eslint-disable-line
      onDelete(admin.get('uuid'), catererId);
    }
  }

  render() {
    const {
      isSaving, isSaveSuccess, isDeleting, admin, outlets, errors, translate,
    } = this.props;
    const { selectedRole } = this.state;

    const isNewAdmin = blankish(admin.get('uuid'));
    const disabled = isSaving || isDeleting;

    const roles = [
      {
        key: 'admin',
        title: translate('vendorAdminsFormsFieldsRoleAdminTitle'),
        description: translate('vendorAdminsFormsFieldsRoleAdminDescription'),
      },
      {
        key: 'store_manager',
        title: translate('vendorAdminsFormsFieldsRoleStoreManagerTitle'),
        description: translate('vendorAdminsFormsFieldsRoleStoreManagerDescription'),
      },
      {
        key: 'finance',
        title: translate('vendorAdminsFormsFieldsRoleFinanceTitle'),
        description: translate('vendorAdminsFormsFieldsRoleFinanceDescription'),
      },
    ];

    return (
      <div>
        <form ref={node => this.form = node}>
          <div className="group-actions">
            <div className="row">
              <div className="col-md-12">
                <div className="heading-title">
                  <h6>
                    {translate('vendorAdminsFormHeader')}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <ActionBar className="item-actions">
            <ActionBarLeftSection>
              <BackButton
                onClick={this.handleOnClickBack}
              >
                <span className="tw-flex tw-content-center tw-justify-center"><BackIcon /></span>
              </BackButton>
              <PrimaryTitle>
                {isNewAdmin ? translate('vendorAdminsFormTitleAdd') : translate('vendorAdminsFormTitleEdit')}
              </PrimaryTitle>
            </ActionBarLeftSection>
            <ActionBarRightSection>
              {
                (!isNewAdmin) &&
                <DeleteButton
                  className="delete-menu-item-button"
                  disabled={disabled}
                  onClick={this.handleOnDelete}
                />

              }
              <SaveButton isSaving={isSaving} onClick={this.handleOnSave} />
            </ActionBarRightSection>
          </ActionBar>

          <div className="content-box" style={{ marginTop: '20px' }}>
            {
              (errors.size > 0) &&
              <div className="form-group row">
                <div className="col-sm-9 offset-md-3">
                  <ErrorComponent
                    errors={errors}
                  />
                </div>
              </div>
            }

            {
              (isSaveSuccess) &&
              <div className="form-group row">
                <div className="col-sm-9 offset-md-3">
                  <SuccessComponent
                    subject={translate('vendorAdminsFormHeader')}
                  />
                </div>
              </div>
            }

            <FieldComponent
              isRequired
              label="vendorAdminsFormsFieldsFirstName"
              name="vendor_admin[first_name]"
              type="text"
              value={admin.get('first_name')}
            />

            <FieldComponent
              isRequired
              label="vendorAdminsFormsFieldsLastName"
              name="vendor_admin[last_name]"
              type="text"
              value={admin.get('last_name')}
            />

            <FieldComponent
              isRequired
              label="vendorAdminsFormsFieldsEmail"
              name="vendor_admin[email]"
              type="email"
              value={admin.get('email')}
            />

            <div className="row form-group">
              <div className="col-md-3 col-form-label">
                <label className="mb-0" htmlFor="vendor_admin[role]" style={{ color: '#95989d' }}>
                  {translate('vendorAdminsFormsFieldsRole')}
                  <span className="label-required"> * </span>
                </label>
              </div>
              <div className="col-md-9">
                <div className="row">
                  {roles.map(role =>
                    (
                      <div className="col-md-4">
                        <RoleSelection
                          title={role.title}
                          description={role.description}
                          isSelected={selectedRole === role.key}
                          onClick={() => { this.setState({ selectedRole: role.key }); }}
                        />
                      </div>))}
                </div>
              </div>
            </div>


            {
              outlets.map(outlet => {
                const id = outlet.get('uuid');

                let value = false;

                if (notBlank(admin.get('outlets'))) {
                  value = getIndex(outlet.get('uuid'), admin.get('outlets')) > -1;
                }

                return (
                  <FieldComponent
                    key={`outlet-${id}`}
                    optionName={outlet.get('name')}
                    name={`vendor_admin[outlet_ids][][${id}]`}
                    type="toggle"
                    value={value}
                  />
                );
              })
            }
          </div>
        </form>
      </div>
    );
  }
}

export default withI18n(CatererAdminForm);
