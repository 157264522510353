/* eslint max-len: 0 */

import React from 'react';

const LargeHistory = (_props) => (
  <svg width="39" height="35" viewBox="0 0 39 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.3477 33.3043C29.9921 33.3043 36.9998 26.2966 36.9998 17.6522C36.9998 9.00772 29.9921 2 21.3477 2" stroke="#141415" strokeWidth="3" strokeLinecap="round" />
    <path d="M36.9997 17.6523C36.9997 9.00789 29.9919 2.00017 21.3475 2.00017C12.703 2.00017 5.69531 9.00789 5.69531 17.6523" stroke="#141415" strokeWidth="3" strokeLinecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M1.19677 15.5053C1.45874 15.2378 1.88347 15.2378 2.14544 15.5053L5.69595 19.1297L9.24646 15.5053C9.50843 15.2378 9.93316 15.2378 10.1951 15.5053C10.4571 15.7727 10.4571 16.2063 10.1951 16.4737L6.17028 20.5824C5.90832 20.8498 5.48358 20.8498 5.22162 20.5824L1.19677 16.4737C0.934806 16.2063 0.934806 15.7727 1.19677 15.5053Z" fill="#141415" stroke="#141415" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M21.7393 9.04297V17.6058C21.7393 18.1363 21.5286 18.645 21.1535 19.0201L16.6523 23.5212" stroke="black" strokeWidth="3" strokeLinecap="round" />
  </svg>
);
export default LargeHistory;
