import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import BaseComponent from 'libs/components/BaseComponent';
import { notBlank } from 'libs/support/string';
import { defaultMessages } from 'libs/i18n/default';

class SuccessComponent extends BaseComponent {
  static propTypes = {
    subject: PropTypes.string.isRequired,
    message: PropTypes.string,
    intl: intlShape.isRequired,
  };

  render() {
    const { subject, message, intl } = this.props;
    const { formatMessage } = intl;

    return (
      <div className="alert alert-full alert-success text-xs-center">
        {subject}

        &nbsp;

        {
          (notBlank(message)) ?
            message :
            formatMessage(defaultMessages.termsHasBeenSaved)
        }
      </div>
    );
  }
}

export default injectIntl(SuccessComponent);
