import { useQuery, useMutation, useInfiniteQuery } from 'react-query';
import { httpClient } from 'libs/support/http-client';

export const useOrdersCount = (params) => (
  useQuery([
    'ORDERS_COUNT',
    params.vendor_id,
    params.outlet_ids,
    params.query,
    params.delivery_date_from,
    params.delivery_date_to,
    params.order_date_from,
    params.order_date_to,
  ], async () => {
    const { data } = await httpClient.get('/orders/count.json', {
      params,
    });

    return data;
  }, {
    staleTime: Infinity,
  })
);

export const useOrders = (params) => (
  useInfiniteQuery({
    queryKey: ['ORDERS', Object.values(params)],
    queryFn: async (e) => {
      if (e.pageParam) {
        params.page = e.pageParam; // eslint-disable-line
      }
      const { data } = await httpClient.get('/orders.json', {
        params,
      });
      return data;
    },
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.ordersMetadata.page + 1;
      if (nextPage <= lastPage.ordersMetadata.totalPages) {
        return nextPage;
      }
      return undefined;
    },
    staleTime: Infinity,
  })
);

export const useOrder = (params) => (
  useQuery([
    'ORDER',
    params.orderableId,
  ], async () => {
    const { data } = await httpClient.get(`/orders/${params.orderableId}.json`);
    return data;
  }, {
    staleTime: Infinity,
    enabled: !params.isDraftOrder,
  })
);

export const useOrderBilling = (params) => (
  useQuery([
    'ORDER_BILLING',
    params.orderId,
  ], async () => {
    const { data } = await httpClient.get(`/orders/${params.orderId}/order_billings.json`);
    return data;
  }, {
    staleTime: Infinity,
    enabled: !params.isDraftOrder,

  })
);

export const useOrderHistories = (params) => {
  const { orderId, isDraftOrder } = params;
  return useQuery(['ORDER_HISTORIES', orderId], async () => {
    const { data } = await httpClient.get(`/orders/${orderId}/order_histories.json`);
    return data;
  }, {
    staleTime: Infinity,
    enabled: !isDraftOrder,
  });
};


export const useAcceptOrder = () => (
  useMutation((body) => (
    httpClient.post(`/orders/${body.order_id}/confirm.json`, body)
  ))
);

export const useDeclineOrder = () => (
  useMutation((body) => (
    httpClient.post(`/orders/${body.order_id}/decline.json`, body)
  ))
);

export const useAcceptChangesOrder = () => (
  useMutation((body) => (
    httpClient.post(`/orders/${body.order_id}/confirm_changes.json`, body)
  ))
);

export const useDeclineChangesOrder = () => (
  useMutation((orderId) => (
    httpClient.post(`/orders/${orderId}/decline_changes.json`)
  ))
);

export const useCancelOrder = () => (
  useMutation((orderId) => (
    httpClient.post(`/orders/${orderId}/cancel_by_customer.json`)
  ))
);

export const useUpdateOrderDriver = (orderId) => (
  useMutation((payload) => {
    httpClient.put(`/orders/${orderId}/update_driver.json`, payload);
  })
);

export const useConfirmOrderReminder = () => (
  useMutation((orderId) => {
    httpClient.put(`/orders/${orderId}/confirm_order_reminder.json`);
  })
);
