import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';
import {
  PrimaryTitle,
} from 'libs/support/sharedComponents';

const Container = styled(PrimaryTitle)`
  padding-bottom: 1.5rem;
`;

const Header = ({
  value,
}) => (
  <Container>
    { value }
  </Container>
);

Header.propTypes = {
  value: string,
};

export default Header;
