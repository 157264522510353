/* eslint no-return-assign: 0 */

import { useRef } from 'react';
import {
  round,
  calculateDisplayPrice,
  calculateBasePrice,
  calculateVendorPrice,
} from 'libs/support/price';
import { blankish } from 'libs/support/string';

export const usePrice = ({
  basePrice = 0,
  taxRate,
  taxType,
  markupPricePercent,
  roundingAdjustment = 2,
}) => {
  const inputPriceRef = useRef();
  const displayPriceRef = useRef();

  const vendorPrice = basePrice ? calculateVendorPrice({
    taxRate,
    taxType,
    value: basePrice,
    rounding: roundingAdjustment,
  }).toFixed(2) : null;

  const displayPrice = roundingAdjustment !== 5
    ? calculateDisplayPrice({
      taxRate,
      taxType,
      markupPricePercent,
      value: basePrice,
      rounding: roundingAdjustment,
    }).toFixed(2)
    : Number(parseFloat(basePrice).toFixed(roundingAdjustment));

  const handleVendorPriceChange = (e) => {
    if (!e.currentTarget) return;

    const fixed = parseFloat(e.currentTarget.value).toFixed(2).toString();
    if (fixed.length < parseFloat(e.currentTarget.value).toString().length) {
      e.currentTarget.value = fixed;
    }

    const newBasePrice = calculateBasePrice({
      taxRate,
      taxType,
      value: e.currentTarget.value,
      rounding: roundingAdjustment,
    });

    const newPriceDisplay = calculateDisplayPrice({
      taxRate,
      markupPricePercent,
      value: newBasePrice,
      rounding: roundingAdjustment,
    });

    displayPriceRef.current.textContent = roundingAdjustment !== 5
      ? newPriceDisplay.toFixed(2)
      : Number(newBasePrice.toFixed(roundingAdjustment));

    inputPriceRef.current.value = newBasePrice;
  };

  const handleVendorPriceBlur = (e) => {
    if (blankish(e.currentTarget.value)) return e.currentTarget.value = '';

    const formattedPriceDisplay = round(parseFloat(e.currentTarget.value || 0, 10)).toFixed(2);

    return e.currentTarget.value = formattedPriceDisplay;
  };

  return [
    vendorPrice,
    displayPrice,
    inputPriceRef,
    displayPriceRef,
    handleVendorPriceChange,
    handleVendorPriceBlur,
  ];
};
