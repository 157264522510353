/* eslint camelcase: 0 */

import Immutable from 'immutable';
import {
  HIDE_IMAGE_MODAL,
  SHOW_IMAGE_MODAL,
} from 'bundles/common/constants/imageConstants';

const defaultImageModal = {
  details: null,
  isShow: false,
  title: null,
  photo: null,
};

export const $$initialState = Immutable.fromJS({
  imageModal: defaultImageModal,
});

export default function messagesReducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  switch (type) {
    case SHOW_IMAGE_MODAL: {
      return $$state.merge({
        imageModal: {
          details: response.details,
          onCrop: response.onCrop,
          isShow: true,
          title: response.title,
          photo: response.photo,
        },
      });
    }

    case HIDE_IMAGE_MODAL: {
      return $$state.merge({
        imageModal: defaultImageModal,
      });
    }

    default: {
      return $$state;
    }
  }
}
