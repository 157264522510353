import React from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import MenuItemNestedAttributeFieldGroup from './MenuItemNestedAttributeFieldGroup';

const ExpirationFieldGroup = ({
  menuItem,
  expirations,
}) => (
  <MenuItemNestedAttributeFieldGroup
    hintText="How long should the item keep after delivery."
    label="Freshness"
    optionalText="optional"
    options={expirations}
    optionKey="expiration"
    item={menuItem.get('menu_item_expiration')}
    hasValue
    isInteger
  />
);

ExpirationFieldGroup.propTypes = {
  menuItem: object.isRequired,
  expirations: object.isRequired,
};

const mapStateToProps = (state) => ({
  menuItem: state.$$menuItemsStore.get('menuItem'),
  expirations: state.$$filtersStore.get('expirations'),
});

export default connect(mapStateToProps)(ExpirationFieldGroup);
