/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, Fragment } from 'react';
import { array, bool, func, string } from 'prop-types';
import {
  ModalContainer,
} from 'libs/support/sharedComponents';
import Modal from 'libs/components/ModalComponent';
import ChevronRight from 'libs/components/Icons/v2/ChevronRight';
import MobileHeader from 'bundles/v2/common/components/MobileHeader';
import ArrowLeft from 'libs/components/Icons/v2/ArrowLeft';
import { withI18n } from 'libs/support/i18n';

import DatePicker from 'react-datepicker';
import moment from 'moment';
import { trackOrderDateChange, trackDeliveryDateChange, trackStatusState, trackSortState } from 'packs/events';

const MobileFilterModal = ({
  showModal,
  handleOnHideModal,
  setSort,
  orderDates,
  setOrderDates,
  deliveryDates,
  setDeliveryDates,
  translate,
  statusesCategories,
  setStatus,
  status,
  dateDisplayText,
  ordersCountResponse,
}) => {
  const [selectedSort, setSelectedSort] = useState('created_at');
  const [view, setView] = useState('sort');
  const [modalOrderDates, setModalOrderDates] = useState(orderDates);
  const [modalDeliveryDates, setModalDeliveryDates] = useState(deliveryDates);
  const [modalStatus, setModalStatus] = useState(status);
  const dateView = view === 'orderDates' ? modalOrderDates : modalDeliveryDates;

  const onClickApply = () => {
    if (selectedSort) {
      trackSortState(`${selectedSort} asc`);
      setSort(`${selectedSort} asc`);
    }
    trackStatusState(modalStatus);
    setStatus(modalStatus);
    if (modalOrderDates.length === 2) {
      trackOrderDateChange();
      setOrderDates(modalOrderDates);
    }
    if (modalDeliveryDates.length === 2) {
      trackDeliveryDateChange();
      setDeliveryDates(modalDeliveryDates);
    }
    handleOnHideModal();
  };

  const handleDateChange = (dates) => {
    if (view === 'orderDates') {
      setModalOrderDates(dates);
    } else {
      setModalDeliveryDates(dates);
    }
  };

  const handleDateSubmit = () => {
    if (view === 'orderDates') {
      trackOrderDateChange();
      setOrderDates(modalOrderDates);
    } else {
      trackDeliveryDateChange();
      setDeliveryDates(modalDeliveryDates);
    }
    setView('sort');
  };

  const excludeDateIntervals = () => {
    if (view === 'deliveryDates' && orderDates.length === 2) {
      return [
        { start: moment(orderDates[0]).subtract(1, 'years').toDate(), end: orderDates[0] },
        { start: orderDates[1], end: moment(orderDates[1]).add(1, 'years').toDate() },
      ];
    }
    return [];
  };

  const toggleStatus = (state) => {
    if (modalStatus.includes(state)) {
      setModalStatus(modalStatus.filter(e => e !== state));
    } else {
      setModalStatus([
        ...modalStatus,
        state,
      ]);
    }
  };

  const filterCount = [
    orderDates.length > 0,
    deliveryDates.length > 0,
  ].filter(e => e === true).length + modalStatus.length;

  const displayViewDate = (v) => { // eslint-disable-line
    let viewDate;
    if (v === 'orderDates') {
      viewDate = modalOrderDates;
    }
    if (v === 'deliveryDates') {
      viewDate = modalDeliveryDates;
    }

    if (viewDate.length === 2) {
      return <span className="mobile-filter-text-display">{dateDisplayText(viewDate)}</span>;
    }
  };

  const resetAll = () => {
    setModalOrderDates([]);
    setModalDeliveryDates([]);
    setModalStatus([]);
    setOrderDates([]);
    setDeliveryDates([]);
    setStatus([]);
    const statusInputs = document.querySelectorAll('[name="statuses[]"]');
    statusInputs.forEach((input) => {
      input.checked = false; // eslint-disable-line
    });
  };

  return (
    <ModalContainer>
      <Modal
        elementId=""
        show={showModal}
        className="align-left"
        onHide={() => { handleOnHideModal(); }}
        removeFade
      >
        <Fragment>
          { view === 'sort' && (
            <Fragment>
              <MobileHeader>
                <div className="d-flex mobile-order-header-titles filter-modal">
                  <span
                    onClick={() => { handleOnHideModal(); }}
                  >
                    <ArrowLeft />
                  </span>
                  <span className="order-text">{translate('ordersModalFiltersHeader')}</span>
                </div>
              </MobileHeader>
              <div className="filter-modal-content">
                <span className="header-text">{translate('ordersModalFiltersSortBy')}</span>
                <div className="sort-by">
                  <div className="sort-by-row sort-by-row-first">
                    <input
                      type="radio"
                      id="order_date"
                      name="sort"
                      value="order_date"
                      className="radio-button"
                      onChange={() => setSelectedSort('created_at')}
                      checked={selectedSort === 'created_at'}
                    />
                    <span>{translate('ordersModalFiltersOrderDate')}</span>
                  </div>
                  <div className="sort-by-row sort-by-row-first">
                    <input
                      type="radio"
                      id="delivery_date"
                      name="sort"
                      value="delivery_date"
                      className="radio-button"
                      onChange={() => setSelectedSort('deliver_at')}
                    />
                    <span>{translate('ordersModalFiltersDeliveryDate')}</span>
                  </div>
                  <div className="sort-by-row sort-by-row-first">
                    <input
                      type="radio"
                      id="status"
                      name="sort"
                      value="status"
                      className="radio-button"
                      onChange={() => setSelectedSort('status')}
                    />
                    <span>{translate('ordersModalFiltersStatus')}</span>
                  </div>
                  <div className="sort-by-row sort-by-row-first">
                    <input
                      type="radio"
                      id="outlet"
                      name="sort"
                      value="outlet"
                      className="radio-button"
                      onChange={() => setSelectedSort('outlet_id')}
                    />
                    <span>{translate('ordersModalFiltersOutlet')}</span>
                  </div>
                </div>
                <span className="header-text">{translate('ordersModalFiltersStatus')}</span>
                <div className="sort-by">
                  { statusesCategories.map((category) => (
                    <Fragment>
                      {category.children.map((child) => (
                        <div className="sort-by-row sort-by-row-first">
                          <input
                            type="checkbox"
                            className="custom-checkbox-v2"
                            id={`checkbox-mobile-${child.value}`}
                            value={child.value}
                            name="statuses[]"
                            defaultChecked={status.includes(child.value)}
                            onChange={() => { toggleStatus(child.value); }}
                          />
                          <label className="status-dropdown-item-label" htmlFor={`checkbox-mobile-${child.value}`}>
                            {child.label} ({ordersCountResponse?.[`total${child.key}OrdersCount`] || 0})
                          </label>
                        </div>
                      ))}
                    </Fragment>
                  ))}
                </div>
                <div className="line-filter-mobile" />
                <span className="header-text">{translate('ordersModalFiltersFilterBy')}</span>
                <div className="sort-by">
                  <div
                    className="sort-by-row"
                    onClick={() => setView('orderDates')}
                  >
                    <span>{translate('ordersModalFiltersOrderDate')}</span>
                    <div className="sort-by-row-end-container">
                      <Fragment>
                        { modalOrderDates.length === 2 &&
                          <span className="mobile-filter-text-display">{dateDisplayText(modalOrderDates)}</span>
                        }
                        <ChevronRight />
                      </Fragment>
                    </div>
                  </div>
                </div>
                <div className="sort-by">
                  <div
                    className="sort-by-row"
                    onClick={() => setView('deliveryDates')}
                  >
                    <span>{translate('ordersModalFiltersDeliveryDate')}</span>
                    <div className="sort-by-row-end-container">
                      <Fragment>
                        { modalDeliveryDates.length === 2 &&
                          <span className="mobile-filter-text-display">{dateDisplayText(modalDeliveryDates)}</span>
                        }
                        <ChevronRight />
                      </Fragment>
                    </div>
                  </div>
                </div>
                <div className="order-filter-mobile-footer-button">
                  <button className="btn btn-default" onClick={() => resetAll()}>Reset ({filterCount})</button>
                  <button className="btn btn-primary" onClick={() => onClickApply()}>Apply</button>
                </div>
              </div>
            </Fragment>
          )}
          { (view === 'orderDates' || view === 'deliveryDates') && (
            <Fragment>
              <DatePicker
                selected={dateView[0]}
                onChange={date => handleDateChange(date)}
                startDate={dateView[0]}
                endDate={dateView[1]}
                excludeDateIntervals={excludeDateIntervals()}
                selectsRange
                inline
                monthsShown={2}
              />
              <div className="order-filter-mobile-footer-button full">
                <div className="date-picker-container">
                  { displayViewDate(view) }
                  <div className="d-flex button-container">
                    <button
                      className="btn btn-default"
                      onClick={() => setView('sort')}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary btn-block"
                      onClick={() => handleDateSubmit()}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </Fragment>
      </Modal>
    </ModalContainer>
  );
};

MobileFilterModal.propTypes = {
  showModal: bool,
  handleOnHideModal: func,
  setSort: func,
  orderDates: array,
  setOrderDates: func,
  deliveryDates: array,
  setDeliveryDates: func,
  translate: func,
  statusesCategories: array,
  setStatus: func,
  status: array,
  dateDisplayText: string,
  ordersCountResponse: string,
};

export default withI18n(MobileFilterModal);
