import React from 'react';
import { bool } from 'prop-types';
import { connect } from 'react-redux';
import { SaveButton } from 'bundles/common/components/Buttons';
import styled from 'styled-components';
import { trackClickedSaveForBillingDetails } from 'packs/events';

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Actions = ({
  isSaving,
}) => {
  const handleSave = () => {
    trackClickedSaveForBillingDetails();
    document
      .getElementById('vendor_billing_form')
      .querySelector('input[type="submit"]')
      .click();
  };

  return (
    <Container className="vendor-billing-actions">
      <SaveButton
        isSaving={isSaving}
        onClick={handleSave}
      />
    </Container>
  );
};

Actions.propTypes = {
  isSaving: bool.isRequired,
};


const mapStateToProps = ({
  $$vendorBillingStore,
}) => ({
  isSaving: $$vendorBillingStore.get('isSaving'),
});

export default connect(mapStateToProps)(Actions);
