/* eslint-disable quotes */
import React, { Fragment, useState, useEffect } from "react";
import { withAppContext } from 'libs/support/appContext';
import { withQueryClient } from 'libs/support/withQueryClient';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IntlProvider } from 'react-intl';
import { translations } from 'libs/i18n/translations';
import { object, func, number, bool, array } from "prop-types";
import moment from "moment";
import { trackViewedV2Dashboard } from "packs/events";

import * as dashboardActions from "../actions/dashboardActions";
import ActionRequiredSection from "../components/ActionRequiredSection";
import OrderList from "../components/OrderList";
import OverviewContainer from "../components/OverviewContainer";
import { useDashboardPerformance } from '../queries/dashboards';

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

const DashboardContainer = ({
  outletsSelectedIds,
  fetchDashboardData,
  vendorId,
  isFetchingDashboardData,
  orderCount,
  orders,
  actionRequiredOrders,
  localeStore,
  tomorrowOrders,
}) => {
  const locale = localeStore.get('locale');
  const messages = translations[locale];

  const defaultStartDate = moment().startOf('week').toDate();
  const defaultEndDate = moment().endOf('week').toDate();

  const [dates, setDates] = useState([defaultStartDate, defaultEndDate]);

  const startDate = dates[0];
  const endDate = dates[1];

  const defaultParams = {
    outlet_ids: outletsSelectedIds.toArray(),
    start_date: moment(startDate).format('YYYY-MM-D'),
    end_date: moment(endDate).format('YYYY-MM-D'),
    vendor_id: vendorId,
  };

  const { data: dashboardPerformance, isLoading: isLoadingPerformance } = useDashboardPerformance(defaultParams);

  useEffect(() => {
    trackViewedV2Dashboard();
    const params = {
      outlet_ids: outletsSelectedIds.toArray(),
      start_date: moment(startDate).format('YYYY-MM-D'),
      end_date: moment(endDate).format('YYYY-MM-D'),
      vendor_id: vendorId,
    };
    fetchDashboardData(params);
  }, [vendorId, outletsSelectedIds]);

  const onChangeDate = (newDates) => {
    setDates(newDates);
  };

  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      <Fragment>
        { actionRequiredOrders?.orders?.length > 0 &&
          <ActionRequiredSection
            {
              ...{
                orderCount,
                actionRequiredOrders,
                isFetchingDashboardData,
              }
            }
          />
        }
        <OverviewContainer
          {
            ...{
              startDate,
              endDate,
              onChangeDate,
              dashboardPerformance,
              isLoadingPerformance,
            }
          }
        />
        <div className="py-m-20">
          <OrderList
            {
              ...{
                orders,
                tomorrowOrders,
                isFetchingDashboardData,
              }
            }
          />
        </div>
      </Fragment>
    </IntlProvider>
  );
};

const mapStateToProps = ({
  $$outletSelectorStore,
  $$dashboardStore,
  $$localeStore,
}) => ({
  outletsSelected: $$outletSelectorStore.get('outletsSelected'),
  outletsSelectedIds: $$outletSelectorStore.get('outletsSelectedIds'),
  outlets: $$outletSelectorStore.get('outlets'),
  vendorId: $$outletSelectorStore.get('vendorId'),
  totalRevenue: $$dashboardStore.get('totalRevenue'),
  totalRevenueActiveOrder: $$dashboardStore.get('totalRevenueActiveOrder'),
  totalRevenueCompletedOrder: $$dashboardStore.get('totalRevenueCompletedOrder'),
  averageOrderValue: $$dashboardStore.get('averageOrderValue'),
  totalOrders: $$dashboardStore.get('totalOrders'),
  totalActiveOrders: $$dashboardStore.get('totalActiveOrders'),
  completedOrders: $$dashboardStore.get('completedOrders'),
  isFetchingDashboardData: $$dashboardStore.get('isFetchingDashboardData'),
  orderCount: $$dashboardStore.get('orderCount'),
  orders: $$dashboardStore.get('orders'),
  actionRequiredOrders: $$dashboardStore.get('actionRequiredOrders'),
  tomorrowOrders: $$dashboardStore.get('tomorrowOrders'),
  localeStore: $$localeStore,
  fullName: $$outletSelectorStore.get('fullName'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchDashboardData: dashboardActions.fetchDashboardData,
}, dispatch);

DashboardContainer.propTypes = {
  outletsSelectedIds: object,
  fetchDashboardData: func,
  vendorId: number,
  isFetchingDashboardData: bool,
  orderCount: number,
  orders: array,
  actionRequiredOrders: array,
  tomorrowOrders: array,
  localeStore: object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withAppContext(withQueryClient(DashboardContainer)));
