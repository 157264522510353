import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ORDER_ACTIONS } from 'bundles/v2/orders/constants/orderConstants';

const ConfirmOrderReminder = ({
  isLoadingAction,
  isReminderConfirmed,
  confirmOrderReminder,
  deliveryType,
  hasDriver,
  isConfirmOrderReminderSuccess,
  translate,
  onChangeAction,
}) => {
  const displayOrderReminderDescription = () => {
    if (deliveryType === 'own') {
      return hasDriver ?
        `${translate('ordersShowDetailsOrderNoticeConfirmOrderReminderDescriptionWithDriver')}` :
        `${translate('ordersShowDetailsOrderNoticeConfirmOrderReminderDescriptionWithoutDriver')}`;
    }
    return `${translate('ordersShowDetailsOrderNoticeConfirmOrderReminderDescription')}`;
  };

  return (
    <div className="notice notice-purple notice-purple-changes mb-1">
      { isLoadingAction ? <div className="loader-container"><span className="loader" /></div> :
      <Fragment>
        { isReminderConfirmed ?
          <span className="notice-text-header notice-text-header-success">
            {translate('ordersShowDetailsOrderNoticeConfirmedOrderReminderDescription')}
          </span> :
          (confirmOrderReminder || !isConfirmOrderReminderSuccess) &&
            <Fragment>
              <div className="notice-text-group">
                <span className="notice-text-subheader mt-8">
                  {displayOrderReminderDescription()}
                </span>
              </div>
              <div className="notice-button-group notice-button-group-change-requested">
                <button
                  className="btn btn-accept"
                  onClick={(e) => onChangeAction(e, ORDER_ACTIONS.confirm_order_reminder)}
                  disabled={deliveryType === 'own' && !hasDriver}
                >
                  {translate('ordersShowDetailsOrderNoticeConfirm')}
                </button>
              </div>
            </Fragment>
        }
      </Fragment>
        }
    </div>
  );
};

ConfirmOrderReminder.propTypes = {
  isLoadingAction: PropTypes.bool.isRequired,
  isReminderConfirmed: PropTypes.bool.isRequired,
  confirmOrderReminder: PropTypes.bool.isRequired,
  deliveryType: PropTypes.string.isRequired,
  hasDriver: PropTypes.bool.isRequired,
  isConfirmOrderReminderSuccess: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  onChangeAction: PropTypes.func.isRequired,
};

export default ConfirmOrderReminder;
