/* eslint max-len: 0 */

import React from 'react';

const LargeCart = (_props) => (
  <svg width="59" height="45" viewBox="0 0 59 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M57 2L49.8349 2C48.7303 2 47.8349 2.89543 47.8349 4V31C47.8349 32.1046 46.9394 33 45.8349 33H2" stroke="#141415" strokeWidth="3" strokeLinecap="round" />
    <circle cx="9" cy="40" r="4" fill="#141415" stroke="#141415" strokeWidth="2" />
    <circle cx="35" cy="40" r="4" fill="#141415" stroke="#141415" strokeWidth="2" />
    <rect x="31" y="19" width="12" height="10" rx="2" fill="white" stroke="#141415" strokeWidth="2" />
    <rect x="31" y="5" width="12" height="10" rx="2" fill="white" stroke="#141415" strokeWidth="2" />
    <rect x="15" y="5" width="12" height="10" rx="2" fill="white" stroke="#141415" strokeWidth="2" />
    <rect x="8" y="19" width="19" height="10" rx="2" fill="white" stroke="#141415" strokeWidth="2" />
  </svg>
);
export default LargeCart;
