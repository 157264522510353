/* eslint max-len: 0 */

import React from 'react';

const CalendarThick = (_props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icons/CalendarThick">
      <path id="Union" fillRule="evenodd" clipRule="evenodd" d="M5.3335 0.666748V2.00008H10.6668V0.666748H12.0002V2.00008H12.6668C13.7714 2.00008 14.6668 2.89551 14.6668 4.00008V13.3334C14.6668 14.438 13.7714 15.3334 12.6668 15.3334H3.3335C2.22893 15.3334 1.3335 14.438 1.3335 13.3334V4.00008C1.3335 2.89551 2.22893 2.00008 3.3335 2.00008H4.00016V0.666748H5.3335ZM12.0002 3.33341H10.6668H5.3335H4.00016H3.3335C2.96531 3.33341 2.66683 3.63189 2.66683 4.00008V4.66675H13.3335V4.00008C13.3335 3.63189 13.035 3.33341 12.6668 3.33341H12.0002ZM2.66683 6.00008V13.3334C2.66683 13.7016 2.96531 14.0001 3.3335 14.0001H12.6668C13.035 14.0001 13.3335 13.7016 13.3335 13.3334V6.00008H2.66683ZM6.00016 8.00008H4.66683V9.33342H6.00016V8.00008ZM6.00016 10.6667H4.66683V12.0001H6.00016V10.6667ZM8.66683 8.00008H7.3335V9.33342H8.66683V8.00008ZM8.66683 10.6667H7.3335V12.0001H8.66683V10.6667ZM11.3335 8.00008H10.0002V9.33342H11.3335V8.00008ZM11.3335 10.6667H10.0002V12.0001H11.3335V10.6667Z" fill="#515154" />
    </g>
  </svg>
);

export default CalendarThick;
