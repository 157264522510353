/* eslint max-len: 0 */
import React from 'react';

const Print = (_props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.1499 4.1556C5.1499 3.83343 5.41107 3.57227 5.73324 3.57227H14.2666C14.5887 3.57227 14.8499 3.83343 14.8499 4.1556V6.82227C14.8499 7.14443 14.5887 7.4056 14.2666 7.4056C13.9444 7.4056 13.6832 7.14443 13.6832 6.82227V4.73893H6.31657V6.82227C6.31657 7.14443 6.0554 7.4056 5.73324 7.4056C5.41107 7.4056 5.1499 7.14443 5.1499 6.82227V4.1556Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.1499 11.6224C5.1499 11.3002 5.41107 11.0391 5.73324 11.0391H14.2666C14.5887 11.0391 14.8499 11.3002 14.8499 11.6224V16.1557C14.8499 16.4779 14.5887 16.7391 14.2666 16.7391H5.73324C5.41107 16.7391 5.1499 16.4779 5.1499 16.1557V11.6224ZM6.31657 12.2057V15.5724H13.6832V12.2057H6.31657Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.48667 7.40495C4.13141 7.40495 3.91667 7.66617 3.91667 7.88828V12.6383H5.73333C6.0555 12.6383 6.31667 12.8994 6.31667 13.2216C6.31667 13.5438 6.0555 13.8049 5.73333 13.8049H3.33333C3.01117 13.8049 2.75 13.5438 2.75 13.2216V7.88828C2.75 6.93706 3.57525 6.23828 4.48667 6.23828H15.5133C16.4247 6.23828 17.25 6.93706 17.25 7.88828V13.2216C17.25 13.5438 16.9888 13.8049 16.6667 13.8049H14.2667C13.9445 13.8049 13.6833 13.5438 13.6833 13.2216C13.6833 12.8994 13.9445 12.6383 14.2667 12.6383H16.0833V7.88828C16.0833 7.66617 15.8686 7.40495 15.5133 7.40495H4.48667Z" fill="white" />
  </svg>
);

export default Print;
