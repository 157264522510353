import React, { Fragment, useState } from 'react';
import { number, string, object } from 'prop-types';
import startCase from 'lodash/startCase';

import { SelectField } from 'libs/components/Forms';
import { notBlank } from 'libs/support/string';
import {
  getHintText,
} from '../../Item/FieldGroups/helpers/TagsFieldGroupHelpers';

const filteredSelectedOptions = (tagOptions, categoryTags) => {
  const categoryTagIds = categoryTags.map(c => c.get('taggable_id'));
  return tagOptions.filter(t => categoryTagIds.includes(t.value));
};

const Tag = ({
  label,
  limit,
  categoryTags,
  tags,
  type,
}) => {
  if (!tags || tags.isEmpty()) return null;

  const tagOptions = tags.map(tag => ({ label: tag.get('name'), value: tag.get('uuid') })).toJS();
  const [selectedOptions, setSelectedOptions] = useState(filteredSelectedOptions(tagOptions, categoryTags));

  const finalLabel = label || startCase(type);

  const handleOnChange = (options) => {
    if (options.length > limit) return;

    setSelectedOptions(options);
  };

  const renderSelectedItems = () => (
    selectedOptions.map(selectedOption => {
      const categoryTag = categoryTags.find(c => c.get('taggable_id') === selectedOption.value);

      return (
        <div key={`${type}-selected-option-${selectedOption.value}`}>
          <input type="hidden" name="category[category_tags_attributes][][taggable_type]" value={type} />
          <input type="hidden" name="category[category_tags_attributes][][taggable_id]" value={selectedOption.value} />

          {
            notBlank(categoryTag) &&
              <div>
                <input type="hidden" name="category[category_tags_attributes][][id]" value={categoryTag.get('uuid')} />
              </div>
          }
        </div>
      );
    })
  );

  const renderItemsToDelete = () => {
    const selectedOptionIds = selectedOptions.map(t => t.value);
    const toDeleteOptions = categoryTags.filter(c => !selectedOptionIds.includes(c.get('taggable_id')));

    return toDeleteOptions.map(categoryTag => (
      <div key={`${type}-deleted-option-${categoryTag.get('uuid')}`}>
        <input type="hidden" name="category[category_tags_attributes][][id]" value={categoryTag.get('uuid')} />
        <input type="hidden" name="category[category_tags_attributes][][taggable_type]" value={type} />
        <input
          type="hidden"
          name="category[category_tags_attributes][][taggable_id]"
          value={categoryTag.get('taggable_id')}
        />
        <input type="hidden" name="category[category_tags_attributes][][_destroy]" value />
      </div>
    ));
  };

  return (
    <Fragment>
      { renderSelectedItems() }
      { renderItemsToDelete() }

      <SelectField
        id={`tag-${type}`}
        label={finalLabel}
        hintText={getHintText(finalLabel, limit)}
        isMulti
        clearable
        isRequired
        onChange={handleOnChange}
        value={selectedOptions}
        options={tagOptions}
      />
    </Fragment>
  );
};

Tag.propTypes = {
  label: string,
  limit: number.isRequired,
  categoryTags: object.isRequired,
  tags: object,
  type: string.isRequired,
};

export default Tag;
