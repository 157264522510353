import React from 'react';
import { bool, object, func } from 'prop-types';
import uppercamelcase from 'uppercamelcase';
import { connect } from 'react-redux';
import { withI18n } from 'libs/support/i18n';
import styled from 'styled-components';
import {
  Table,
  HeaderRow,
  Header,
  ScrollableInnerContainer,
  ScrollableOuterContainer,
  ScrollableShadow,
} from 'libs/support/sharedComponents';
import NoRecord from 'libs/components/Tables/NoRecord';
import VendorStatement from './VendorStatement';

const HEADERS = [
  { key: 'month', width: '20%' },
  { key: 'outlet', width: '50%' },
  { key: 'link', width: '30%' },
];

const Container = styled.div`
  position: relative;
`;

const LoadingContainer = styled.div`
  left: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 3rem;
  position: absolute:
  right: 0;
  text-align: center;
`;

const Body = ({
  isFetchingVendorStatements,
  isVendorStatementsFetched,
  vendorStatements,
  translate,
}) => {
  if (isFetchingVendorStatements) {
    return (
      <div>
        Loading...
      </div>
    );
  }

  return (
    <Container>
      <ScrollableOuterContainer>
        <ScrollableInnerContainer>
          <Table className="items-list">
            <thead>
              <HeaderRow>
                {
                  HEADERS.map((header) => (
                    <Header
                      key={`header-${header.key}`}
                      style={{ minWidth: header.width }}
                      className={header.className}
                    >
                      { translate(`billings${uppercamelcase(header.key)}`) }
                    </Header>
                  ))
                }
              </HeaderRow>
            </thead>
            <tbody>
              {
                isVendorStatementsFetched && vendorStatements.size > 0 && vendorStatements.map((vendorStatement) => (
                  <VendorStatement
                    key={`vendor-statement-${vendorStatement.get('uuid')}`}
                    {
                      ...{
                        vendorStatement,
                      }
                    }
                  />
                ))
              }

              {
                isVendorStatementsFetched && vendorStatements.size === 0 &&
                  <NoRecord colSpan={3} />
              }
            </tbody>
          </Table>
        </ScrollableInnerContainer>
        <ScrollableShadow />
      </ScrollableOuterContainer>

      {
        isFetchingVendorStatements &&
          <LoadingContainer>
            <i className="fa fa-refresh fa-spin a-fw fa-3x" />
          </LoadingContainer>
      }
    </Container>
  );
};

Body.propTypes = {
  isFetchingVendorStatements: bool,
  isVendorStatementsFetched: bool.isRequired,
  vendorStatements: object.isRequired,
  translate: func.isRequired,
};

const mapStateToProps = ({
  $$vendorStatementsStore,
}) => ({
  isFetchingVendorStatements: $$vendorStatementsStore.get('isFetchingVendorStatements'),
  isVendorStatementsFetched: $$vendorStatementsStore.get('isVendorStatementsFetched'),
  vendorStatements: $$vendorStatementsStore.get('vendorStatements'),
});

export default connect(mapStateToProps)(withI18n(Body));
