import React from 'react';
import styled from 'styled-components';
import { PrimaryButton } from 'libs/support/sharedComponents';
import { Link } from 'react-router-dom';
import { PlusIcon } from 'libs/components/Icons';

const Button = styled(PrimaryButton)`
  align-items: center;
  display: flex;
`;

const IconContainer = styled.span`
  display: flex;
  margin-right: 5px;
`;

const AddButton = () => (
  <Link to="/menus/categories/new">
    <Button>
      <IconContainer>
        <PlusIcon />
      </IconContainer>
      Add Category
    </Button>
  </Link>
);

export default AddButton;
