import React from 'react';
import styled from 'styled-components';
import { PrimaryButton } from 'libs/support/sharedComponents';
import { Link } from 'react-router-dom';
import { PlusIcon } from 'libs/components/Icons';

const LinkStyled = styled(Link)`
  width: 100%

  @media (min-width: ${props => props.theme.breakPoints.sm}) {
    width: auto;
  }
`;

const Button = styled(PrimaryButton)`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%

  @media (min-width: ${props => props.theme.breakPoints.sm}) {
    width: auto;
  }
`;

const IconContainer = styled.span`
  display: flex;
  margin-right: 5px;
`;

const AddButton = () => (
  <LinkStyled to="/menus/items/new">
    <Button>
      <IconContainer>
        <PlusIcon />
      </IconContainer>
      Add Item
    </Button>
  </LinkStyled>
);

export default AddButton;
