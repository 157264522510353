import { useQuery } from 'react-query';
import { httpClient } from 'libs/support/http-client';

export const useVendorDrivers = (params) => (
  useQuery([
    'VENDOR_DRIVERS',
    params.vendorId,
  ], async () => {
    const { data } = await httpClient.get(`/vendors/${params.vendorId}/vendor_drivers.json`);
    return data;
  }, {
    staleTime: Infinity,
    enabled: !!params.vendorId,
  })
);
