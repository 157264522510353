/* eslint max-len: 0 */

import {
  HIDE_ALERT_MESSAGE,
  SHOW_ALERT_MESSAGE,
} from 'bundles/common/constants/messagesConstants';

export function showAlertMessage(params) {
  return { type: SHOW_ALERT_MESSAGE, response: params };
}

export function hideAlertMessage() {
  return { type: HIDE_ALERT_MESSAGE };
}
