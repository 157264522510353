import React, { useState, useEffect } from 'react';
import { bool, object, func, string } from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { withRouter, Redirect } from 'react-router';
import * as catererActions from 'bundles/common/actions/catererActions';
import * as categoriesActions from 'bundles/vendor/actions/categoriesActions';
import * as orderNoticesActions from 'bundles/vendor/actions/orderNoticesActions';
import * as baseDeliveryFeesActions from 'bundles/vendor/actions/baseDeliveryFeesActions';
import Header from 'bundles/vendor/components/Vendor/Header';
import { notBlank } from 'libs/support/string';
import Form from './Form';

const Container = styled.div`
  padding: 1rem 1.5rem;
`;

const NewCategoryContainer = ({
  catererId,
  caterer,
  category,
  createEmptyCategory,
  fetchBaseDeliveryFees,
  fetchCaterer,
  fetchOrderNotices,
  isBaseDeliveryFeesFetched,
  isOrderNoticesFetched,
}) => {
  const [isInitialized, setInitialize] = useState(false);

  useEffect(() => {
    if (!isInitialized) {
      setInitialize(true);
      createEmptyCategory();

      if (!isOrderNoticesFetched) {
        fetchOrderNotices();
      }

      if (!isBaseDeliveryFeesFetched) {
        fetchBaseDeliveryFees();
      }

      if (caterer.size === 0) {
        fetchCaterer(catererId);
      }
    }
  });

  if (isInitialized && category.size > 0 && notBlank(category.get('uuid'))) {
    return <Redirect to={`/menus/categories/${category.get('uuid')}/edit`} />;
  }

  if (!isInitialized || category.size === 0 || caterer.size === 0 || !isOrderNoticesFetched || !isBaseDeliveryFeesFetched) {
    return (
      <div key={`new-category-container-${category.get('tempId')}`}>
        <Header />
        <Container>Loading... </Container>
      </div>
    );
  }

  return (
    <div key={`new-category-container-${category.get('tempId')}`}>
      <Header />
      <Form />
    </div>
  );
};

NewCategoryContainer.propTypes = {
  catererId: string.isRequired,
  caterer: object,
  category: object,
  createEmptyCategory: func.isRequired,
  fetchBaseDeliveryFees: func.isRequired,
  fetchCaterer: func.isRequired,
  fetchOrderNotices: func.isRequired,
  isBaseDeliveryFeesFetched: bool,
  isOrderNoticesFetched: bool,
};

const mapStateToProps = ({
  $$baseDeliveryFeesStore,
  $$categoriesStore,
  $$catererStore,
  $$orderNoticesStore,
}) => ({
  catererId: $$catererStore.get('catererId'),
  caterer: $$catererStore.get('caterer'),
  category: $$categoriesStore.get('category'),
  isBaseDeliveryFeesFetched: $$baseDeliveryFeesStore.get('isBaseDeliveryFeesFetched'),
  isOrderNoticesFetched: $$orderNoticesStore.get('isOrderNoticesFetched'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createEmptyCategory: categoriesActions.createEmptyCategory,
  fetchBaseDeliveryFees: baseDeliveryFeesActions.fetchBaseDeliveryFees,
  fetchOrderNotices: orderNoticesActions.fetchOrderNotices,
  fetchCaterer: catererActions.fetchCaterer,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NewCategoryContainer));
