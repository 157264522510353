/* eslint max-len: 0 */
import React from 'react';

const ExportCsv = () => (
  <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1757:7271)">
      <path d="M4.40002 5.6L5.50002 4.5V9.2C5.50002 9.9 6.00002 10.4 6.70002 10.4C7.40002 10.4 7.90002 9.9 7.90002 9.2V4.5L9.00002 5.6C9.50002 6.1 10.2 6.1 10.6 5.6C11.1 5.1 11.1 4.4 10.6 4L7.50002 0.9C7.30002 0.7 7.00002 0.5 6.70002 0.5C6.30002 0.5 6.10002 0.7 5.90002 0.9L2.80002 4C2.30002 4.5 2.30002 5.2 2.80002 5.6C3.20002 6.1 3.90002 6.1 4.40002 5.6Z" fill="#7D9E1D" />
      <path d="M18.9 2.6001H11.7L11.8 2.7001C12.3 3.2001 12.5 3.9001 12.6 4.5001H18.6V19.5001H6.4C5.9 19.5001 5.5 19.9001 5.5 20.4001C5.5 20.9001 5.9 21.3001 6.4 21.3001H18.7C19.6 21.3001 20.4 20.5001 20.4 19.6001V4.3001C20.5 3.5001 19.7 2.6001 18.9 2.6001Z" fill="#7D9E1D" />
      <path d="M16.4 16.6V12.6C16.4 12 15.9 11.5 15.2 11.5H1.80001C1.20001 11.5 0.600006 11.9 0.600006 12.6V16.6C0.600006 17.2 1.10001 17.7 1.80001 17.7H15.1C15.9 17.7 16.4 17.3 16.4 16.6ZM6.60001 15.7C6.10001 16.8 4.50001 16.8 3.80001 16C2.70001 14.8 3.50001 12.7 5.20001 12.8C5.80001 12.8 6.70001 13.2 6.80001 13.9C6.80001 14.3 6.20001 14.3 6.10001 13.9C5.20001 12.4 3.70001 14.1 4.30001 15.4C4.70001 16.3 6.00001 16 6.20001 15.1C6.30001 14.9 6.80001 15.1 6.80001 15.4C6.70001 15.4 6.70001 15.5 6.60001 15.7ZM7.30001 15.9C7.00001 15.6 7.20001 14.8 7.70001 15.2C8.00001 15.4 8.00001 15.7 8.30001 15.9C8.70001 16.1 9.50001 16 9.50001 15.5C9.50001 15.1 9.00001 15 8.60001 14.9C7.40001 14.9 6.70001 13.5 8.00001 13C8.70001 12.8 10 12.8 10.1 13.7C10.1 14 9.80001 14.1 9.60001 14C9.40001 13.7 9.30001 13.4 8.80001 13.4C8.40001 13.3 7.90001 13.8 8.40001 14.1C9.10001 14.4 10.4 14.3 10.4 15.4C10.2 16.6 8.00001 16.8 7.30001 15.9ZM14 13.2C14 13.3 13.9 13.3 13.9 13.4C13.8 13.6 13.7 13.9 13.6 14.1C13.4 14.6 13.2 15 13 15.5C12.9 15.9 12.6 16.8 12 16.4C11.8 16.3 11.7 16 11.6 15.8L10.7 13.5C10.6 13.3 10.5 13.1 10.7 12.9C11 12.6 11.3 12.9 11.4 13.3L12.2 15.7C12.3 15.6 13.1 13.1 13.1 13L13.2 12.9C13.2 12.9 13.3 12.8 13.4 12.8C13.5 12.8 13.6 12.8 13.7 12.8C13.8 12.8 13.9 12.9 13.9 13C14 13 14 13.1 14 13.2Z" fill="#7D9E1D" />
    </g>
    <defs>
      <clipPath id="clip0_1757:7271">
        <rect width="21" height="21" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
export default ExportCsv;
