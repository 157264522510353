/* eslint react/no-array-index-key: 0, max-len: 0 */

import React from 'react';
import { func, object, string } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as customisationsActions from 'bundles/vendor/actions/customisationsActions';
import snakeCase from 'lodash/snakeCase';
import { notBlank, blankish } from 'libs/support/string';

import PhotoUpload from 'bundles/vendor/components/PhotoUploads';
import {
  FieldGroup,
} from 'libs/components/Forms';
import {
  PhotosContainer,
  PhotoContainer,
} from 'libs/support/sharedComponents';

const ItemAddOnPhotoUpload = ({
  currentType,
  photo,
  onDropCustomisationPhoto,
  onDeleteCustomisationPhoto,
}) => {
  const snakeCaseType = snakeCase(currentType);

  return (
    <FieldGroup
      label="Cover Photo"
    >
      <PhotosContainer>
        {
          notBlank(photo) &&
            <PhotoContainer
              key="photo-1"
            >
              <input
                type="hidden"
                name={`${snakeCaseType}[photos][][id]`}
                value={photo.get('id') || 'new'}
              />
              {
                blankish(photo.get('id')) &&
                  <input
                    type="hidden"
                    name={`${snakeCaseType}[photos][][hash]`}
                    value={photo.get('hash')}
                  />
              }
              <PhotoUpload
                details={{ index: 0 }}
                photo={photo}
                preload
                onDrop={onDropCustomisationPhoto}
                onDelete={onDeleteCustomisationPhoto}
              />
            </PhotoContainer>
        }

        {
          blankish(photo) &&
            <PhotoContainer>
              <PhotoUpload
                multiple
                preload
                details={{ index: 0 }}
                onDrop={onDropCustomisationPhoto}
                onDelete={onDeleteCustomisationPhoto}
              />
            </PhotoContainer>
        }
      </PhotosContainer>
    </FieldGroup>
  );
};

ItemAddOnPhotoUpload.propTypes = {
  currentType: string,
  onDropCustomisationPhoto: func.isRequired,
  onDeleteCustomisationPhoto: func.isRequired,
  photo: object,
};

const mapStateToProps = ({
  $$customisationsStore,
}) => ({
  currentType: $$customisationsStore.get('currentType'),
  photo: $$customisationsStore.getIn(['customisation', 'photos', 0]),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onDropCustomisationPhoto: customisationsActions.addTemporaryCustomisationPhoto,
  onDeleteCustomisationPhoto: customisationsActions.deleteCustomisationPhoto,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ItemAddOnPhotoUpload);
