/* eslint max-len: 0 */

import React, { useEffect } from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider, addLocaleData } from 'react-intl';
import Intl from 'intl';
import en from 'react-intl/locale-data/en';
import zh from 'react-intl/locale-data/zh';
import 'intl/locale-data/jsonp/en-US';

import { translations } from 'libs/i18n/translations';
import { trackViewedV2BillingPage } from 'packs/events';

import Billings from '../components/Billings';

global.Intl = Intl;

addLocaleData([...en, ...zh]);

function mapStatesToProps(state) {
  return {
    localeStore: state.$$localeStore,
  };
}

const VendorContainer = ({
  localeStore,
}) => {
  const locale = localeStore.get('locale');
  const messages = translations[locale];

  useEffect(() => trackViewedV2BillingPage(), []);

  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      <Billings />
    </IntlProvider>
  );
};

VendorContainer.propTypes = {
  localeStore: object.isRequired,
};

export default connect(mapStatesToProps)(VendorContainer);
