import React, { Fragment, useState } from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import {
  Link,
} from 'libs/support/sharedComponents';
import { ImportCsvIcon } from 'libs/components/Icons';
import ImportModal from 'bundles/vendor/components/Items/ImportModal';
import { withI18n } from 'libs/support/i18n';

const IconContainer = styled.span`
  display: flex;
  margin-right: 0.5rem;
`;

const ImportButton = ({
  translate,
}) => {
  const [isShowModal, setShowModal] = useState(false);
  const handleOnImport = () => setShowModal(true);
  const handleOnHide = () => setShowModal(false);

  return (
    <Fragment>
      <Link
        onClick={handleOnImport}
      >
        <IconContainer>
          <ImportCsvIcon />
        </IconContainer>
        { translate('menuItemsActionsImportCsv') }
      </Link>
      <ImportModal
        key={`import-modal-${Date.now()}`}
        isShow={isShowModal}
        onHide={handleOnHide}
      />
    </Fragment>
  );
};

ImportButton.propTypes = {
  translate: func.isRequired,
};

export default withI18n(ImportButton);
