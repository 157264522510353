import styled from 'styled-components';

export const PhotosContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-bottom: -1rem;
  display: flex;
  justify-content: center;

  @media (min-width: ${props => props.theme.breakPoints.md}) {
    justify-content: flex-start;
  }
`;

export const PhotoContainer = styled.div`
  margin-right: 1rem;
  margin-bottom: 1rem;
`;
