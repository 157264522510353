import React, { useState } from 'react';
import { func, object } from 'prop-types';
import uppercamelcase from 'uppercamelcase';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { notBlank } from 'libs/support/string';
import {
  CheckBox,
  FieldGroup,
  InputField,
  SelectField,
} from 'libs/components/Forms';
import { withI18n } from 'libs/support/i18n';
import {
  Spacer,
} from 'libs/support/sharedComponents';
import * as customisationsActions from 'bundles/vendor/actions/customisationsActions';
import { useSelectInput } from 'libs/support/selectInput';
import ItemAddOnOptionsList from './ItemAddOnOptionsList';
import ItemAddOnPhotoUpload from './ItemAddOnPhotoUpload';

const Container = styled.div`

`;

const InputsContainer = styled.div`
  display: flex;

  > div:not(:first-child) {
    margin-left: 1rem;
  }
`;

const InputContainer = styled.div`
  display: flex;
`;

const Label = styled.label`
  align-items: center;
  display: flex;
  margin-bottom: 0 !important;
  margin-left: 0.5rem;
`;

const Submit = styled.input`
  display: none;
`;

export const KIND_TEXT = 'text';
export const KINDS = [
  'choices',
  'upload',
  KIND_TEXT,
];

const ItemAddOnForm = ({
  itemAddOn,
  saveItemAddOn,
  translate,
}) => {
  const kindOptions = KINDS.map(kind => (
    {
      label: translate(`vendorItemAddOnTypes${uppercamelcase(kind)}`),
      value: kind,
    }
  ));
  const [selectedKindOption, handleKindChange] = useSelectInput(
    kindOptions,
    itemAddOn.get('kind'),
  );
  const [enableQuantity, setEnableQuantity] = useState(itemAddOn.get('enable_quantity_option'));
  const handleEnableQuantityChange = () => {
    setEnableQuantity(!enableQuantity);
  };

  const formUrl = notBlank(itemAddOn.get('uuid')) ?
    `/menus/item_add_ons/${itemAddOn.get('uuid')}` : '/menus/item_add_ons';
  const formMethod = notBlank(itemAddOn.get('uuid')) ? 'put' : 'post';
  const onSubmit = (e) => {
    e.preventDefault();
    const form = document.getElementById('item_add_on_form');
    const params = new FormData(form);

    saveItemAddOn(itemAddOn.get('uuid'), params);
  };

  return (
    <Container>
      <form
        id="item_add_on_form"
        action={formUrl}
        method={formMethod}
        onSubmit={onSubmit}
      >
        <FieldGroup
          label="Nickname"
          htmlFor="item_add_on_nickname"
        >
          <InputField
            id="item_add_on_nickname"
            name="item_add_on[nickname]"
            value={itemAddOn.get('nickname')}
          />
        </FieldGroup>

        <FieldGroup
          label="Question Type"
          htmlFor="item_add_on_kind"
        >
          <SelectField
            id="item_add_on_kind"
            name="item_add_on[kind]"
            value={[selectedKindOption]}
            options={kindOptions}
            onChange={handleKindChange}
            isRequired
          />
        </FieldGroup>

        <FieldGroup
          label="Question"
          htmlFor="item_add_on_question"
        >
          <InputField
            id="item_add_on_question"
            isRequired
            name="item_add_on[question]"
            value={itemAddOn.get('question')}
          />
        </FieldGroup>

        <FieldGroup
          label="More Info"
          htmlFor="item_add_on_description"
        >
          <InputField
            id="item_add_on_description"
            name="item_add_on[description]"
            value={itemAddOn.get('description')}
          />
        </FieldGroup>

        {
          itemAddOn.get('kind') === KIND_TEXT &&
            <FieldGroup
              label="Character Limit"
              htmlFor="item_add_on_limit"
            >
              <InputField
                id="item_add_on_limit"
                name="item_add_on[limit]"
                value={itemAddOn.get('limit')}
                isRequired
              />
            </FieldGroup>
        }

        <ItemAddOnPhotoUpload />

        <FieldGroup
          label="Conditions"
        >
          <InputsContainer>
            <InputContainer>
              <InputField
                id="item_add_on_min_order_quantity"
                name="item_add_on[min_order_quantity]"
                value={itemAddOn.get('min_order_quantity')}
              />
              <Label>Choose min</Label>
            </InputContainer>
            <InputContainer>
              <InputField
                id="item_add_on_max_order_quantity"
                name="item_add_on[max_order_quantity]"
                value={itemAddOn.get('max_order_quantity')}
              />
              <Label>Choose max</Label>
            </InputContainer>
          </InputsContainer>
        </FieldGroup>

        <FieldGroup
          label=" "
          htmlFor="item_add_on_enable_quantity_option"
        >
          <CheckBox
            onChange={handleEnableQuantityChange}
            checked={enableQuantity}
            id="item_add_on_enable_quantity_option"
            name="item_add_on[enable_quantity_option]"
            value="true"
            text="Quantity Options"
          />
        </FieldGroup>

        <Spacer />

        <ItemAddOnOptionsList />

        <Submit />
      </form>
    </Container>
  );
};

ItemAddOnForm.propTypes = {
  itemAddOn: object,
  saveItemAddOn: func.isRequired,
  translate: func.isRequired,
};

const mapStateToProps = ({
  $$customisationsStore,
}) => ({
  itemAddOn: $$customisationsStore.get('customisation'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  saveItemAddOn: customisationsActions.saveItemAddOn,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withI18n(ItemAddOnForm));
