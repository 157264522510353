/* eslint react/no-danger: 0 */

import React from 'react';
import { bool, object, func } from 'prop-types';
import uppercamelcase from 'uppercamelcase';
import { connect } from 'react-redux';
import { withI18n } from 'libs/support/i18n';
import {
  Table,
  HeaderRow,
  Header,
  ScrollableInnerContainer,
  ScrollableOuterContainer,
  ScrollableShadow,
} from 'libs/support/sharedComponents';
import { bindActionCreators } from 'redux';
import * as categoriesActions from 'bundles/vendor/actions/categoriesActions';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDragAndDropList } from 'libs/support/dragAndDrop';
import Category from './Category';

const HEADERS = [
  { key: '_blank', width: '50px' },
  { key: 'status', width: '100px' },
  { key: 'category name', width: '200px' },
  { key: 'description', width: '200px' },
  { key: 'delivery' },
  { key: 'availability' },
  { key: 'delivery_fee_excluding_gst', width: '105px' },
  { key: 'order_notice', width: '60px' },
];

const Body = ({
  isCategoriesFetched,
  categories,
  saveCategories,
  translate,
}) => {
  if (!isCategoriesFetched) {
    return (
      <div>
        Loading...
      </div>
    );
  }

  const [rows, moveCard, didDrop] = useDragAndDropList(
    categories,
    saveCategories,
  );

  return (
    <ScrollableOuterContainer>
      <ScrollableInnerContainer>
        <Table className="categories-list">
          <thead>
            <HeaderRow>
              {
                HEADERS.map((header) => (
                  <Header
                    key={`header-${header.key}`}
                    style={{ minWidth: header.width }}
                  >
                    <span dangerouslySetInnerHTML={{ __html: translate(`vendor${uppercamelcase(header.key)}`) }} />
                  </Header>
                ))
              }
            </HeaderRow>
          </thead>
          <tbody>
            <DndProvider backend={HTML5Backend}>
              {
                rows.map((category, index) => (
                  <Category
                    key={`category-${category.get('uuid')}`}
                    {
                      ...{
                        category,
                        didDrop,
                        index,
                        moveCard,
                      }
                    }
                  />
                ))
              }
            </DndProvider>
          </tbody>
        </Table>
      </ScrollableInnerContainer>
      <ScrollableShadow />
    </ScrollableOuterContainer>
  );
};

Body.propTypes = {
  isCategoriesFetched: bool.isRequired,
  categories: object.isRequired,
  saveCategories: func.isRequired,
  translate: func.isRequired,
};

const mapStateToProps = ({
  $$categoriesStore,
}) => ({
  isCategoriesFetched: $$categoriesStore.get('isCategoriesFetched'),
  categories: $$categoriesStore.get('categories'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  saveCategories: categoriesActions.saveCategories,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withI18n(Body));
