import styled from 'styled-components';

export const Input = styled.input`
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.gainsboro};
  box-shadow: inset 0px 1px 1px ${props => props.theme.colors.gray200};
  box-sizing: border-box;
  font-size: 14px;
  height: 32px;
  padding: 0 1rem;
  width: 100%;
`;
