/* eslint camelcase: 0 */

import Immutable from 'immutable';
import * as actionTypes from '../constants/itemAddOnsConstants';
import * as customisationsActionTypes from '../constants/customisationsConstants';

export const $$initialState = Immutable.fromJS({
  isItemAddOnsFetched: false,
  itemAddOn: Immutable.fromJS({}),
  itemAddOns: Immutable.fromJS([]),
});

export default function itemAddOnsReducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  switch (type) {
    case actionTypes.FETCH_ITEM_ADD_ONS_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .set('isItemAddOnsFetched', true)
          .set('itemAddOns', Immutable.fromJS(response.item_add_ons))
      ));
    }

    case customisationsActionTypes.SAVE_ITEM_ADD_ON_SUCCESS: {
      const { item_add_on } = response;

      return $$state.withMutations(state => (
        state.update('itemAddOns', itemAddOns => {
          const index = itemAddOns.findIndex(d => d.get('uuid') === item_add_on.uuid);

          const newItemAddOn = Immutable.fromJS(item_add_on);

          if (index < 0) return itemAddOns.push(newItemAddOn);

          return itemAddOns.update(index, existingItemAddOn => existingItemAddOn.merge(newItemAddOn));
        })
      ));
    }

    case customisationsActionTypes.DELETE_ITEM_ADD_ON_SUCCESS: {
      const { item_add_on } = response;

      return $$state.withMutations(state => (
        state.update('itemAddOns', itemAddOns => {
          const index = itemAddOns.findIndex(d => d.get('uuid') === item_add_on.uuid);

          if (index >= 0) return itemAddOns.delete(index);

          return itemAddOns;
        })
      ));
    }

    case customisationsActionTypes.CLONE_ITEM_ADD_ON_SUCCESS: {
      const { item_add_on } = response;

      return $$state.withMutations(state => (
        state.update('itemAddOns', itemAddOns => {
          const newItemAddOn = Immutable.fromJS(item_add_on);

          return itemAddOns.push(newItemAddOn);
        })
      ));
    }

    default: {
      return $$state;
    }
  }
}
