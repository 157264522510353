

/* eslint max-len: 0 */
import React from 'react';

const Settings = (_props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icons/Settings" clipPath="url(#clip0_5082_2204)">
      <path id="Union (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M17.2177 7.1875H19.1666V5.3125H17.2177C16.8107 3.6951 15.3751 2.5 13.6666 2.5C11.9581 2.5 10.5225 3.6951 10.1154 5.3125H0.833252V7.1875H10.1154C10.5225 8.8049 11.9581 10 13.6666 10C15.3751 10 16.8107 8.8049 17.2177 7.1875ZM9.8844 12.8125C9.47736 11.1951 8.04177 10 6.33325 10C4.62473 10 3.18914 11.1951 2.7821 12.8125H0.833252V14.6875H2.7821C3.18914 16.3049 4.62473 17.5 6.33325 17.5C8.04177 17.5 9.47736 16.3049 9.8844 14.6875H19.1666V12.8125H9.8844ZM8.4017 13.75L8.26813 13.2193C8.0372 12.3016 7.23656 11.6667 6.33325 11.6667C5.42994 11.6667 4.6293 12.3016 4.39837 13.2193L4.2648 13.75L4.39837 14.2807C4.6293 15.1984 5.42994 15.8333 6.33325 15.8333C7.23656 15.8333 8.0372 15.1984 8.26813 14.2807L8.4017 13.75ZM11.5981 6.25L11.7317 5.71925C11.9626 4.80164 12.7633 4.16667 13.6666 4.16667C14.5699 4.16667 15.3705 4.80164 15.6015 5.71925L15.735 6.25L15.6015 6.78075C15.3705 7.69836 14.5699 8.33333 13.6666 8.33333C12.7633 8.33333 11.9626 7.69836 11.7317 6.78075L11.5981 6.25Z" fill="#515154" />
    </g>
    <defs>
      <clipPath id="clip0_5082_2204">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>

);
export default Settings;
