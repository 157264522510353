export const customStyles = {
  option: (provided) => ({
    ...provided,
    fontSize: '14px',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '14px',
  }),
};
