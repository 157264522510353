import * as api from 'libs/middlewares/api';

import * as actionTypes from '../constants/adminsConstants';

const API_URL = '/vendor_admins';

export function selectVendorAdmin(admin) {
  return { type: actionTypes.SELECT_VENDOR_ADMIN, response: admin };
}

export function hideVendorAdminForm() {
  return { type: actionTypes.HIDE_VENDOR_ADMIN_FORM };
}

export function showVendorAdminForm() {
  return { type: actionTypes.SHOW_VENDOR_ADMIN_FORM };
}

export function findVendorAdmins(catererId) {
  const { FINDING_VENDOR_ADMINS, FIND_VENDOR_ADMINS_SUCCESS, FIND_VENDOR_ADMINS_FAILURE } = actionTypes;

  return api.get({
    types: [FINDING_VENDOR_ADMINS, FIND_VENDOR_ADMINS_SUCCESS, FIND_VENDOR_ADMINS_FAILURE],
    endpoint: `/caterers/${catererId}${API_URL}`,
    params: null,
    id: null,
  });
}

export function saveVendorAdmin(id, catererId, params) {
  const { SAVING_VENDOR_ADMIN, SAVE_VENDOR_ADMIN_SUCCESS, SAVE_VENDOR_ADMIN_FAILURE } = actionTypes;

  return api.save({
    types: [SAVING_VENDOR_ADMIN, SAVE_VENDOR_ADMIN_SUCCESS, SAVE_VENDOR_ADMIN_FAILURE],
    endpoint: `${catererId}${API_URL}`,
    params,
    id,
  });
}

export function deleteVendorAdmin(id, catererId) {
  const { DELETING_VENDOR_ADMIN, DELETE_VENDOR_ADMIN_SUCCESS, DELETE_VENDOR_ADMIN_FAILURE } = actionTypes;

  return api.destroy({
    types: [DELETING_VENDOR_ADMIN, DELETE_VENDOR_ADMIN_SUCCESS, DELETE_VENDOR_ADMIN_FAILURE],
    endpoint: `${catererId}${API_URL}`,
    params: null,
    id,
  });
}
