import React, { Fragment } from 'react';
import { func, object } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import {
  InputField,
} from 'libs/components/Forms';
import { blankish } from 'libs/support/string';
import {
  SecondaryTitle,
} from 'libs/support/sharedComponents';
import VendorBillingDetail from '../VendorBillingDetail';

const OutletFields = ({
  outlet,
  outletBillingDetail,
  translate,
}) => {
  if (blankish(outlet)) return null;

  return (
    <Fragment>
      <InputField
        label={translate('billingsLabelsOutletName')}
        name="outlet_name"
        type="text"
        isDisabled
        value={outlet.get('name')}
      />

      <div>
        <SecondaryTitle>{translate('billingsTitlesBillingDetails')}</SecondaryTitle>
      </div>

      {
        outletBillingDetail &&
          <VendorBillingDetail
            vendorBillingDetail={outletBillingDetail}
            billableId={outlet.get('uuid')}
            billableType="Outlet"
            isModal
          />
      }
    </Fragment>
  );
};

OutletFields.propTypes = {
  outlet: object,
  outletBillingDetail: object,
  translate: func.isRequired,
};

export default withI18n(OutletFields);
