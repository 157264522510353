import React from 'react';
import { func, object } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import uppercamelcase from 'uppercamelcase';
import Immutable from 'immutable';
import { FieldGroup } from 'libs/components/Forms';
import {
  Table,
  HeaderRow,
  Header,
} from 'libs/support/sharedComponents';
import Outlet from './Outlet';

const HEADERS = [
  'outlet_name',
  'entity',
  'bank_details',
  'actions',
];

const List = ({
  translate,
  outlets,
  outletBillingDetails,
}) => (
  <FieldGroup
    label=" "
  >
    <Table className="test-outlets-list">
      <thead>
        <HeaderRow>
          {
            HEADERS.map(header => (
              <Header key={`header-${header}`}>
                {translate(`billingsHeaders${uppercamelcase(header)}`)}
              </Header>
            ))
          }
        </HeaderRow>
      </thead>
      <tbody>
        {
          outlets.map(outlet => {
            const outletBillingDetail = outletBillingDetails.find(billingDetail => (
              billingDetail.get('billableId') === outlet.get('uuid')
            )) || Immutable.fromJS({});

            return (
              <Outlet
                key={`outlet-${outlet.get('uuid')}-${outletBillingDetail.get('updatedAt')}`}
                outlet={outlet}
                outletBillingDetail={outletBillingDetail}
              />
            );
          })
        }
      </tbody>
    </Table>
  </FieldGroup>
);

List.propTypes = {
  translate: func.isRequired,
  outlets: object.isRequired,
  outletBillingDetails: object.isRequired,
};

export default withI18n(List);
