/* eslint no-alert: 0 */

import React from 'react';
import { func, string } from 'prop-types';
import { blankish } from 'libs/support/string';
import uppercamelcase from 'uppercamelcase';
import {
  DropdownItem,
} from 'libs/support/sharedComponents';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as customisationsActions from 'bundles/vendor/actions/customisationsActions';
import { withI18n } from 'libs/support/i18n';

const DeleteCustomisationAction = ({
  currentType,
  deleteCustomisation,
  translate,
  uuid,
}) => {
  if (blankish(uuid)) return null;

  const type = uppercamelcase(currentType);

  const confirmationMessage = translate(`vendorDelete${type}Confirmation`);

  const handleDelete = (e) => {
    e.preventDefault();

    if (window.confirm(confirmationMessage)) {
      deleteCustomisation(currentType, uuid);
    }
  };

  return (
    <DropdownItem
      className="dropdown-item delete-customisation"
      onClick={handleDelete}
      href="#"
    >
      Delete
    </DropdownItem>
  );
};

DeleteCustomisationAction.propTypes = {
  currentType: string,
  deleteCustomisation: func.isRequired,
  translate: func,
  uuid: string,
};

const mapStateToProps = ({
  $$customisationsStore,
}) => ({
  currentType: $$customisationsStore.get('currentType'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  deleteCustomisation: customisationsActions.deleteCustomisation,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withI18n(DeleteCustomisationAction));
