// https://codesandbox.io/s/react-select-v2-required-input-3xvvb?file=/src/FixRequiredSelect.js:0-1609
/* eslint react/prop-types: 0, react/sort-comp: 0, no-unused-expressions: 0 */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import {
  Nbsp,
  SecondaryBadge,
} from 'libs/support/sharedComponents';

const noop = () => {
  // no operation (do nothing real quick)
};

const Option = (props) => {
  const { children, data } = props;
  return (
    <components.Option {...props}>
      { children }
      {
        data && data.badge &&
          <Fragment>
            <Nbsp />
            <SecondaryBadge>
              {data.badge}
            </SecondaryBadge>
          </Fragment>
      }
    </components.Option>
  );
};

const SingleValue = (props) => {
  const { children, data } = props;
  return (
    <components.SingleValue {...props}>
      { children }
      {
        data && data.badge &&
          <Fragment>
            <Nbsp />
            <SecondaryBadge>
              {data.badge}
            </SecondaryBadge>
          </Fragment>
      }
    </components.SingleValue>
  );
};

class FixRequiredSelect extends React.Component {
  state = {
    value: this.props.value || '',
  };

  selectRef = null;
  setSelectRef = ref => {
    this.selectRef = ref;
  };

  onChange = (value, actionMeta) => {
    this.props.onChange(value, actionMeta);
    this.setState({ value });
  };

  getValue = () => {
    if (this.props.value !== undefined) return this.props.value;
    return this.state.value || '';
  };

  render() {
    const { SelectComponent, isRequired, ...props } = this.props;
    const { isDisabled } = this.props;
    const enableRequired = !isDisabled;

    return (
      <div>
        <SelectComponent
          {...props}
          ref={this.setSelectRef}
          onChange={this.onChange}
          components={{ Option, SingleValue }}
        />
        {enableRequired && (
          <input
            tabIndex={-1}
            autoComplete="off"
            style={{
              opacity: 0,
              height: 0,
              position: 'absolute',
            }}
            value={this.getValue()}
            onChange={noop}
            onFocus={() => this.selectRef.focus()}
            required={isRequired}
          />
        )}
      </div>
    );
  }
}

FixRequiredSelect.defaultProps = {
  onChange: noop,
};

FixRequiredSelect.protoTypes = {
  // react-select component class (e.g. Select, Creatable, Async)
  SelectComponent: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
};

export default FixRequiredSelect;
