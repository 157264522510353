import React, { useState, useEffect } from 'react';
import { bool, object, func, string } from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { withI18n } from 'libs/support/i18n';
import * as menuItemsActions from 'bundles/vendor/actions/menuItemsActions';
import * as categoriesActions from 'bundles/vendor/actions/categoriesActions';
import * as catererActions from 'bundles/common/actions/catererActions';
import * as filtersActions from 'bundles/vendor/actions/filtersActions';
import * as messagesActions from 'bundles/common/actions/messagesActions';
import Header from 'bundles/vendor/components/Vendor/Header';
import Actions from './Actions';
import Form from './Forms/Form';

const Container = styled.div`
  padding: 1rem 1.5rem;
`;

const EditContainer = ({
  catererId,
  caterer,
  fetchCategories,
  fetchCaterer,
  fetchFilters,
  fetchMenuItem,
  history,
  isCategoriesFetched,
  isFiltersFetched,
  match,
  menuItem,
  metadata,
  showAlertMessage,
  translate,
}) => {
  const [isInitialized, setInitialize] = useState(false);

  useEffect(() => {
    if (!isInitialized) {
      setInitialize(true);
      if (menuItem.size === 0 || menuItem.get('uuid') !== match.params.id) {
        fetchMenuItem(match.params.id);
      }

      if (!isCategoriesFetched) {
        fetchCategories();
      }

      if (caterer.size === 0) {
        fetchCaterer(catererId).then((data) => {
          fetchFilters(data.response.vendor.kind);
        });
      }

      if (!isFiltersFetched && caterer.size > 0) {
        fetchFilters(caterer.get('kind'));
      }
    }
  }, [isInitialized]);

  if (!isInitialized || menuItem.size === 0 || match.params.id !== menuItem.get('uuid') || caterer.size === 0) {
    return (
      <div key={`edit-container-${menuItem.get('uuid')}`}>
        <Header />
        <Container>Loading... </Container>
      </div>
    );
  }

  if (menuItem.get('uuid') === match.params.id) {
    const inUseMealPlans = menuItem.get('in_use_meal_plans');
    const page = metadata.get('page');
    const keyword = metadata.get('keyword');

    const url = new URL(window.location);
    url.pathname = '/menus/items';
    if (page) url.searchParams.set('page', page);
    if (keyword) url.searchParams.set('keyword', keyword);

    if (inUseMealPlans.size > 0) {
      showAlertMessage({
        title: translate('vendorModalsEditItemWarningTitle'),
        message: translate('vendorModalsEditItemWarningMessage'),
        submit: () => (
          history.push(`${url.pathname}${url.search}`)
        ),
      });
    }
  }

  return (
    <div key={`edit-container-${menuItem.get('uuid')}`}>
      <Header />
      <Actions />
      <Form />
    </div>
  );
};

EditContainer.propTypes = {
  catererId: string.isRequired,
  caterer: object,
  fetchCategories: func.isRequired,
  fetchCaterer: func.isRequired,
  fetchFilters: func.isRequired,
  fetchMenuItem: func.isRequired,
  history: object,
  isCategoriesFetched: bool.isRequired,
  isFiltersFetched: bool.isRequired,
  match: object.isRequired,
  menuItem: object,
  metadata: object,
  showAlertMessage: func.isRequired,
  translate: func.isRequired,
};

const mapStateToProps = (state) => ({
  catererId: state.$$catererStore.get('catererId'),
  caterer: state.$$catererStore.get('caterer'),
  isCategoriesFetched: state.$$filtersStore.get('isFiltersFetched'),
  isFiltersFetched: state.$$filtersStore.get('isFiltersFetched'),
  menuItem: state.$$menuItemsStore.get('menuItem'),
  metadata: state.$$menuItemsStore.get('metadata'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCaterer: catererActions.fetchCaterer,
  fetchMenuItem: menuItemsActions.fetchMenuItem,
  fetchCategories: categoriesActions.fetchCategories,
  fetchFilters: filtersActions.fetchFilters,
  showAlertMessage: messagesActions.showAlertMessage,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withI18n(EditContainer)));
