export const defaultTheme = {
  colors: {
    azure: '#f4f5f5',
    beautyBush: '#e5b5b5',
    blackRussian: '#141415',
    chestNut: '#c3423f',
    tutu: '#fadbdb',
    gainsboro: '#dbdbdb',
    greyChateau: '#95989d',
    gunPowder: '#515154',
    gray200: '#eaeaeb',
    lavenderBlush: '#ffeaf5',
    lima: '#7d9e1d',
    mediumRedViolet: '#bc2c9c',
    nobel: '#a1a1a1',
    red: 'red',
    palatinatePurple: '#5c164d',
    riceFlower: '#f3fbdc',
    snowFlurry: '#e4efc3',
    spindle: '#bfc1c4',
    tusk: '#dae7b6',
    twilightBlue: '#fcfff3',
    white: '#ffffff',
  },
  breakPoints: {
    xxs: '23.75em',
    sm: '48em',
    md: '64em',
    lg: '74.9em',
  },
  fonts: {
    geomanistbook: 'geomanistbook, Arial, sans-serif',
    proxima: 'proxima-nova-soft, sans-serif',
  },
};
