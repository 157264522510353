/* eslint no-alert: 0 */

import React from 'react';
import { func, string } from 'prop-types';
import { blankish } from 'libs/support/string';
import uppercamelcase from 'uppercamelcase';
import {
  DeleteButton,
} from 'bundles/common/components/Buttons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as customisationsActions from 'bundles/vendor/actions/customisationsActions';
import { withI18n } from 'libs/support/i18n';

const DetachCustomisationButton = ({
  type,
  detachCustomisation,
  translate,
  uuid,
}) => {
  if (blankish(uuid)) return null;

  const uppercaseType = uppercamelcase(type);

  const confirmationMessage = translate(`vendorDetach${uppercaseType}Confirmation`);

  const handleDelete = () => {
    if (window.confirm(confirmationMessage)) {
      detachCustomisation(type, uuid);
    }
  };

  return (
    <DeleteButton
      name="Delete"
      onClick={handleDelete}
    />
  );
};

DetachCustomisationButton.propTypes = {
  type: string,
  detachCustomisation: func.isRequired,
  translate: func,
  uuid: string,
};

const mapStateToProps = ({
  $$customisationsStore,
}) => ({
  currentType: $$customisationsStore.get('currentType'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  detachCustomisation: customisationsActions.detachCustomisation,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withI18n(DetachCustomisationButton));
