/* eslint react/no-array-index-key: 0 */
/* eslint react/no-danger: 0 */

import React from 'react';
import { bool, func, object, string } from 'prop-types';
import styled from 'styled-components';
import { withI18n } from 'libs/support/i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { notBlank } from 'libs/support/string';
import * as imageActions from 'bundles/common/actions/imageActions';
import * as menuItemsActions from 'bundles/vendor/actions/menuItemsActions';
import {
  SectionDescription,
  SectionError,
  SectionTitle,
} from 'bundles/vendor/components/Shared';
import PhotoUploads from 'bundles/vendor/components/PhotoUploads';
import { blankish } from 'libs/support/string';
import {
  RequiredFont,
  PhotosContainer,
  PhotoContainer,
} from 'libs/support/sharedComponents';

const Container = styled.div`

`;

const Required = styled(RequiredFont)`
  padding-left: 0.5rem;
`;

const PhotosSection = ({
  addTemporaryCatererMenuItemPhoto,
  addTemporaryCatererMenuItemPhotoFailure,
  cropTemporaryCatererMenuItemPhoto,
  deleteCatererMenuItemPhoto,
  error,
  isRequired,
  menuItem,
  showImageModal,
}) => {
  const photos = menuItem.get('photos');
  const photosSize = photos ? photos.size : 0;

  return (
    <Container className="photos-section">
      <SectionTitle>
        Photos
        { isRequired && <Required>*</Required> }
      </SectionTitle>
      <SectionDescription>
        Add as many as you can so customer can have a
        better idea of how the item looks like and what is
        the portion size.
      </SectionDescription>

      { notBlank(error) && <SectionError>{ error }</SectionError> }

      <PhotosContainer>
        {
          photos && photos.map((photo, index) => (
            <PhotoContainer
              key={`photo-${index}`}
            >
              <input
                type="hidden"
                name="menu_item[photos][][id]"
                value={photo.get('id') || 'new'}
              />
              {
                blankish(photo.get('id')) &&
                  <input
                    type="hidden"
                    name="menu_item[photos][][hash]"
                    value={photo.get('hash')}
                  />
              }
              <PhotoUploads
                key={`photo-${photo.get('id')}`}
                details={{ index }}
                photo={photo}
                preload
                onEdit={showImageModal}
                onCrop={cropTemporaryCatererMenuItemPhoto}
                onDrop={addTemporaryCatererMenuItemPhoto}
                onDropFailure={addTemporaryCatererMenuItemPhotoFailure}
                onDelete={deleteCatererMenuItemPhoto}
                multiple
                isEditable
              />
            </PhotoContainer>
          ))
        }

        <PhotoContainer>
          <PhotoUploads
            multiple
            preload
            details={{ index: photosSize }}
            isRequired={isRequired && photos.size === 0}
            onDrop={addTemporaryCatererMenuItemPhoto}
            onDropFailure={addTemporaryCatererMenuItemPhotoFailure}
            onDelete={deleteCatererMenuItemPhoto}
          />
        </PhotoContainer>
      </PhotosContainer>
    </Container>
  );
};

PhotosSection.propTypes = {
  addTemporaryCatererMenuItemPhoto: func,
  addTemporaryCatererMenuItemPhotoFailure: func,
  cropTemporaryCatererMenuItemPhoto: func,
  deleteCatererMenuItemPhoto: func,
  error: string,
  isRequired: bool,
  menuItem: object,
  showImageModal: func.isRequired,
};

const mapStateToProps = (state) => {
  const errors = state.$$menuItemsStore.get('errors');

  return {
    menuItem: state.$$menuItemsStore.get('menuItem'),
    error: errors.get('photos'),
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  addTemporaryCatererMenuItemPhoto: menuItemsActions.addTemporaryCatererMenuItemPhoto,
  addTemporaryCatererMenuItemPhotoFailure: menuItemsActions.addTemporaryCatererMenuItemPhotoFailure,
  cropTemporaryCatererMenuItemPhoto: menuItemsActions.cropTemporaryCatererMenuItemPhoto,
  deleteCatererMenuItemPhoto: menuItemsActions.deleteCatererMenuItemPhoto,
  showImageModal: imageActions.showImageModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withI18n(PhotosSection));
