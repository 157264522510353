/* eslint-disable arrow-body-style */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { withAppContext } from 'libs/support/appContext';
import { withQueryClient } from 'libs/support/withQueryClient';
import { Document, Page, pdfjs } from 'react-pdf';
import { string } from 'prop-types';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

const styles = {
  viewerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh', // Center vertically within the viewport height
    backgroundColor: '#f0f0f0', // Optional: adds a background color
  },
  page: {
    margin: '10px 0', // Optional: adds spacing between pages if displaying multiple
  },
};

const TermContainer = ({ pdfUrl }) => {
  const [numPages, setNumPages] = useState();
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div className="term-container">
      <Document
        file={pdfUrl}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from({ length: numPages }, (_, i) => (
          <Page
            size={{ width: 100 }}
            style={styles.page}
            className="term-page"
            key={i}
            pageNumber={i + 1}
            loading={<div>Loading...</div>}
            error={<div>Error!</div>}
          />
        ))}

      </Document>
    </div>
  );
};

TermContainer.propTypes = {
  pdfUrl: string,
};

export default withAppContext(withQueryClient(TermContainer));
