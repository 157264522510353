/* eslint max-len: 0 */

import catererReducer, { $$initialState as $$catererState } from 'bundles/common/reducers/catererReducer';
import localeReducer, { $$initialState as $$localeState } from 'bundles/common/reducers/localeReducer';
import outletsReducer, { $$initialState as $$outletsState } from 'bundles/common/reducers/outletsReducer';
import vendorBillingReducer, { $$initialState as $$vendorBillingState } from './vendorBillingReducer';
import vendorStatementsReducer, { $$initialState as $$vendorStatementsState } from './vendorStatementsReducer';

export default {
  $$catererStore: catererReducer,
  $$localeStore: localeReducer,
  $$outletsStore: outletsReducer,
  $$vendorBillingStore: vendorBillingReducer,
  $$vendorStatementsStore: vendorStatementsReducer,
};

export const initialStates = {
  $$catererState,
  $$localeState,
  $$vendorBillingState,
  $$outletsState,
  $$vendorStatementsState,
};
