// https://www.jacklmoore.com/notes/rounding-in-javascript/
export const round = (value, decimal = 2) => {
  const rounded = Math.round(`${value}e${decimal}`);
  return Number(`${rounded}e-${decimal}`);
};

export const calculateDisplayPrice = ({ taxRate, value, markupPricePercent, rounding = 2 }) => {
  const priceWithoutTax = parseFloat(value || 0, 10);
  const markupPrice = priceWithoutTax * (markupPricePercent / 100);
  const taxAmount = (priceWithoutTax + markupPrice) * (taxRate / 100);

  return round(priceWithoutTax + markupPrice + taxAmount, rounding);
};

export const calculateBasePrice = ({ taxType, taxRate, value, rounding = 3 }) => {
  const inputPrice = parseFloat(value || 0, 10);

  switch (taxType) {
    case 'inclusive':
      return round(inputPrice / (1 + (taxRate / 100)), rounding);

    default:
      return inputPrice;
  }
};

export const calculateVendorPrice = ({ taxType, taxRate, value, rounding = 2 }) => {
  const inputPrice = parseFloat(value || 0, 10);

  switch (taxType) {
    case 'inclusive':
      return round(inputPrice * (1 + (taxRate / 100)), rounding);

    default:
      return round(inputPrice, rounding);
  }
};

export function numberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formattedPrice(
  amount,
  opts = {
    currency: '$',
    withSpace: false,
    withComma: false,
    decimal: 2,
    compact: false,
    fixed: false,
  },
) {
  const decimal = (() => {
    if (opts.compact && amount % 2 === 0) {
      return 0;
    }
    return typeof opts.decimal !== 'number' ? 2 : opts.decimal;
  })();

  let number = parseFloat(round(amount, decimal) || 0).toFixed(decimal);

  if (opts.withComma) {
    number = numberWithCommas(number);
  }

  if (opts.withSpace) {
    number = ` ${number}`;
  }

  if (opts.compact) {
    return Number(number);
  }

  return `${opts.currency || '$'}${number}`;
}

export const formatDisplayPrice = (value, precision = 2) =>
  formattedPrice(
    (value) || 0,
    {
      decimal: precision,
      compact: true,
      padding: true,
    },
  );
