/* eslint max-len: 0 */
import React from 'react';

const StatusIcon = (_props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icons/Settings" clipPath="url(#clip0_5081_5679)">
      <path id="Union (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M13.7741 5.75H15.3332V4.25H13.7741C13.4485 2.95608 12.3 2 10.9332 2C9.56635 2 8.41788 2.95608 8.09225 4.25H0.666504V5.75H8.09225C8.41788 7.04392 9.56635 8 10.9332 8C12.3 8 13.4485 7.04392 13.7741 5.75ZM7.90742 10.25C7.58179 8.95608 6.43332 8 5.0665 8C3.69969 8 2.55121 8.95608 2.22558 10.25H0.666504V11.75H2.22558C2.55121 13.0439 3.69969 14 5.0665 14C6.43332 14 7.58179 13.0439 7.90742 11.75H15.3332V10.25H7.90742ZM6.72126 11L6.61441 10.5754C6.42967 9.84131 5.78915 9.33333 5.0665 9.33333C4.34386 9.33333 3.70334 9.84131 3.5186 10.5754L3.41174 11L3.5186 11.4246C3.70334 12.1587 4.34386 12.6667 5.0665 12.6667C5.78915 12.6667 6.42967 12.1587 6.61441 11.4246L6.72126 11ZM9.27841 5L9.38527 4.5754C9.57001 3.84131 10.2105 3.33333 10.9332 3.33333C11.6558 3.33333 12.2963 3.84131 12.4811 4.5754L12.5879 5L12.4811 5.4246C12.2963 6.15869 11.6558 6.66667 10.9332 6.66667C10.2105 6.66667 9.57001 6.15869 9.38527 5.4246L9.27841 5Z" fill="#515154" />
    </g>
    <defs>
      <clipPath id="clip0_5081_5679">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default StatusIcon;
