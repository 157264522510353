/* eslint camelcase: 0 */

import Immutable from 'immutable';

import * as actionTypes from '../constants/orderConstants';

export const $$initialState = Immutable.fromJS({
  orders: [],
  newOrders: [],
  orderCount: {},
  isFetching: false,
});

export default function orderReducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  switch (type) {
    case actionTypes.FETCHING_ORDERS: {
      return $$state.withMutations(state => (
        state
          .set('isFetching', true)
      ));
    }
    case actionTypes.FETCH_ORDERS_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .set('orders', response.orders)
          .set('newOrders', response.new_orders)
          .set('orderCount', response.order_count)
          .set('ordersMetadata', Immutable.fromJS(response.orders_metadata))
          .set('isFetching', false)
      ));
    }

    default: {
      return $$state;
    }
  }
}
