/* eslint max-len:0 */

import React from 'react';

const Photo = (_props) => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.9306 2C17.6258 2 19 3.37424 19 5.06944V15.9306C19 17.6258 17.6258 19 15.9306 19H5.06944C3.37424 19 2 17.6258 2 15.9306V5.06944C2 3.37424 3.37424 2 5.06944 2H15.9306ZM16.4788 17.4902L10.9955 12.1195C10.7452 11.8744 10.3585 11.8521 10.0833 12.0527L10.0044 12.1195L4.5202 17.4899C4.69205 17.5504 4.8769 17.5833 5.06944 17.5833H15.9306C16.1227 17.5833 16.3073 17.5505 16.4788 17.4902L10.9955 12.1195L16.4788 17.4902ZM15.9306 3.41667H5.06944C4.15664 3.41667 3.41667 4.15664 3.41667 5.06944V15.9306C3.41667 16.1274 3.45107 16.3162 3.51419 16.4912L9.01324 11.1073C9.80004 10.3369 11.0359 10.3003 11.8654 10.9973L11.9867 11.1074L17.4855 16.4922C17.5488 16.3168 17.5833 16.1277 17.5833 15.9306V5.06944C17.5833 4.15664 16.8434 3.41667 15.9306 3.41667ZM13.5714 5.30556C14.7462 5.30556 15.6984 6.25784 15.6984 7.43255C15.6984 8.60726 14.7462 9.55955 13.5714 9.55955C12.3967 9.55955 11.4444 8.60726 11.4444 7.43255C11.4444 6.25784 12.3967 5.30556 13.5714 5.30556ZM13.5714 6.72222C13.1791 6.72222 12.8611 7.04025 12.8611 7.43255C12.8611 7.82486 13.1791 8.14288 13.5714 8.14288C13.9637 8.14288 14.2818 7.82486 14.2818 7.43255C14.2818 7.04025 13.9637 6.72222 13.5714 6.72222Z" fill="#7D9E1D" />
  </svg>
);

export default Photo;
