/* eslint no-return-assign: 0 */

import React from 'react';
import { func, number, object, oneOfType, string } from 'prop-types';
import {
  StripedRow,
  Cell,
  CenteredCell,
} from 'libs/support/sharedComponents';
import {
  InputField,
} from 'libs/components/Forms';
import {
  DeleteButton,
} from 'bundles/common/components/Buttons';
import { notBlank } from 'libs/support/string';
import { usePrice } from 'libs/support/usePrice';

const ServiceAddOnOptionFields = ({
  count,
  index,
  markupPricePercent,
  option,
  onDelete,
  taxRate,
  taxType,
}) => {
  const style = { display: `${notBlank(option.get('deleted_at')) ? 'none' : 'table-row'}` };
  const handleDelete = () => (
    onDelete && onDelete(index)
  );

  const basePrice = option.get('price');
  const [
    vendorPrice,
    displayPrice,
    inputPriceRef,
    displayPriceRef,
    handleVendorPriceChange,
    handleVendorPriceBlur,
  ] = usePrice({ basePrice, taxType, taxRate, markupPricePercent });

  return (
    <StripedRow style={style}>
      <Cell>
        <InputField
          id={`service_add_on_service_add_on_option_${index}_id`}
          name="service_add_on[service_add_on_options_attributes][][id]"
          value={option.get('uuid')}
          type="hidden"
        />
        <InputField
          id={`service_add_on_service_add_on_option_${index}_deleted_at`}
          name="service_add_on[service_add_on_options_attributes][][deleted_at]"
          value={option.get('deleted_at')}
          type="hidden"
        />
      </Cell>
      <Cell>
        <InputField
          id={`service_add_on_service_add_on_option_${index}_name`}
          isRequired
          name="service_add_on[service_add_on_options_attributes][][name]"
          value={option.get('name')}
        />
      </Cell>
      <Cell>
        <InputField
          id={`service_add_on_service_add_on_option_${index}_vendor_price`}
          isRequired
          name="service_add_on[service_add_on_options_attributes][][vendor_price]"
          value={vendorPrice || 0}
          onChange={handleVendorPriceChange}
          onBlur={handleVendorPriceBlur}
          type="number"
          step="any"
          hideStepper
          rightAligned
        />
      </Cell>
      <Cell>
        <input
          id={`service_add_on_service_add_on_option_${index}_price`}
          name="service_add_on[service_add_on_options_attributes][][price]"
          ref={inputPriceRef}
          defaultValue={basePrice || 0}
          style={{ height: 0, width: 0, position: 'absolute', border: 'none', visibility: 'hidden' }}
        />
        <div
          id={`service_add_on_service_add_on_option_${index}_display_price`}
          ref={displayPriceRef}
        >
          { displayPrice }
        </div>
      </Cell>
      <CenteredCell>
        <DeleteButton
          onClick={handleDelete}
          isDisabled={count === 1}
        />
      </CenteredCell>
    </StripedRow>
  );
};

ServiceAddOnOptionFields.propTypes = {
  count: number,
  index: number,
  markupPricePercent: string,
  option: object,
  onDelete: func,
  taxRate: oneOfType([string, number]).isRequired,
  taxType: string.isRequired,
};

export default ServiceAddOnOptionFields;
