/* eslint react/no-danger: 0 */

import React from 'react';
import { func, number } from 'prop-types';
import styled from 'styled-components';
import {
  NormalFont,
} from 'libs/support/sharedComponents';
import { withI18n } from 'libs/support/i18n';

const Container = styled(NormalFont)`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const Metadata = ({
  currentCount,
  currentPage,
  perPage,
  totalCount,
  totalPages,
  translate,
}) => {
  if (currentCount === 0) {
    return (
      <Container className="pagination-metadata">
        { translate('paginationNoResult') }
      </Container>
    );
  }

  const start = ((currentPage - 1) * perPage) + 1;
  const end = (currentCount < perPage ? start + currentCount : start + perPage) - 1;
  const singlePageOutput = translate('paginationResultsSinglePage', { number: currentCount });
  const multiPagesOutput = currentCount > 1 ?
    translate('paginationResultsMultiplePagesMultipleResults', { start, end, totalCount }) :
    translate('paginationResultsMultiplePagesSingleResult', { start, totalCount });
  const output = totalPages === 1 ? singlePageOutput : multiPagesOutput;

  return (
    <Container className="pagination-metadata">
      <span dangerouslySetInnerHTML={{ __html: output }} />
    </Container>
  );
};

Metadata.propTypes = {
  currentCount: number,
  currentPage: number,
  perPage: number,
  totalCount: number,
  totalPages: number,
  translate: func.isRequired,
};

export default withI18n(Metadata);
