import requestsManager from 'libs/middlewares/requestsManager';

/**
 * API to export Menu Items
 * POST /menus/menu_items/export
 */
export const createMenuItemExport = (params, successCallback, failedCallback) => {
  const endpoint = '/menus/menu_items/export';

  return requestsManager.createEntity(params, endpoint)
    .then((res) => {
      if (successCallback) successCallback(res.data);
    })
    .catch((res) => {
      if (failedCallback) failedCallback(res.response.data);
    });
};

/**
 * API to import Menu Items
 * POST /menus//menu_items/import
 */
export const createMenuItemImport = (params, successCallback, failedCallback) => {
  const endpoint = '/menus/menu_items/import';

  return requestsManager.createEntity(params, endpoint)
    .then((res) => {
      if (successCallback) successCallback(res.data);
    })
    .catch((res) => {
      if (failedCallback) failedCallback(res.response.data);
    });
};
