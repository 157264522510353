/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable quotes */
import React, { Fragment, useState } from "react";
import ChevronUp from 'libs/components/Icons/v2/ChevronUp';
import ChevronDown from 'libs/components/Icons/v2/ChevronDown';
import PropTypes from 'prop-types';

const OverviewCard = ({
  overviewText,
  overviewValue,
  isFetchingDashboardData,
  overviewActiveOrder,
  overviewCompletedOrder,
}) => {
  const [openOverviewDetails, setOpenOverviewDetails] = useState(false);

  return (
    <Fragment>
      {
        isFetchingDashboardData ? (
          <div className="overview-card d-flex skeleton" style={{ alignItems: 'baseline' }}>
            <span className="overview-text line" style={{ width: '100px' }} />
            <div className="d-flex group-value-percent">
              <span className="overview-value line" style={{ width: '50px' }} />
            </div>
          </div>
        ) : (
          <div
            className="overview-card d-flex skeleton-inner"
            onClick={() => setOpenOverviewDetails(!openOverviewDetails)}
          >
            <span className="overview-text">
              <span>
                {overviewText}
                <span className="mobile-overview-text">(this week)</span>
              </span>
              <span>
                { openOverviewDetails ? <ChevronUp /> : <ChevronDown />}
              </span>
            </span>
            <div className="d-flex group-value-percent">
              <span className="overview-value">{overviewValue}</span>
            </div>
            {
              openOverviewDetails &&
                <Fragment>
                  <div className="overview-text overview-line overview-active-orders">
                    <span>Active Orders</span>
                    <span>{overviewActiveOrder}</span>
                  </div>
                  <div className="overview-text">
                    <span>Completed Orders</span>
                    <span>{overviewCompletedOrder}</span>
                  </div>
                </Fragment>
            }
          </div>
        )
      }
    </Fragment>
  );
};

OverviewCard.propTypes = {
  overviewText: PropTypes.string,
  overviewValue: PropTypes.number,
  isFetchingDashboardData: PropTypes.bool,
  overviewActiveOrder: PropTypes.number,
  overviewCompletedOrder: PropTypes.number,
};

export default OverviewCard;
