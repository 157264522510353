/* eslint max-len: 0 */

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import Intl from 'intl';
import { array, func, object, string, bool } from 'prop-types';

import { translations } from 'libs/i18n/translations';
import { defaultLocale } from 'libs/i18n/default';

import * as mealLabelActions from '../actions/mealLabelActions';

import EditPanel from '../components/EditPanel';
import LeftPanel from '../components/LeftPanel';

global.Intl = Intl;

const MealLabelContainer = ({
  addLabel,
  duplicateLabel,
  removeLabel,
  updateLabel,
  selectTemplate,
  newTemplate,
  deleteLabelTemplate,
  fetchLabelTemplates,
  labelTemplates,
  labels,
  mealPlanId,
  selectedTemplate,
  onlySheet,
  catererName,
  catererId,
  isGroupOrder,
}) => {
  const locale = defaultLocale;
  const messages = translations[locale];

  const labelsByCurrentVendor = labels.filter(e => e.vendor_name === catererName);
  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      <div className="flex-container">
        <LeftPanel {...{ labels: labelsByCurrentVendor, template: selectedTemplate, isGroupOrder }} />

        {
          !onlySheet &&
            <EditPanel
              {
                ...{
                  addLabel,
                  duplicateLabel,
                  removeLabel,
                  updateLabel,
                  selectTemplate,
                  newTemplate,
                  deleteLabelTemplate,
                  fetchLabelTemplates,
                  labelTemplates,
                  labels: labelsByCurrentVendor,
                  mealPlanId,
                  selectedTemplate,
                  catererId,
                }
              }
            />
        }
      </div>
    </IntlProvider>
  );
};

MealLabelContainer.propTypes = {
  addLabel: func.isRequired,
  duplicateLabel: func.isRequired,
  removeLabel: func.isRequired,
  updateLabel: func.isRequired,
  selectTemplate: func.isRequired,
  fetchLabelTemplates: func.isRequired,
  deleteLabelTemplate: func.isRequired,
  newTemplate: func.isRequired,
  labelTemplates: array.isRequired,
  labels: array.isRequired,
  selectedTemplate: object.isRequired,
  mealPlanId: string,
  onlySheet: bool,
  catererName: string,
  catererId: string,
  isGroupOrder: bool,
};

const mapStateToProps = ({
  $$mealLabelStore,
}) => ({
  labels: $$mealLabelStore.get('labels').toJS(),
  selectedTemplate: $$mealLabelStore.get('selectedTemplate').toJS(),
  labelTemplates: $$mealLabelStore.get('labelTemplates').toJS(),
  mealPlanId: $$mealLabelStore.get('meal_plan_id'),
  successMsg: $$mealLabelStore.get('successMsg'),
  catererName: $$mealLabelStore.get('catererName'),
  catererId: $$mealLabelStore.get('catererId'),
  isGroupOrder: $$mealLabelStore.get('isGroupOrder'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  ...mealLabelActions,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)((MealLabelContainer));
