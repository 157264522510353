import React from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { notBlank } from 'libs/support/string';
import { bindActionCreators } from 'redux';
import * as filtersActions from 'bundles/vendor/actions/filtersActions';
import * as menuItemsActions from 'bundles/vendor/actions/menuItemsActions';
import Flash from 'bundles/vendor/components/Item/Flash';
import {
  InputField,
} from 'libs/components/Forms';
import CustomisationModal from 'bundles/vendor/components/Item/CustomisationModal';
import CateringForm from './CateringForm';
import PantryForm from './PantryForm';
import GiftsForm from './GiftsForm';
import ActivitiesForm from './ActivitiesForm';
import EcommerceForm from './EcommerceForm';

const FORMS = {
  catering: CateringForm,
  pantry: PantryForm,
  gifts: GiftsForm,
  activities: ActivitiesForm,
  ecommerce: EcommerceForm,
};

const Container = styled.div`
  padding: 1rem 1.5rem;
`;

const Submit = styled.input`
  display: none;
`;

const Form = ({
  caterer,
  fetchFilters,
  menuItem,
  saveMenuItem,
}) => {
  const formUrl = notBlank(menuItem.get('uuid')) ?
    `/menus/menu_items/${menuItem.get('uuid')}` : '/menus/menu_items';
  const formMethod = notBlank(menuItem.get('uuid')) ? 'put' : 'post';
  const onSubmit = (e) => {
    e.preventDefault();
    const form = document.getElementById('menu_item_form');
    const params = new FormData(form);

    saveMenuItem(menuItem.get('uuid'), params)
      .then(_ => {
        fetchFilters(caterer.get('kind'));
      });
  };

  const FormFields = FORMS[caterer.get('kind')];

  return (
    <Container>
      <Flash isSticky />

      <form
        id="menu_item_form"
        action={formUrl}
        method={formMethod}
        onSubmit={onSubmit}
      >
        <InputField
          id="menu_item_vendor_id"
          name="menu_item[vendor_id]"
          value={menuItem.get('vendor_id') || caterer.get('uuid')}
          type="hidden"
        />

        <FormFields />

        <Submit type="submit" />
      </form>

      <CustomisationModal />
    </Container>
  );
};

Form.propTypes = {
  caterer: object.isRequired,
  fetchFilters: func.isRequired,
  menuItem: object.isRequired,
  saveMenuItem: func.isRequired,
};

const mapStateToProps = (state) => ({
  caterer: state.$$catererStore.get('caterer'),
  menuItem: state.$$menuItemsStore.get('menuItem'),
  categories: state.$$categoriesStore.get('categories'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  saveMenuItem: menuItemsActions.saveMenuItem,
  fetchFilters: filtersActions.fetchFilters,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Form);
