import React, { useState } from 'react';
import { object, func } from 'prop-types';
import styled from 'styled-components';
import VendorInput from './VendorInput';
import VendorOptions from './VendorOptions';

const Container = styled.div`
  margin-right: 1.5rem;
  margin-bottom: 0.8rem;

  @media (min-width: ${props => props.theme.breakPoints.md}) {
    margin-bottom: 0
  }
`;

const VendorSelection = ({
  onSelectVendor,
  selectedVendor,
}) => {
  const [isOpen, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!isOpen);
  };
  const handleBlur = () => {
    setOpen(false);
  };

  return (
    <Container className="outlet-navigation__vendor-selection">
      <VendorInput
        onClick={handleClick}
        onBlur={handleBlur}
        {
          ...{
            selectedVendor,
          }
        }
      />
      <VendorOptions
        {
          ...{
            isOpen,
            onSelectVendor,
            selectedVendor,
          }
        }
      />
    </Container>
  );
};

VendorSelection.propTypes = {
  selectedVendor: object.isRequired,
  onSelectVendor: func.isRequired,
};

export default VendorSelection;
