/* eslint no-unused-expressions: 0 */

import React from 'react';
import { func, object, string } from 'prop-types';
import uppercamelcase from 'uppercamelcase';
import snakeCase from 'lodash/snakeCase';
import styled from 'styled-components';
import {
  Link,
  EditButton,
} from 'bundles/common/components/Buttons';
import {
  StripedRow,
  Cell,
  CenteredCell,
} from 'libs/support/sharedComponents';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as customisationsActions from 'bundles/vendor/actions/customisationsActions';
import { withI18n } from 'libs/support/i18n';
import DetachCustomisationButton from './DetachCustomisationButton';

const ActionsCell = styled(CenteredCell)`
  button:not(first-child) {
    margin-left: 0.5rem;
  }
`;

const Customisation = ({
  editCustomisation,
  customisation,
  menuItemId,
  translate,
  type,
}) => {
  const snakeCaseType = snakeCase(type);
  const question = customisation.get('question');
  const uuid = customisation.get('uuid');
  const typeLabel = translate(`vendor${uppercamelcase(type)}`);
  const handleEdit = (e) => {
    e && e.preventDefault();

    editCustomisation(type, customisation);
  };

  return (
    <StripedRow>
      <Cell>
        <input
          type="hidden"
          name={`menu_item[menu_item_${snakeCaseType}s_attributes][][${snakeCaseType}_id]`}
          value={uuid}
        />
        <input
          type="hidden"
          name={`menu_item[menu_item_${snakeCaseType}s_attributes][][menu_item_id]`}
          value={menuItemId}
        />
      </Cell>
      <Cell>
        <Link
          href="#"
          name={question}
          onClick={handleEdit}
        />
      </Cell>
      <Cell>{ customisation.get('nickname') }</Cell>
      <Cell>{ typeLabel }</Cell>
      <ActionsCell>
        <DetachCustomisationButton
          {
            ...{
              uuid,
              type,
            }
          }
        />
        <EditButton
          onClick={handleEdit}
        />
      </ActionsCell>
    </StripedRow>
  );
};

Customisation.propTypes = {
  customisation: object,
  editCustomisation: func.isRequired,
  menuItemId: string.isRequired,
  translate: func.isRequired,
  type: string.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  editCustomisation: customisationsActions.editCustomisation,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withI18n(Customisation));
