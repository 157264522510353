/* eslint jsx-a11y/label-has-for: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import classnames from 'classnames';
import $ from 'jquery';
import bindAll from 'lodash/bindAll';
import { injectIntl, intlShape } from 'react-intl';
import Select from 'react-select';

import BaseComponent from 'libs/components/BaseComponent';
import { notBlank } from 'libs/support/string';
import { defaultMessages } from 'libs/i18n/default';

const TEXTAREA = 'textarea';
const SELECT = 'select';
const TOGGLE = 'toggle';
const REACT_SELECT = 'react-select';

class FieldComponent extends BaseComponent {
  static propTypes = {
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
    rows: PropTypes.number,
    label: PropTypes.string,
    optionName: PropTypes.string,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    children: PropTypes.any,
    value: PropTypes.any,
    intl: intlShape.isRequired,
  };

  constructor() {
    super();

    bindAll(this, 'handleOnKeyUp');
  }

  handleOnKeyUp(e) {
    const { isRequired } = this.props;

    if (isRequired) {
      const $field = $(e.currentTarget);
      const fieldValue = $field.val();

      if (notBlank(fieldValue)) {
        $field.removeClass('form-control-danger');
        $field.closest('.form-group').removeClass('has-danger');
      }
    }
  }

  renderField() {
    const { isRequired, isDisabled, rows, type, name, placeholder, children, value, onChange, options } = this.props;
    const className = classnames({
      'required-field': isRequired,
      'custom-select': type === SELECT,
      'form-control': ![SELECT, REACT_SELECT].includes(type),
    });
    const defaultHash = { name, className, placeholder, disabled: isDisabled };
    const finalValue = value || '';

    if (notBlank(onChange)) {
      defaultHash.value = finalValue;
      defaultHash.onChange = onChange;
    } else {
      defaultHash.defaultValue = finalValue;
    }

    switch (type) {
      case TEXTAREA: {
        return (
          <textarea
            rows={rows}
            onKeyUp={this.handleOnKeyUp}
            {...defaultHash}
          />
        );
      }

      case SELECT: {
        return (
          <select
            style={{ width: '100%' }}
            {...defaultHash}
          >
            {children}
          </select>
        );
      }

      case TOGGLE: {
        return (
          <Toggle
            name={name}
            defaultChecked={value}
          />
        );
      }

      case REACT_SELECT: {
        return (
          <Select
            name={name}
            onChange={onChange}
            options={options}
            isDisabled={isDisabled}
            {...defaultHash}
          />
        );
      }

      default: {
        return (
          <input
            {...defaultHash}
            type={type}
            onKeyUp={this.handleOnKeyUp}
          />
        );
      }
    }
  }

  render() {
    const { isRequired, label, name, intl, optionName } = this.props;
    const { formatMessage } = intl;

    return (
      <div className="form-group row">
        <label
          className="col-sm-3 form-control-label"
          htmlFor={name}
        >
          {label && formatMessage(defaultMessages[`${label}`])}
          {optionName && optionName}
          {
            (isRequired) &&
            <span className="label-required"> * </span>
          }
        </label>

        <div className="col-sm-9">
          {this.renderField()}
        </div>
      </div>
    );
  }
}

export default injectIntl(FieldComponent);
