import React from 'react';
import { bool, func, string } from 'prop-types';
import {
  Link as LinkElement,
} from 'libs/support/sharedComponents';

const Link = ({
  className = '',
  href = '#',
  isDisabled,
  name,
  onClick,
}) => {
  const handleOnClick = (e) => {
    e.preventDefault();

    return onClick && onClick();
  };

  return (
    <LinkElement
      className={className}
      onClick={handleOnClick}
      disabled={isDisabled}
      href={href}
    >
      {name}
    </LinkElement>
  );
};

Link.propTypes = {
  className: string,
  isDisabled: bool,
  href: string,
  onClick: func,
  name: string,
};

export default Link;
