import * as api from 'libs/middlewares/api';
import * as actionTypes from '../constants/filtersConstants';

export const fetchFilters = (vendorKind) => {
  const types = [
    actionTypes.FETCHING_FILTERS,
    actionTypes.FETCH_FILTERS_SUCCESS,
    actionTypes.FETCH_FILTERS_FAILURE,
  ];

  return api.get({
    types,
    endpoint: '/menus/filters',
    params: { vendor_kind: vendorKind },
  });
};
