/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment } from 'react';
import { func, object, bool, string } from 'prop-types';
import uppercamelcase from 'uppercamelcase';
import { withI18n } from 'libs/support/i18n';
import moment from 'moment';
import { formattedPrice } from 'libs/support/price';

const OrderCard = ({
  translate,
  full = false,
  order,
  className = '',
  onClickCard,
}) => (
  <Fragment>
    <div
      className={`order-card ${className} ${full ? 'order-card-full' : ''}`}
      key={order.uuid}
      onClick={() => onClickCard(order.uuid, order.klassName)}
    >
      <div className="d-flex order-card-header">
        <span className="order-code">#{order.code}</span>
        { order.status &&
        <span className={`chip ${order.status}`}>
          {translate(`ordersStates${uppercamelcase(order.status)}`)}
        </span>
          }
      </div>
      <div className="d-flex order-card-body">
        <div className="order-calendar-icon">
          <div className="order-calendar-icon-header" />
          <span className="order-calendar-text-day">{ moment(order.deliverAt).utc().format('ddd').toUpperCase() }</span>
          <span className="order-calendar-text-date">{ moment(order.deliverAt).utc().format('MMM D') }</span>
        </div>
        <div className="order-content">
          <span className="order-time">{ moment(order.deliverAt).utc().format('h:mm A') }</span>
          <span className="delivery-type">
            {translate(`ordersShowFormsDeliveryTypeOptions${uppercamelcase(order.deliveryType)}`)}
          </span>
          <span className="order-outlet">{order?.outlet?.name}</span>
        </div>
      </div>
      <div className="d-flex order-card-footer">
        <span className="no-items">{order.itemsCount} items</span>
        <span className="total-value">{formattedPrice(order.vTotalAmount)}</span>
      </div>
    </div>
  </Fragment>
);

OrderCard.propTypes = {
  translate: func,
  full: bool,
  order: object,
  className: string,
  onClickCard: func,
};

export default withI18n(OrderCard);
