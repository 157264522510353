/* eslint camelcase: 0 */

import Immutable from 'immutable';
import * as actionTypes from '../constants/catererConstants';

export const $$initialState = Immutable.fromJS({
  caterer: Immutable.fromJS({}),
});

export default function catererReducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  switch (type) {
    case actionTypes.FETCH_CATERER_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .set('caterer', Immutable.fromJS(response.vendor))
      ));
    }

    default: {
      return $$state;
    }
  }
}
