import React, { useEffect, useState } from 'react';
import { bool, array, object, number, string } from 'prop-types';
import { connect } from 'react-redux';
import {
  SelectField,
} from 'libs/components/Forms';
import { blankish, notBlank } from 'libs/support/string';
import {
  getHintText,
} from './helpers/TagsFieldGroupHelpers';

const FormatsFieldGroup = ({
  isRequired,
  initialSelectedOptions,
  selectOptions,
  label: customLabel,
  limit = 2,
}) => {
  const [isInitialized, setInitialized] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(initialSelectedOptions);
  const handleOptionChange = (options) => {
    setSelectedOptions(options);
  };
  const isMaxedOut = selectedOptions.length >= limit;
  const limitOptions = isMaxedOut ? [] : selectOptions;
  const noOptionsMessage = () => (isMaxedOut ? 'Selection is maxed out' : 'No options');
  const hintText = getHintText('format', limit);

  useEffect(() => {
    if (!isInitialized && selectedOptions.length === 0 && initialSelectedOptions.length > 0) {
      setInitialized(true);
      setSelectedOptions(initialSelectedOptions);
    }
  });

  return (
    <SelectField
      id="menu_item_food_attributes_menu_items_attributes_food_attribute_id"
      name="menu_item[food_attributes_menu_items_attributes][][food_attribute_id]"
      value={selectedOptions}
      options={limitOptions}
      onChange={handleOptionChange}
      noOptionsMessage={noOptionsMessage}
      isMulti
      isRequired={isRequired}
      label={customLabel || 'Format'}
      hintText={hintText}
      fieldGroupClassName="format-tags"
    />
  );
};

FormatsFieldGroup.propTypes = {
  initialSelectedOptions: array,
  isRequired: bool,
  label: string,
  limit: number,
  selectOptions: object.isRequired,
};

const FORMAT_KEYS = {
  catering: 'Format',
  pantry: 'Pantry Delivery Format',
};

const buildOption = option => (
  {
    label: option.get('name'),
    value: option.get('uuid'),
  }
);

const buildSelectOptions = (formats) => {
  const withParent = formats.some(item => notBlank(item.get('parent_id')));

  if (withParent) {
    const parents = formats.filter(item => blankish(item.get('parent_id')));

    return parents.map((parent) => {
      let children = formats.filter(item => item.get('parent_id') === parent.get('uuid'));

      if (children.size === 0) {
        children = children.push(parent);
      }

      return ({
        label: parent.get('name'),
        options: children.map(buildOption).toJS(),
      });
    });
  }

  return formats.map(buildOption);
};

const mapStateToProps = (state) => {
  const catererKind = state.$$catererStore.getIn(['caterer', 'kind']);
  const menuItem = state.$$menuItemsStore.get('menuItem');
  const formatKey = FORMAT_KEYS[catererKind] || catererKind;
  const formats = state.$$filtersStore.get('foodAttributes').filter(item => item.get('category_name') === formatKey);
  const foodAttributesMenuItems = menuItem.get('food_attributes_menu_items');
  const selectOptions = buildSelectOptions(formats);
  const selectedOptionIds = (
    foodAttributesMenuItems && foodAttributesMenuItems.map(item => item.get('food_attribute_id'))
  ) || [];
  const initialSelectedOptions = (
    formats && selectedOptionIds && formats
      .filter(item => selectedOptionIds.includes(item.get('uuid')))
      .map(buildOption).toJS()
  ) || [];

  return ({
    initialSelectedOptions,
    selectOptions,
  });
};

export default connect(mapStateToProps)(FormatsFieldGroup);
