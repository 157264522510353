import React, { Fragment } from 'react';
import { number } from 'prop-types';
import styled from 'styled-components';
import Page from './Page';
import TruncatedPage from './TruncatedPage';

const Container = styled.span`
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  justify-content: center;
  line-height: 16px;

  > a {
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.gray200};
    box-sizing: border-box;
    padding: 0.5rem 0.75rem;

    &:first-child {
      border-radius: 3px 0 0 3px;
    }

    &:last-child {
      border-radius: 0 3px 3px 0;
    }

    &:not(first-child) {
      margin-left: -1px;
    }

    &.--active {
      background-color: ${props => props.theme.colors.lima};
      color: ${props => props.theme.colors.white};
      pointer-events: none;
    }
  }
`;

const Pagination = ({
  currentPage,
  totalPages,
}) => {
  const pages = new Array(totalPages).fill().map((_, idx) => 1 + idx);
  const hasPrevious = currentPage > 1;
  const hasNext = currentPage < totalPages;

  if (totalPages < 2) return null;

  return (
    <Container
      className="react-pagination"
    >
      {
        hasPrevious &&
          <Fragment>
            <Page
              key="pagination-first"
              className="pagination-first"
              page={1}
              label="First"
              currentPage={currentPage}
            />

            <Page
              key="pagination-previous"
              className="pagination-previous"
              page={currentPage - 1}
              label="Prev"
              currentPage={currentPage}
            />
          </Fragment>
      }
      {
        currentPage > 5 &&
          <TruncatedPage
            key="truncated-pagination-before"
          />
      }
      {
        pages.map((page) => {
          if (Math.abs(currentPage - page) > 4) {
            return null;
          }

          return (
            <Page
              key={`pagination-${page}`}
              page={page}
              label={page}
              isActive={currentPage === page}
              currentPage={currentPage}
            />
          );
        })
      }
      {
        totalPages - currentPage > 5 &&
          <TruncatedPage
            key="truncated-pagination-after"
          />
      }
      {
        hasNext &&
          <Fragment>
            <Page
              key="pagination-next"
              className="pagination-next"
              page={currentPage + 1}
              label="Next"
              currentPage={currentPage}
            />

            <Page
              key="pagination-last"
              className="pagination-last"
              page={totalPages}
              label="Last"
              currentPage={currentPage}
            />
          </Fragment>
      }
    </Container>
  );
};

Pagination.propTypes = {
  currentPage: number,
  totalPages: number,
};

export default Pagination;
