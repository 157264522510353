import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  font-family: ${props => props.theme.fonts.proxima};
  font-size: 14px;
  line-height: 24px;
  color: red;
`;


const SectionError = ({ children }) => (
  <Container>{children}</Container>
);

SectionError.propTypes = {
  children: string.isRequired,
};

export default SectionError;
