import React, { Fragment } from 'react';
import { bool, func, object } from 'prop-types';
import { blankish } from 'libs/support/string';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Flash as FlashComponent,
} from 'libs/components/Forms';
import * as vendorBillingActions from 'bundles/billings/actions/vendorBillingActions';
import {
  Spacer,
} from 'libs/support/sharedComponents';

const Flash = ({
  flash,
  isSticky,
  removeNoticeFlash,
}) => {
  if (flash.size === 0 || (blankish(flash.get('notice')) && blankish(flash.get('errors')))) {
    return null;
  }

  return (
    <Fragment>
      <FlashComponent
        {
          ...{
            flash,
            isSticky,
            removeNoticeFlash,
          }
        }
      />
      <Spacer />
    </Fragment>
  );
};

Flash.propTypes = {
  flash: object,
  isSticky: bool,
  removeNoticeFlash: func.isRequired,
};

const mapStateToProps = (state) => ({
  flash: state.$$vendorBillingStore.get('flash'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  removeNoticeFlash: vendorBillingActions.removeNoticeFlash,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Flash);
