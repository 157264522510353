import styled from 'styled-components';

export const ActionBar = styled.div`
  background-color: ${props => props.theme.colors.twilightBlue};
  box-shadow: 0px -1px 0px #EAEAEB, 0px 1px 0px ${props => props.theme.colors.gray200};
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1.5rem;
  position: sticky;
  top: 0;
  z-index: 100;
`;

export const ActionBarLeftSection = styled.div`
  align-items: center;
  display: flex;

  & > * {
    margin-right: 1.5rem;
  }
`;

export const ActionBarRightSection = styled.div`
  align-items: center;
  display: flex;

  & > * {
    margin-left: 1rem;
  }
`;
