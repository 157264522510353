/* eslint react/no-array-index-key: 0 */
/* eslint react/no-danger: 0 */

import React from 'react';
import styled from 'styled-components';
import { withI18n } from 'libs/support/i18n';
import TagsFieldGroup from 'bundles/vendor/components/Item/FieldGroups/TagsFieldGroup';
import FormatsFieldGroup from 'bundles/vendor/components/Item/FieldGroups/FormatsFieldGroup';
import SectionTitle from 'bundles/vendor/components/Shared/SectionTitle';

const Container = styled.div`

`;

const PantryTagsSection = () => (
  <Container className="tags-section">
    <SectionTitle>Classification Tags</SectionTitle>

    <TagsFieldGroup kind="presentation" limit={2} isRequired />
    <TagsFieldGroup kind="dietary" />
    <FormatsFieldGroup label="Category" isRequired />
    <TagsFieldGroup kind="brand" isCreatable />
  </Container>
);

export default withI18n(PantryTagsSection);
