/* eslint camelcase: 0 */

import Immutable from 'immutable';
import * as actionTypes from '../constants/orderNoticesConstants';

export const $$initialState = Immutable.fromJS({
  isOrderNoticesFetched: false,
  orderNotice: Immutable.fromJS({}),
  orderNotices: Immutable.fromJS([]),
});

export default function orderNoticesReducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  switch (type) {
    case actionTypes.FETCH_ORDER_NOTICES_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .set('isOrderNoticesFetched', true)
          .set('orderNotices', Immutable.fromJS(response.order_notices))
      ));
    }

    default: {
      return $$state;
    }
  }
}
