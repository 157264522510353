/* eslint react/no-array-index-key:0 */

import React, { useEffect, useState } from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as customisationsActions from 'bundles/vendor/actions/customisationsActions';
import {
  Table,
  HeaderRow,
  Header,
  TBody,
  Spacer,
} from 'libs/support/sharedComponents';
import styled from 'styled-components';
import {
  SecondaryButton,
} from 'bundles/common/components/Buttons';
import Subheader from 'bundles/vendor/components/Item/TaxTypes/Subheader';
import ItemAddOnOptionFields from './ItemAddOnOptionFields';

const Container = styled.div`
`;

const ItemAddOnOptionsList = ({
  addCustomisationOption,
  caterer,
  itemAddOnOptions,
}) => {
  const handleAddOption = () => {
    addCustomisationOption();
  };

  const [isInitialized, setInitialize] = useState(false);

  useEffect(() => {
    if (!isInitialized) {
      setInitialize(true);

      if (itemAddOnOptions.size === 0) {
        addCustomisationOption();
      }
    }
  });

  const markupPriceEnabled = caterer.get('markup_price_enabled');
  const markupPricePercent = caterer.get('markup_price_percent') && markupPriceEnabled ?
    caterer.get('markup_price_percent') : '0';
  const taxType = caterer.get('tax_type');
  const taxRate = caterer.get('country_tax_rate') || '0';

  return (
    <Container>
      <Subheader taxType={taxType} />
      <Spacer />
      <Table>
        <thead>
          <HeaderRow>
            <Header key="position" />
            <Header key="name" >Name</Header>
            <Header key="price" >Price</Header>
            <Header key="display_price" >Display Price</Header>
            <Header key="photo" >Photo</Header>
            <Header key="actions" />
          </HeaderRow>
        </thead>
        <TBody>
          {
            itemAddOnOptions.size > 0 &&
              itemAddOnOptions.map((option, index) => (
                <ItemAddOnOptionFields
                  key={`option_${option.get('uuid') || option.get('tempId')}_${index}`}
                  option={option}
                  index={index}
                  count={itemAddOnOptions.size}
                  markupPricePercent={markupPricePercent}
                  taxRate={taxRate}
                  taxType={taxType}
                />
              ))
          }
        </TBody>
      </Table>

      <Spacer />

      <SecondaryButton
        name="Add Another"
        onClick={handleAddOption}
      />
    </Container>
  );
};

ItemAddOnOptionsList.propTypes = {
  addCustomisationOption: func.isRequired,
  caterer: object,
  itemAddOnOptions: object,
};

const mapStateToProps = ({
  $$catererStore,
  $$customisationsStore,
}) => ({
  caterer: $$catererStore.get('caterer'),
  itemAddOnOptions: $$customisationsStore.getIn(['customisation', 'item_add_on_options']),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  addCustomisationOption: customisationsActions.addCustomisationOption,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ItemAddOnOptionsList);
