import React from 'react';
import PropTypes from 'prop-types';

const DeclineReason = ({ reasons, detail }) => (
  <div>
    <p>This order has been declined because:</p>
    <ul>
      {reasons.map(reason => (<li>{reason}</li>))}
      {detail && <li>More detail: {detail}</li>}
    </ul>
  </div>
);

DeclineReason.propTypes = {
  reasons: PropTypes.object,
  detail: PropTypes.string,
};

export default DeclineReason;
