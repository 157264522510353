import React, { Fragment } from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import {
  InputField,
  TextAreaField,
} from 'libs/components/Forms';
import SecondarySection from 'bundles/vendor/components/Item/SecondarySection';
import VariationsSection from 'bundles/vendor/components/Item/Variations/VariationsSection';
import { Separator } from 'libs/support/sharedComponents';
import NutritionsFieldGroup from 'bundles/vendor/components/Item/FieldGroups/NutritionsFieldGroup';
import TemperatureFieldGroup from 'bundles/vendor/components/Item/FieldGroups/TemperatureFieldGroup';
import PackagingUnitFieldGroup from 'bundles/vendor/components/Item/FieldGroups/PackagingUnitFieldGroup';
import MeasurementUnitFieldGroup from 'bundles/vendor/components/Item/FieldGroups/MeasurementUnitFieldGroup';
import OriginFieldGroup from 'bundles/vendor/components/Item/FieldGroups/OriginFieldGroup';
import ExpirationFieldGroup from 'bundles/vendor/components/Item/FieldGroups/ExpirationFieldGroup';
import PantryTagsSection from 'bundles/vendor/components/Item/Sections/PantryTagsSection';
import PhotosSection from 'bundles/vendor/components/Item/Sections/PhotosSection';
import CustomisationsSection from 'bundles/vendor/components/Item/Sections/CustomisationsSection';
import PrimarySection from 'bundles/vendor/components/Item/Sections/PrimarySection';
import ExternalFieldGroup from 'bundles/vendor/components/Item/FieldGroups/ExternalFieldGroup';

const PantryForm = ({
  menuItem,
}) => (
  <Fragment>
    <PrimarySection />

    <Separator />

    <VariationsSection />

    <Separator />

    <SecondarySection />
    <PackagingUnitFieldGroup />
    <MeasurementUnitFieldGroup />
    <NutritionsFieldGroup />

    <InputField
      label="Flavor"
      id="menu_item_flavor"
      name="menu_item[flavor]"
      optionalText="optional"
      value={menuItem.get('flavor')}
    />

    <TextAreaField
      hintText="Any ingredients or notes on the item content."
      id="menu_item_content"
      label="Content"
      name="menu_item[content]"
      optionalText="optional"
      value={menuItem.get('content')}
      rows={3}
    />

    <TextAreaField
      hintText="Any instructions on how the item needs to be stored."
      id="menu_item_storage"
      label="Storage"
      name="menu_item[storage]"
      value={menuItem.get('storage')}
      rows={3}
    />
    <TemperatureFieldGroup />
    <OriginFieldGroup />
    <ExpirationFieldGroup />
    <ExternalFieldGroup />

    <Separator />

    <PantryTagsSection />

    <Separator />

    <PhotosSection isRequired />

    <Separator />

    <CustomisationsSection />
  </Fragment>
);

PantryForm.propTypes = {
  menuItem: object.isRequired,
};

const mapStateToProps = (state) => ({
  menuItem: state.$$menuItemsStore.get('menuItem'),
});

export default connect(mapStateToProps)(PantryForm);
