import React, { Fragment } from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import { InputField, SelectField } from 'libs/components/Forms';
import { useSelectInput } from 'libs/support/selectInput';
import { withI18n } from 'libs/support/i18n';

const PrimarySection = ({
  categories,
  menuItem,
  translate,
}) => {
  const categoryOptions = categories.map((category) => (
    {
      label: category.get('name'),
      value: category.get('uuid'),
      badge: !category.get('active') && translate('vendorInactiveCategory'),
    }
  ));
  const [selectedCategory, handleCategoryChange] = useSelectInput(
    categoryOptions,
    menuItem.get('category_id'),
  );

  return (
    <Fragment>
      <InputField
        id="menu_item_name"
        label="Name"
        isRequired
        name="menu_item[name]"
        value={menuItem.get('name')}
      />

      <SelectField
        id="menu_item_category_id"
        label="Category"
        name="menu_item[category_id]"
        value={[selectedCategory]}
        options={categoryOptions}
        onChange={handleCategoryChange}
        isRequired
      />
    </Fragment>
  );
};

PrimarySection.propTypes = {
  categories: object,
  menuItem: object,
  translate: func.isRequired,
};

const mapStateToProps = (state) => ({
  menuItem: state.$$menuItemsStore.get('menuItem'),
  categories: state.$$categoriesStore.get('categories'),
});

export default connect(mapStateToProps)(withI18n(PrimarySection));
