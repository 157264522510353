import React from 'react';
import { bool, func, object, string } from 'prop-types';

import BaseComponent from 'libs/components/BaseComponent';
import { createTempFile } from 'libs/services/tempFilesApi';
import Immutable from 'immutable';
import PhotoPreview from './PhotoPreview';
import TextPreview from './TextPreview';

const uploadImage = (file, details) => (
  new Promise((resolve, reject) => {
    const dataForm = new FormData();
    dataForm.append('file', file);

    createTempFile(
      dataForm,
      (res) => (resolve({ file, ...res, ...details })),
      (err) => (reject(err)),
    );
  })
);

class PhotoUploads extends BaseComponent {
  static propTypes = {
    backgroundColor: string,
    photo: object,
    details: object,
    isEditable: bool,
    isRequired: bool,
    isTextOnly: bool,
    multiple: bool,
    onCrop: func,
    onDrop: func.isRequired,
    onDelete: func,
    onEdit: func,
    preload: bool,
    url: string,
  };

  constructor(props, context) {
    super(props, context);

    this.state = { uploading: false };
  }

  handleOnDropPreload = (files) => {
    const { details, onDrop, onDropFailure } = this.props;

    let { index } = details;
    const promises = [];

    files.forEach((file) => {
      promises.push(uploadImage(file, { ...details, index }));

      index += 1;
    });

    Promise.all(promises).then((values) => {
      this.setState({ uploading: false });
      onDrop(values);
    }).catch(response => {
      this.setState({ uploading: false });
      onDropFailure({ ...details, errors: response.errors });
    });
  }

  handleOnDrop = (files) => {
    this.setState({ uploading: true });

    if (this.props.preload) {
      this.handleOnDropPreload(files);
    } else { // LEGACY
      const { details, onDrop } = this.props;
      this.setState({ uploading: false });
      onDrop(files, details);
    }
  }

  handleOnDelete = (e) => {
    const { details, onDelete } = this.props;
    e.preventDefault();
    onDelete(details);
  }

  handleOnEdit = (e) => {
    const { details, onEdit, photo, onCrop } = this.props;
    e.preventDefault();

    onEdit({
      title: 'Crop Image',
      photo,
      details,
      onCrop,
    });
  }

  render() {
    const {
      details,
      isEditable,
      isRequired,
      isTextOnly,
      photo = Immutable.fromJS({}),
      multiple,
    } = this.props;
    const {
      uploading,
    } = this.state;
    const Component = isTextOnly ? TextPreview : PhotoPreview;

    return (
      <Component
        {
          ...{
            details,
            isEditable,
            isRequired,
            photo,
            uploading,
            multiple,
          }
        }
        handleOnDrop={this.handleOnDrop}
        handleOnDelete={this.handleOnDelete}
        handleOnEdit={this.handleOnEdit}
      />
    );
  }
}

export default PhotoUploads;
