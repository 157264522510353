import React, { useState } from 'react';
import { bool, func, string } from 'prop-types';
import Toggle from 'react-toggle';
import styled from 'styled-components';
import { FieldGroup } from 'libs/components/Forms';
import { notBlank } from 'libs/support/string';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ToggleField = ({
  id,
  checked,
  defaultChecked,
  isDisabled,
  label,
  name,
  onChange,
}) => {
  const [localChecked, setLocalChecked] = useState(checked || defaultChecked);

  const handleOnChange = (e) => {
    setLocalChecked(e.target.checked);

    onChange && onChange(e); // eslint-disable-line no-unused-expressions
  };

  const renderInputProps = {
    id,
    name,
  };

  if (notBlank(checked)) {
    renderInputProps.checked = checked;
  }

  if (notBlank(defaultChecked)) {
    renderInputProps.defaultChecked = defaultChecked;
  }

  const renderInput = () => (
    <Container>
      <Toggle
        disabled={isDisabled}
        onChange={handleOnChange}
        {...renderInputProps}
      />

      {
        !localChecked &&
        <input
          type="hidden"
          name={name}
          value="false"
        />
      }
    </Container>
  );

  if (label) {
    return (
      <FieldGroup
        label={label}
        htmlFor={id}
      >
        {renderInput()}
      </FieldGroup>
    );
  }

  return renderInput();
};

ToggleField.propTypes = {
  id: string.isRequired,
  isDisabled: bool,
  checked: bool,
  defaultChecked: bool.isRequired,
  label: string,
  name: string,
  onChange: func,
};

export default ToggleField;
