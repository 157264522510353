import * as api from 'libs/middlewares/api';
import * as actionTypes from '../constants/vendorStatementsConstants';

export const fetchVendorStatements = (params) => {
  const types = [
    actionTypes.FETCHING_VENDOR_STATEMENTS,
    actionTypes.FETCH_VENDOR_STATEMENTS_SUCCESS,
    actionTypes.FETCH_VENDOR_STATEMENTS_FAILURE,
  ];

  return api.get({
    types,
    endpoint: '/billings/vendor_statements',
    params,
  });
};

export const fetchVendorStatement = (id) => {
  const types = [
    actionTypes.FETCHING_VENDOR_STATEMENT,
    actionTypes.FETCH_VENDOR_STATEMENT_SUCCESS,
    actionTypes.FETCH_VENDOR_STATEMENT_FAILURE,
  ];

  return api.get({
    types,
    endpoint: '/billings/vendor_statements',
    params: {},
    id,
  });
};

export const setSelectedYear = (response) => (
  { type: actionTypes.SET_SELECTED_YEAR, response }
);
