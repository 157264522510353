/* eslint react/no-danger: 0 */

import React, { useRef, useState, useEffect, useCallback } from 'react';
import { bool, func, object } from 'prop-types';
import Immutable from 'immutable';

import { withAppContext } from 'libs/support/appContext';
import { withI18n } from 'libs/support/i18n';
import Modal from 'libs/components/ModalComponent';
import ModalHeader from 'libs/components/ModalHeaderComponent';
import ModalBody from 'libs/components/ModalBodyComponent';
import ModalFooter from 'libs/components/ModalFooterComponent';
import { CloseButton } from 'bundles/common/components/Buttons';
import {
  ModalContainer,
  Spacer,
  Nbsp,
} from 'libs/support/sharedComponents';
import { Flash } from 'libs/components/Forms';
import { createMenuItemImport } from 'libs/services/menuItemsApi';
import { SubmitButton } from 'bundles/common/components/Buttons';
import styled from 'styled-components';
import * as menuItemsActions from 'bundles/vendor/actions/menuItemsActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { trackClickedImportCsvButton } from 'packs/events';

const FileInput = styled.input`
  cursor: pointer;
  opacity: 0;
  position: absolute;
  width: 100%;
  z-index: 10;
`;

const InputContainer = styled.div`
  border-radius: 4px;
  border: 1px solid  ${props => props.theme.colors.gray200};
  display: flex;
  margin-bottom: 0;
  padding: 0px 10px;
  position: relative;
  width: 100%;
`;

const LeftLabel = styled.label`
  align-items: center;
  display: flex;
  margin-right: 1rem;
  white-space: nowrap;

  @media (min-width: ${props => props.theme.breakPoints.sm}) {
    margin-bottom: 0;
  }
`;

const DisplayInput = styled.input`
  display: inline-block;
  flex: 1;
  left: 0;
  border: none;
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: ${props => props.theme.breakPoints.sm}) {
    flex-wrap: nowrap;
  }
`;

const AddOn = styled.span`
  align-items: center;
  border-left: 1px solid ${props => props.theme.colors.gray200};
  display: flex;
  height: 100%;
  padding: 5px 20px;
`;

const CHANNEL = 'menuItemImportChannel';

const ImportModal = ({
  fetchMenuItems,
  isShow,
  onHide,
  cable,
  translate,
}) => {
  const [flash, setFlash] = useState(Immutable.fromJS({ notice: translate('menuItemsImportModalImporting') }));
  const [isImporting, setIsImporting] = useState(false);
  const channel = useRef(null);
  const form = useRef(null);
  const [fileName, setFileName] = useState(null);

  const handleOnChange = (e) => {
    if (e.target.files[0]) { setFileName(e.target.files[0].name); }
  };

  const reset = () => {
    setFlash(Immutable.fromJS({}));
    setIsImporting(false);
  };

  const handleOnImport = (e) => {
    if (fileName) {
      e.preventDefault();
      trackClickedImportCsvButton();
      reset();
      setIsImporting(true);
      const params = new FormData(form.current);
      createMenuItemImport(params);
    }
  };

  const subscribeChannel = useCallback(() => {
    channel.current = cable.subscribe({
      channel: CHANNEL,
      receivedCallback: (data) => {
        if (!data) return;

        if (data.msg) {
          setFlash(Immutable.fromJS({ notice: data.msg }));
        }

        if (data.status === 'succeeded') {
          setFlash(Immutable.fromJS({ notice: translate('menuItemsImportModalAlertsSuccess') }));
          setIsImporting(false);
          fetchMenuItems();
        }

        if (data.errors) {
          setFlash(Immutable.fromJS({ errors: data.errors }));
          setIsImporting(false);
        }
      },
    });
  }, []);

  useEffect(() => {
    if (!channel.current) subscribeChannel();

    return () => {
      if (channel.current) { return cable.unsubscribe(channel.current); }

      return false;
    };
  }, []);

  useEffect(() => {
    if (isShow) {
      reset();
    }
  }, [isShow]);

  return (
    <ModalContainer>
      <Modal
        show={isShow}
        className="modal-lg"
        onHide={onHide}
      >
        <ModalHeader>
          {translate('menuItemsImportModalSubject')}
        </ModalHeader>

        <ModalBody>
          <Flash flash={flash} />
          <Spacer />
          <Form
            id="menu-items-import-form"
            ref={form}
          >
            <input type="hidden" name="import[sid]" value={cable.socketId} />

            <LeftLabel
              htmlFor="import[file]"
            >
              Select File
            </LeftLabel>

            <InputContainer>
              <FileInput
                type="file"
                accept=".csv"
                name="import[file]"
                id="input-file"
                aria-describedby="input-file"
                onChange={handleOnChange}
                required
              />

              <DisplayInput
                value={fileName}
              />
              <AddOn>
                Browse
              </AddOn>
            </InputContainer>
          </Form>
        </ModalBody>

        <ModalFooter>
          <CloseButton />
          <Nbsp />
          <SubmitButton
            onClick={handleOnImport}
            isSaving={isImporting}
            name={translate('menuItemsImportModalImport')}
            savingName={translate('menuItemsImportModalImporting')}
            form="menu-items-import-form"
          />
        </ModalFooter>
      </Modal>
    </ModalContainer>
  );
};

ImportModal.propTypes = {
  fetchMenuItems: func.isRequired,
  isShow: bool.isRequired,
  onHide: func.isRequired,
  cable: object.isRequired,
  translate: func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchMenuItems: menuItemsActions.fetchMenuItems,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withAppContext(withI18n(ImportModal)));
