import React from 'react';
import { object } from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  GreyFont,
  VerticalRule,
} from 'libs/support/sharedComponents';
import { usePolicies } from 'bundles/common/queries/policies';

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 35px;
`;

const Text = styled(GreyFont)`
  white-space: nowrap;
`;

const SelectedMenuItems = ({
  selectedIds,
}) => {
  const policiesResponse = usePolicies();
  const { policies } = policiesResponse.isSuccess ? policiesResponse.data : {};
  const count = selectedIds.size;

  if (count === 0) return null;

  return (
    <Container>
      <Text>{`${count} selected`}</Text>
      { policies?.menuItem?.update && <VerticalRule /> }
    </Container>
  );
};

SelectedMenuItems.propTypes = {
  selectedIds: object,
};

const mapStateToProps = ({
  $$menuItemsStore,
}) => ({
  selectedIds: $$menuItemsStore.get('selectedIds'),
});

export default connect(mapStateToProps)(SelectedMenuItems);
