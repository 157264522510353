/* eslint camelcase: 0 */

import Immutable from 'immutable';
import {
  HIDE_ALERT_MESSAGE,
  SHOW_ALERT_MESSAGE,
} from 'bundles/common/constants/messagesConstants';

const defaultAlert = {
  isShow: false,
  title: null,
  message: null,
  submit: null,
};

export const $$initialState = Immutable.fromJS({
  alert: defaultAlert,
});

export default function messagesReducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  switch (type) {
    case SHOW_ALERT_MESSAGE: {
      return $$state.merge({
        alert: {
          isShow: true,
          title: response.title,
          message: response.message,
          submit: response.submit,
        },
      });
    }

    case HIDE_ALERT_MESSAGE: {
      return $$state.merge({
        alert: defaultAlert,
      });
    }

    default: {
      return $$state;
    }
  }
}
