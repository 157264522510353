/* eslint max-len: 0 */

import React from 'react';

const Calendar = (_props) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.33337 1.38013V2.71346H10.6667V1.38013H12V2.71346H12.6667C13.7713 2.71346 14.6667 3.60889 14.6667 4.71346V14.0468C14.6667 15.1514 13.7713 16.0468 12.6667 16.0468H3.33337C2.22881 16.0468 1.33337 15.1514 1.33337 14.0468V4.71346C1.33337 3.60889 2.2288 2.71346 3.33337 2.71346H4.00004V1.38013H5.33337ZM12 4.04679H10.6667H5.33337H4.00004H3.33337C2.96518 4.04679 2.66671 4.34527 2.66671 4.71346V5.38013H13.3334V4.71346C13.3334 4.34527 13.0349 4.04679 12.6667 4.04679H12ZM2.66671 6.71346V14.0468C2.66671 14.415 2.96518 14.7135 3.33337 14.7135H12.6667C13.0349 14.7135 13.3334 14.415 13.3334 14.0468V6.71346H2.66671ZM6.00004 8.71346H4.66671V10.0468H6.00004V8.71346ZM6.00004 11.3801H4.66671V12.7135H6.00004V11.3801ZM8.66671 8.71346H7.33337V10.0468H8.66671V8.71346ZM8.66671 11.3801H7.33337V12.7135H8.66671V11.3801ZM11.3334 8.71346H10V10.0468H11.3334V8.71346ZM11.3334 11.3801H10V12.7135H11.3334V11.3801Z" fill="#95989D" />
  </svg>
);
export default Calendar;
