import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

const defaultQueryClient = new QueryClient();

export const withQueryClient = (Component, queryClient = defaultQueryClient) => (props) => (
  <QueryClientProvider client={queryClient}>
    <Component {...props} />
  </QueryClientProvider>
);
