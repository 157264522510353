import React, { createContext, useContext } from 'react';
import APICable from 'libs/support/api_cable';

const AppContext = createContext();
export default AppContext;

const flagIsEnabled = (fflags) => (key) => fflags[key] === 'true' || fflags[key] === true;

export const withAppContext = Component => (
  (props) => {
    const appContext = useContext(AppContext);
    // Unique cable throughout the page. Lazily loaded.
    if (!appContext.cable) appContext.cable = new APICable(Date.now());

    return (
      <Component
        cable={appContext.cable}
        flagIsEnabled={flagIsEnabled(appContext.fflags)}
        {...props}
      />
    );
  }
);
