import * as api from 'libs/middlewares/api';
import * as actionTypes from '../constants/menuItemsConstants';

export const saveMenuItem = (id, params) => {
  const types = [
    actionTypes.SAVING_MENU_ITEM,
    actionTypes.SAVE_MENU_ITEM_SUCCESS,
    actionTypes.SAVE_MENU_ITEM_FAILURE,
  ];

  return api.save({
    types,
    endpoint: '/menus/menu_items',
    params,
    id,
  });
};

export const deleteMenuItem = (id) => {
  const types = [
    actionTypes.DELETING_MENU_ITEM,
    actionTypes.DELETE_MENU_ITEM_SUCCESS,
    actionTypes.DELETE_MENU_ITEM_FAILURE,
  ];

  return api.destroy({
    types,
    endpoint: '/menus/menu_items',
    params: null,
    id,
  });
};

export const fetchMenuItems = (params) => {
  const types = [
    actionTypes.FETCHING_MENU_ITEMS,
    actionTypes.FETCH_MENU_ITEMS_SUCCESS,
    actionTypes.FETCH_MENU_ITEMS_FAILURE,
  ];

  return api.get({
    types,
    endpoint: '/menus/menu_items',
    params,
  });
};

export const fetchMenuItem = (id) => {
  const types = [
    actionTypes.FETCHING_MENU_ITEM,
    actionTypes.FETCH_MENU_ITEM_SUCCESS,
    actionTypes.FETCH_MENU_ITEM_FAILURE,
  ];

  return api.get({
    types,
    endpoint: '/menus/menu_items',
    params: {},
    id,
  });
};

export const toggleMenuItemActive = (id) => {
  const { TOGGLING_MENU_ITEM_ACTIVE, TOGGLE_MENU_ITEM_ACTIVE_SUCCESS, TOGGLE_MENU_ITEM_ACTIVE_FAILURE } = actionTypes;

  return api.save({
    types: [TOGGLING_MENU_ITEM_ACTIVE, TOGGLE_MENU_ITEM_ACTIVE_SUCCESS, TOGGLE_MENU_ITEM_ACTIVE_FAILURE],
    endpoint: `/menus/menu_items/${id}/toggle_active`,
    params: {},
    forceUpdate: true,
  });
};


export const createEmptyMenuItem = () => (
  { type: actionTypes.CREATE_EMPTY_MENU_ITEM }
);

export const removeNoticeFlash = () => (
  { type: actionTypes.REMOVE_NOTICE_FLASH }
);

export const resetFlash = () => (
  { type: actionTypes.RESET_FLASH }
);

export const addTemporaryCatererMenuItemPhoto = (params) => {
  const response = { params };
  return { type: actionTypes.ADD_TEMPORARY_CATERER_MENU_ITEM_PHOTO, response };
};

export const addTemporaryCatererMenuItemPhotoFailure = (response) => (
  { type: actionTypes.ADD_TEMPORARY_CATERER_MENU_ITEM_PHOTO_FAILURE, response }
);

export const deleteCatererMenuItemPhoto = (details) => {
  const response = { details };
  return { type: actionTypes.DELETE_CATERER_MENU_ITEM_PHOTO, response };
};

export const cropTemporaryCatererMenuItemPhoto = (params) => {
  const response = { params };
  return { type: actionTypes.CROP_TEMPORARY_CATERER_MENU_ITEM_PHOTO, response };
};

export const toggleSelectMenuItem = (response) => (
  { type: actionTypes.TOGGLE_SELECT_MENU_ITEM, response }
);

export const toggleSelectAllMenuItems = () => (
  { type: actionTypes.TOGGLE_SELECT_ALL_MENU_ITEMS }
);

export const cloneMenuItems = (params) => {
  const types = [
    actionTypes.CLONING_MENU_ITEMS,
    actionTypes.CLONE_MENU_ITEMS_SUCCESS,
    actionTypes.CLONE_MENU_ITEMS_FAILURE,
  ];

  return api.save({
    types,
    endpoint: '/menus/menu_items/clone',
    params,
  });
};

export const filterMenuItemsByActive = (response) => (
  { type: actionTypes.FILTER_MENU_ITEMS_BY_ACTIVE, response }
);

export const filterMenuItemsByCategory = (response) => (
  { type: actionTypes.FILTER_MENU_ITEMS_BY_CATEGORY, response }
);

export const addVariation = (response) => (
  { type: actionTypes.ADD_VARIATION, response }
);

export const deleteVariation = (response) => (
  { type: actionTypes.DELETE_VARIATION, response }
);

export const closeInUseMealPlanMessage = () => (
  { type: actionTypes.CLOSE_IN_USE_MEAL_PLAN_MESSAGE }
);
