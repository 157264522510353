/* eslint max-len: 0, no-global-assign: 0, no-restricted-globals: 0 */
/* eslint jsx-a11y/click-events-have-key-events: 0, no-script-url: 0, jsx-a11y/no-static-element-interactions: 0 */

import React, { useState } from 'react';
import { object, bool, func, string } from 'prop-types';
import Select from 'react-select';
import classNames from 'classnames';
import { PlusIcon, ThreeDotsIcon } from 'libs/components/Icons';
import { notBlank } from 'libs/support/string';

const CustomOption = (props) => {
  const {
    children,
    className,
    cx,
    data,
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    innerRef,
    innerProps,
    selectProps,
  } = props;

  const [showDropdown, setShowDropdown] = useState(false);

  const handleOnShowDropdownClick = (evt) => {
    evt.stopPropagation();

    setShowDropdown(prev => !prev);
  };

  const handleOnDeleteClick = (evt) => {
    evt.stopPropagation();

    selectProps.onDelete && selectProps.onDelete(data.uuid); // eslint-disable-line
    setShowDropdown(false);
  };

  return (
    <div
      id={`option-${data.value}`}
      style={getStyles('option', props)}
      className={cx(
        {
          option: true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected,
        },
        className,
      )}
      ref={innerRef}
      aria-disabled={isDisabled}
      {...innerProps}
    >
      <div className="d-flex justify-content-between">
        {
          data.new ?
            <a href="javascript:void(0);">
              <PlusIcon />
              <span className="ml-2">{children}</span>
            </a> :
            <span>{children}</span>
        }

        {
          !data.new && notBlank(data.uuid) &&
            <div className="dropdown mt-2">
              <span
                id={`delete-template-${data.uuid}`}
                style={{ cursor: 'pointer' }}
                onClick={handleOnShowDropdownClick}
              >
                <ThreeDotsIcon />
              </span>

              <div
                className={classNames('dropdown-menu dropdown-menu-right', { show: showDropdown })}
                style={{ marginTop: '-0.5rem', cursor: 'pointer' }}
              >
                <div className="dropdown-item" onClick={handleOnDeleteClick}>
                  Delete
                </div>
              </div>
            </div>
        }
      </div>
    </div>
  );
};

CustomOption.propTypes = {
  children: object,
  className: string,
  cx: object,
  data: object,
  getStyles: func,
  isDisabled: bool,
  isFocused: bool,
  isSelected: bool,
  innerRef: object,
  innerProps: object,
  selectProps: func,
};

const TemplateSelect = (props) => (<Select {...props} components={{ Option: CustomOption }} />);
export default TemplateSelect;
