import * as api from 'libs/middlewares/api';
import * as actionTypes from '../constants/orderNoticesConstants';

export const fetchOrderNotices = () => {
  const types = [
    actionTypes.FETCHING_ORDER_NOTICES,
    actionTypes.FETCH_ORDER_NOTICES_SUCCESS,
    actionTypes.FETCH_ORDER_NOTICES_FAILURE,
  ];

  return api.get({
    types,
    endpoint: '/menus/order_notices',
    params: {},
  });
};
