/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { InputField } from 'libs/components/Forms';
import { SaveButton } from 'bundles/common/components/Buttons';
import { array, func, object, string } from 'prop-types';
import { Creatable } from 'react-select';
import { customStyles } from 'bundles/v2/orders/utils/driverSelectCustomStyle';

const DriverForm = ({
  onClickSaveVendorDriver,
  vendorDrivers,
  translate,
  selectedDriver,
  errors,
}) => {
  const [driverOption, setDriverOption] = useState(null);
  const driversOptions = vendorDrivers
                          ?.map((driver) => ({
                            value: driver.uuid,
                            label: driver.name,
                          }))
                          ?.sort((a, b) => a.label?.localeCompare(b.label, undefined, { sensitivity: 'base' }));

  useEffect(() => {
    const selectedDriverOption = driversOptions?.find((option) => option.value === selectedDriver?.uuid);
    setDriverOption(selectedDriverOption);
  }, [vendorDrivers]);

  const onSelectDriver = (selectedOption, actionMeta) => {
    const driver = vendorDrivers.find((d) => d.uuid === selectedOption?.value);
    const phoneNumber = driver?.phoneNumber || '';
    const driverNumberInput = document.getElementById('order_driver_phone');
    driverNumberInput.value = phoneNumber;
    if (phoneNumber === '' && actionMeta.action !== 'create-option') {
      driverNumberInput.disabled = true;
    } else {
      driverNumberInput.disabled = false;
    }
    setDriverOption(selectedOption);
  };

  useEffect(() => {
    if (errors) {
      setTimeout(() => {
        const successAlert = document.getElementById('success-alert');
        if (successAlert) {
          successAlert.style.display = 'none';
        }
      }, 3000);
    }
  }, [errors]);

  return (
    <div className="row mt-2 d-flex align-items-end tw-flex-col">
      {errors && errors.length > 0 && (
        <div
          id="success-alert-form"
          className="tw-border-red tw-bg-red-light-6 tw-flex tw-w-full tw-rounded-lg tw-border-l-[6px] tw-px-7 tw-py-4 tw-shadow-[0px_2px_10px_0px_rgba(0,0,0,0.08)] md:tw-p-4 tw-mb-4"
        >
          <div
            className="tw-bg-red tw-mr-5 tw-flex tw-h-[34px] tw-w-full tw-max-w-[34px] tw-items-center tw-justify-center tw-rounded-lg"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_961_15645)">
                <path
                  d="M9 0.506256C4.30313 0.506256 0.50625 4.30313 0.50625 9.00001C0.50625 13.6969 4.30313 17.5219 9 17.5219C13.6969 17.5219 17.5219 13.6969 17.5219 9.00001C17.5219 4.30313 13.6969 0.506256 9 0.506256ZM9 16.2563C5.00625 16.2563 1.77188 12.9938 1.77188 9.00001C1.77188 5.00626 5.00625 1.77188 9 1.77188C12.9938 1.77188 16.2563 5.03438 16.2563 9.02813C16.2563 12.9938 12.9938 16.2563 9 16.2563Z"
                  fill="white"
                />
                <path
                  d="M11.5875 6.38438C11.3344 6.13125 10.9406 6.13125 10.6875 6.38438L9 8.1L7.28438 6.38438C7.03125 6.13125 6.6375 6.13125 6.38438 6.38438C6.13125 6.6375 6.13125 7.03125 6.38438 7.28438L8.1 9L6.38438 10.7156C6.13125 10.9688 6.13125 11.3625 6.38438 11.6156C6.49688 11.7281 6.66563 11.8125 6.83438 11.8125C7.00313 11.8125 7.17188 11.7563 7.28438 11.6156L9 9.9L10.7156 11.6156C10.8281 11.7281 10.9969 11.8125 11.1656 11.8125C11.3344 11.8125 11.5031 11.7563 11.6156 11.6156C11.8688 11.3625 11.8688 10.9688 11.6156 10.7156L9.9 9L11.6156 7.28438C11.8406 7.03125 11.8406 6.6375 11.5875 6.38438Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_961_15645">
                  <rect width="18" height="18" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="tw-w-full">
            <ul className="tw-list-inside tw-list-disc">
              <li className="ttw-ext-red-light tw-text-base tw-leading-relaxed">
                {errors}
              </li>
            </ul>
          </div>
        </div>
      )}
      <form
        key="driver_form"
        id="driver_form"
        className="tw-w-full"
      >
        <div className="col-md-5">
          <div className="order-details-group">
            <span className="order-details-title">{translate('ordersShowDetailsDriver')}</span>
            <span className="order-details-value order-details-address">
              <Creatable
                isClearable
                options={driversOptions}
                name="order_driver_id"
                id="order_driver_id"
                styles={customStyles}
                onChange={onSelectDriver}
                value={driverOption}
              />
            </span>
          </div>
        </div>
        <div className="col-md-5">
          <div className="order-details-group">
            <span className="order-details-title">{translate('ordersShowDetailsPhone')}</span>
            <span className="order-details-value">
              <InputField
                type="tel"
                pattern="[0-9]{4} [0-9]{4}"
                id="order_driver_phone"
                name="order_driver_phone"
                value={selectedDriver?.phoneNumber}
                inputStyle={{ height: '37px' }}
              />
            </span>
          </div>
        </div>
        <div className="col-md-2 save-driver-button tw-mt-4">
          <SaveButton
            style={{ marginTop: 'auto' }}
            onClick={onClickSaveVendorDriver}
          />
        </div>
      </form>
    </div>
  );
};

DriverForm.propTypes = {
  onClickSaveVendorDriver: func,
  translate: func,
  vendorDrivers: array,
  selectedDriver: object,
  errors: string,
};

export default DriverForm;
