import React, { useEffect, useState } from 'react';
import { bool, func, object, string, number } from 'prop-types';
import { connect } from 'react-redux';
import uppercamelcase from 'uppercamelcase';
import {
  SelectField,
} from 'libs/components/Forms';
import isEqual from 'lodash/isEqual';
import { withI18n } from 'libs/support/i18n';
import {
  buildProps,
  filterOptions,
  buildOptions,
  buildInputAttrs,
} from './helpers/TagsFieldGroupHelpers';

const TagsFieldGroup = ({
  filtersStore,
  isCreatable = false,
  isRequired,
  kind,
  limit,
  menuItem,
  label: customLabel,
  optionalText,
  translate,
}) => {
  const {
    initialSelectedOptions,
    selectOptions,
  } = buildOptions(menuItem, filtersStore, kind);
  const [isInitialized, setInitialized] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(initialSelectedOptions);
  const [changeContext, setChangeContext] = useState({});
  const handleOptionChange = (options, context) => {
    setChangeContext(context);
    setSelectedOptions(options);
  };
  const {
    filteredOptions,
    noOptionsMessage,
  } = filterOptions(selectOptions, selectedOptions, limit);
  const [availableOptions, setAvailableOptions] = useState(filteredOptions);
  const {
    className,
    hintText,
    inputId,
    inputName,
    label,
  } = buildInputAttrs(kind, limit);

  const resolvedLabel = customLabel ||
    translate(`vendorTags${uppercamelcase(kind)}`) ||
    label;

  useEffect(() => {
    if (!isInitialized && selectedOptions.length === 0 && initialSelectedOptions.length > 0) {
      setInitialized(true);
      setSelectedOptions(initialSelectedOptions);
    }
  });

  useEffect(() => {
    const {
      initialSelectedOptions: updatedSelectedOptions,
      selectOptions: updatedSelectOptions,
    } = buildOptions(menuItem, filtersStore, kind);

    const {
      filteredOptions: updatedFilteredOptions,
    } = filterOptions(updatedSelectOptions, selectedOptions, limit);

    if (!isEqual(availableOptions, updatedFilteredOptions)) {
      setAvailableOptions(updatedFilteredOptions);

      if (
        (!isEqual(selectedOptions, updatedSelectedOptions) && changeContext.action !== 'remove-value') &&
        updatedFilteredOptions.size > 0
      ) {
        setSelectedOptions(updatedSelectedOptions);
      }
    }
  }, [menuItem, filtersStore, selectedOptions]);

  return (
    <SelectField
      fieldGroupClassName={className}
      key={inputId}
      id={inputId}
      name={inputName}
      value={selectedOptions}
      options={availableOptions}
      onChange={handleOptionChange}
      optionalText={optionalText}
      noOptionsMessage={noOptionsMessage}
      hintText={hintText}
      isMulti
      isCreatable={isCreatable}
      isRequired={isRequired}
      label={resolvedLabel}
    />
  );
};

TagsFieldGroup.propTypes = {
  filtersStore: object.isRequired,
  kind: string.isRequired,
  label: string,
  limit: number,
  menuItem: object.isRequired,
  optionalText: string,
  isRequired: bool,
  isCreatable: bool,
  translate: func.isRequired,
};

const mapStateToProps = (state) => (buildProps(state));

export default connect(mapStateToProps)(withI18n(TagsFieldGroup));
