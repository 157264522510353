import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  color: ${props => props.theme.colors.blackRussian};
  font-family: ${props => props.theme.fonts.geomanistbook};
  font-size: 17px;
  line-height: 21px;
  margin-bottom: 1rem;
`;


const SectionTitle = ({ children }) => (
  <Container>{children}</Container>
);

SectionTitle.propTypes = {
  children: string.isRequired,
};

export default SectionTitle;
