/* eslint camelcase: 0 */

import Immutable from 'immutable';
import * as actionTypes from '../constants/baseDeliveryFeesConstants';

export const $$initialState = Immutable.fromJS({
  isBaseDeliveryFeesFetched: false,
  baseDeliveryFee: Immutable.fromJS({}),
  baseDeliveryFees: Immutable.fromJS([]),
});

export default function baseDeliveryFeesReducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  switch (type) {
    case actionTypes.FETCH_BASE_DELIVERY_FEES_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .set('isBaseDeliveryFeesFetched', true)
          .set('baseDeliveryFees', Immutable.fromJS(response.base_delivery_fees))
      ));
    }

    default: {
      return $$state;
    }
  }
}
