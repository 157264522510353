/* eslint max-len: 0 */

import React, { Fragment } from 'react';
import { object, string, bool } from 'prop-types';

import { notBlank } from 'libs/support/string';
import isForWideView from 'bundles/orders/support/isForWideView';
import classNames from 'classnames';

import HistoryOrderOption from './HistoryOrderOption';

const HistoryOrderVariation = ({ item, type, after = false }) => {
  const showChanged = isForWideView(type) && after &&
                      notBlank(item.get('option_choices')) &&
                      notBlank(item.get('item_add_on_options')) &&
                      notBlank(item.get('service_add_on_options'));

  return (
    <div className={isForWideView(type) ? 'col-md-12 history-with-diff-card-body-container' : 'col-md-5 order-diff__table-content--with-bg'}>
      <div className="order-diff__table-content">
        <div className="row no-gutters diff-order-title">
          <div
            className={classNames('font-weight-bold', {
              changed: showChanged,
              'col-md-1 col-xs-6': isForWideView(type),
              'col-md-2 col-xs-6': !isForWideView(type),
              })
            }
          >
            {item.get('quantity')}x
          </div>
          <div
            className={classNames('font-weight-bold', {
              changed: showChanged,
              'col-md-11': isForWideView(type),
              'col-md-10': !isForWideView(type),
              })
            }
          >
            {item.get('name')}
          </div>
        </div>

        {
          <HistoryOrderOption
            options={item.get('option_choices')}
            title="Options"
            type={type}
          />
        }

        {
          <HistoryOrderOption
            options={item.get('item_add_on_options')}
            title="Options"
            type={type}
          />
        }

        {
          <HistoryOrderOption
            options={item.get('service_add_on_options')}
            title="Service add-ons"
            type={type}
          />
        }

        {
          notBlank(item.get('additional_instructions')) &&
            <Fragment>
              <div className="row mt-1 no-gutters">
                <div className={isForWideView(type) ? 'font-weight-bold offset-md-1 col-md-11' : 'font-weight-bold offset-md-2 col-md-10'}>
                  Additional instructions:
                </div>
              </div>

              <div className="row no-gutters">
                <div className={isForWideView(type) ? 'additional-instructions-text offset-md-1 col-md-11' : 'additional-instructions-text offset-md-2 col-md-10'}>
                  {item.get('additional_instructions')}
                </div>
              </div>
            </Fragment>
        }
      </div>
    </div>
  );
};

HistoryOrderVariation.propTypes = {
  item: object.isRequired,
  type: string,
  after: bool,
};

export default HistoryOrderVariation;
