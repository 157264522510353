import React, { Fragment, useEffect } from 'react';
import { bool, func, object } from 'prop-types';
import { connect } from 'react-redux';
import { withI18n } from 'libs/support/i18n';
import { withAppContext } from 'libs/support/appContext';
import Immutable from 'immutable';
import uppercamelcase from 'uppercamelcase';
import { bindActionCreators } from 'redux';
import * as categoriesActions from 'bundles/vendor/actions/categoriesActions';
import * as filtersActions from 'bundles/vendor/actions/filtersActions';
import { Separator } from 'libs/support/sharedComponents';
import {
  FieldGroup,
  InputField,
  SelectField,
  TextField,
  TextAreaField,
  ToggleField,
} from 'libs/components/Forms';
import { useSelectInput } from 'libs/support/selectInput';
import CategoryAvailabilitiesList from './CategoryAvailabilitiesList';
import ClassificationTags from './ClassificationTags';

export const DELIVERY_TYPES = [
  'caterspot',
  'own',
];

const FOOD_ATTRIBUTE_FORMATS = {
  catering: 'Format',
  pantry: 'Pantry Delivery Format',
  gifts: 'gifts',
};

const FieldSets = ({
  baseDeliveryFees,
  courses,
  category,
  caterer,
  fetchFilters,
  isFiltersFetched,
  orderNotices,
  presentations,
  occasions,
  foodTypes,
  foodAttributes,
  flagIsEnabled,
  translate,
}) => {
  useEffect(() => {
    if (!isFiltersFetched && caterer.size > 0) {
      fetchFilters(caterer.get('kind'));
    }
  }, []);

  const deliveryTypeOptions = DELIVERY_TYPES.map(type => (
    {
      label: translate(`vendorDeliveryTypesSelections${uppercamelcase(type)}`),
      value: type,
    }
  ));
  const [selectedDeliveryTypeOption, handleDeliveryTypeChange] = useSelectInput(
    deliveryTypeOptions,
    category.get('delivery_type') || caterer.get('delivery_type'),
  );

  const uuid = category.get('uuid');

  const categoryBaseDeliveryFee = (
    uuid ?
      category.get('base_delivery_fees').find((baseDeliveryFee) => baseDeliveryFee.get('fee_type') === 'single') :
      baseDeliveryFees.find((baseDeliveryFee) => (
        baseDeliveryFee.get('default') === true && baseDeliveryFee.get('fee_type') === 'single'
      ))
  ) || Immutable.fromJS({});

  const deliveryFeeAmount = categoryBaseDeliveryFee.getIn(['delivery_fees', 0, 'amount']);

  const baseDeliveryFeeDisplay = deliveryFeeAmount ?
    `$${parseFloat(deliveryFeeAmount).toFixed(2)} for single location` :
    'N/A';

  const categoryOrderNotice = (uuid ?
    category.get('order_notice') :
    orderNotices.find((orderNotice) => orderNotice.get('default') === true)) ||
    Immutable.fromJS({});

  const filterBy = (name) => (item) => item.get('category_name') === name;

  const catererKind = caterer.get('kind');

  return (
    <Fragment>
      <FieldGroup
        label="Category Name"
        htmlFor="category_name"
      >
        <InputField
          id="category_name"
          isRequired
          name="category[name]"
          value={category.get('name')}
        />
      </FieldGroup>

      <FieldGroup
        label="Description"
        htmlFor="category_description"
      >
        <TextAreaField
          id="category_description"
          name="category[description]"
          value={category.get('description')}
        />
      </FieldGroup>

      <FieldGroup
        label="Delivery Type"
        htmlFor="category_delivery_type"
      >
        <SelectField
          id="category_delivery_type"
          name="category[delivery_type]"
          value={[selectedDeliveryTypeOption]}
          options={deliveryTypeOptions}
          onChange={handleDeliveryTypeChange}
          isSearchable={false}
          menuIsOpen={false}
          isRequired
        />
      </FieldGroup>

      <FieldGroup
        label="Delivery Fee"
        htmlFor="category_base_delivery_fee"
        hintText="(GST excl.)"
        isInlineHint
      >
        <TextField
          value={baseDeliveryFeeDisplay}
          hint={translate('vendorMessagesContactCaterspotToChange')}
        />
      </FieldGroup>

      <FieldGroup
        label="Order Notice"
        htmlFor="category_order_notice"
      >
        <TextField
          value={categoryOrderNotice.get('title') || 'N/A'}
          hint={translate('vendorMessagesContactCaterspotToChange')}
        />
      </FieldGroup>

      <FieldGroup
        label="Home Delivery"
        htmlFor="category_home_delivery"
      >
        <ToggleField
          id="category_home_delivery"
          defaultChecked={category.get('home_delivery') || false}
          name="category[home_delivery]"
        />
      </FieldGroup>

      {
        flagIsEnabled('category_classfication_tags_enabled') &&
          <Fragment>
            <Separator />

            <ClassificationTags
              courses={courses.filter(filterBy(catererKind))}
              presentations={presentations.filter(filterBy(catererKind))}
              occasions={occasions.filter(filterBy(catererKind))}
              foodAttributes={foodAttributes.filter(filterBy(FOOD_ATTRIBUTE_FORMATS[catererKind]))}
              foodTypes={catererKind === 'catering' ? foodTypes : null}
              categoryTags={category.get('category_tags')}
            />
          </Fragment>
      }

      <Separator />

      <CategoryAvailabilitiesList />
    </Fragment>
  );
};

FieldSets.propTypes = {
  baseDeliveryFees: object,
  category: object.isRequired,
  caterer: object.isRequired,
  courses: object.isRequired,
  fetchFilters: func.isRequired,
  isFiltersFetched: bool.isRequired,
  orderNotices: object,
  presentations: object,
  occasions: object,
  foodTypes: object,
  foodAttributes: object,
  flagIsEnabled: func.isRequired,
  translate: func.isRequired,
};

const mapStateToProps = ({
  $$baseDeliveryFeesStore,
  $$catererStore,
  $$categoriesStore,
  $$orderNoticesStore,
  $$filtersStore,
}) => ({
  baseDeliveryFees: $$baseDeliveryFeesStore.get('baseDeliveryFees'),
  category: $$categoriesStore.get('category'),
  caterer: $$catererStore.get('caterer'),
  isFiltersFetched: $$filtersStore.get('isFiltersFetched'),
  orderNotices: $$orderNoticesStore.get('orderNotices'),
  courses: $$filtersStore.get('courses'),
  presentations: $$filtersStore.get('presentations'),
  occasions: $$filtersStore.get('occasions'),
  foodTypes: $$filtersStore.get('foodTypes'),
  foodAttributes: $$filtersStore.get('foodAttributes'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  saveCategory: categoriesActions.saveCategory,
  fetchFilters: filtersActions.fetchFilters,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withAppContext(withI18n(FieldSets)));
