/* eslint camelcase: 0 */

import Immutable from 'immutable';

import { getIndex } from 'libs/support/findUtil';

import * as actionTypes from '../constants/adminsConstants';

export const $$initialState = Immutable.fromJS({
  isFinding: false,
  isFindSuccess: false,
  isSaving: false,
  isSaveSuccess: false,
  isDeleting: false,
  isDeleteSuccess: false,
  showForm: false,
  admins: [],
  errors: [],
  admin: {},
});

export default function adminsReducer($$state = $$initialState, action = null) {
  const { type, response } = action;
  const defaultHash = {
    isSaving: false,
    isSaveSuccess: false,
    isDeleting: false,
    isDeleteSuccess: false,
    errors: [],
  };

  switch (type) {
    case actionTypes.HIDE_VENDOR_ADMIN_FORM: {
      return $$state.withMutations(state => (
        state
          .merge(defaultHash)
          .set('showForm', false)
      ));
    }

    case actionTypes.SHOW_VENDOR_ADMIN_FORM: {
      return $$state.withMutations(state => (
        state
          .merge(defaultHash)
          .set('admin', Immutable.fromJS({}))
          .set('showForm', true)
      ));
    }

    case actionTypes.SELECT_VENDOR_ADMIN: {
      return $$state.withMutations(state => (
        state
          .merge(defaultHash)
          .set('admin', response)
          .set('showForm', true)
      ));
    }

    case actionTypes.FINDING_VENDOR_ADMINS: {
      return $$state.withMutations(state => (
        state
          .merge(defaultHash)
          .set('isFinding', true)
      ));
    }

    case actionTypes.FIND_VENDOR_ADMINS_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .update('vendorAdmins', () => Immutable.fromJS(response.vendor_admins))
          .merge(defaultHash)
          .set('isFinding', false)
          .set('isFindSuccess', true)
      ));
    }

    case actionTypes.SAVING_VENDOR_ADMIN: {
      return $$state.withMutations(state => (
        state
          .merge(defaultHash)
          .set('isSaving', true)
      ));
    }

    case actionTypes.DELETE_VENDOR_ADMIN_FAILURE:
    case actionTypes.SAVE_VENDOR_ADMIN_FAILURE: {
      return $$state.withMutations(state => (
        state
          .merge(defaultHash)
          .set('errors', Immutable.fromJS(response.errors))
      ));
    }

    case actionTypes.SAVE_VENDOR_ADMIN_SUCCESS: {
      const { vendor_admin } = response;
      const newData = Immutable.fromJS(vendor_admin);

      return $$state.withMutations(state => (
        state
          .update('vendorAdmins', data => {
            const index = getIndex(vendor_admin.uuid, data);

            if (index > -1) return data.update(index, () => newData);

            return data.push(newData);
          })
          .set('admin', newData)
          .merge(defaultHash)
          .set('isSaveSuccess', true)
      ));
    }

    case actionTypes.DELETING_VENDOR_ADMIN: {
      return $$state.withMutations(state => (
        state
          .merge(defaultHash)
          .set('isDeleting', true)
      ));
    }

    case actionTypes.DELETE_VENDOR_ADMIN_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .update('vendorAdmins', data => {
            const index = getIndex(response.vendor_admin.uuid, data);
            return data.delete(index);
          })
          .merge(defaultHash)
          .set('admin', Immutable.fromJS({}))
          .set('isDeleteSuccess', true)
          .set('showForm', false)
      ));
    }

    default: {
      return $$state;
    }
  }
}
