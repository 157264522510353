import { useCallback, useState, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

export const useDragAndDropList = (categories, onDrop) => {
  const [rows, setRows] = useState(categories);

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setRows(rows.withMutations((list) => {
      const temp = list.get(dragIndex);
      return list.set(dragIndex, list.get(hoverIndex)).set(hoverIndex, temp);
    }));
  }, [rows]);

  const didDrop = useCallback(() => {
    const categoriesParams = rows.map((row, index) => ({
      uuid: row.get('uuid'),
      name: row.get('name'),
      ordinal_value: index,
    }));
    const params = { categories: categoriesParams };

    onDrop(params);
  });

  return [rows, moveCard, didDrop];
};

export const useDragAndDropRow = (name, id, index, didDrop, moveCard) => {
  const ref = useRef(null);

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: name, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: name,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current && ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex; /* eslint no-param-reassign: 0 */
    },
    drop(_item, _monitor) {
      didDrop();
    },
  });

  drag(drop(ref));

  const styles = {
    opacity: (isDragging ? 0 : 1),
  };

  return [ref, preview, styles];
};

