/* eslint max-len: 0 */

import React from 'react';

const ChevronRight = (_props) => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.6358 18.4623C10.3011 18.1276 10.3011 17.5849 10.6358 17.2501L15.1726 12.7134L10.6358 8.17661C10.3011 7.84188 10.3011 7.29917 10.6358 6.96443C10.9706 6.6297 11.5133 6.62969 11.848 6.96443L16.9909 12.1073C17.3256 12.442 17.3256 12.9847 16.9909 13.3195L11.848 18.4623C11.5133 18.7971 10.9706 18.7971 10.6358 18.4623Z" fill="#95989D" stroke="#95989D" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
export default ChevronRight;
