import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { BackIcon } from 'libs/components/Icons';
import { withI18n } from 'libs/support/i18n';
import { SaveButton } from 'bundles/common/components/Buttons';
import {
  ActionBar,
  ActionBarLeftSection,
  ActionBarRightSection,
  BackButton,
  PrimaryTitle,
} from 'libs/support/sharedComponents';
import { bindActionCreators } from 'redux';
import * as menuItemsActions from 'bundles/vendor/actions/menuItemsActions';
import styled from 'styled-components';
import { trackClickedSaveForAddItem, trackClickedSaveForEditItem } from 'packs/events';
import DeleteMenuItemButton from './DeleteMenuItemButton';

const BackLink = styled(Link)`
  &[disabled] {
    pointer-events: none;
  }
`;

const Actions = ({
  isMenuItemSaving,
  menuItemId,
  menuItemInUseMealPlans,
  metadata,
  resetFlash,
  translate,
}) => {
  const handleSave = () => {
    if (menuItemId) {
      trackClickedSaveForEditItem();
    } else {
      trackClickedSaveForAddItem();
    }

    document
      .getElementById('menu_item_form')
      .querySelector('input[type="submit"]')
      .click();
  };

  const handleBack = (e) => {
    if (isMenuItemSaving) {
      e.preventDefault();
    } else {
      resetFlash();
    }
  };

  const titleKey = menuItemId ? 'vendorEditItem' : 'vendorAddItem';
  const url = new URL(window.location);

  if (metadata && metadata.get('filter_active')) {
    url.searchParams.set('filter_active', metadata.get('filter_active'));
  }

  if (metadata && metadata.get('filter_category_id')) {
    url.searchParams.set('filter_category_id', metadata.get('filter_category_id'));
  }

  if (metadata && metadata.get('page')) {
    url.searchParams.set('page', metadata.get('page'));
  }

  if (metadata && metadata.get('keyword')) {
    url.searchParams.set('keyword', metadata.get('keyword'));
  }

  return (
    <ActionBar className="item-actions">
      <ActionBarLeftSection>
        <BackButton>
          <BackLink
            className="back-link"
            to={`/menus/items${url.search || ''}`}
            onClick={handleBack}
            disabled={isMenuItemSaving}
          >
            <BackIcon />
          </BackLink>
        </BackButton>
        <PrimaryTitle>
          { translate(titleKey) }
        </PrimaryTitle>
      </ActionBarLeftSection>
      {
        menuItemInUseMealPlans.size === 0 &&
          <ActionBarRightSection>
            <DeleteMenuItemButton />
            <SaveButton isSaving={isMenuItemSaving} onClick={handleSave} />
          </ActionBarRightSection>
      }
    </ActionBar>
  );
};

Actions.propTypes = {
  isMenuItemSaving: bool.isRequired,
  metadata: object,
  menuItemId: string,
  menuItemInUseMealPlans: object,
  resetFlash: func.isRequired,
  translate: func.isRequired,
};

const mapStateToProps = (state) => ({
  menuItemId: state.$$menuItemsStore.getIn(['menuItem', 'uuid']),
  menuItemInUseMealPlans: state.$$menuItemsStore.getIn(['menuItem', 'in_use_meal_plans']),
  isMenuItemSaving: state.$$menuItemsStore.get('isMenuItemSaving'),
  metadata: state.$$menuItemsStore.get('metadata'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  resetFlash: menuItemsActions.resetFlash,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withI18n(Actions));
