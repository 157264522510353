import React, { useState, Fragment } from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import {
  DropdownActions,
} from 'libs/components/Forms';
import ExportModal from 'bundles/vendor/components/Items/ExportModal';
import ImportModal from 'bundles/vendor/components/Items/ImportModal';
import ExportDropdownAction from './ExportDropdownAction';
import ImportDropdownAction from './ImportDropdownAction';

const MobileDropdown = ({
  selectedIds,
}) => {
  const [isShowExportModal, setShowExportModal] = useState(false);
  const [isShowImportModal, setShowImportModal] = useState(false);
  const handleHideExport = () => setShowExportModal(false);
  const handleHideImport = () => setShowImportModal(false);
  const handleExportClick = () => setShowExportModal(true);
  const handleImportClick = () => setShowImportModal(true);

  return (
    <Fragment>
      <DropdownActions>
        {
          selectedIds.size === 0 &&
            <ImportDropdownAction onClick={handleImportClick} />
        }
        <ExportDropdownAction onClick={handleExportClick} />
      </DropdownActions>

      <ImportModal
        isShow={isShowImportModal}
        onHide={handleHideImport}
      />
      <ExportModal
        isShow={isShowExportModal}
        onHide={handleHideExport}
      />
    </Fragment>
  );
};

MobileDropdown.propTypes = {
  selectedIds: object,
};

const mapStateToProps = ({ $$menuItemsStore }) => ({
  selectedIds: $$menuItemsStore.get('selectedIds'),
});

export default connect(mapStateToProps)(MobileDropdown);
