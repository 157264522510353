import React from 'react';
import { bool, func, string } from 'prop-types';
import styled from 'styled-components';
import { SecondaryButton } from 'libs/support/sharedComponents';
import { TrashIcon } from 'libs/components/Icons';

const Button = styled(SecondaryButton)`
  padding: 0.43rem 0.5rem;
  height: 32px;
`;

const DeleteButton = ({
  className = '',
  isDeleting,
  isDisabled,
  onClick,
}) => {
  const handleOnClick = (e) => {
    e.preventDefault();

    return onClick && onClick();
  };

  return (
    <Button
      className={className}
      onClick={handleOnClick}
      disabled={isDeleting || isDisabled}
    >
      {
        isDeleting ?
          <i className="fa fa-refresh fa-spin a-fw" /> :
          <TrashIcon />
      }
    </Button>
  );
};

DeleteButton.propTypes = {
  className: string,
  isDeleting: bool,
  isDisabled: bool,
  onClick: func,
};

export default DeleteButton;
