import requestsManager from 'libs/middlewares/requestsManager';

/**
 * API to create temp file
 * POST /temp_files
 */
export const createTempFile = (dataForm, successCallback, failedCallback) => {
  const endpoint = '/temp_files';

  return requestsManager.createMultiPart(dataForm, endpoint)
    .then((res) => {
      if (successCallback) successCallback(res.data);
    })
    .catch((res) => {
      if (failedCallback) failedCallback(res.response.data);
    });
};
