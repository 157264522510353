import React from 'react';
import { func, number, object } from 'prop-types';
import uppercamelcase from 'uppercamelcase';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { withI18n } from 'libs/support/i18n';
import { usePolicies } from 'bundles/common/queries/policies';
import moment from 'moment';
import { useDragAndDropRow } from 'libs/support/dragAndDrop';
import {
  Row,
  Cell,
} from 'libs/support/sharedComponents';
import { HandleIcon } from 'libs/components/Icons';
import ActiveToggle from './ActiveToggle';

const BodyRow = styled(Row)`
  height: 40px;
  vertical-align: top;
`;

const Availability = styled.div`
  white-space: nowrap;
`;

const HandleContainer = styled.div`
  align-items: center;
  display: flex;
  height: 23px;
`;

const Category = ({
  category,
  didDrop,
  index,
  moveCard,
  translate,
}) => {
  const policiesResponse = usePolicies();
  const { policies } = policiesResponse.isSuccess ? policiesResponse.data : {};
  const id = category.get('uuid');
  const isEditDisabled = !policies?.category?.update;

  const [ref, preview, styles] = useDragAndDropRow(
    'Category',
    id,
    index,
    didDrop,
    moveCard,
  );

  const dragCursor = isEditDisabled ? 'not-allowed' : 'grab';
  const dragRef = isEditDisabled ? null : ref;

  return (
    <BodyRow
      ref={preview}
      style={styles}
    >
      <Cell
        className="drag-cell"
        ref={dragRef}
        style={{ cursor: dragCursor }}
      >
        <HandleContainer><HandleIcon /></HandleContainer>
      </Cell>
      <Cell>
        <ActiveToggle category={category} />
      </Cell>
      <Cell>
        { !isEditDisabled ?
          <Link to={`/menus/categories/${category.get('uuid')}/edit`}>
            { category.get('name') }
          </Link> : category.get('name')
        }
      </Cell>
      <Cell>{ category.get('description') }</Cell>
      <Cell>{ translate(`vendorDeliveryTypes${uppercamelcase(category.get('delivery_type'))}`) }</Cell>
      <Cell>
        {
          category.get('category_availabilities').map((availability) => {
            const type = translate(`vendorCategoryAvailabilities${uppercamelcase(availability.get('availability_type'))}`);
            const startAt = moment(availability.get('start_date')).format('MMM D YYYY');
            const endAt = moment(availability.get('end_date')).format('MMM D YYYY');

            return (
              <Availability
                key={`availability_${availability.get('uuid')}`}
              >
                { `${type} ${startAt} - ${endAt}` }
              </Availability>
            );
          })
        }
      </Cell>
      <Cell>
        {
          // eslint-disable-next-line
          !isNaN(parseFloat(category.getIn(['base_delivery_fees', 0, 'delivery_fees', 0, 'amount']))) &&
            parseFloat(category.getIn(['base_delivery_fees', 0, 'delivery_fees', 0, 'amount'])).toFixed(2)
        }
      </Cell>
      <Cell>
        {
          category.getIn(['order_notice', 'hours']) &&
            translate('vendorHours', { number: category.getIn(['order_notice', 'hours']) })
        }
      </Cell>
    </BodyRow>
  );
};

Category.propTypes = {
  category: object,
  didDrop: func.isRequired,
  index: number,
  moveCard: func.isRequired,
  translate: func.isRequired,
};

export default withI18n(Category);
