import React from 'react';
import { object } from 'prop-types';
import Immutable from 'immutable';

import {
  Spacer,
} from 'libs/support/sharedComponents';
import {
  SectionDescription,
  SectionTitle,
} from 'bundles/vendor/components/Shared';

import Tag from './Tag';

const COURSE_TAG = 'Course';
const PRESENTATION_TAG = 'Presentation';
const FOOD_TYPE_TAG = 'FoodType';
const FOOD_ATTRIBUTE_TAG = 'FoodAttribute';
const OCCASION_TAG = 'Occasion';

const filterBy = (name) => (item) => item.get('taggable_type') === name;

const ClassificationTags = ({
  categoryTags,
  courses,
  foodAttributes,
  foodTypes,
  presentations,
  occasions,
}) => (
  <div>
    <SectionTitle>
      Classification Tags
    </SectionTitle>
    <SectionDescription>
      All menu items under this category will be tagged with the selected tags below automatically.
    </SectionDescription>

    <Spacer />

    <Tag
      tags={presentations}
      categoryTags={(categoryTags || Immutable.fromJS([])).filter(filterBy(PRESENTATION_TAG))}
      type={PRESENTATION_TAG}
      limit={2}
    />

    <Tag
      tags={occasions}
      categoryTags={(categoryTags || Immutable.fromJS([])).filter(filterBy(OCCASION_TAG))}
      type={OCCASION_TAG}
      limit={2}
    />

    <Tag
      label="Format"
      tags={foodAttributes}
      categoryTags={(categoryTags || Immutable.fromJS([])).filter(filterBy(FOOD_ATTRIBUTE_TAG))}
      type={FOOD_ATTRIBUTE_TAG}
      limit={1}
    />

    <Tag
      tags={courses}
      categoryTags={(categoryTags || Immutable.fromJS([])).filter(filterBy(COURSE_TAG))}
      type={COURSE_TAG}
      limit={1}
    />

    <Tag
      tags={foodTypes}
      categoryTags={(categoryTags || Immutable.fromJS([])).filter(filterBy(FOOD_TYPE_TAG))}
      type={FOOD_TYPE_TAG}
      limit={4}
    />
  </div>
);

ClassificationTags.propTypes = {
  categoryTags: object,
  courses: object,
  foodAttributes: object,
  foodTypes: object,
  presentations: object,
  occasions: object,
};

export default ClassificationTags;
