import metaTagsManager from 'libs/middlewares/metaTagsManager';

const options = () => (
  {
    margin: [-5, 0, 0, 0],
    pagebreak: { mode: [] },
    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
  }
);

const combineElements = (elements) => {
  const element = document.createElement('div');

  elements.forEach((el) => {
    element.appendChild(el.cloneNode(true));
  });

  return element;
};

const injectHtml2Pdf = () => {
  const script = document.createElement('script');
  script.type = 'application/javascript';
  script.src = 'https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.10.1/html2pdf.bundle.min.js';
  document.head.appendChild(script);
};

const isHtml2PdfAvailable = () => (
  typeof html2pdf === 'function'
);

const getToken = () => (
  metaTagsManager.getCSRFToken()
);

const getHeaders = () => (
  {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-Token': getToken(),
  }
);

const createPdf = async (base64Data) => {
  try {
    const response = await fetch('/blobs', {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({ data: base64Data }),
    });

    if (response.ok) {
      const downloadUrl = await response.text();
      // window.open does not work on iOS web view
      window.location.href = downloadUrl;
    } else {
      /* eslint-disable-next-line no-console */
      console.error('Request failed:', response.statusText);
    }
  } catch (error) {
    /* eslint-disable-next-line no-console */
    console.error('Error:', error.message);
  }
};

const renderPdf = async (elements) => {
  try {
    /* global html2pdf */
    const pdf = await html2pdf()
      .set(options())
      .from(combineElements(elements))
      .toPdf()
      .get('pdf');

    const blob = pdf.output('blob');
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = () => {
        createPdf(reader.result)
          .then(resolve)
          .catch(reject);
      };

      reader.readAsDataURL(blob);
    });
  } catch (error) {
    throw error;
  }
};

export const downloadAsPdf = async (elements, attempt = 1) => {
  if (attempt > 10) {
    throw new Error('Exceeded maximum attempts');
  }

  if (isHtml2PdfAvailable()) {
    try {
      return await renderPdf(elements);
    } catch (error) {
      throw error;
    }
  } else {
    injectHtml2Pdf();

    await new Promise(resolve => setTimeout(resolve, 100));

    return downloadAsPdf(elements, attempt + 1);
  }
};
