import * as api from 'libs/middlewares/api';
import * as actionTypes from '../constants/serviceAddOnsConstants';

export const fetchServiceAddOns = () => {
  const types = [
    actionTypes.FETCHING_SERVICE_ADD_ONS,
    actionTypes.FETCH_SERVICE_ADD_ONS_SUCCESS,
    actionTypes.FETCH_SERVICE_ADD_ONS_FAILURE,
  ];

  return api.get({
    types,
    endpoint: '/menus/service_add_ons',
    params: {},
  });
};
