/* eslint react/no-danger: 0 */
/* eslint jsx-a11y/click-events-have-key-events: 0 */
/* eslint jsx-a11y/no-static-element-interactions: 0 */
/* eslint no-confusing-arrow: 0 */

import React from 'react';
import { bool, func, string } from 'prop-types';
import { notBlank } from 'libs/support/string';
import DropZone from 'react-dropzone';
import { withI18n } from 'libs/support/i18n';
import styled from 'styled-components';
import { SecondaryButton } from 'libs/support/sharedComponents';
import { CropIcon } from 'libs/components/Icons';

const DropContainer = styled.div`
  align-items: center;
  border: 2px dashed ${props => props.theme.colors.spindle};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 100%
  justify-content: center;
`;

const PhotoContainer = styled.div`
  align-items: center;
  box-shadow: 2px 1px 8px ${props => props.theme.colors.gray200}, 0px 1px 0px ${props => props.theme.colors.gray200};
  border: 1px solid  ${props => props.theme.colors.gainsboro};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 100%
  justify-content: center;
  position: relative;
`;

const DropText = styled.div`
  font-family: ${props => props.theme.fonts.proxima};
  font-size: 12px;
  line-height: 21px;
  text-align: center;
`;

const Button = styled(SecondaryButton)`
  margin-bottom: 10px;
`;

const EditButton = styled(SecondaryButton)`
  display: flex;
  padding: 0.5rem;
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
`;

const StyledDropZone = styled(DropZone)`
  background-color: ${props => props.theme.colors.white};
  background-position: center;
  background-size: ${({ orientation }) => orientation === 'portrait' ? 'contain' : 'cover'};
  background-repeat: no-repeat;
  cursor: pointer;
  height: 150px;
  width: 250px;
`;

const DeleteButton = styled.button`
  right: -0.7rem !important;
  top: -0.7rem !important;
`;

const PhotoPreview = ({
  handleOnDelete,
  handleOnDrop,
  handleOnEdit,
  isRequired,
  isEditable,
  multiple,
  photo,
  translate,
  uploading,
}) => {
  const thumb = notBlank(photo) && photo.get('thumb');
  const dropzoneStyle = {
    backgroundImage: (notBlank(thumb) ? `url(${thumb})` : ''),
  };

  if (notBlank(thumb)) {
    return (
      <div className="preview-container">
        <StyledDropZone
          orientation={photo.get('orientation') || 'landscape'}
          className="dropzone dropzone-uploaded"
          style={dropzoneStyle}
          onClick={handleOnEdit}
        >
          <PhotoContainer>
            {
              isEditable &&
                <EditButton
                  as="a"
                  onClick={handleOnEdit}
                >
                  <CropIcon />
                </EditButton>
            }
          </PhotoContainer>
        </StyledDropZone>

        <DeleteButton
          className="btn-delete"
          onClick={handleOnDelete}
        >
          {translate('buttonsDelete')}
        </DeleteButton>
      </div>
    );
  }

  return (
    <div className="preview-container">
      <StyledDropZone
        onDrop={handleOnDrop}
        multiple={multiple || false}
        accept="image/png,image/jpeg"
        className="dropzone"
        style={dropzoneStyle}
        inputProps={{
          required: isRequired,
        }}
      >
        <DropContainer>
          <Button as="a">Add file</Button>
          <DropText>
            {
              uploading ?
                <i className="fa fa-refresh fa-spin fa-5x fa-fw" /> :
                <span dangerouslySetInnerHTML={{ __html: translate('photoUploadDropzoneMessage') }} />
            }
          </DropText>
        </DropContainer>
      </StyledDropZone>
    </div>
  );
};

PhotoPreview.propTypes = {
  handleOnDelete: func.isRequired,
  handleOnDrop: func.isRequired,
  handleOnEdit: func.isRequired,
  isEditable: bool,
  isRequired: bool,
  multiple: bool,
  photo: string,
  translate: func.isRequired,
  uploading: bool,
};

export default withI18n(PhotoPreview);
