import React, { Fragment } from 'react';
import { func, object, string } from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { withAppContext } from 'libs/support/appContext';
import { usePolicies } from 'bundles/common/queries/policies';
import {
  ActiveToggle,
} from 'bundles/vendor/components/Item/Actions';
import {
  Cell,
  Nbsp,
  Row,
} from 'libs/support/sharedComponents';
import {
  EditIcon,
  ExternalIcon,
} from 'libs/components/Icons';
import {
  calculateVendorPrice,
} from 'libs/support/price';
import SelectItemCheckbox from './SelectItemCheckbox';

const BodyRow = styled(Row)`
  height: 40px;
  vertical-align: top;
`;

const CheckBoxCell = styled.td`
  display: flex;
  padding-left: 12px;
  padding-top: 12px;
`;

const SubRow = styled.div`
  margin-bottom: 1rem;
`;

const ItemOuterLink = styled.span`
  display: flex;
`;

const ExternalItemLink = styled(Link)`
  margin-top: 0.1rem;
`;

const ItemLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  width: 100%;

  svg {
    margin-right: 1rem;
    margin-top: 0.2rem;
    min-width: 14px;
    width: 14px;
  }

  path {
    fill: ${props => props.theme.colors.lima};
    opacity: 1;
  }
`;

const ToggleContainer = styled.div`
  margin-top: 0.1rem;
`;

const Item = ({
  menuItem,
  taxRate,
  taxType,
  flagIsEnabled,
}) => {
  const policiesResponse = usePolicies();
  const { policies } = policiesResponse.isSuccess ? policiesResponse.data : {};
  const pluck = (key) => ((items, item) => {
    items.push(item.get(key));
    return items;
  });

  const customisations = [];
  menuItem.get('menu_item_item_add_ons').reduce(pluck('question'), customisations);
  menuItem.get('menu_item_service_add_ons').reduce(pluck('question'), customisations);

  const dietaries = menuItem.get('dietaries').reduce(pluck('name'), []);
  const foodAttributes = menuItem.get('food_attributes').reduce(pluck('name'), []);
  const occasions = menuItem.get('occasions').reduce(pluck('name'), []);

  return (
    <BodyRow>
      <CheckBoxCell>
        <SelectItemCheckbox menuItem={menuItem} />
      </CheckBoxCell>
      <Cell>
        <ToggleContainer>
          <ActiveToggle menuItem={menuItem} />
        </ToggleContainer>
      </Cell>
      <Cell className="freeze">
        { policies?.menuItem?.update &&
          <ItemOuterLink>
            <ExternalItemLink
              to={`/menus/items/${menuItem.get('uuid')}/edit`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ExternalIcon />
            </ExternalItemLink>
            <Nbsp />
            <ItemLink to={`/menus/items/${menuItem.get('uuid')}/edit`}>
              { menuItem.get('name') }

              <EditIcon />
            </ItemLink>
          </ItemOuterLink>
        }

        { !policies?.menuItem?.update && menuItem.get('name') }
      </Cell>
      <Cell>{ menuItem.get('category_name') }</Cell>
      <Cell>
        {
          menuItem.get('variations').map((variation) => (
            <SubRow key={`variation-name-${variation.get('uuid')}`} >
              {variation.get('name')}
            </SubRow>
          ))
        }
      </Cell>
      <Cell>
        {
          menuItem.get('variations').map((variation) => {
            const vendorPrice = calculateVendorPrice({
              taxRate,
              taxType,
              value: variation.get('base_price'),
            }).toFixed(2);

            return (
              <SubRow key={`variation-price-${variation.get('uuid')}`} >
                {vendorPrice}
              </SubRow>
            );
          })
        }
      </Cell>
      <Cell>
        {
          menuItem.get('variations').map((variation) => (
            <SubRow key={`variation-serving-${variation.get('uuid')}`} >
              {variation.get('servings_description_string')}
            </SubRow>
          ))
        }
      </Cell>
      <Cell>
        {
          menuItem.get('variations').map((variation) => (
            <SubRow key={`variation-min-qty-${variation.get('uuid')}`} >
              {variation.get('min_order_quantity')}
            </SubRow>
          ))
        }
      </Cell>
      <Cell>{ customisations.join(', ') }</Cell>
      <Cell>{ foodAttributes.join(', ') }</Cell>
      <Cell>{ occasions.join(', ') }</Cell>
      <Cell>{ dietaries.join(', ') }</Cell>

      {
        flagIsEnabled('external_fields_enabled') &&
          <Fragment>
            <Cell>{ menuItem.get('sku') }</Cell>
            <Cell>{ menuItem.get('internal_id') }</Cell>
          </Fragment>
      }
    </BodyRow>
  );
};

Item.propTypes = {
  menuItem: object,
  taxRate: string,
  taxType: string,
  flagIsEnabled: func.isRequired,
};

export default withAppContext(Item);
