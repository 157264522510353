import React from 'react';
import { any } from 'prop-types';
import { ThreeDotsIcon } from 'libs/components/Icons';
import { SecondaryButton } from 'libs/support/sharedComponents';
import styled from 'styled-components';

const Container = styled.div`
  display: inline-block;
  height: 34px;

  .dropdown-menu {
    margin-top: 0;
  }

  .dropdown-toggle:after {
    content: none;
  }

  &.open>.dropdown-toggle {
    border: 1px solid ${props => props.theme.colors.gainsboro} !important;
    background: ${props => props.theme.colors.white} !important;
    color: ${props => props.theme.colors.gunPowder};
  }
`;

const Button = styled(SecondaryButton)`
  display: flex !important;
  padding: 0.43rem 0.5rem !important;
  height: 32px;
`;

const DropdownActions = ({
  children,
}) => (
  <Container className="dropdown">
    <Button
      className="btn btn-secondary dropdown-toggle"
      type="button"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <ThreeDotsIcon />
    </Button>
    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
      { children }
    </div>
  </Container>
);

DropdownActions.propTypes = {
  children: any,
};

export default DropdownActions;
