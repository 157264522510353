import React from 'react';
import { object } from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  max-width: 500px;
  max-height: 300px;

  .cropper-wrap-box {
    background-color: ${props => props.theme.colors.white};
  }

  .cropper-view-box {
    background-color: ${props => props.theme.colors.white};
  }

  .cropper-modal {
    border: 1px solid ${props => props.theme.colors.greyChateau};
  }
`;

const Image = styled.img`
  display: block;
  max-width: 100%;
  max-height: 300px;
`;

const ImageContainer = ({
  photo,
  imageRef,
}) => (
  <Container>
    <Image ref={imageRef} src={photo.get('url') || photo.get('thumb')} />
  </Container>
);

ImageContainer.propTypes = {
  imageRef: object,
  photo: object,
};

export default ImageContainer;
