/* eslint camelcase: 0 */

import Immutable from 'immutable';
import { defaultSetting } from '@caterspot/react-label-sheet';

import * as actionTypes from '../constants/mealLabelConstants';
import { TEMPLATES } from '../constants/sampleTemplates';

export const $$initialState = Immutable.fromJS({
  labels: [],
  labelTemplates: [],
  setting: defaultSetting,
  selectedTemplate: TEMPLATES[0],
  isEditTemplateMode: false,
  successMsg: '',
});

const DEFAULT_TEMPLATE_ATTRS = {
  name: '',
  label: 'Custom',
  value: 'custom',
  new: true,
  settings: {
    page_size: 'A4',
    sheet_margin_top_bottom: '0mm',
    sheet_margin_left_right: '0mm',
    label_margin_top_bottom: '0mm',
    label_margin_left_right: '0mm',
    label_width: '70mm',
    label_height: '37.125mm',
    label_per_row: 3,
    label_per_col: 8,
  },
};

const DEFAULT_LABEL_ATTRS = {
  id: Date.now(),
  meal_name: '',
  name: '',
  vendor_name: '',
  schedule_at: '',
};

const setSuccessMsg = (state, response) => {
  if (!response || !response.flash) return state.set('successMsg', '');
  return state.set('successMsg', response.flash.success);
};

export default function mealLabelReducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  const newState = setSuccessMsg($$state, response);

  switch (type) {
    case actionTypes.ADD_LABEL: {
      return newState.withMutations(state => {
        state.set(
          'labels',
          state
            .get('labels')
            .unshift(Immutable.fromJS(DEFAULT_LABEL_ATTRS)),
        );
      });
    }
    case actionTypes.REMOVE_LABEL: {
      const { id } = response;

      return newState.withMutations(state => {
        state.deleteIn(['labels', id]);
      });
    }
    case actionTypes.UPDATE_LABEL: {
      const { id, attrs } = response;

      return newState.withMutations(state => {
        state.mergeIn(['labels', id], attrs);
      });
    }
    case actionTypes.DUPLICATE_LABEL: {
      const { id } = response;

      return newState.withMutations((state) => {
        const labels = state.get('labels');

        return state.set('labels', labels.unshift(labels.get(id).merge({ id: Date.now() })));
      });
    }

    case actionTypes.SELECT_TEMPLATE: {
      const { template } = response;

      return newState
        .set('isEditTemplateMode', false)
        .set('selectedTemplate', Immutable.fromJS(template));
    }

    case actionTypes.UPDATE_TEMP_TEMPLATE: {
      const { attrs } = response;

      return newState.withMutations((state) => (
        state.mergeIn(['selectedTemplate', 'settings'], attrs)
      ));
    }

    case actionTypes.NEW_TEMPLATE: {
      return newState
        .set('isEditTemplateMode', true)
        .set('selectedTemplate', Immutable.fromJS(DEFAULT_TEMPLATE_ATTRS));
    }

    case actionTypes.SET_EDIT_TEMPLATE_MODE: {
      const { isEditTemplateMode } = response;

      return newState.set('isEditTemplateMode', isEditTemplateMode);
    }

    case actionTypes.SAVING_LABEL_TEMPLATE: {
      return newState;
    }

    case actionTypes.SAVE_LABEL_TEMPLATE_SUCCESS: {
      const { label_template } = response;
      const idx = newState.get('labelTemplates').findIndex(labelTemplate => (
        labelTemplate.get('uuid') === label_template.uuid
      ));
      const newLabelTemplate = Immutable.fromJS(label_template);

      return newState.updateIn(['labelTemplates'], labelTemplates => {
        if (idx > -1) {
          return labelTemplates.set(idx, newLabelTemplate);
        }
        return labelTemplates.push(newLabelTemplate);
      }).set('selectedTemplate', newLabelTemplate);
    }

    case actionTypes.SAVE_LABEL_TEMPLATE_FAILURE: {
      return newState;
    }

    case actionTypes.FETCHING_LABEL_TEMPLATES: {
      return newState;
    }

    case actionTypes.FETCH_LABEL_TEMPLATES_SUCCESS: {
      const { label_templates } = response;

      return newState.set('labelTemplates', Immutable.fromJS(label_templates));
    }

    case actionTypes.FETCH_LABEL_TEMPLATES_FAILURE: {
      return newState;
    }

    case actionTypes.DELETING_LABEL_TEMPLATE: {
      return newState;
    }

    case actionTypes.DELETE_LABEL_TEMPLATE_SUCCESS: {
      const { label_template } = response;
      const idx = newState.get('labelTemplates').findIndex(labelTemplate => (
        labelTemplate.get('uuid') === label_template.uuid
      ));

      return newState.withMutations(state => (
        state
          .deleteIn(['labelTemplates', idx])
          .updateIn(['selectedTemplate'], selectedTemplate => (
            selectedTemplate.get('uuid') === label_template.uuid ?
              Immutable.fromJS(TEMPLATES[0]) : selectedTemplate
          ))
      ));
    }

    case actionTypes.DELETE_LABEL_TEMPLATE_FAILURE: {
      return newState;
    }

    default: {
      return newState;
    }
  }
}
