/* eslint camelcase: 0, max-len: 0 */

import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';

import loggerMiddleware from 'libs/middlewares/loggerMiddleware';

import reducers, { initialStates } from '../reducers';

export default (props) => {
  const {
    current_outlet,
  } = props;

  const {
    $$catererState,
    $$localeState,
  } = initialStates;

  const initialState = {
    $$catererStore: $$catererState.merge({
      catererId: current_outlet && current_outlet.vendor_id,
    }),
    $$localeStore: $$localeState,
  };

  const reducer = combineReducers(reducers);
  const composedStore = composeWithDevTools(applyMiddleware(thunkMiddleware, loggerMiddleware));

  return composedStore(createStore)(reducer, initialState);
};
