import React from 'react';
import { object, func } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  color: ${props => props.theme.colors.white} !important
  font-size: 12px;
  line-height: 24px;
  opacity: 0.5
  text-transform: uppercase;
`;

const InputContainer = styled.div`
  align-items: center;
  color: ${props => props.theme.colors.white};
  display: flex;
  font-size: 20px;
  line-height: 24px;
  position: relative;

  &:after {
    color: ${props => props.theme.colors.white} !important;
    content: '\0003E';
    display: inline-block;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.1;
    margin-left: 1rem;
    opacity: 0.5;
    transform: scale(2, 1) rotate(90deg);
    transition: transform 0.3s linear;
  }
`;

const Input = styled.input`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  line-height: 24px;
  opacity: 0;
  outline: none;
  padding-right: 0.5rem;
  position: absolute;
  width: 100%;
`;

const VendorInput = ({
  selectedVendor,
  onBlur,
  onClick,
  translate,
}) => {
  const vendorName = selectedVendor.get('name');

  return (
    <Container className="outlet-navigation__vendor-input">
      <Label>{ translate('outletNavigationStore') }</Label>
      <InputContainer>
        {vendorName}
        <Input
          value={vendorName}
          readOnly
          {
            ...{
              onBlur,
              onClick,
            }
          }
        />
      </InputContainer>
    </Container>
  );
};

VendorInput.propTypes = {
  selectedVendor: object.isRequired,
  onBlur: func.isRequired,
  onClick: func.isRequired,
  translate: func.isRequired,
};

export default withI18n(VendorInput);
