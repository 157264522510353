/* eslint max-len: 0 */

import mealLabelReducer, { $$initialState as $$mealLabelState } from './mealLabelReducer';

export default {
  $$mealLabelStore: mealLabelReducer,
};

export const initialStates = {
  $$mealLabelState,
};
