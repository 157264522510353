import React from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import { withI18n } from 'libs/support/i18n';
import {
  PrimaryTitle,
} from 'libs/support/sharedComponents';

const Container = styled(PrimaryTitle)`
  padding-bottom: 1.5rem;
`;

const Header = ({
  translate,
}) => (
  <Container>
    { translate('vendorAllItems') }
  </Container>
);

Header.propTypes = {
  translate: func.isRequired,
};

export default withI18n(Header);
