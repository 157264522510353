import React from 'react';
import styled from 'styled-components';
import AddButton from './AddButton';
import Search from './Search';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Actions = () => (
  <Container>
    <Search />
    <AddButton />
  </Container>
);

export default Actions;
