import React, { useRef } from 'react';
import { bool, func, string } from 'prop-types';
import styled from 'styled-components';
import { withI18n } from 'libs/support/i18n';
import { SearchIcon } from 'libs/components/Icons';

const Container = styled.div`
  align-items: center;
  border: 1px solid ${props => props.theme.colors.gainsboro};
  border-radius: 4px;
  display: flex;
  font-family: ${props => props.theme.fonts.proxima};
  font-size: 14px;
  line-height: 21px;
  padding: 0.25rem 1rem;
  position: relative;
  width: 100%;

  @media (min-width: ${props => props.theme.breakPoints.md}) {
    width: 366px;
  }
`;

const Input = styled.input`
  border: none;
  color: ${props => props.theme.colors.blackRussian};
  outline: none;
  width: 100%;
`;

const Button = styled.button`
  align-items: center;
  background: ${props => props.theme.colors.lima};
  border: none;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 0 1rem;
  position: absolute;
  right: 0;
  width: 50px;
`;

const Search = ({
  className = '',
  onSearch,
  isProcessing,
  translate,
  keyword,
}) => {
  const inputRef = useRef();
  const handleClick = (e) => {
    e.preventDefault();

    return onSearch && onSearch(inputRef.current.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      return onSearch && onSearch(inputRef.current.value);
    }

    return false;
  };

  return (
    <Container className={className}>
      <Input
        ref={inputRef}
        placeholder={translate('vendorSearch')}
        defaultValue={keyword}
        onKeyPress={handleKeyPress}
        name="keyword"
      />
      <Button onClick={handleClick}>
        {
          isProcessing ?
            <i className="fa fa-refresh fa-spin a-fw fa-white" /> :
            <SearchIcon />
        }
      </Button>
    </Container>
  );
};

Search.propTypes = {
  className: string,
  isProcessing: bool,
  keyword: string,
  onSearch: func.isRequired,
  translate: func.isRequired,
};

export default withI18n(Search);
