export const TEMPLATES = [
  {
    name: 'A4 Popular 24s',
    value: 'template-a4',
    label: 'A4 Popular 24s',
    settings: {
      page_size: 'A4',
      sheet_margin_top_bottom: '0mm',
      sheet_margin_left_right: '0mm',
      label_margin_top_bottom: '3mm',
      label_margin_left_right: '5mm',
      label_width: '70mm',
      label_height: '37.125mm',
      label_per_row: 3,
      label_per_col: 8,
    },
  },
  {
    name: 'A4 Popular 24s - No margin',
    value: 'template-a4-no-margin',
    label: 'A4 Popular 24s - No margin',
    settings: {
      page_size: 'A4',
      sheet_margin_top_bottom: '0mm',
      sheet_margin_left_right: '0mm',
      label_margin_top_bottom: '0mm',
      label_margin_left_right: '0mm',
      label_height: '37.125mm',
      label_width: '70mm',
      label_per_row: 3,
      label_per_col: 8,
    },
  },
];
