/* eslint camelcase: 0 */

import Immutable from 'immutable';
import snakeCase from 'lodash/snakeCase';
import * as customisationsActionTypes from 'bundles/vendor/constants/customisationsConstants';
import { notBlank } from 'libs/support/string';
import * as actionTypes from '../constants/menuItemsConstants';

export const $$initialState = Immutable.fromJS({
  isFetchingMenuItems: false,
  isMenuItemsFetched: false,
  isMenuItemSaving: false,
  isMenuItemCloning: false,
  isMenuItemDeleting: false,
  flash: Immutable.fromJS({}),
  menuItem: Immutable.fromJS({}),
  menuItems: Immutable.fromJS([]),
  selectedIds: Immutable.fromJS([]),
  showInUseMealPlanMessage: false,
  metadata: Immutable.fromJS({}),
  errors: {
    photos: '',
  },
});

export default function menuItemsReducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  switch (type) {
    case actionTypes.FETCHING_MENU_ITEMS: {
      return $$state.withMutations(state => (
        state
          .set('isFetchingMenuItems', true)
      ));
    }

    case actionTypes.FETCH_MENU_ITEMS_SUCCESS: {
      const metadata = $$state.get('metadata');
      return $$state.withMutations(state => (
        state
          .set('isFetchingMenuItems', false)
          .set('isMenuItemsFetched', true)
          .set('menuItems', Immutable.fromJS(response.menu_items))
          .set('metadata', Immutable.fromJS({ ...metadata.toJS(), ...response.metadata }))
      ));
    }

    case actionTypes.FETCH_MENU_ITEMS_FAILURE: {
      return $$state.withMutations(state => (
        state
          .set('isFetchingMenuItems', false)
      ));
    }

    case actionTypes.SAVING_MENU_ITEM: {
      return $$state.withMutations(state => (
        state
          .set('flash', Immutable.fromJS({}))
          .set('isMenuItemSaving', true)
      ));
    }

    case actionTypes.SAVE_MENU_ITEM_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .set('menuItem', Immutable.fromJS(response.menu_item))
          .set('flash', Immutable.fromJS(response.flash))
          .set('isMenuItemsFetched', false)
          .set('isMenuItemSaving', false)
      ));
    }

    case actionTypes.SAVE_MENU_ITEM_FAILURE: {
      return $$state.withMutations(state => (
        state
          .set('isMenuItemSaving', false)
          .set('flash', Immutable.fromJS({ errors: response.errors }))
      ));
    }

    case actionTypes.DELETING_MENU_ITEM: {
      return $$state.withMutations(state => (
        state
          .set('flash', Immutable.fromJS({}))
          .set('isMenuItemDeleting', true)
      ));
    }

    case actionTypes.DELETE_MENU_ITEM_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .set('flash', Immutable.fromJS(response.flash))
          .set('isMenuItemDeleting', false)
          .set('menuItem', Immutable.fromJS({}))
          .update('menuItems', menuItems => {
            const index = menuItems.findIndex((item) => response.menu_item.uuid === item.get('uuid'));

            if (index >= 0) return menuItems.delete(index);

            return menuItems;
          })
      ));
    }

    case actionTypes.DELETE_MENU_ITEM_FAILURE: {
      return $$state.withMutations(state => (
        state
          .set('isMenuItemDeleting', false)
          .set('flash', Immutable.fromJS({ errors: response.errors }))
      ));
    }

    case actionTypes.FETCH_MENU_ITEM_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .set('menuItem', Immutable.fromJS(response.menu_item))
          .set('flash', Immutable.fromJS({}))
      ));
    }

    case actionTypes.TOGGLE_MENU_ITEM_ACTIVE_SUCCESS: {
      const { menu_item } = response;
      return $$state.withMutations(state => (
        state
          .update(
            'menuItems',
            data => {
              const index = data.findIndex(d => d.get('uuid') === menu_item.uuid);
              if (index > -1) {
                return data.update(index, () => data.get(index).set('active', menu_item.active));
              }

              return data;
            },
          )
      ));
    }

    case actionTypes.TOGGLE_MENU_ITEM_ACTIVE_FAILURE: {
      const { error = '' } = response;

      return $$state.withMutations(state => (
        state.set('showInUseMealPlanMessage', error === 'Used in Meal Plan')
      ));
    }

    case actionTypes.CLOSE_IN_USE_MEAL_PLAN_MESSAGE: {
      return $$state.withMutations(state => (
        state.set('showInUseMealPlanMessage', false)
      ));
    }

    case actionTypes.REMOVE_NOTICE_FLASH: {
      return $$state.withMutations(state => (
        state
          .setIn(['flash', 'notice'], '')
      ));
    }

    case actionTypes.RESET_FLASH: {
      return $$state.withMutations(state => (
        state
          .set('flash', Immutable.fromJS({}))
      ));
    }

    case actionTypes.CREATE_EMPTY_MENU_ITEM: {
      return $$state.withMutations(state => (
        state
          .set(
            'menuItem',
            Immutable.fromJS({
              tempId: (new Date()).getTime(),
              uuid: '',
              in_use_meal_plans: [],
              variations: [
                Immutable.fromJS({
                  tempId: (new Date()).getTime(),
                  display_serving: true,
                }),
              ],
              included_items: [],
              subitems: [],
              photos: [],
            }),
          ).set(
            'errors',
            Immutable.fromJS({
              photo: '',
            }),
          ).set(
            'flash',
            Immutable.fromJS({
              notice: '',
            }),
          )
      ));
    }

    case actionTypes.ADD_TEMPORARY_CATERER_MENU_ITEM_PHOTO: {
      const { params } = response;

      return $$state.withMutations(state => (
        state
          .updateIn(['menuItem', 'photos'], photos => {
            let newPhotos = photos;
            params.forEach((param) => {
              const { file, hash, index } = param;
              const data = Immutable.fromJS({ thumb: file.preview, temporaryDetails: file, hash });

              if (newPhotos.get(index)) {
                newPhotos = newPhotos.update(index, photo => photo.merge(data));
              } else {
                newPhotos = newPhotos.push(data);
              }
            });

            return newPhotos;
          })
          .updateIn(['errors', 'photos'], _ => (''))
      ));
    }

    case actionTypes.ADD_TEMPORARY_CATERER_MENU_ITEM_PHOTO_FAILURE: {
      return $$state.withMutations(state => (
        state.updateIn(['errors', 'photos'], _ => (response.errors[0]))
      ));
    }

    case actionTypes.DELETE_CATERER_MENU_ITEM_PHOTO: {
      const { index } = response.details;

      return $$state.withMutations(state => (
        state
          .deleteIn(['menuItem', 'photos', index])
          .updateIn(['errors', 'photos'], _ => (''))
      ));
    }

    case actionTypes.CROP_TEMPORARY_CATERER_MENU_ITEM_PHOTO: {
      const { params } = response;

      return $$state.withMutations(state => (
        state
          .updateIn(['menuItem', 'photos'], photos => {
            let newPhotos = photos;
            params.forEach((param) => {
              const { file, hash, index } = param;
              const data = Immutable.fromJS({ thumb: file.preview, temporaryDetails: file, hash });

              if (newPhotos.get(index)) {
                newPhotos = newPhotos.set(index, data);
              }
            });

            return newPhotos;
          })
      ));
    }

    case customisationsActionTypes.APPLY_CUSTOMISATIONS: {
      const { itemAddOnIds, serviceAddOnIds } = response;

      const itemAddOns = itemAddOnIds.map(id => ({
        add_onable_id: id,
        item_add_on_id: id,
      })) || [];

      const serviceAddOns = serviceAddOnIds.map(id => ({
        add_onable_id: id,
        service_add_on_id: id,
      })) || [];

      return $$state.withMutations(state => (
        state
          .setIn(['menuItem', 'menu_item_item_add_ons'], Immutable.fromJS(itemAddOns))
          .setIn(['menuItem', 'menu_item_service_add_ons'], Immutable.fromJS(serviceAddOns))
      ));
    }

    case customisationsActionTypes.DETACH_CUSTOMISATION: {
      const { customisationType, id } = response;
      const snakeCaseType = snakeCase(customisationType);

      return $$state.withMutations(state => (
        state
          .updateIn(['menuItem', `menu_item_${snakeCaseType}s`], menuItemCustomisations => {
            const index = menuItemCustomisations.findIndex(c => c.get('add_onable_id') === id);

            if (index >= 0) return menuItemCustomisations.delete(index);

            return menuItemCustomisations;
          })
      ));
    }

    case actionTypes.TOGGLE_SELECT_MENU_ITEM: {
      const toggledId = response;

      return $$state.withMutations(state => (
        state
          .update('selectedIds', selectedIds => {
            const index = selectedIds.findIndex(id => id === toggledId);

            if (index >= 0) return selectedIds.delete(index);

            return selectedIds.push(toggledId);
          })
      ));
    }

    case actionTypes.TOGGLE_SELECT_ALL_MENU_ITEMS: {
      return $$state.withMutations(state => (
        state
          .update('selectedIds', selectedIds => {
            const pageIds = state.get('menuItems').map((m) => m.get('uuid'));

            const hasCurrentPageIds = pageIds.some(id => selectedIds.some(sid => sid === id));

            let newSelectedIds = selectedIds;

            if (hasCurrentPageIds) {
              pageIds.forEach(id => {
                const index = newSelectedIds.findIndex(selectedId => selectedId === id);
                newSelectedIds = newSelectedIds.delete(index);
              });

              return newSelectedIds;
            }

            pageIds.forEach(id => {
              newSelectedIds = newSelectedIds.push(id);
            });

            return newSelectedIds;
          })
      ));
    }

    case actionTypes.CLONING_MENU_ITEMS: {
      return $$state.withMutations(state => (
        state
          .set('isMenuItemCloning', true)
          .set('flash', Immutable.fromJS({}))
      ));
    }

    case actionTypes.CLONE_MENU_ITEMS_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .set('isMenuItemCloning', false)
          .set('selectedIds', Immutable.fromJS([]))
          .set('flash', Immutable.fromJS(response.flash))
          .update('menuItems', (menuItems) => {
            const isOnLastPage = state.getIn(['metadata', 'page']) === state.getIn(['metadata', 'total_pages']);

            if (isOnLastPage) return menuItems.concat(Immutable.fromJS(response.menu_items));

            return menuItems;
          })
      ));
    }

    case actionTypes.CLONE_MENU_ITEMS_FAILURE: {
      return $$state.withMutations(state => (
        state
          .set('isMenuItemCloning', false)
      ));
    }

    case actionTypes.FILTER_MENU_ITEMS_BY_ACTIVE: {
      return $$state.withMutations(state => (
        state
          .setIn(['metadata', 'filter_active'], response)
      ));
    }

    case actionTypes.FILTER_MENU_ITEMS_BY_CATEGORY: {
      return $$state.withMutations(state => (
        state
          .setIn(['metadata', 'filter_category_id'], response)
      ));
    }

    case actionTypes.ADD_VARIATION: {
      return $$state.withMutations(state => (
        state
          .updateIn(['menuItem', 'variations'], variations => (
            variations.push(Immutable.fromJS({
              tempId: (new Date()).getTime(),
              display_serving: true,
            }))
          ))
      ));
    }

    case actionTypes.DELETE_VARIATION: {
      return $$state.withMutations(state => (
        state
          .updateIn(['menuItem', 'variations'], variations => {
            const index = variations.findIndex(variation => (
              variation.get('uuid') === response || variation.get('tempId') === response
            ));

            if (notBlank(variations.getIn([index, 'uuid']))) {
              return variations.setIn([index, 'deleted_at'], new Date());
            }

            return variations.delete(index);
          })
      ));
    }

    default: {
      return $$state;
    }
  }
}
