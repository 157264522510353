import * as api from 'libs/middlewares/api';
import snakeCase from 'lodash/snakeCase';
import pluralize from 'pluralize';
import * as actionTypes from '../constants/customisationsConstants';

export const openCustomisationsModal = () => (
  { type: actionTypes.OPEN_CUSTOMISATIONS_MODAL }
);

export const closeCustomisationsModal = () => (
  { type: actionTypes.CLOSE_CUSTOMISATIONS_MODAL }
);

export const changeCurrentType = (type) => (
  { type: actionTypes.CHANGE_CURRENT_TYPE, response: type }
);

export const applyCustomisations = (itemAddOnIds, serviceAddOnIds) => (
  { type: actionTypes.APPLY_CUSTOMISATIONS, response: { itemAddOnIds, serviceAddOnIds } }
);

export const editCustomisation = (type, customisation) => (
  { type: actionTypes.EDIT_CUSTOMISATION, response: { type, customisation } }
);

export const newCustomisation = (type) => (
  { type: actionTypes.NEW_CUSTOMISATION, response: type }
);

export const closeCustomisation = () => (
  { type: actionTypes.CLOSE_CUSTOMISATION }
);

export const saveItemAddOn = (id, params) => {
  const types = [
    actionTypes.SAVING_ITEM_ADD_ON,
    actionTypes.SAVE_ITEM_ADD_ON_SUCCESS,
    actionTypes.SAVE_ITEM_ADD_ON_FAILURE,
  ];

  return api.save({
    types,
    endpoint: '/menus/item_add_ons',
    params,
    id,
  });
};

export const saveServiceAddOn = (id, params) => {
  const types = [
    actionTypes.SAVING_SERVICE_ADD_ON,
    actionTypes.SAVE_SERVICE_ADD_ON_SUCCESS,
    actionTypes.SAVE_SERVICE_ADD_ON_FAILURE,
  ];

  return api.save({
    types,
    endpoint: '/menus/service_add_ons',
    params,
    id,
  });
};

export const deleteCustomisation = (type, id) => {
  const snakeCaseType = snakeCase(type);
  const uppercaseType = snakeCaseType.toUpperCase();
  const types = [
    actionTypes[`DELETING_${uppercaseType}`],
    actionTypes[`DELETE_${uppercaseType}_SUCCESS`],
    actionTypes[`DELETE_${uppercaseType}_FAILURE`],
  ];

  return api.destroy({
    types,
    endpoint: `/menus/${pluralize(snakeCaseType)}`,
    params: null,
    id,
  });
};

export const removeNoticeFlash = () => (
  { type: actionTypes.REMOVE_NOTICE_FLASH }
);

export const detachCustomisation = (customisationType, id) => (
  { type: actionTypes.DETACH_CUSTOMISATION, response: { customisationType, id } }
);

export function addTemporaryCustomisationPhoto(params) {
  const response = { params };
  return { type: actionTypes.ADD_TEMPORARY_CUSTOMISATION_PHOTO, response };
}

export function deleteCustomisationPhoto(details) {
  const response = { details };
  return { type: actionTypes.DELETE_CUSTOMISATION_PHOTO, response };
}

export function addTemporaryCustomisationOptionPhoto(params) {
  const response = { params };
  return { type: actionTypes.ADD_TEMPORARY_CUSTOMISATION_OPTION_PHOTO, response };
}

export function deleteCustomisationOptionPhoto(details) {
  const response = { details };
  return { type: actionTypes.DELETE_CUSTOMISATION_OPTION_PHOTO, response };
}

export function addCustomisationOption() {
  return { type: actionTypes.ADD_CUSTOMISATION_OPTION };
}

export function deleteCustomisationOption(tempId) {
  return { type: actionTypes.DELETE_CUSTOMISATION_OPTION, response: tempId };
}

export const cloneCustomisation = (type, id) => {
  const snakeCaseType = snakeCase(type);
  const uppercaseType = snakeCaseType.toUpperCase();
  const types = [
    actionTypes[`CLONING_${uppercaseType}`],
    actionTypes[`CLONE_${uppercaseType}_SUCCESS`],
    actionTypes[`CLONE_${uppercaseType}_FAILURE`],
  ];

  return api.save({
    types,
    endpoint: `/menus/${pluralize(snakeCaseType)}/${id}/clone`,
    params: null,
    id: null,
  });
};

export const setCustomisationsKeyword = (keyword) => (
  { type: actionTypes.SET_CUSTOMISATIONS_KEYWORD, response: keyword }
);
