/* eslint max-len: 0 */
import React from 'react';

const LargeTruck = (_props) => (
  <svg width="61" height="44" viewBox="0 0 61 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0459 34V25.7692H36.2117C37.3163 25.7692 38.2117 24.8738 38.2117 23.7692V10C38.2117 8.89543 39.1071 8 40.2117 8H51.9173C52.6424 8 53.3107 8.39244 53.6639 9.02567L59.747 19.9304C59.9131 20.2283 60.0004 20.5637 60.0004 20.9047V34C60.0004 35.1046 59.1049 36 58.0004 36H12.0459C10.9413 36 10.0459 35.1046 10.0459 34Z" fill="white" stroke="#141415" strokeWidth="2" />
    <rect x="9.0459" y="1" width="29.9745" height="25" rx="3" fill="white" stroke="#141415" strokeWidth="2" />
    <line x1="13.4854" y1="8.6084" x2="2.49992" y2="8.6084" stroke="white" strokeWidth="5" strokeLinecap="round" />
    <line x1="13.4854" y1="17.2568" x2="6.49628" y2="17.2568" stroke="white" strokeWidth="5" strokeLinecap="round" />
    <line x1="15.4844" y1="8.6084" x2="2.49895" y2="8.6084" stroke="#141415" strokeWidth="3" strokeLinecap="round" />
    <line x1="15.4844" y1="17.2568" x2="6.4953" y2="17.2568" stroke="#141415" strokeWidth="3" strokeLinecap="round" />
    <path d="M28.0283 37C28.0283 40.3146 25.344 43 22.0346 43C18.7253 43 16.041 40.3146 16.041 37C16.041 33.6854 18.7253 31 22.0346 31C25.344 31 28.0283 33.6854 28.0283 37Z" fill="white" stroke="#141415" strokeWidth="2" />
    <path d="M53.0058 37C53.0058 40.3146 50.3215 43 47.0122 43C43.7029 43 41.0186 40.3146 41.0186 37C41.0186 33.6854 43.7029 31 47.0122 31C50.3215 31 53.0058 33.6854 53.0058 37Z" fill="white" stroke="#141415" strokeWidth="2" />
    <path d="M50.4103 14H44.5146C43.9624 14 43.5146 14.4477 43.5146 15V19.5C43.5146 20.0523 43.9624 20.5 44.5146 20.5H52.8311C53.5876 20.5 54.0702 19.6925 53.7118 19.0262L51.2909 14.5262C51.1166 14.2021 50.7783 14 50.4103 14Z" stroke="#141415" strokeWidth="2" />
  </svg>
);
export default LargeTruck;
