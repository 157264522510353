import { isMultipleVariations } from 'bundles/vendor/helpers/menuItemsHelper';

export const FETCHING_MENU_ITEM = 'FETCHING_MENU_ITEM';
export const FETCH_MENU_ITEM_SUCCESS = 'FETCH_MENU_ITEM_SUCCESS';
export const FETCH_MENU_ITEM_FAILURE = 'FETCH_MENU_ITEM_FAILURE';

export const FETCHING_MENU_ITEMS = 'FETCHING_MENU_ITEMS';
export const FETCH_MENU_ITEMS_SUCCESS = 'FETCH_MENU_ITEMS_SUCCESS';
export const FETCH_MENU_ITEMS_FAILURE = 'FETCH_MENU_ITEMS_FAILURE';

export const SAVING_MENU_ITEM = 'SAVING_MENU_ITEM';
export const SAVE_MENU_ITEM_SUCCESS = 'SAVE_MENU_ITEM_SUCCESS';
export const SAVE_MENU_ITEM_FAILURE = 'SAVE_MENU_ITEM_FAILURE';

export const DELETING_MENU_ITEM = 'DELETING_MENU_ITEM';
export const DELETE_MENU_ITEM_SUCCESS = 'DELETE_MENU_ITEM_SUCCESS';
export const DELETE_MENU_ITEM_FAILURE = 'DELETE_MENU_ITEM_FAILURE';

export const TOGGLE_MENU_ITEM_ACTIVE_FAILURE = 'TOGGLE_MENU_ITEM_ACTIVE_FAILURE';
export const TOGGLE_MENU_ITEM_ACTIVE_SUCCESS = 'TOGGLE_MENU_ITEM_ACTIVE_SUCCESS';
export const TOGGLING_MENU_ITEM_ACTIVE = 'TOGGLING_MENU_ITEM_ACTIVE';

export const REMOVE_NOTICE_FLASH = 'REMOVE_NOTICE_FLASH';
export const RESET_FLASH = 'RESET_FLASH';

export const CREATE_EMPTY_MENU_ITEM = 'CREATE_EMPTY_MENU_ITEM';

export const DEFAULT_SERVING_DESCRIPTIONS = [
  '',
  'Person',
  'Piece',
  'Dozen',
  'Selection',
  'Set',
  'Box',
  'Tray',
  'Platter',
  'Package',
  'Cake',
  'Pizza',
  'Dish',
  'Item',
];

export const PANTRY_SERVING_DESCRIPTIONS = [
  '',
  'Slice',
  'Can',
  'Bottle',
  'Carton',
  'Pack',
  'Cup',
  'Box',
  'Roll',
];

export const ACTIVITIES_PRICE_DESCRIPTIONS = [
  'Pax',
  'Group',
];

export const DEFAULT_VARIATIONS_HEADERS = [
  { key: '_blank', width: '40px' },
  { key: 'variation', width: '240px', isRequiredFunc: isMultipleVariations },
  { key: 'description', width: '240px', isOptional: true },
  { key: 'price', width: '80px', isRequired: true },
  { key: 'display_price', width: '50px' },
  { key: 'min_qty', width: '50px', isRequired: true },
  { key: 'serving_description', width: '50px', isRequired: true },
  { key: 'serves', width: '80px', isRequired: true },
  { key: 'display_serving', width: '50px' },
  { key: '_blank', width: '50px' },
];

export const ACTIVITIES_VARIATIONS_HEADERS = [
  { key: '_blank', width: '40px' },
  { key: 'variation', width: '240px', isRequiredFunc: isMultipleVariations },
  { key: 'description', width: '240px', isOptional: true },
  { key: 'price', width: '80px', isRequired: true },
  { key: 'display_price', width: '50px' },
  { key: 'min_qty', width: '50px', isRequired: true },
  { key: 'price_description', width: '50px', isRequired: true },
];

export const GIFTS_VARIATIONS_HEADERS = [
  { key: '_blank', width: '40px' },
  { key: 'variation', width: '240px', isRequiredFunc: isMultipleVariations },
  { key: 'description', width: '240px', isOptional: true },
  { key: 'price', width: '80px', isRequired: true },
  { key: 'display_price', width: '50px' },
  { key: 'min_qty', width: '50px' },
];

export const PANTRY_VARIATIONS_HEADERS = [
  { key: '_blank', width: '40px' },
  { key: 'variation', width: '100px', isRequiredFunc: isMultipleVariations },
  { key: 'description', width: '100px', isOptional: true },
  { key: 'price', width: '50px', isRequired: true },
  { key: 'display_price', width: '120px' },
  { key: 'min_qty', width: '50px', isRequired: true },
  { key: 'pack_size', width: '50px', isRequired: true },
  { key: 'uom', width: '180px', isRequired: true },
  { key: 'units', width: '30px', isRequired: true },
];

export const INCLUDED_ITEMS_HEADERS = [
  { key: '_blank', width: '40px' },
  { key: 'item_name', width: '240px' },
  { key: 'description', width: '240px', isOptional: true },
];

export const SUBITEMS_HEADERS = [
  { key: '_blank', width: '40px' },
  { key: 'item_name', width: '450px' },
];

export const DEFAULT_QUANTITY_TYPES = [
  'Courses',
  'Dishes',
  'Items',
  'Pieces',
  'Inches',
  'Kg',
  'Servings',
];

export const PANTRY_QUANTITY_TYPES = [
  'l',
  'ml',
  'g',
  'lb',
  'Slices',
  'Cans',
  'Bottles',
  'Cartons',
  'Packs',
  'Cups',
  'Bars',
  'Boxes',
  'Rolls',
];

export const QUANTITY_TYPES = DEFAULT_QUANTITY_TYPES.concat(PANTRY_QUANTITY_TYPES);

export const ADD_TEMPORARY_CATERER_MENU_ITEM_PHOTO = 'ADD_TEMPORARY_CATERER_MENU_ITEM_PHOTO';
export const ADD_TEMPORARY_CATERER_MENU_ITEM_PHOTO_FAILURE = 'ADD_TEMPORARY_CATERER_MENU_ITEM_PHOTO_FAILURE';
export const DELETE_CATERER_MENU_ITEM_PHOTO = 'DELETE_CATERER_MENU_ITEM_PHOTO';
export const CROP_TEMPORARY_CATERER_MENU_ITEM_PHOTO = 'CROP_TEMPORARY_CATERER_MENU_ITEM_PHOTO';

export const TOGGLE_SELECT_MENU_ITEM = 'TOGGLE_SELECT_MENU_ITEM';
export const TOGGLE_SELECT_ALL_MENU_ITEMS = 'TOGGLE_SELECT_ALL_MENU_ITEMS';

export const CLONING_MENU_ITEMS = 'CLONING_MENU_ITEMS';
export const CLONE_MENU_ITEMS_SUCCESS = 'CLONE_MENU_ITEMS_SUCCESS';
export const CLONE_MENU_ITEMS_FAILURE = 'CLONE_MENU_ITEMS_FAILURE';

export const FILTER_MENU_ITEMS_BY_ACTIVE = 'FILTER_MENU_ITEMS_BY_ACTIVE';
export const FILTER_MENU_ITEMS_BY_CATEGORY = 'FILTER_MENU_ITEMS_BY_CATEGORY';

export const ADD_VARIATION = 'ADD_VARIATION';
export const DELETE_VARIATION = 'DELETE_VARIATION';

export const CLOSE_IN_USE_MEAL_PLAN_MESSAGE = 'CLOSE_IN_USE_MEAL_PLAN_MESSAGE';
