/* eslint max-len: 0 */
import React from 'react';

const Selector = (_props) => (
  <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M14.7287 23.4623C14.394 23.1276 14.394 22.5849 14.7287 22.2501L19.2655 17.7134L14.7287 13.1766C14.394 12.8419 14.394 12.2992 14.7287 11.9644C15.0634 11.6297 15.6062 11.6297 15.9409 11.9644L21.0838 17.1073C21.4185 17.442 21.4185 17.9847 21.0838 18.3195L15.9409 23.4623C15.6062 23.7971 15.0634 23.7971 14.7287 23.4623Z" fill="#7D9E1D" stroke="#7D9E1D" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" />
    <rect x="1" y="1.71338" width="32" height="32" rx="16" stroke="#F2F5E8" strokeWidth="2" />
  </svg>
);
export default Selector;
