import React from 'react';
import { bool, func, object } from 'prop-types';
import { connect } from 'react-redux';
import { withI18n } from 'libs/support/i18n';
import { CheckMarkIcon } from 'libs/components/Icons';
import styled from 'styled-components';
import classnames from 'classnames';

const Container = styled.div`
  background: ${props => props.theme.colors.white};
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 8px 16px rgba(20, 20, 21, 0.08);
  margin: 0.5rem 0 0;
  opacity: 0;
  padding: 1rem 0;
  position: absolute;
  transition: 0.3s ease;
  width: 100%;
  z-index: -1;

  &.--open {
    opacity: 1;
    z-index: 1000;
  }

  @media (min-width: ${props => props.theme.breakPoints.md}) {
    width: 320px;
  }
`;

const Header = styled.div`
  color: ${props => props.theme.colors.blackRussian};
  font-size: 14px;
  line-height: 24px;
  padding: 0 1.5rem
`;

const List = styled.ul`
  list-style: none;
  padding: 0 1.5rem
  margin: 0
`;

const Item = styled.li`
  color: ${props => props.theme.colors.gunPowder};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  line-height: 24px;
  margin-top: 1rem;

  &:first-child {
    margin-top: 0;
  }

  &.--selected {
    color: ${props => props.theme.colors.lima};
  }
`;

const IconContainer = styled.span`
  display: inline-block;
  min-width: 1.5rem;
`;

const VendorOptions = ({
  isOpen,
  onSelectVendor,
  selectedVendor,
  translate,
  vendors,
}) => {
  const isSelected = (vendor) => {
    if (selectedVendor.get('uuid') === vendor.get('uuid')) {
      return '--selected';
    }

    return '';
  };

  const className = classnames('outlet-navigation__vendor-options', {
    '--open': isOpen,
  });

  return (
    <Container className={className} >
      <Header>{ translate('outletNavigationStore') }</Header>
      <hr />
      <List>
        {
          vendors.map((vendor) => (
            <Item
              key={`vendor-${vendor.get('uuid')}`}
              className={isSelected(vendor)}
              onClick={() => onSelectVendor(vendor)}
            >
              <IconContainer>
                { isSelected(vendor) && <CheckMarkIcon /> }
              </IconContainer>
              { vendor.get('name') }
            </Item>
          ))
        }
      </List>
    </Container>
  );
};

VendorOptions.propTypes = {
  isOpen: bool.isRequired,
  onSelectVendor: func.isRequired,
  selectedVendor: object.isRequired,
  translate: func.isRequired,
  vendors: object.isRequired,
};

const mapStatesToProps = (state) => {
  const vendors = state.$$vendorsStore.get('vendors');

  return {
    vendors,
  };
};

export default connect(mapStatesToProps)(withI18n(VendorOptions));
