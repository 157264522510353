import React from 'react';

import shallowEqualImmutable from '../middlewares/shallowEqualImmutable';

/* eslint-disable react/prefer-stateless-function */
export default class BaseComponent extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return !shallowEqualImmutable(this.props, nextProps) || !shallowEqualImmutable(this.state, nextState);
  }
}
