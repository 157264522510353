/* eslint max-len: 0 */

import React from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider, addLocaleData } from 'react-intl';
import { withQueryClient } from 'libs/support/withQueryClient';
import Intl from 'intl';
import en from 'react-intl/locale-data/en';
import zh from 'react-intl/locale-data/zh';

import { translations } from 'libs/i18n/translations';

import Vendor from '../components/Vendor';

global.Intl = Intl;

addLocaleData([...en, ...zh]);

function mapStatesToProps(state) {
  return {
    localeStore: state.$$localeStore,
  };
}

const VendorContainer = ({
  localeStore,
}) => {
  const locale = localeStore.get('locale');
  const messages = translations[locale];

  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      <React.Fragment>
        <Vendor />
      </React.Fragment>
    </IntlProvider>
  );
};

VendorContainer.propTypes = {
  localeStore: object.isRequired,
};

export default connect(mapStatesToProps)(withQueryClient(VendorContainer));
