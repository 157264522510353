/* eslint camelcase: 0 */

import Immutable from 'immutable';
import { notBlank } from 'libs/support/string';
import * as actionTypes from '../constants/categoriesConstants';

export const $$initialState = Immutable.fromJS({
  isCategoriesFetched: false,
  isCategorySaving: false,
  isCategoryDeleting: false,
  flash: Immutable.fromJS({}),
  category: Immutable.fromJS({}),
  categories: Immutable.fromJS([]),
});

export default function categoriesReducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  switch (type) {
    case actionTypes.FETCH_CATEGORIES_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .set('isCategoriesFetched', true)
          .set('categories', Immutable.fromJS(response.categories))
      ));
    }

    case actionTypes.SAVING_CATEGORY: {
      return $$state.withMutations(state => (
        state
          .set('flash', Immutable.fromJS({}))
          .set('isCategorySaving', true)
      ));
    }

    case actionTypes.SAVE_CATEGORY_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .set('category', Immutable.fromJS(response.category))
          .set('flash', Immutable.fromJS(response.flash))
          .set('isCategoriesFetched', false)
          .set('isCategorySaving', false)
      ));
    }

    case actionTypes.SAVE_CATEGORY_FAILURE: {
      return $$state.withMutations(state => (
        state
          .set('isCategorySaving', false)
          .set('flash', Immutable.fromJS({ errors: response.errors }))
      ));
    }

    case actionTypes.FETCH_CATEGORY_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .set('category', Immutable.fromJS(response.category))
          .set('flash', Immutable.fromJS(response.flash))
      ));
    }

    case actionTypes.TOGGLE_CATEGORY_ACTIVE_SUCCESS: {
      const { category } = response;
      return $$state.withMutations(state => (
        state
          .update(
            'categories',
            data => {
              const index = data.findIndex(d => d.get('uuid') === category.uuid);
              if (index > -1) {
                return data.update(index, () => data.get(index).set('active', category.active));
              }

              return data;
            },
          ).update(
            'category',
            data => {
              if (data.get('uuid') === category.uuid) {
                return data.set('active', category.active);
              }

              return data;
            },
          )
      ));
    }

    case actionTypes.REMOVE_NOTICE_FLASH: {
      return $$state.withMutations(state => (
        state
          .setIn(['flash', 'notice'], '')
      ));
    }

    case actionTypes.RESET_FLASH: {
      return $$state.withMutations(state => (
        state
          .set('flash', Immutable.fromJS({}))
      ));
    }

    case actionTypes.CREATE_EMPTY_CATEGORY: {
      return $$state.withMutations(state => (
        state
          .set(
            'category',
            Immutable.fromJS({
              tempId: (new Date()).getTime(),
              uuid: '',
              category_availabilities: [],
              in_use_meal_plans: [],
            }),
          ).set(
            'flash',
            Immutable.fromJS({
              notice: '',
            }),
          )
      ));
    }

    case actionTypes.DELETING_CATEGORY: {
      return $$state.withMutations(state => (
        state
          .set('flash', Immutable.fromJS({}))
          .set('isCategoryDeleting', true)
      ));
    }

    case actionTypes.DELETE_CATEGORY_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .set('flash', Immutable.fromJS(response.flash))
          .set('isCategoryDeleting', false)
          .set('category', Immutable.fromJS({}))
          .update('categories', categories => {
            const index = categories.findIndex((item) => response.category.uuid === item.get('uuid'));

            if (index >= 0) return categories.delete(index);

            return categories;
          })
      ));
    }

    case actionTypes.DELETE_CATEGORY_FAILURE: {
      return $$state.withMutations(state => (
        state
          .set('isCategoryDeleting', false)
          .set('flash', Immutable.fromJS({ errors: response.errors }))
      ));
    }

    case actionTypes.ADD_CATEGORY_AVAILABILITY: {
      return $$state.withMutations(state => (
        state
          .updateIn(['category', 'category_availabilities'], categoryAvailabilities => (
            categoryAvailabilities.push(Immutable.fromJS({
              tempId: (new Date()).getTime(),
              photos: [],
            }))
          ))
      ));
    }

    case actionTypes.DELETE_CATEGORY_AVAILABILITY: {
      return $$state.withMutations(state => (
        state
          .updateIn(['category', 'category_availabilities'], categoryAvailabilities => {
            const index = categoryAvailabilities.findIndex(option => (
              option.get('uuid') === response || option.get('tempId') === response
            ));

            if (notBlank(categoryAvailabilities.getIn([index, 'uuid']))) {
              return categoryAvailabilities.setIn([index, 'destroy'], true);
            }

            return categoryAvailabilities.delete(index);
          })
      ));
    }

    default: {
      return $$state;
    }
  }
}
