/* eslint react/no-array-index-key: 0 */

import React, { useEffect } from 'react';
import { array, func, object } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as menuItemsActions from 'bundles/vendor/actions/menuItemsActions';
import uppercamelcase from 'uppercamelcase';
import styled from 'styled-components';
import { List } from 'immutable';
import { withI18n } from 'libs/support/i18n';
import { isMultipleVariations } from 'bundles/vendor/helpers/menuItemsHelper';
import { SecondaryButton } from 'bundles/common/components/Buttons';
import { withAppContext } from 'libs/support/appContext';
import {
  DEFAULT_VARIATIONS_HEADERS,
  ACTIVITIES_VARIATIONS_HEADERS,
  GIFTS_VARIATIONS_HEADERS,
  PANTRY_VARIATIONS_HEADERS,
} from 'bundles/vendor/constants/menuItemsConstants';
import {
  OptionalFont,
  RequiredFont,
  ScrollableShadow,
  ScrollableShadowContainer,
  Table,
} from 'libs/support/sharedComponents';
import SectionTitle from 'bundles/vendor/components/Shared/SectionTitle';
import Subheader from 'bundles/vendor/components/Item/TaxTypes/Subheader';
import Variation from './Variation';

const HEADERS = {
  activities: ACTIVITIES_VARIATIONS_HEADERS,
  gifts: GIFTS_VARIATIONS_HEADERS,
  pantry: PANTRY_VARIATIONS_HEADERS,
};

const Container = styled.div`

`;

const Optional = styled(OptionalFont)`
  margin-left: 0.3rem;
  text-transform: none;
`;

const ResponsiveScrollableShadow = styled(ScrollableShadow)`
  @media (min-width: ${props => props.theme.breakPoints.lg}) {
    display: none;
  }
`;

const TableContainer = styled.div`
  overflow-x: auto;
`;

const HeaderRow = styled.tr`
  height: 40px;
  box-shadow: 0px 1px 0px ${props => props.theme.colors.gray200};
`;

const Header = styled.th`
  font-family: ${props => props.theme.fonts.proxima};
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  padding: 0.75rem 0.75rem 0.5rem;
  text-transform: uppercase;
  vertical-align: bottom;
`;

const TBody = styled.tbody`
  background-color: ${props => props.theme.colors.azure};
  box-shadow: 0px -1px 0px ${props => props.theme.colors.gray200}, 0px 1px 0px ${props => props.theme.colors.gray200};
`;

const Required = styled(RequiredFont)`
  padding-left: 0.5rem;
`;

const VariationsSection = withI18n(({
  addVariation,
  caterer,
  menuItem,
  translate,
  measurementUnits,
  flagIsEnabled,
}) => {
  const vendorKind = caterer.get('kind');
  const markupPriceEnabled = caterer.get('markup_price_enabled');
  const markupPricePercent = caterer.get('markup_price_percent') && markupPriceEnabled ?
    caterer.get('markup_price_percent') : '0';
  const taxType = caterer.get('tax_type');
  const taxRate = caterer.get('country_tax_rate') || '0';
  const tableHeaders = HEADERS[vendorKind] || DEFAULT_VARIATIONS_HEADERS;
  const variations = menuItem.get('variations');
  const variationNameHeader = tableHeaders.find(header => header.key === 'variation');
  const isNameMandatory = variationNameHeader && variationNameHeader.isRequiredFunc(variations);
  const isVariationDeletable = isMultipleVariations(variations);
  const vendorBasePriceV2Enabled = flagIsEnabled('vendor_base_price_v2_enabled');
  const roundingAdjustment = vendorBasePriceV2Enabled ? 5 : 3;

  const headers = () => {
    const heads = List(tableHeaders);
    const headerV2 = heads.set(4, { key: 'base_price', width: '10%' }).toJS();
    return vendorBasePriceV2Enabled
      ? headerV2
      : heads.toJS();
  };

  const handleAddVariation = () => addVariation();

  useEffect(() => {
    if (variations.size === 0) {
      addVariation();
    }
  }, [variations]);

  return (
    <Container className="variations-section">
      <SectionTitle>{ translate('vendorVariations') }</SectionTitle>
      <Subheader taxType={taxType} />
      <ScrollableShadowContainer>
        <TableContainer>
          <Table>
            <thead>
              <HeaderRow>
                {
                  headers().map((header, index) => (
                    <Header
                      key={`${header.key}-${index}`}
                      style={{ minWidth: header.width }}
                    >
                      { translate(`vendor${uppercamelcase(header.key)}`) }

                      {
                        ((header.isRequiredFunc && header.isRequiredFunc(variations)) || header.isRequired) &&
                          <Required>*</Required>
                      }

                      { header.isOptional && <Optional>(Optional)</Optional> }
                    </Header>
                  ))
                }
              </HeaderRow>
            </thead>
            <TBody>
              {
                variations.map((variation, index) => (
                  <Variation
                    key={`variation-${variation.get('uuid') || variation.get('tempId')}`}
                    taxRate={taxRate}
                    taxType={taxType}
                    variation={variation}
                    isNameMandatory={isNameMandatory}
                    isDeletable={isVariationDeletable}
                    index={index}
                    vendorKind={caterer.get('kind')}
                    markupPricePercent={markupPricePercent}
                    measurementUnits={measurementUnits}
                    roundingAdjustment={roundingAdjustment}
                    vendorBasePriceV2Enabled={vendorBasePriceV2Enabled}
                  />
                ))
              }
            </TBody>
          </Table>
        </TableContainer>
        <ResponsiveScrollableShadow />
      </ScrollableShadowContainer>

      <SecondaryButton
        name="Add Another"
        onClick={handleAddVariation}
      />
    </Container>
  );
});

VariationsSection.propTypes = {
  addVariation: func.isRequired,
  caterer: object.isRequired,
  menuItem: object.isRequired,
  translate: func.isRequired,
  measurementUnits: array.isRequired,
  flagIsEnabled: func,
};

const mapStateToProps = (state) => ({
  caterer: state.$$catererStore.get('caterer'),
  menuItem: state.$$menuItemsStore.get('menuItem'),
  measurementUnits: state.$$filtersStore.get('measurementUnits'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  addVariation: menuItemsActions.addVariation,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withAppContext(VariationsSection));
