/* eslint camelcase: 0 */

import Immutable from 'immutable';
import { getLastMonthYear } from 'libs/support/date';
import * as actionTypes from '../constants/vendorStatementsConstants';

export const $$initialState = Immutable.fromJS({
  isFetchingVendorStatements: false,
  isVendorStatementsFetched: false,
  isVendorStatementSaving: false,
  flash: Immutable.fromJS({}),
  vendorStatement: Immutable.fromJS({}),
  vendorStatements: Immutable.fromJS([]),
  selectedYear: getLastMonthYear(),
  metadata: Immutable.fromJS({}),
  errors: {
    photos: '',
  },
});

export default function vendorStatementsReducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  switch (type) {
    case actionTypes.FETCHING_VENDOR_STATEMENTS: {
      return $$state.withMutations(state => (
        state
          .set('isFetchingVendorStatements', true)
      ));
    }

    case actionTypes.FETCH_VENDOR_STATEMENTS_SUCCESS: {
      const metadata = $$state.get('metadata');
      return $$state.withMutations(state => (
        state
          .set('isFetchingVendorStatements', false)
          .set('isVendorStatementsFetched', true)
          .set('vendorStatements', Immutable.fromJS(response.vendor_statements))
          .set('metadata', Immutable.fromJS({ ...metadata.toJS(), ...response.metadata }))
      ));
    }

    case actionTypes.FETCH_VENDOR_STATEMENT_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .set('vendorStatement', Immutable.fromJS(response.vendor_statement))
          .set('flash', Immutable.fromJS({}))
      ));
    }

    case actionTypes.SET_SELECTED_YEAR: {
      return $$state.withMutations(state => (
        state
          .set('selectedYear', response)
      ));
    }

    default: {
      return $$state;
    }
  }
}
