import React from 'react';
import styled from 'styled-components';
import { usePolicies } from 'bundles/common/queries/policies';
import AddButton from './AddButton';
import Header from './Header';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Actions = () => {
  const policiesResponse = usePolicies();
  const { policies } = policiesResponse.isSuccess ? policiesResponse.data : {};

  return (
    <Container className="categories-actions">
      <Header />
      { policies?.category?.create && <AddButton /> }
    </Container>
  );
};

Actions.propTypes = {};

export default Actions;
