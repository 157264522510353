import React from 'react';
import { object } from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import Immutable from 'immutable';
import { notBlank } from 'libs/support/string';
import {
  Cell,
  Row,
  GreyFont,
} from 'libs/support/sharedComponents';
import { Link } from 'react-router-dom';
import { trackClickedDownloadStatement } from 'packs/events';

const BodyRow = styled(Row)`
  height: 40px;
  vertical-align: top;
`;

const VendorStatement = ({
  vendorStatement,
}) => {
  const vendorId = vendorStatement.get('vendor_id');
  const uuid = vendorStatement.get('uuid');
  const hasDeliveries = notBlank(vendorStatement.get('data'));
  const data = vendorStatement.get('data') || Immutable.fromJS([{ date: vendorStatement.get('date') }]);
  const getLink = (index) => `/billings/vendor_statements/${uuid}/download_statement?vendor_id=${vendorId}&index=${index}`;

  return (
    data.map((datum, index) => (
      <BodyRow>
        {
          index === 0 &&
            <Cell rowSpan={data.size} >
              { moment(datum.get('date')).format('MMMM YYYY') }
            </Cell>
        }
        { hasDeliveries && vendorStatement.get('statement_by_outlet') &&
          <Cell>
            { datum.getIn(['billable', 'outlet_name']) }
          </Cell>
        }
        { hasDeliveries && !vendorStatement.get('statement_by_outlet') &&
          <Cell>
            <GreyFont>All Outlets</GreyFont>
          </Cell>
        }
        { !hasDeliveries &&
          <Cell />
        }
        <Cell>
          {
            hasDeliveries && notBlank(vendorStatement.getIn(['files', index])) &&
              <Link
                to={getLink(index)}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => { trackClickedDownloadStatement(); }}
              >
                Download Statement
              </Link>
          }

          {
            !hasDeliveries &&
              <GreyFont>No deliveries this month</GreyFont>
          }
        </Cell>
      </BodyRow>
    ))
  );
};

VendorStatement.propTypes = {
  vendorStatement: object,
};

export default VendorStatement;
