import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;

  .freeze {
    @media (min-width: ${props => props.theme.breakPoints.sm}) {
      position: sticky;
      left: 0;
      background: white;
    }
  }
`;

export const HeaderRow = styled.tr`
  height: 35px;
  border-bottom: 1px solid ${props => props.theme.colors.gray200};
`;

export const Header = styled.th`
  font-family: ${props => props.theme.fonts.proxima};
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  padding: 0.5rem 0.75rem 0.5rem;
  text-transform: uppercase;
  vertical-align: bottom;
`;

export const TBody = styled.tbody`
  background-color: ${props => props.theme.colors.azure};
  box-shadow: 0px -1px 0px ${props => props.theme.colors.gray200}, 0px 1px 0px ${props => props.theme.colors.gray200};
`;

export const Row = styled.tr`
  border-bottom: 1px solid ${props => props.theme.colors.gray200};
  vertical-align: ${props => props.verticalAlign || 'inherit'};
`;

export const StripedRow = styled(Row)`
  :nth-child(even) {
    background-color: ${props => props.theme.colors.white};
  }
`;

export const Cell = styled.td`
  font-size: 14px;
  line-height: 24px;
  padding: 0.5rem 0.3rem;
`;

export const CenteredCell = styled(Cell)`
  text-align: center;
`;

export const RightAlignedCell = styled(Cell)`
  text-align: right;
`;
