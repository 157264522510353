import React, { Fragment, useState } from 'react';
import { object, string } from 'prop-types';
import Immutable from 'immutable';
import { ToggleField } from 'libs/components/Forms';
import { connect } from 'react-redux';
import VendorBillingDetail from './VendorBillingDetail';
import OutletBillingDetailList from './OutletBillingDetail/List';

const BillingDetail = ({
  vendorBilling,
  catererId,
  outlets,
}) => {
  const vendorBillingDetail = vendorBilling.get('vendorBillingDetail') || Immutable.fromJS({});
  const outletBillingDetails = vendorBilling.get('outletBillingDetails');
  const [isStatementByOutlet, setStatementByOutlet] = useState(vendorBilling.get('statementByOutlet'));
  const handleStatementByOutletChange = () => {
    setStatementByOutlet(!isStatementByOutlet);
  };

  return (
    <Fragment>
      <input
        type="hidden"
        name="vendor_billing[statement_by_outlet]"
        value="false"
      />
      <ToggleField
        id="vendor_billing_statement_by_outlet"
        name="vendor_billing[statement_by_outlet]"
        defaultChecked={vendorBilling.get('statementByOutlet')}
        label="Issue Statement by Outlet"
        onChange={handleStatementByOutletChange}
      />

      {
        isStatementByOutlet ?
          <OutletBillingDetailList
            outlets={outlets}
            outletBillingDetails={outletBillingDetails}
          /> :
          <VendorBillingDetail
            vendorBillingDetail={vendorBillingDetail}
            billableId={catererId}
            billableType="Vendor"
          />
      }
    </Fragment>
  );
};

BillingDetail.propTypes = {
  vendorBilling: object.isRequired,
  catererId: string.isRequired,
  outlets: object,
};

const mapStateToProps = ({
  $$catererStore,
  $$outletsStore,
  $$vendorBillingStore,
}) => ({
  catererId: $$catererStore.get('catererId'),
  vendorBilling: $$vendorBillingStore.get('vendorBilling'),
  outlets: $$outletsStore.get('outlets'),
});

export default connect(mapStateToProps)(BillingDetail);
