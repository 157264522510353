import React, { Fragment } from 'react';
import SecondarySection from 'bundles/vendor/components/Item/SecondarySection';
import VariationsSection from 'bundles/vendor/components/Item/Variations/VariationsSection';
import { Separator } from 'libs/support/sharedComponents';
import GiftsTagsSection from 'bundles/vendor/components/Item/Sections/GiftsTagsSection';
import PhotosSection from 'bundles/vendor/components/Item/Sections/PhotosSection';
import CustomisationsSection from 'bundles/vendor/components/Item/Sections/CustomisationsSection';
import PrimarySection from 'bundles/vendor/components/Item/Sections/PrimarySection';
import ExternalFieldGroup from 'bundles/vendor/components/Item/FieldGroups/ExternalFieldGroup';

const GiftsForm = () => (
  <Fragment>
    <PrimarySection />

    <Separator />

    <VariationsSection />

    <Separator />

    <SecondarySection />
    <ExternalFieldGroup />

    <Separator />

    <GiftsTagsSection />

    <Separator />

    <PhotosSection isRequired />

    <Separator />

    <CustomisationsSection />
  </Fragment>
);

GiftsForm.propTypes = {
};

export default GiftsForm;
