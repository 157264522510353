import React from 'react';
import { bool, object, func, string } from 'prop-types';
import uppercamelcase from 'uppercamelcase';
import { connect } from 'react-redux';
import { withI18n } from 'libs/support/i18n';
import { withAppContext } from 'libs/support/appContext';
import styled from 'styled-components';
import {
  Table,
  HeaderRow,
  Header,
  ScrollableInnerContainer,
  ScrollableOuterContainer,
  ScrollableShadow,
} from 'libs/support/sharedComponents';
import SelecAllCheckbox from 'bundles/vendor/components/Items/SelectAllCheckbox';
import Item from './Item';

const HEADERS = [
  { key: '_checkbox', width: '50px' },
  { key: 'status', width: '100px' },
  { key: 'name', width: '300px', className: 'freeze' },
  { key: 'category', width: '200px' },
  { key: 'variation', width: '200px' },
  { key: 'price', width: '60px' },
  { key: 'serves', width: '100px' },
  { key: 'min_qty', width: '60px' },
  { key: 'customisations', width: '200px' },
  { key: 'special_occasions', width: '200px' },
  { key: 'format', width: '200px' },
  { key: 'dietary', width: '200px' },
];

const EXTERNAL_FIELDS_HEADERS = [
  { key: 'sku', width: '160px' },
  { key: 'internal_id', width: '160px' },
];

const Container = styled.div`
  position: relative;
`;

const LoadingContainer = styled.div`
  left: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 3rem;
  position: absolute:
  right: 0;
  text-align: center;
`;

const Body = ({
  isFetchingMenuItems,
  isMenuItemsFetched,
  menuItems,
  taxRate,
  taxType,
  flagIsEnabled,
  translate,
}) => {
  if (!isMenuItemsFetched) {
    return (
      <div>
        Loading...
      </div>
    );
  }

  return (
    <Container>
      <ScrollableOuterContainer>
        <ScrollableInnerContainer>
          <Table className="items-list">
            <thead>
              <HeaderRow>
                {
                  (HEADERS.concat(flagIsEnabled('external_fields_enabled') ? EXTERNAL_FIELDS_HEADERS : [])).map((header) => (
                    <Header
                      key={`header-${header.key}`}
                      style={{ minWidth: header.width }}
                      className={header.className}
                    >
                      {
                        header.key === '_checkbox' &&
                          <SelecAllCheckbox />
                      }

                      { translate(`vendor${uppercamelcase(header.key)}`) }
                    </Header>
                  ))
                }
              </HeaderRow>
            </thead>
            <tbody>
              {
                !isFetchingMenuItems && menuItems.map((menuItem) => (
                  <Item
                    key={`menu-item-${menuItem.get('uuid')}`}
                    {
                      ...{
                        menuItem,
                        taxRate,
                        taxType,
                      }
                    }
                  />
                ))
              }
            </tbody>
          </Table>
        </ScrollableInnerContainer>
        <ScrollableShadow />
      </ScrollableOuterContainer>

      {
        isFetchingMenuItems &&
          <LoadingContainer>
            <i className="fa fa-refresh fa-spin a-fw fa-3x" />
          </LoadingContainer>
      }
    </Container>
  );
};

Body.propTypes = {
  isFetchingMenuItems: bool,
  isMenuItemsFetched: bool.isRequired,
  menuItems: object.isRequired,
  taxRate: string,
  taxType: string,
  flagIsEnabled: func.isRequired,
  translate: func.isRequired,
};

const mapStateToProps = ({
  $$catererStore,
  $$menuItemsStore,
}) => ({
  isFetchingMenuItems: $$menuItemsStore.get('isFetchingMenuItems'),
  isMenuItemsFetched: $$menuItemsStore.get('isMenuItemsFetched'),
  menuItems: $$menuItemsStore.get('menuItems'),
  taxType: $$catererStore.getIn(['caterer', 'tax_type']),
  taxRate: $$catererStore.getIn(['caterer', 'country_tax_rate']) || '0',
});

export default connect(mapStateToProps)(withAppContext(withI18n(Body)));
