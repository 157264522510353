import Segment from 'libs/middlewares/segment';
import retry from 'p-retry';

export const initializeSegment = () => {
  if (window.segment) return;

  const segment = new Segment();
  segment.process();

  window.segment = segment;
};

export const processSegment = () => {
  window.segment.analyzePage();
  window.segment.analyzeAlias();
  window.segment.analyzeIdentify();
  window.segment.analyzeTrack();
};

export const processPage = () => {
  window.segment.analyzePage();
};

export function trackEvent(event, attributes = undefined) {
  retry(async () => {
    if (!window.segment?.initialized) {
      throw new Error('Segment is not initialized');
    }

    window.segment.trackEvent(event, attributes);
  });
}
