import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { notBlank } from 'libs/support/string';

import HistoryOrderVariation from './HistoryOrderVariation';

const HistoryWithDiff = ({ diff }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12 order-diff__title">
        Changes Requested:
      </div>
    </div>

    {
      (notBlank(diff.get('date')) || (diff.get('changed').size > 0)) &&
        <Fragment>
          <div className="row mb-4">
            <div className="col-xs-12">
              <div className="order-diff__badge">
                ITEMS CHANGED
              </div>
            </div>
          </div>

          {
            notBlank(diff.getIn(['date', 'before'])) &&
            notBlank(diff.getIn(['date', 'after'])) &&
              <div className="row">
                <div className="col-xs-12">
                  <div className="row no-gutters">
                    <div className="col-xs-7">
                      <div className="order-diff__table-header">
                        ORIGINAL ITEM
                      </div>
                    </div>
                    <div className="col-xs-5">
                      <div className="order-diff__table-header">
                        CHANGED ITEM
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters order-diff__table-content--with-bg">
                    <div className="col-xs-5">
                      <div className="order-diff__table-content">
                        <span className="font-weight-bold">Delivery Date and Time:</span>
                        <br />
                        {moment(diff.getIn(['date', 'before'])).format('D MMMM YYYY, h:mma')}
                      </div>
                    </div>
                    <div className="col-xs-2 order-diff__table-content--with-bg">
                      <div className="order-diff__arrow">
                        &rarr;
                      </div>
                    </div>
                    <div className="col-xs-5">
                      <div className="order-diff__table-content">
                        <span className="font-weight-bold">Delivery Date and Time:</span>
                        <br />
                        {moment(diff.getIn(['date', 'after'])).format('D MMMM YYYY, h:mma')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          }

          {
            diff.get('changed').map((item, index) => (
              <div
                key={index} // eslint-disable-line react/no-array-index-key
                className="row"
              >
                <div className="col-xs-12">
                  <div className="row no-gutters">
                    <div className="col-xs-7">
                      <div className="order-diff__table-header">
                        ORIGINAL ITEM
                      </div>
                    </div>
                    <div className="col-xs-5">
                      <div className="order-diff__table-header">
                        CHANGED ITEM
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters d-flex">
                    <HistoryOrderVariation
                      item={item.get('before')}
                    />

                    <div className="col-xs-2 order-diff__table-content--with-bg">
                      <div className="order-diff__arrow">
                        &rarr;
                      </div>
                    </div>

                    <HistoryOrderVariation
                      item={item.get('after')}
                    />
                  </div>
                </div>
              </div>
            ))
          }
        </Fragment>
    }

    {
      diff.get('added').size > 0 &&
        <Fragment>
          <div className="row">
            <div className="col-xs-12">
              <div className="order-diff__badge">
                ADDED
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <div className="row no-gutters d-flex">
                {
                  diff.get('added').map((item, index) => (
                    <HistoryOrderVariation
                      type="wide"
                      key={index} // eslint-disable-line react/no-array-index-key
                      item={item}
                    />
                  ))
                }
              </div>
            </div>
          </div>
        </Fragment>
    }

    {
      diff.get('removed').size > 0 &&
        <Fragment>
          <div className="row">
            <div className="col-xs-12">
              <div className="order-diff__badge">
                REMOVED
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <div className="row no-gutters d-flex">
                {
                  diff.get('removed').map((item, index) => (
                    <HistoryOrderVariation
                      type="wide"
                      key={index} // eslint-disable-line react/no-array-index-key
                      item={item}
                    />
                  ))
                }
              </div>
            </div>
          </div>
        </Fragment>
    }
  </Fragment>
);

HistoryWithDiff.propTypes = {
  diff: PropTypes.object.isRequired,
};

export default HistoryWithDiff;
