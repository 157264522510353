import React from 'react';
import { bool, func, object } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as categoriesActions from 'bundles/vendor/actions/categoriesActions';
import {
  Flash as FlashComponent,
} from 'libs/components/Forms';

const Flash = ({
  flash,
  isSticky,
  removeNoticeFlash,
}) => (
  <FlashComponent
    {
      ...{
        flash,
        isSticky,
        removeNoticeFlash,
      }
    }
  />
);

Flash.propTypes = {
  flash: object,
  isSticky: bool,
  removeNoticeFlash: func.isRequired,
};

const mapStateToProps = (state) => ({
  flash: state.$$categoriesStore.get('flash'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  removeNoticeFlash: categoriesActions.removeNoticeFlash,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Flash);
