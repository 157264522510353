import React, { Fragment } from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';

import { withI18n } from 'libs/support/i18n';
import { withAppContext } from 'libs/support/appContext';
import { InputField } from 'libs/components/Forms';

const ExternalFieldGroup = ({
  menuItem,
  flagIsEnabled,
  translate,
}) => {
  if (!flagIsEnabled('external_fields_enabled')) { return null; }

  return (
    <Fragment>
      <InputField
        id="menu_item_sku"
        label={translate('vendorSku')}
        name="menu_item[sku]"
        value={menuItem.get('sku')}
      />

      <InputField
        id="menu_item_internal_id"
        label={translate('vendorInternalId')}
        name="menu_item[internal_id]"
        value={menuItem.get('internal_id')}
      />
    </Fragment>
  );
};

ExternalFieldGroup.propTypes = {
  menuItem: object.isRequired,
  flagIsEnabled: func.isRequired,
  translate: func.isRequired,
};

const mapStateToProps = (state) => ({
  menuItem: state.$$menuItemsStore.get('menuItem'),
});

export default connect(mapStateToProps)(withAppContext(withI18n(ExternalFieldGroup)));
