/* eslint no-return-assign: 0 */

import React from 'react';
import { number, object } from 'prop-types';
import styled from 'styled-components';
import {
  InputField,
} from 'libs/components/Forms';
import {
  DeleteButton,
} from 'bundles/common/components/Buttons';
import {
  Cell,
  StripedRow,
} from 'libs/support/sharedComponents';

const InvisibleButton = styled.button`
  display: none;
`;

const IncludedItem = ({
  includedItem,
  index,
}) => {
  let deletedAtRef = null;
  let rowRef = null;

  const handleDelete = () => {
    if (includedItem.get('uuid')) {
      deletedAtRef.value = new Date();
      rowRef.style.display = 'none';
    } else {
      rowRef.parentNode.removeChild(rowRef);
    }
  };

  return (
    <StripedRow ref={el => rowRef = el}>
      <Cell>
        <InputField
          id={`menu_item_included_items_attributes_${index}_id`}
          name="menu_item[included_items_attributes][][id]"
          value={includedItem.get('uuid')}
          type="hidden"
        />
        <InputField
          inputRef={el => deletedAtRef = el}
          id={`menu_item_included_items_attributes_${index}_deleted_at`}
          name="menu_item[included_items_attributes][][deleted_at]"
          value={includedItem.get('deleted_at')}
          type="hidden"
        />
      </Cell>
      <Cell>
        <InputField
          id={`menu_item_included_items_attributes_${index}_name`}
          name="menu_item[included_items_attributes][][name]"
          value={includedItem.get('name')}
        />
      </Cell>
      <Cell>
        <InputField
          id={`menu_item_included_items_attributes_${index}_description`}
          name="menu_item[included_items_attributes][][description]"
          value={includedItem.get('description')}
        />
      </Cell>
      <Cell>
        <InvisibleButton />
        <DeleteButton onClick={handleDelete} />
      </Cell>
    </StripedRow>
  );
};

IncludedItem.propTypes = {
  index: number.isRequired,
  includedItem: object.isRequired,
};

export default IncludedItem;
