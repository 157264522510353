/* eslint max-len: 0, no-alert: 0, no-restricted-globals: 0 */

import React from 'react';
import { bool, string, func, object } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import BaseComponent from 'libs/components/BaseComponent';
import { defaultMessages } from 'libs/i18n/default';
import classnames from 'classnames';

class DeleteButtonComponent extends BaseComponent {
  static propTypes = {
    shouldConfirm: bool,
    hide: bool,
    disabled: bool,
    id: string,
    isButton: bool,
    confirmationString: string,
    className: string,
    onClick: func.isRequired,
    intl: intlShape.isRequired,
    style: object,
  };

  handleOnClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const {
      shouldConfirm, disabled, id, confirmationString, onClick, intl,
    } = this.props;

    const { formatMessage } = intl;

    if (disabled) return false;

    if (!shouldConfirm) return onClick(id);

    if (confirm(formatMessage(defaultMessages[confirmationString]))) return onClick(id);

    return false;
  }

  render = () => {
    const {
      hide,
      disabled,
      className = '',
      isButton = true,
      children,
      style = {},
    } = this.props;

    if (hide) return null;

    const linkClassName = classnames({
      'btn btn-light btn-small px-2': isButton,
    });

    return (
      <a
        id="delete-button"
        data-id="delete-button"
        href="#"
        className={`${linkClassName} ${className}`}
        disabled={disabled}
        onClick={this.handleOnClick}
        style={style}
      >
        {children && children}
        {
          !children &&
            <svg
              width="14"
              height="16"
              viewBox="0 0 14 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 0.989498C4 0.52189 4.3038 0 4.8 0H9.2C9.6962 0 10 0.52189 10 0.989498V1.979H12.9329C13.4799 1.979 13.7465 1.979 13.8764 2.11226C14 2.23898 14 2.48619 14 2.96849V3.95799H0V2.96849C0 2.48619 0 2.23898 0.12357 2.11226C0.253519 1.979 0.520124 1.979 1.0671 1.979H4V0.989498ZM1 15.0929C1 15.5931 1.55767 16 2.24333 16H11.757C12.4423 16 13 15.5931 13 15.0929V5H1V15.0929ZM3 7V14H11V7H3Z"
                fill="#141415"
              />
            </svg>
        }
      </a>
    );
  }
}

export default injectIntl(DeleteButtonComponent);
