import React, { Fragment } from 'react';
import SecondarySection from 'bundles/vendor/components/Item/SecondarySection';
import VariationsSection from 'bundles/vendor/components/Item/Variations/VariationsSection';
import { Separator } from 'libs/support/sharedComponents';
import QuantityFieldGroup from 'bundles/vendor/components/Item/FieldGroups/QuantityFieldGroup';
import NutritionsFieldGroup from 'bundles/vendor/components/Item/FieldGroups/NutritionsFieldGroup';
import TemperatureFieldGroup from 'bundles/vendor/components/Item/FieldGroups/TemperatureFieldGroup';
import CateringTagsSection from 'bundles/vendor/components/Item/Sections/CateringTagsSection';
import CateringMenuTagsSection from 'bundles/vendor/components/Item/Sections/CateringMenuTagsSection';
import PhotosSection from 'bundles/vendor/components/Item/Sections/PhotosSection';
import CustomisationsSection from 'bundles/vendor/components/Item/Sections/CustomisationsSection';
import PrimarySection from 'bundles/vendor/components/Item/Sections/PrimarySection';
import ExternalFieldGroup from 'bundles/vendor/components/Item/FieldGroups/ExternalFieldGroup';

const CateringForm = () => (
  <Fragment>
    <PrimarySection />

    <Separator />

    <VariationsSection />

    <Separator />

    <SecondarySection />
    <QuantityFieldGroup />

    <CateringMenuTagsSection />

    <NutritionsFieldGroup />
    <TemperatureFieldGroup />
    <ExternalFieldGroup />

    <Separator />

    <CateringTagsSection />

    <Separator />

    <PhotosSection />

    <Separator />

    <CustomisationsSection />
  </Fragment>
);

CateringForm.propTypes = {
};

export default CateringForm;
