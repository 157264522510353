import React, { useState } from 'react';
import { object, func } from 'prop-types';
import styled from 'styled-components';
import OutletInput from './OutletInput';
import OutletOptions from './OutletOptions';

const Container = styled.div`
`;

const OutletSelection = ({
  onSelectOutlet,
  selectedOutlet,
  selectedVendor,
}) => {
  const [isOpen, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!isOpen);
  };
  const handleBlur = () => {
    setOpen(false);
  };

  return (
    <Container className="outlet-navigation__outlet-selection">
      <OutletInput
        onClick={handleClick}
        onBlur={handleBlur}
        {
          ...{
            selectedOutlet,
            selectedVendor,
          }
        }
      />
      <OutletOptions
        {
          ...{
            isOpen,
            onSelectOutlet,
            selectedOutlet,
            selectedVendor,
          }
        }
      />
    </Container>
  );
};

OutletSelection.propTypes = {
  selectedOutlet: object.isRequired,
  selectedVendor: object.isRequired,
  onSelectOutlet: func.isRequired,
};

export default OutletSelection;
