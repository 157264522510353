import React, { Fragment } from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import {
  TextAreaField,
} from 'libs/components/Forms';
import IncludedItemsFieldGroup from 'bundles/vendor/components/Item/IncludedItems/IncludedItemsFieldGroup';

const SecondarySection = ({
  menuItem,
}) => (
  <Fragment>
    <TextAreaField
      id="menu_item_description"
      label="Description"
      name="menu_item[description]"
      value={menuItem.get('description')}
      rows={5}
    />

    <IncludedItemsFieldGroup />

    <TextAreaField
      hintText="Use this section for extra information."
      id="menu_item_other_notes"
      optionalText="optional"
      label="Other Notes"
      name="menu_item[other_notes]"
      value={menuItem.get('other_notes')}
      rows={3}
    />
  </Fragment>
);

SecondarySection.propTypes = {
  menuItem: object.isRequired,
};


const mapStateToProps = (state) => ({
  menuItem: state.$$menuItemsStore.get('menuItem'),
});

export default connect(mapStateToProps)(SecondarySection);
