import Immutable from 'immutable';

import * as actionTypes from '../constants/outletsConstants';

export const $$initialState = Immutable.fromJS({
  isFinding: false,
  isFindSuccess: false,
  isSaving: false,
  isSaveSuccess: false,
  isDeleting: false,
  isDeleteSuccess: false,
  showForm: false,
  outlets: [],
  errors: [],
});

export default function outletsReducer($$state = $$initialState, action = null) {
  const { type, response } = action;
  const defaultHash = {
    isSaving: false,
    isSaveSuccess: false,
    isDeleting: false,
    isDeleteSuccess: false,
    errors: [],
  };

  switch (type) {
    case actionTypes.FINDING_OUTLETS: {
      return $$state.withMutations(state => (
        state
          .merge(defaultHash)
          .set('isFinding', true)
      ));
    }

    case actionTypes.FIND_OUTLETS_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .update('outlets', () => Immutable.fromJS(response.outlets))
          .merge(defaultHash)
          .set('isFinding', false)
          .set('isFindSuccess', true)
      ));
    }

    default: {
      return $$state;
    }
  }
}
