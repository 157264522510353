import React, { useState, useEffect } from 'react';
import { bool, object, func, string } from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { withRouter, Redirect } from 'react-router';
import * as menuItemsActions from 'bundles/vendor/actions/menuItemsActions';
import * as categoriesActions from 'bundles/vendor/actions/categoriesActions';
import * as catererActions from 'bundles/common/actions/catererActions';
import * as filtersActions from 'bundles/vendor/actions/filtersActions';
import Header from 'bundles/vendor/components/Vendor/Header';
import { notBlank } from 'libs/support/string';
import Actions from './Actions';
import Form from './Forms/Form';

const Container = styled.div`
  padding: 1rem 1.5rem;
`;

const NewContainer = ({
  catererId,
  caterer,
  createEmptyMenuItem,
  fetchCategories,
  fetchCaterer,
  fetchFilters,
  isCategoriesFetched,
  isFiltersFetched,
  menuItem,
}) => {
  const [isInitialized, setInitialize] = useState(false);

  useEffect(() => {
    if (!isInitialized) {
      setInitialize(true);
      createEmptyMenuItem();

      if (caterer.size === 0) {
        fetchCaterer(catererId).then((data) => {
          fetchFilters(data.response.vendor.kind);
        });
      }

      if (!isCategoriesFetched) {
        fetchCategories();
      }

      if (!isFiltersFetched && caterer.size > 0) {
        fetchFilters(caterer.get('kind'));
      }
    }
  }, [isInitialized]);

  if (isInitialized && menuItem.size > 0 && notBlank(menuItem.get('uuid'))) {
    return <Redirect to={`/menus/items/${menuItem.get('uuid')}/edit`} />;
  }

  if (!isInitialized || menuItem.size === 0 || caterer.size === 0) {
    return (
      <div key={`new-item-container-${menuItem.get('tempId')}`}>
        <Header />
        <Container>Loading... </Container>
      </div>
    );
  }

  return (
    <div key={`new-item-container-${menuItem.get('tempId')}`}>
      <Header />
      <Actions />
      <Form />
    </div>
  );
};

NewContainer.propTypes = {
  catererId: string.isRequired,
  caterer: object,
  createEmptyMenuItem: func.isRequired,
  fetchCategories: func.isRequired,
  fetchCaterer: func.isRequired,
  fetchFilters: func.isRequired,
  isCategoriesFetched: bool.isRequired,
  isFiltersFetched: bool.isRequired,
  menuItem: object,
};

const mapStateToProps = (state) => ({
  catererId: state.$$catererStore.get('catererId'),
  caterer: state.$$catererStore.get('caterer'),
  isCategoriesFetched: state.$$filtersStore.get('isFiltersFetched'),
  isFiltersFetched: state.$$filtersStore.get('isFiltersFetched'),
  menuItem: state.$$menuItemsStore.get('menuItem'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createEmptyMenuItem: menuItemsActions.createEmptyMenuItem,
  fetchCaterer: catererActions.fetchCaterer,
  fetchCategories: categoriesActions.fetchCategories,
  fetchFilters: filtersActions.fetchFilters,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NewContainer));
