import React from 'react';
import { object, func } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  color: ${props => props.theme.colors.white} !important;
  font-size: 12px;
  line-height: 24px;
  opacity: 0.5;
  text-transform: uppercase;
`;

const InputContainer = styled.div`
  align-items: center;
  color: ${props => props.theme.colors.white};
  display: flex;
  font-size: 20px;
  line-height: 24px;
  position: relative;

  &:after {
    color: ${props => props.theme.colors.white} !important;
    content: '\0003E';
    display: inline-block;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.1;
    margin-left: 1rem;
    opacity: 0.5
    transform: scale(2, 1) rotate(90deg);
    transition: transform 0.3s linear;
  }
`;

const Input = styled.input`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  line-height: 24px;
  opacity: 0;
  outline: none;
  padding-right: 0.5rem;
  position: absolute;
  width: 100%
`;

const Placeholder = styled.span`
  opacity: 0.5;
`;

const OutletInput = ({
  selectedOutlet,
  selectedVendor,
  onBlur,
  onClick,
  translate,
}) => {
  const outlet = selectedVendor.get('outlets').find(o => o.get('uuid') === selectedOutlet.get('uuid'));
  const outletName = (outlet && outlet.get('name')) || '';

  return (
    <Container className="outlet-navigation__outlet-input">
      <Label>{ translate('outletNavigationOutlet') }</Label>
      <InputContainer>
        {outletName}

        {
          !outletName &&
            <Placeholder>
              {translate('outletNavigationPleaseSelectAnOutlet')}
            </Placeholder>
        }

        <Input
          value={outletName}
          readOnly
          {
            ...{
              onBlur,
              onClick,
            }
          }
        />
      </InputContainer>
    </Container>
  );
};

OutletInput.propTypes = {
  selectedOutlet: object.isRequired,
  selectedVendor: object.isRequired,
  onBlur: func.isRequired,
  onClick: func.isRequired,
  translate: func.isRequired,
};

export default withI18n(OutletInput);
