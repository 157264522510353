import React from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import MenuItemNestedAttributeFieldGroup from './MenuItemNestedAttributeFieldGroup';

const TemperatureFieldGroup = ({
  menuItem,
  temperatures,
}) => (
  <MenuItemNestedAttributeFieldGroup
    label="Temperature"
    options={temperatures}
    optionKey="temperature"
    item={menuItem.get('menu_item_temperature')}
  />
);

TemperatureFieldGroup.propTypes = {
  menuItem: object.isRequired,
  temperatures: object.isRequired,
};

const mapStateToProps = (state) => ({
  menuItem: state.$$menuItemsStore.get('menuItem'),
  temperatures: state.$$filtersStore.get('temperatures'),
});

export default connect(mapStateToProps)(TemperatureFieldGroup);
