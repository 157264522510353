import React from 'react';
import { bool, string } from 'prop-types';
import Select, { Creatable } from 'react-select';
import styled from 'styled-components';
import {
  Hint as HintStyled,
} from 'libs/support/sharedComponents';
import { FieldGroup } from 'libs/components/Forms';
import FixRequiredSelect from './FixRequiredSelect';

// https://react-select.com/styles#style-object
const customStyles = {
  control: (base, state) => {
    const {
      width = 'auto',
      isLefty = false,
      isRighty = false,
    } = state.selectProps;

    return ({
      ...base,
      fontSize: '14px',
      height: '32px',
      minHeight: '32px',
      width,
      borderTopRightRadius: isLefty ? 0 : '4px',
      borderBottomRightRadius: isLefty ? 0 : '4px',
      borderTopLeftRadius: isRighty ? 0 : '4px',
      borderBottomLeftRadius: isRighty ? 0 : '4px',
      borderRightWidth: isLefty ? 0 : '1px',
    });
  },
  menu: base => ({
    ...base,
    marginTop: '5px',
    fontSize: '14px',
  }),
  menuList: base => ({
    ...base,
    zIndex: '100',
  }),
  option: (base, state) => ({
    ...base,
    alignItems: 'center',
    display: 'flex',
    background: state.isFocused || state.isSelected ? 'rgba(125, 158, 29, 0.1);' : 'transparent',
    color: 'black',
    height: '37px',
  }),
  singleValue: (base, state) => ({
    ...base,
    alignItems: 'center',
    display: 'flex',
    background: state.isFocused || state.isSelected ? 'rgba(125, 158, 29, 0.1);' : 'transparent',
    color: 'black',
  }),
  valueContainer: base => ({
    ...base,
    padding: '0 1rem',
    height: '30px',
  }),
  indicatorsContainer: (base, state) => {
    const { hideIndicator = false } = state.selectProps;
    const display = hideIndicator ? 'none' : 'flex';

    return ({
      ...base,
      display,
      height: '30px',
    });
  },
};

const Hint = styled(HintStyled)`
  display: block;
  margin-top: 0.5rem;
`;

const SelectField = (props) => {
  const {
    fieldGroupClassName,
    hint,
    hintText,
    id,
    isCreatable,
    isRequired,
    label,
    optionalText,
  } = props;
  const Component = isCreatable ? Creatable : Select;

  const renderInput = () => (
    <div>
      <FixRequiredSelect
        SelectComponent={Component}
        className="react-select-container"
        classNamePrefix="react-select"
        styles={customStyles}
        {...props}
      />

      { hint && <Hint>{hint}</Hint> }
    </div>
  );

  if (label) {
    return (
      <FieldGroup
        label={label}
        className={fieldGroupClassName}
        hintText={hintText}
        htmlFor={id}
        isRequired={isRequired}
        optionalText={optionalText}
      >
        {renderInput()}
      </FieldGroup>
    );
  }

  return renderInput();
};

SelectField.propTypes = {
  fieldGroupClassName: string,
  hint: string,
  hintText: string,
  id: string,
  isCreatable: bool,
  isRequired: bool,
  label: string,
  optionalText: string,
};

export default SelectField;
