import React from 'react';
import { integer } from 'prop-types';
import styled from 'styled-components';
import {
  Cell,
  Row,
  GreyFont,
} from 'libs/support/sharedComponents';

const BodyRow = styled(Row)`
  height: 40px;
  vertical-align: top;
`;

const NoRecord = ({
  colSpan,
}) => (
  <BodyRow colSpan={colSpan}>
    <Cell>
      <GreyFont>
        No record found.
      </GreyFont>
    </Cell>
  </BodyRow>
);

NoRecord.propTypes = {
  colSpan: integer,
};

export default NoRecord;
