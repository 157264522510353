/* eslint react/no-array-index-key:0 */

import React, { useEffect, useState } from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import {
  Table,
  HeaderRow,
  Header,
  TBody,
  Spacer,
} from 'libs/support/sharedComponents';
import styled from 'styled-components';
import {
  SecondaryButton,
} from 'bundles/common/components/Buttons';
import { notBlank } from 'libs/support/string';
import Subheader from 'bundles/vendor/components/Item/TaxTypes/Subheader';
import ServiceAddOnOptionFields from './ServiceAddOnOptionFields';

const Container = styled.div`

`;

const ServiceAddOnOptionsList = ({
  caterer,
  serviceAddOnOptions,
}) => {
  const markupPriceEnabled = caterer.get('markup_price_enabled');
  const markupPricePercent = caterer.get('markup_price_percent') && markupPriceEnabled ?
    caterer.get('markup_price_percent') : '0';
  const taxType = caterer.get('tax_type');
  const taxRate = caterer.get('country_tax_rate') || '0';
  const [options, setOptions] = useState(serviceAddOnOptions);
  const handleAddOption = () => {
    setOptions(options.push(Immutable.fromJS({})));
  };
  const handleDelete = (index) => {
    if (notBlank(options.getIn([index, 'uuid']))) {
      setOptions(options.setIn([index, 'deleted_at'], new Date()));
    } else {
      setOptions(options.delete(index));
    }
  };

  const [isInitialized, setInitialize] = useState(false);

  useEffect(() => {
    if (!isInitialized) {
      setInitialize(true);

      if (options.size === 0) {
        setOptions(options.push(Immutable.fromJS({})));
      }
    }
  });

  return (
    <Container>
      <Subheader taxType={taxType} />
      <Spacer />
      <Table>
        <thead>
          <HeaderRow>
            <Header key="position" />
            <Header key="name" >Name</Header>
            <Header key="price" >Price</Header>
            <Header key="display_price" >Display Price</Header>
            <Header key="actions" />
          </HeaderRow>
        </thead>
        <TBody>
          {
            options.size > 0 &&
              options.map((option, index) => (
                <ServiceAddOnOptionFields
                  key={`option_${option.get('uuid') || ''}_${index}`}
                  option={option}
                  index={index}
                  count={options.size}
                  markupPricePercent={markupPricePercent}
                  onDelete={handleDelete}
                  taxRate={taxRate}
                  taxType={taxType}
                />
              ))
          }
        </TBody>
      </Table>

      <Spacer />

      <SecondaryButton
        name="Add Another"
        onClick={handleAddOption}
      />
    </Container>
  );
};

ServiceAddOnOptionsList.propTypes = {
  caterer: object,
  serviceAddOnOptions: object,
};

const mapStateToProps = ({
  $$catererStore,
  $$customisationsStore,
}) => ({
  caterer: $$catererStore.get('caterer'),
  serviceAddOnOptions: $$customisationsStore.getIn(['customisation', 'service_add_on_options']),
});

export default connect(mapStateToProps)(ServiceAddOnOptionsList);
