import React, { useState, useEffect } from 'react';
import { bool, object, func, string } from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { withI18n } from 'libs/support/i18n';
import * as catererActions from 'bundles/common/actions/catererActions';
import * as categoriesActions from 'bundles/vendor/actions/categoriesActions';
import * as orderNoticesActions from 'bundles/vendor/actions/orderNoticesActions';
import * as baseDeliveryFeesActions from 'bundles/vendor/actions/baseDeliveryFeesActions';
import * as messagesActions from 'bundles/common/actions/messagesActions';
import Header from 'bundles/vendor/components/Vendor/Header';
import Form from './Form';

const Container = styled.div`
  padding: 1rem 1.5rem;
`;

const EditCategoryContainer = ({
  catererId,
  caterer,
  category,
  fetchBaseDeliveryFees,
  fetchCategory,
  fetchCaterer,
  fetchOrderNotices,
  history,
  isBaseDeliveryFeesFetched,
  isOrderNoticesFetched,
  match,
  showAlertMessage,
  translate,
}) => {
  const [isInitialized, setInitialize] = useState(false);

  useEffect(() => {
    if (!isInitialized) {
      setInitialize(true);
      if (category.size === 0 || category.get('uuid') !== match.params.id) {
        fetchCategory(match.params.id);
      }

      if (!isOrderNoticesFetched) {
        fetchOrderNotices();
      }

      if (!isBaseDeliveryFeesFetched) {
        fetchBaseDeliveryFees();
      }

      if (caterer.size === 0) {
        fetchCaterer(catererId);
      }
    }
  });

  if (!isInitialized || category.size === 0 || caterer.size === 0 || match.params.id !== category.get('uuid')) {
    return (
      <div key={`edit-container-${category.get('uuid')}`}>
        <Header />
        <Container>Loading... </Container>
      </div>
    );
  }

  if (category.get('uuid') === match.params.id) {
    const inUseMealPlans = category.get('in_use_meal_plans');

    if (inUseMealPlans.size > 0) {
      showAlertMessage({
        title: translate('vendorModalsEditCategoryWarningTitle'),
        message: translate('vendorModalsEditCategoryWarningMessage'),
        submit: () => (
          history.push('/menus/categories')
        ),
      });
    }
  }

  return (
    <div key={`edit-category-container-${category.get('uuid')}`}>
      <Header />
      <Form />
    </div>
  );
};

EditCategoryContainer.propTypes = {
  catererId: string.isRequired,
  caterer: object,
  category: object,
  fetchBaseDeliveryFees: func.isRequired,
  fetchCategory: func.isRequired,
  fetchCaterer: func.isRequired,
  fetchOrderNotices: func.isRequired,
  history: object,
  isBaseDeliveryFeesFetched: bool,
  isOrderNoticesFetched: bool,
  match: object.isRequired,
  showAlertMessage: func.isRequired,
  translate: func.isRequired,
};

const mapStateToProps = ({
  $$baseDeliveryFeesStore,
  $$catererStore,
  $$categoriesStore,
  $$orderNoticesStore,
}) => ({
  catererId: $$catererStore.get('catererId'),
  caterer: $$catererStore.get('caterer'),
  category: $$categoriesStore.get('category'),
  isBaseDeliveryFeesFetched: $$baseDeliveryFeesStore.get('isBaseDeliveryFeesFetched'),
  isOrderNoticesFetched: $$orderNoticesStore.get('isOrderNoticesFetched'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchBaseDeliveryFees: baseDeliveryFeesActions.fetchBaseDeliveryFees,
  fetchCategory: categoriesActions.fetchCategory,
  fetchCaterer: catererActions.fetchCaterer,
  fetchOrderNotices: orderNoticesActions.fetchOrderNotices,
  showAlertMessage: messagesActions.showAlertMessage,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withI18n(EditCategoryContainer)));
