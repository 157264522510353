import * as api from 'libs/middlewares/api';

import * as actionTypes from '../constants/orderConstants';

const apiUrl = '/orders';

export function fetchOrders(params = {}) {
  const types = [
    actionTypes.FETCHING_ORDERS,
    actionTypes.FETCH_ORDERS_SUCCESS,
    actionTypes.FETCH_ORDERS_FAILURE,
  ];
  return api.get({
    types,
    endpoint: apiUrl,
    params,
    id: null,
  });
}
