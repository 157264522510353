/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import debounce from 'lodash/debounce';
import {
  ModalContainer,
} from 'libs/support/sharedComponents';
import Modal from 'libs/components/ModalComponent';
import IconClose from 'libs/components/Icons/v2/IconClose';
import ChevronRight from 'libs/components/Icons/v2/ChevronRight';
import classnames from 'classnames';
import { object, func, bool } from 'prop-types';
import { trackClickedChangeOutletButton } from 'packs/events';

import OutletSelectorMobile from './OutletSelectorMobile';

const OutletSelectorModal = ({
  isShowOutletSelectorModal,
  setIsShowOutletSelectorModal,
  vendors,
  onSaveOutletsSelected,
  mobile,
  outletsSelectedIds,
  vendorSelected,
}) => {
  const [selectedVendorId, setSelectedVendorId] = useState(vendorSelected || {});
  const [selectedOutlets, setSelectedOutlets] = useState(outletsSelectedIds || []);
  const [filteredVendors, setFilteredVendors] = useState(vendors || []);

  // eslint-disable-next-line
  const outlets = vendors.find((vendor) => vendor.getIn(['table', 'uuid']) === selectedVendorId)?.getIn(['table', 'outlets']) || [];

  const handleCheckBoxChange = (uuid) => {
    if (selectedOutlets.includes(uuid)) {
      setSelectedOutlets(selectedOutlets.filter((outlet) => outlet !== uuid));
    } else {
      setSelectedOutlets([...selectedOutlets, uuid]);
    }
  };

  const handleBrandChange = (uuid) => {
    setSelectedVendorId(uuid);
    setSelectedOutlets([]);
  };

  const handleOnHideModal = () => {
    setIsShowOutletSelectorModal(false);
    document.querySelector('footer').style.display = 'flex';
  };

  const handleSubmit = () => {
    trackClickedChangeOutletButton();
    onSaveOutletsSelected({
      vendor_selected_id: selectedVendorId,
      outlet_selected_ids: selectedOutlets,
    });
    handleOnHideModal();
  };

  const debouncedSearch = debounce((query) => {
    // eslint-disable-next-line
    const newFilteredVendors = vendors.filter((vendor) => vendor.getIn(['table', 'name']).toLowerCase().includes(query.toLowerCase()));
    setFilteredVendors(newFilteredVendors);
  }, 500);

  const onChangeSearchInput = (e) => {
    debouncedSearch(e.target.value);
    setSelectedOutlets([]);
  };

  return (
    <ModalContainer>
      <Modal
        show={isShowOutletSelectorModal}
        className="align-left"
        onHide={() => { handleOnHideModal(); }}
      >
        {mobile ? (
          <OutletSelectorMobile
            {
              ...{
                vendors,
                selectedVendorId,
                selectedOutlets,
                handleBrandChange,
                handleCheckBoxChange,
                handleSubmit,
                setIsShowOutletSelectorModal,
                outlets,
              }
            }
          />
        ) :
          <div className="outlet-selector-desktop">
            <div
              className="d-flex"
              style={{
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '20px',
                borderBottom: '1px solid #EAEAEB',
              }}
            >
              <div className="search-container">
                <input
                  type="text"
                  placeholder="Search..."
                  className="search-input"
                  onChange={(e) => onChangeSearchInput(e)}
                />
              </div>
              <span
                onClick={() => { handleOnHideModal(); }}
                style={{ cursor: 'pointer' }}
              >
                <IconClose />
              </span>
            </div>
            <div
              className="d-flex flex-column"
              style={{
                minHeight: '80vh',
              }}
            >
              <div style={{ width: '50%' }}>
                <div style={{ padding: '8px 25px 8px 25px', borderBottom: '1px solid #ECEDED' }}>
                  <span>Brands</span>
                </div>
                <div className="outlets-list-container">
                  {filteredVendors.map((vendor) => (
                    <div
                      className={classnames('d-flex outlet-selector-selection', {
                        '--selected': selectedVendorId === vendor.getIn(['table', 'uuid']),
                      })}
                      onClick={() => handleBrandChange(vendor.getIn(['table', 'uuid']))}
                      key={vendor.getIn(['table', 'uuid'])}
                    >
                      <div className="d-flex" style={{ flexDirection: 'column', padding: '10px 16px 10px 16px' }}>
                        <span style={{ fontWeight: '600' }}>{vendor.getIn(['table', 'name'])}</span>
                        <span>{vendor.getIn(['table', 'outlets']).size} Outlets</span>
                      </div>

                      <ChevronRight />
                    </div>
                  ))}
                </div>
              </div>
              <div style={{ width: '50%', borderLeft: '1px solid #ECEDED' }}>
                <div style={{ padding: '8px 25px 8px 25px', borderBottom: '1px solid #ECEDED' }}>
                  <span>Outlets</span>
                </div>
                <div className="outlets-list-container">
                  {outlets.map((outlet) => (
                    <div
                      className="d-flex outlet-selector-selection"
                      onClick={() => handleCheckBoxChange(outlet.get('uuid'))}
                    >
                      <div className="d-flex" style={{ flexDirection: 'column', padding: '10px 16px 10px 16px' }}>
                        <span style={{ fontWeight: '600' }}>{outlet.get('name')}</span>
                      </div>

                      <input
                        type="checkbox"
                        name={`outlet-${outlet.get('uuid')}`}
                        className="custom-checkbox-v2"
                        id={`outlet-${outlet.get('uuid')}`}
                        checked={selectedOutlets.includes(outlet.get('uuid'))}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              className=""
              style={{
                padding: '20px',
                marginTop: '1rem',
                display: 'flex',
                justifyContent: 'space-between',
                position: 'sticky',
                bottom: '0',
                zIndex: '999',
                borderTop: '1px solid #F4F5F5',
              }}
            >
              <button className="btn btn-default">Clear</button>
              <div>
                <button className="btn btn-default mr-1">Cancel</button>
                <button className="btn btn-primary" onClick={() => handleSubmit()}>Confirm selection</button>
              </div>
            </div>
          </div>
        }
      </Modal>
    </ModalContainer>
  );
};

OutletSelectorModal.propTypes = {
  isShowOutletSelectorModal: bool.isRequired,
  setIsShowOutletSelectorModal: func.isRequired,
  vendors: object.isRequired,
  onSaveOutletsSelected: func.isRequired,
  mobile: bool.isRequired,
  outletsSelectedIds: object.isRequired,
  vendorSelected: object.isRequired,
};

export default OutletSelectorModal;
