import * as api from 'libs/middlewares/api';
import * as actionTypes from '../constants/categoriesConstants';

export const saveCategory = (id, params) => {
  const types = [
    actionTypes.SAVING_CATEGORY,
    actionTypes.SAVE_CATEGORY_SUCCESS,
    actionTypes.SAVE_CATEGORY_FAILURE,
  ];

  return api.save({
    types,
    endpoint: '/menus/categories',
    params,
    id,
  });
};

export const saveCategories = (params) => {
  const {
    SAVING_CATEGORIES,
    SAVE_CATEGORIES_SUCCESS,
    SAVE_CATEGORIES_FAILURE,
  } = actionTypes;

  return api.save({
    types: [SAVING_CATEGORIES, SAVE_CATEGORIES_SUCCESS, SAVE_CATEGORIES_FAILURE],
    endpoint: '/menus/categories/bulk_update',
    params,
    forceUpdate: true,
  });
};

export const deleteCategory = (id) => {
  const types = [
    actionTypes.DELETING_CATEGORY,
    actionTypes.DELETE_CATEGORY_SUCCESS,
    actionTypes.DELETE_CATEGORY_FAILURE,
  ];

  return api.destroy({
    types,
    endpoint: '/menus/categories',
    params: null,
    id,
  });
};

export const fetchCategories = () => {
  const types = [
    actionTypes.FETCHING_CATEGORIES,
    actionTypes.FETCH_CATEGORIES_SUCCESS,
    actionTypes.FETCH_CATEGORIES_FAILURE,
  ];

  return api.get({
    types,
    endpoint: '/menus/categories',
    params: {},
  });
};

export const fetchCategory = (id) => {
  const types = [
    actionTypes.FETCHING_CATEGORY,
    actionTypes.FETCH_CATEGORY_SUCCESS,
    actionTypes.FETCH_CATEGORY_FAILURE,
  ];

  return api.get({
    types,
    endpoint: '/menus/categories',
    params: {},
    id,
  });
};

export const toggleCategoryActive = (id) => {
  const { TOGGLING_CATEGORY_ACTIVE, TOGGLE_CATEGORY_ACTIVE_SUCCESS, TOGGLE_CATEGORY_ACTIVE_FAILURE } = actionTypes;

  return api.save({
    types: [TOGGLING_CATEGORY_ACTIVE, TOGGLE_CATEGORY_ACTIVE_SUCCESS, TOGGLE_CATEGORY_ACTIVE_FAILURE],
    endpoint: `/menus/categories/${id}/toggle_active`,
    params: {},
    forceUpdate: true,
  });
};

export const removeNoticeFlash = () => (
  { type: actionTypes.REMOVE_NOTICE_FLASH }
);

export const resetFlash = () => (
  { type: actionTypes.RESET_FLASH }
);

export const createEmptyCategory = () => (
  { type: actionTypes.CREATE_EMPTY_CATEGORY }
);

export function addCategoryAvailability() {
  return { type: actionTypes.ADD_CATEGORY_AVAILABILITY };
}

export function deleteCategoryAvailability(tempId) {
  return { type: actionTypes.DELETE_CATEGORY_AVAILABILITY, response: tempId };
}
