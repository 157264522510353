import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'libs/components/ModalComponent';
import ModalHeader from 'libs/components/ModalHeaderComponent';
import ModalBody from 'libs/components/ModalBodyComponent';
import ModalFooter from 'libs/components/ModalFooterComponent';
import {
  CheckBox,
  FieldGroup,
  InputField,
  TextAreaField,
} from 'libs/components/Forms';
import { blankish } from 'libs/support/string';
import { Spacer } from 'libs/support/sharedComponents';
import { SubmitButton } from 'bundles/common/components/Buttons';

const OrderDeclineModal = ({ show, onHide, onSubmit }) => {
  const reasons = [
    'Item(s) not available',
    'Kitchen fully booked',
    'Kitchen closed',
    'Staff shortage',
  ];

  const formId = 'decline-order-form';

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = document.getElementById(formId);
    const params = new FormData(form);
    const declineReasons = ((otherReason) => {
      const reason = params.getAll('decline_reasons[]');
      if (blankish(otherReason)) {
        return reason;
      }
      return [...reason, `Other: ${otherReason}`];
    })(params.get('other_reason'));

    onSubmit({
      decline_reasons: declineReasons,
      decline_detail: params.get('decline_detail'),
    });

    onHide();
  };

  return (
    <Modal
      show={show}
      className="modal-lg modal-decline-form"
      onHide={onHide}
    >
      <ModalHeader enableClose>
        <h5 className="modal-title">Declining Order</h5>
      </ModalHeader>

      <ModalBody>
        <div className="modal-body-content container-fluid">
          <p>Why are you declining this order?</p>
          <form id={formId}>
            {
              reasons.map((reason, id) => (
                <div>
                  <CheckBox
                    text={reason}
                    id={`decline-reason-${id}`}
                    value={reason}
                    name="decline_reasons[]"
                  />
                  <Spacer />
                </div>
              ))
            }
            <FieldGroup
              label="Other reason:"
            >
              <InputField
                placeholder="Specify other reason"
                name="other_reason"
              />
            </FieldGroup>

            <TextAreaField
              placeholder="Please provide more detail here."
              name="decline_detail"
            />
          </form>
        </div>
      </ModalBody>

      <ModalFooter>
        <SubmitButton
          onClick={handleSubmit}
          name="Decline Order"
          form={formId}
        />
      </ModalFooter>
    </Modal>
  );
};

OrderDeclineModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default OrderDeclineModal;
