import React from 'react';
import { any } from 'prop-types';

import BaseComponent from 'libs/components/BaseComponent';

export default class ModalBodyComponent extends BaseComponent {
  static propTypes = {
    children: any,
  };

  render = () => (
    <div className="modal-footer">
      {this.props.children}
    </div>
  )
}
