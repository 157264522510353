/* eslint max-len: 0 */

import adminsReducer, { $$initialState as $$vendorAdminState } from './adminsReducer';

export default {
  $$vendorAdminStore: adminsReducer,
};

export const initialStates = {
  $$vendorAdminState,
};
