import React from 'react';
import { bool, any } from 'prop-types';

import BaseComponent from 'libs/components/BaseComponent';
import ChevronLeft from 'libs/components/Icons/v2/ChevronLeft';

export default class ModalHeaderComponent extends BaseComponent {
  static propTypes = {
    enableClose: bool,
    children: any,
  };

  render = () => {
    const { enableClose, children } = this.props;

    return (
      <div className="modal-header">
        {
          (enableClose) &&
            <button
              type="button"
              className="close-mobile"
              data-dismiss="modal"
              aria-label="Close"
            >
              <ChevronLeft />
            </button>
        }
        {children}

        {
          (enableClose) &&
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
        }
      </div>
    );
  }
}
