import styled from 'styled-components';

export const Button = styled.button`
  border-radius: 4px;
  cursor: pointer;
  line-height: normal;
  outline: none;
  padding: 7px 15px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;

  &:active {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }
}`;

export const PrimaryButton = styled(Button)`
  background: ${props => props.theme.colors.lima};
  border: 1px solid ${props => props.theme.colors.lima};
  color: ${props => props.theme.colors.white};
  font-family: ${props => props.theme.fonts.proxima};
  font-size: 14px;

  &:hover {
    border: 1px solid ${props => props.theme.colors.lima} !important;
    background: ${props => props.theme.colors.lima} !important;
    color: ${props => props.theme.colors.white} !important;
    opacity: 0.9;
  }
}`;

export const SecondaryButton = styled(Button)`
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.gainsboro};
  color: ${props => props.theme.colors.gunPowder};
  font-family: ${props => props.theme.fonts.proxima};
  font-size: 14px;

  &:active {
    border: 1px solid ${props => props.theme.colors.gainsboro} !important;
    background: ${props => props.theme.colors.white} !important;
    color: ${props => props.theme.colors.gunPowder};
    opacity: 0.9;
  }

  &:hover {
    border: 1px solid ${props => props.theme.colors.gainsboro} !important;
    background: ${props => props.theme.colors.white} !important;
    color: ${props => props.theme.colors.gunPowder};
    opacity: 0.9;
  }
}`;

export const BackButton = styled.button`
  background: transparent;
  border-radius: 3px;
  border: 1px solid ${props => props.theme.colors.lima};
  box-sizing: border-box;
  height: 40px;
  width: 40px;

  a {
    align-items: center;
    display: flex;
    justify-content: center;
  }
`;
