import React from 'react';
import $ from 'jquery';
import { bool, string, func } from 'prop-types';
import { notBlank } from 'libs/support/string';
import BaseComponent from './BaseComponent';

export default class ModalComponent extends BaseComponent {
  static propTypes = {
    elementId: string,
    show: bool,
    className: string,
    onHide: func,
    lockOverlay: bool,
  };

  componentDidMount() {
    const { show, onHide } = this.props;

    $(this.modal).on('hidden.bs.modal', () => {
      if (notBlank(onHide)) onHide();
    });

    if (show) {
      $(this.modal).modal('show');
    }
  }

  componentDidUpdate(prevProps) {
    const { show } = this.props;

    if (prevProps.show !== show) {
      if (show) {
        $(this.modal).modal('show');
      } else {
        $(this.modal).modal('hide');
      }
    }
  }

  render = () => {
    const {
      className,
      children,
      elementId,
      lockOverlay,
    } = this.props;

    let finalClassName = 'modal-dialog modal-dialog-centered';

    if (notBlank(className)) finalClassName = `${finalClassName} ${className}`;

    const finalElementId = elementId || `modal_${parseInt(Math.random() * 10, 10)}`;

    const dataAttributes = lockOverlay ? {
      'data-keyboard': 'false',
      'data-backdrop': 'static',
    } : {};

    return (
      <div
        id={finalElementId}
        ref={node => { this.modal = node; }}
        className="modal fade"
        role="dialog"
        {...dataAttributes}
      >
        <div className={finalClassName}>
          <div className="modal-content">
            {children}
          </div>
        </div>
      </div>
    );
  }
}
