import * as api from 'libs/middlewares/api';
import * as actionTypes from '../constants/catererConstants';

export const fetchCaterer = (id) => {
  const types = [
    actionTypes.FETCHING_CATERER,
    actionTypes.FETCH_CATERER_SUCCESS,
    actionTypes.FETCH_CATERER_FAILURE,
  ];

  return api.get({
    types,
    endpoint: '/vendors',
    params: {},
    id,
  });
};
