import { useEffect } from 'react';

export const useURLState = (params) => {
  useEffect(() => {
    const currentUrl = new URL(location.href); // eslint-disable-line

    Object.entries(params).forEach(([key, value]) => {
      currentUrl.searchParams.set(key, value);
    });

    const newUrl = currentUrl.href;

    history.pushState(null, null, newUrl); // eslint-disable-line
  }, [params]);
};
