import pluralize from 'pluralize';
import startCase from 'lodash/startCase';
import camelize from 'camelize';
import { blankish } from 'libs/support/string';
import Immutable from 'immutable';

const buildOption = option => (
  {
    label: option.get('name'),
    value: option.get('uuid'),
  }
);

export const buildProps = (state) => {
  const menuItem = state.$$menuItemsStore.get('menuItem');
  const filtersStore = state.$$filtersStore;

  return ({
    menuItem,
    filtersStore,
  });
};

export const buildOptions = (menuItem, filtersStore, kind) => {
  const pluralName = pluralize(kind);
  const camelcaseName = camelize(pluralName);
  const filters = filtersStore.get(camelcaseName);
  const menuItemPresentations = menuItem.get(`menu_item_${pluralName}`);
  const selectOptions = filters.map(buildOption);
  const selectedOptionIds = (
    menuItemPresentations && menuItemPresentations.map(item => item.get(`${kind}_id`))
  ) || [];
  const initialSelectedOptions = (
    filters && selectedOptionIds && filters
      .filter(item => selectedOptionIds.includes(item.get('uuid')))
      .map(buildOption).toJS()
  ) || [];

  return ({
    initialSelectedOptions,
    selectOptions,
  });
};

export const filterOptions = (selectOptions, selectedOptions, maxLimit) => {
  const isMaxedOut = maxLimit && selectedOptions.length >= maxLimit;
  const filteredOptions = isMaxedOut ? Immutable.fromJS([]) : selectOptions;
  const noOptionsMessage = () => (isMaxedOut ? 'Selection is maxed out' : 'No options');

  return {
    filteredOptions,
    noOptionsMessage,
  };
};

export const removeUnderscore = (kind) => kind.replace(/_/g, ' ');

export const getHintText = (kind, limit) => {
  const displayKind = removeUnderscore(kind);

  if (blankish(limit)) {
    return 'Select all that apply';
  } else if (limit > 1) {
    return `Add up to ${limit} ${pluralize(displayKind)}`;
  }

  return `Add only 1 ${displayKind}`;
};

export const buildInputAttrs = (kind, limit) => {
  const pluralName = pluralize(kind);
  const inputId = `menu_item_menu_item_${pluralName}_attributes_${kind}_id`;
  const inputName = `menu_item[menu_item_${pluralName}_attributes][][${kind}_id]`;
  const label = startCase(removeUnderscore(pluralName));
  const hintText = getHintText(kind, limit);
  const className = `${kind}-tags`;

  return {
    className,
    hintText,
    inputId,
    inputName,
    label,
  };
};
