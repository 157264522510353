const driverParamsBuilder = (orderDriverNameOrId, driverPhoneNumber, vendorDrivers) => {
  const driverId = vendorDrivers.find((driver) => driver.uuid === orderDriverNameOrId) ? orderDriverNameOrId : null;

  const driverName = driverId ? '' : orderDriverNameOrId; // if driverId exists, driverName is empty

  return {
    driverId,
    driverPhoneNumber,
    driverName,
  };
};

export default driverParamsBuilder;
