import React, { Fragment, useEffect, useState } from 'react';
import { bool, func } from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as categoriesActions from 'bundles/vendor/actions/categoriesActions';
import { default as MainHeader } from 'bundles/vendor/components/Vendor/Header'; /* eslint import/no-named-default: 0 */
import Tabs from 'bundles/vendor/components/Vendor/Tabs';
import Flash from 'bundles/vendor/components/Category/Flash';
import {
  Spacer,
} from 'libs/support/sharedComponents';
import Actions from './Actions';
import List from './List';

const Container = styled.div`
  padding: 1.5rem;
`;

const CategoriesContainer = ({
  fetchCategories,
  isCategoriesFetched,
}) => {
  const [isInitialized, setInitialize] = useState(false);

  useEffect(() => {
    if (!isInitialized) {
      setInitialize(true);
      if (!isCategoriesFetched) {
        fetchCategories();
      }
    }
  }, [isInitialized]);

  return (
    <Fragment>
      <MainHeader />
      <Tabs />
      <Container>
        <Flash />
        <Spacer />
        <Actions />
        <List />
      </Container>
    </Fragment>
  );
};

CategoriesContainer.propTypes = {
  fetchCategories: func.isRequired,
  isCategoriesFetched: bool,
};

const mapStateToProps = ({
  $$categoriesStore,
}) => ({
  isCategoriesFetched: $$categoriesStore.get('isCategoriesFetched'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCategories: categoriesActions.fetchCategories,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesContainer);
