import React from 'react';
import { func, object } from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withI18n } from 'libs/support/i18n';
import { SelectField } from 'libs/components/Forms';
import { useSelectInput } from 'libs/support/selectInput';
import { bindActionCreators } from 'redux';
import * as menuItemsActions from 'bundles/vendor/actions/menuItemsActions';
import { withRouter } from 'react-router';

const Container = styled.div`
  min-width: 50%;

  @media (min-width: ${props => props.theme.breakPoints.lg}) {
    min-width: 300px;
  }
`;

const CategoryFilter = ({
  categories,
  filterMenuItemsByCategory,
  history,
  translate,
}) => {
  const url = new URL(window.location);
  const initialValue = url.searchParams.get('filter_category_id') || 'all';
  const options = categories.map((category) => (
    {
      label: category.get('name'),
      value: category.get('uuid'),
    }
  )).unshift({
    label: translate('vendorMenuItemCategoryFilterAll'),
    value: 'all',
  });
  const [selectedOption, handleOptionChange] = useSelectInput(
    options,
    initialValue,
  );
  filterMenuItemsByCategory(initialValue);
  const handleChange = (data) => {
    const newUrl = new URL(window.location);
    handleOptionChange(data);
    filterMenuItemsByCategory(data.value);

    if (data.value === 'all') {
      newUrl.searchParams.delete('filter_category_id');
    } else {
      newUrl.searchParams.set('filter_category_id', data.value);
    }
    history.push(`${newUrl.pathname}${newUrl.search}`);
  };

  return (
    <Container>
      <SelectField
        id="menu_item_category"
        name="menu_item[category]"
        value={[selectedOption]}
        options={options}
        onChange={handleChange}
        isRequired
        isRighty
      />
    </Container>
  );
};

CategoryFilter.propTypes = {
  categories: object,
  filterMenuItemsByCategory: func.isRequired,
  history: object.isRequired,
  translate: func.isRequired,
};

const mapStateToProps = ({
  $$categoriesStore,
}) => ({
  categories: $$categoriesStore.get('categories'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  filterMenuItemsByCategory: menuItemsActions.filterMenuItemsByCategory,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withI18n(CategoryFilter)));
