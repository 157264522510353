/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable quotes */
import React, { useState, Fragment } from 'react';
import IconClose from 'libs/components/Icons/v2/IconClose';
import ChevronRight from 'libs/components/Icons/v2/ChevronRight';
import ChevronLeft from 'libs/components/Icons/v2/ChevronLeft';
import { object, func, string } from 'prop-types';
import { trackClickedChangeOutletButton } from "packs/events";

const STEPS = {
  VENDOR: 1,
  OUTLET: 2,
};

const OutletSelectorMobile = ({
  vendors,
  selectedVendorId,
  selectedOutlets,
  handleBrandChange,
  handleCheckBoxChange,
  handleSubmit,
  setIsShowOutletSelectorModal,
  outlets,
}) => {
  const [currentStep, setCurrentStep] = useState(1); // 1 - vendor, 2 - outlet

  const onChangeBrand = (uuid) => {
    handleBrandChange(uuid);
    setCurrentStep(STEPS.OUTLET);
  };

  const onClickSubmit = () => {
    trackClickedChangeOutletButton();
    handleSubmit();
    setCurrentStep(STEPS.VENDOR);
  };

  const selectedVendor = vendors.find((vendor) => vendor.getIn(['table', 'uuid']) === selectedVendorId);

  return (
    <div className="outlet-selector-mobile">
      <div
        className=""
        style={{
          minHeight: '80vh',
        }}
      >

        {currentStep === STEPS.VENDOR && (
          <Fragment>
            <div className="d-flex">
              <span
                onClick={() => { setIsShowOutletSelectorModal(false); }}
                style={{ cursor: 'pointer' }}
              >
                <IconClose />
              </span>
              <span style={{ marginLeft: '6rem' }}>Select a Vendor</span>
            </div>
            <div className="outlets-list-container-mobile">
              {vendors.map((vendor) => (
                <div
                  className="d-flex outlet-selector-selection mobile"
                  onClick={() => onChangeBrand(vendor.getIn(['table', 'uuid']))}
                  key={vendor.getIn(['table', 'uuid'])}
                >
                  <div className="d-flex outlet-selector-selection-group-text">
                    <span style={{ fontWeight: '600' }}>{vendor.getIn(['table', 'name'])}</span>
                    <span>{vendor.getIn(['table', 'outlets']).size} Outlets</span>
                  </div>

                  <ChevronRight />
                </div>
              ))}
            </div>
          </Fragment>
        )}

        {currentStep === STEPS.OUTLET && (
          <Fragment>
            <div className="d-flex">
              <span
                onClick={() => { setCurrentStep(1); }}
                style={{ cursor: 'pointer' }}
              >
                <ChevronLeft />
              </span>
              <div className="outlet-selector-mobile-text-header">
                <span>{selectedVendor.getIn(['table', 'name'])}</span>
                <span>Select Outlets</span>
              </div>
            </div>
            <div className="outlets-list-container-mobile outlets-list-container-mobile-outlets">
              {outlets.map((outlet) => (
                <div
                  className="d-flex outlet-selector-selection mobile"
                  key={outlet.get('uuid')}
                  onClick={() => handleCheckBoxChange(outlet.get('uuid'))}
                >
                  <div className="d-flex vendor-selected-text">
                    <span style={{ fontWeight: '600' }}>{outlet.get('name')}</span>
                  </div>

                  <input
                    type="checkbox"
                    name={`outlet-${outlet.get('uuid')}`}
                    className="custom-checkbox-v2"
                    id={`outlet-${outlet.get('uuid')}`}
                    checked={selectedOutlets.includes(outlet.get('uuid'))}
                  />
                </div>
              ))}
            </div>
          </Fragment>
        )}
      </div>
      {currentStep === 2 && (
        <div
          className="outlet-selector-mobile-footer-button"
        >
          <button
            className="btn btn-primary btn-block"
            onClick={() => onClickSubmit()}
            disabled={selectedOutlets.length === 0}
          >
            Confirm selection
          </button>
        </div>
      )}
    </div>
  );
};

OutletSelectorMobile.propTypes = {
  vendors: object.isRequired,
  selectedVendorId: string.isRequired,
  selectedOutlets: object.isRequired,
  handleBrandChange: func.isRequired,
  handleCheckBoxChange: func.isRequired,
  handleSubmit: func.isRequired,
  setIsShowOutletSelectorModal: func.isRequired,
  outlets: object.isRequired,
};

export default OutletSelectorMobile;
