import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';

const SecondaryText = styled.div`
  color: ${props => props.theme.colors.gray800};
`;

const HistoryWithoutDiffV2 = ({ orderHistory }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12 font-weight-bold">Delivery Date:</div>
      <div className="col-xs-12">
        {
          orderHistory.getIn(['data', 'deliver_at']) &&
            moment(orderHistory.getIn(['data', 'deliver_at']))
            .format('ddd MMM D h:mm A')
        }
      </div>
    </div>
    {
      orderHistory.getIn(['data', 'order_variations']).map((orderVariation, index) => (
        <div
          key={index} // eslint-disable-line react/no-array-index-key
        >
          <div className="row mt-2">
            <div className="col-xs-1">
              {orderVariation.get('quantity')}x
            </div>
            <div className="col-xs-9">
              {orderVariation.get('name')}
            </div>
            <div className="col-xs-2 text-right">
              ${orderVariation.get('v_total_price')}
            </div>
          </div>

          {
            orderVariation.get('option_choices').size > 0 &&
              <div className="row mt-2">
                <div className="col-xs-2 offset-1">Options</div>
                <div className="col-xs-9">
                  {orderVariation.get('option_choices').map(option => (
                    <div key={option.get('option_choice_id')}>
                      <span className="font-weight-bold">
                        {option.get('quantity')}x
                      </span>
                      <span className="ml-2">
                        {option.get('name')}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
          }

          {
            (orderVariation.get('item_add_on_options').size > 0 ||
              orderVariation.get('service_add_on_options').size > 0) &&
              <div className="row mt-4">
                <div className="col-xs-2">Options</div>
                <div className="col-xs-10">
                  {orderVariation.get('item_add_on_options').map(option => (
                    <div className="row" key={option.get('item_add_on_option_id')}>
                      <div className="col-xs-10">
                        <span className="font-weight-bold">
                          {option.get('quantity')}x
                        </span>
                        <span className="ml-2">
                          {option.get('name')}
                        </span>
                        {
                          option.get('photo') &&
                            <SecondaryText className="ml-2">
                              <a
                                href={option.get('photo')}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {option.get('photo').split('/').pop()}
                              </a>
                            </SecondaryText>
                        }
                        {
                          option.get('text') &&
                            <SecondaryText className="ml-2">
                              {option.get('text')}
                            </SecondaryText>
                        }
                      </div>
                      <div className="col-xs-2 text-right">
                        {
                          option.get('v_total_price') > 0 &&
                            `$${option.get('v_total_price')}`
                        }
                      </div>
                    </div>
                  ))}

                  {orderVariation.get('service_add_on_options').map(option => (
                    <div className="row" key={option.get('service_add_on_option_id')}>
                      <div className="col-xs-10">
                        <span className="font-weight-bold">
                          {option.get('quantity')}x
                        </span>
                        <span className="ml-2">
                          {option.get('name')}
                        </span>
                      </div>
                      <div className="col-xs-2 text-right">
                        ${option.get('v_total_price')}
                      </div>
                    </div>
                  ))}

                  {
                    orderVariation.get('additional_instructions') &&
                      <div className="row">
                        <div className="col-xs-12 special-instructions text-danger">
                          Special instructions:
                          <br />
                          {orderVariation.get('additional_instructions').split('\n').map((item, _key) => (
                            <span key={item}>{item}<br /></span>
                          ))}
                        </div>
                      </div>
                  }
                </div>
              </div>
          }

          <hr />
        </div>
      ))
    }

    <div className="row">
      <div className="col-xs-10">Subtotal</div>
      <div className="col-xs-2 text-right">
        ${orderHistory.getIn(['data', 'v_subtotal_amount'])}
      </div>
    </div>

    {
      orderHistory.getIn(['data', 'v_vendor_small_order_fee']) &&
      <div className="row">
        <div className="col-xs-10">Small Order Fee</div>
        <div className="col-xs-2 text-right">
          ${orderHistory.getIn(['data', 'v_vendor_small_order_fee'])}
        </div>
      </div>
    }

    {
      orderHistory.getIn(['data', 'v_delivery_amount']) &&
      <div className="row">
        <div className="col-xs-10">Delivery Fee</div>
        <div className="col-xs-2 text-right">
          ${orderHistory.getIn(['data', 'v_delivery_amount'])}
        </div>
      </div>
    }

    {
      orderHistory.getIn(['data', 'v_surcharge_amount']) &&
      <div className="row">
        <div className="col-xs-10">Surcharge</div>
        <div className="col-xs-2 text-right">
          ${orderHistory.getIn(['data', 'v_surcharge_amount'])}
        </div>
      </div>
    }

    {
      orderHistory.getIn(['data', 'v_vendor_refund']) &&
      <div className="row">
        <div className="col-xs-10">Vendor Discount/Refund</div>
        <div className="col-xs-2 text-right">
          -${orderHistory.getIn(['data', 'v_vendor_refund'])}
        </div>
      </div>
    }

    {
      orderHistory.getIn(['data', 'v_tax_amount']) &&
      <div className="row">
        <div className="col-xs-10">GST</div>
        <div className="col-xs-2 text-right">
          ${orderHistory.getIn(['data', 'v_tax_amount'])}
        </div>
      </div>
    }

    <div className="row mt-4">
      <div className="col-xs-10 font-weight-bold">Total</div>
      <div className="col-xs-2 text-right">
        <h5>
          ${orderHistory.getIn(['data', 'v_total_amount'])}
        </h5>
      </div>
    </div>
  </Fragment>
);

HistoryWithoutDiffV2.propTypes = {
  orderHistory: PropTypes.object.isRequired,
};

export default HistoryWithoutDiffV2;
