/* eslint-disable quotes */
import React, { Fragment, useState } from "react";
import MobileOrderList from "bundles/v2/orders/components/MobileOrderList";
import DesktopOrderList from "bundles/v2/orders/components/DesktopOrderList";
import { array, bool } from "prop-types";
import MobileOrderListHeader from "./MobileOrderListHeader";
import DesktopOrderListHeader from "./DesktopOrderListHeader";

const tabTypes = {
  today: 'today',
  tomorrow: 'tomorrow',
};

const OrderList = ({
  orders,
  isFetchingDashboardData,
  tomorrowOrders,
}) => {
  const [tab, setTab] = useState(tabTypes.today);

  const redirectToOrderDetails = (orderableId, klassName) => {
    window.location = `/${klassName.toLowerCase()}s/${orderableId}`;
  };

  const selectedOrders = tab === tabTypes.today ? orders : tomorrowOrders;

  return (
    <Fragment>
      <div id="order-list-dashboard-mobile">
        <MobileOrderListHeader
          {
            ...{
              orders,
              tomorrowOrders,
              tab,
              setTab,
              tabTypes,
            }
          }
        />
        <MobileOrderList
          {
            ...{
              mobileOrders: [{ orders: selectedOrders }],
              isFetching: isFetchingDashboardData,
              redirectToOrderDetails,
            }
          }
        />
      </div>
      <div
        className="order-list-dashboard-desktop-header"
      >
        Orders
      </div>
      <div id="order-list-dashboard-desktop">
        <DesktopOrderListHeader
          {
            ...{
              orders,
              tomorrowOrders,
              tab,
              setTab,
              tabTypes,
            }
          }
        />
        <hr />
        <DesktopOrderList
          {
            ...{
              orders: selectedOrders,
              isFetching: isFetchingDashboardData,
              redirectToOrderDetails,
              enableSort: false,
            }
          }
        />
      </div>
    </Fragment>
  );
};

OrderList.propTypes = {
  orders: array,
  isFetchingDashboardData: bool,
  tomorrowOrders: array,
};

export default OrderList;
