/* eslint jsx-a11y/click-events-have-key-events:0 */
/* eslint jsx-a11y/no-static-element-interactions:0 */

import React from 'react';
import { bool } from 'prop-types';
import classnames from 'classnames';
import CheckIcon from 'bundles/common/components/Icons/Check';
import { string } from 'prop-types';
import { func } from 'prop-types';

const getClassName = ({ isSelected }) => (
  classnames(
    'user-block__user-address', 'address-block--no-footer', 'address-block--disabled',
    {
      'address-block--selected': isSelected,
    },
  )
);

const RoleSelection = ({
  title,
  description,
  isSelectable,
  isSelected,
  onClick,
}) => (
  <div>
    <div
      className={getClassName({ isSelectable, isSelected })}
    >
      <div className="address-block__box" style={{ height: '80px', minHeight: '80px' }}>
        <div
          className="address-block__content"
          onClick={() => onClick()}
        >
          {
            isSelected &&
              <span className="address-block__selected-icon">
                <CheckIcon />
              </span>
          }

          <div>
            {title}
          </div>
          <div style={{ color: '#95989D' }}>
            {description}
          </div>
        </div>
      </div>
    </div>
  </div>
);

RoleSelection.propTypes = {
  title: string,
  description: string,
  isSelected: bool,
  isSelectable: bool,
  onClick: func,
};

export default RoleSelection;
