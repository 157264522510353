import React from 'react';
import { func } from 'prop-types';
import {
  Link,
} from 'bundles/common/components/Buttons';
import * as customisationsActions from 'bundles/vendor/actions/customisationsActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const BackLink = ({
  closeCustomisation,
}) => (
  <Link
    name="Back to Customisations"
    onClick={closeCustomisation}
  />
);

BackLink.propTypes = {
  closeCustomisation: func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  closeCustomisation: customisationsActions.closeCustomisation,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BackLink);
