import React from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as menuItemsActions from 'bundles/vendor/actions/menuItemsActions';
import { CheckBox } from 'libs/components/Forms';
import { usePolicies } from 'bundles/common/queries/policies';

const SelecAllCheckbox = ({
  menuItemIds,
  selectedIds,
  toggleSelectAllMenuItems,
}) => {
  const policiesResponse = usePolicies();
  const { policies } = policiesResponse.isSuccess ? policiesResponse.data : {};
  const handleOnToggle = () => {
    toggleSelectAllMenuItems();
  };
  const isChecked = menuItemIds.every(menuItemId => selectedIds.find(selectedId => selectedId === menuItemId));
  const disabled = !policies?.menuItem?.create;

  return (
    <CheckBox
      id="menu_item_all"
      name="menu_item[all]"
      disabled={disabled}
      onChange={handleOnToggle}
      checked={isChecked}
    />
  );
};

SelecAllCheckbox.propTypes = {
  menuItemIds: object.isRequired,
  selectedIds: object.isRequired,
  toggleSelectAllMenuItems: func.isRequired,
};

const mapStateToProps = ({
  $$menuItemsStore,
}) => ({
  selectedIds: $$menuItemsStore.get('selectedIds'),
  menuItemIds: $$menuItemsStore.get('menuItems').map(m => m.get('uuid')),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  toggleSelectAllMenuItems: menuItemsActions.toggleSelectAllMenuItems,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SelecAllCheckbox);
