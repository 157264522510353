import React from 'react';
import { Provider } from 'react-redux';
import ReactOnRails from 'react-on-rails';

import AppContext from 'libs/support/appContext';
import { defaultTheme } from 'libs/support/theme';
import { ThemeProvider } from 'styled-components';

const getStore = (storeNames, defaultStoreName) => {
  let store;

  if (!storeNames && !defaultStoreName) return null;

  if (storeNames) {
    for (let i = 0; i <= storeNames.length; i += 1) {
      const name = storeNames[i];
      store = ReactOnRails.getStore(name, false);

      if (store) return store;
    }
  }

  return ReactOnRails.getStore(defaultStoreName);
};

const StartupApp = (
  storeName,
  Container,
  storeNames,
) => (props, railsContext) => {
  const store = getStore(storeNames, storeName);

  const context = {
    fflags: railsContext.fflags,
    vertical: railsContext.vertical,
  };

  window.apiCableUrl = railsContext.settings.apiCableUrl;

  return () => (
    <AppContext.Provider value={context}>
      {
          store ?
            <Provider store={store}>
              <ThemeProvider theme={defaultTheme} >
                <Container {...props} />
              </ThemeProvider>
            </Provider> :
            <ThemeProvider theme={defaultTheme} >
              <Container {...props} />
            </ThemeProvider>
        }
    </AppContext.Provider>
  );
};

export default StartupApp;
