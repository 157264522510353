import React from 'react';
import { func, string } from 'prop-types';
import styled from 'styled-components';
import uppercamelcase from 'uppercamelcase';
import { connect } from 'react-redux';
import {
  NormalFont,
  Spacer,
  TitleFont,
} from 'libs/support/sharedComponents';
import { withI18n } from 'libs/support/i18n';
import List from './List';
import Tabs from './Tabs';
import Actions from './Actions';
import Flash from '../Flash';

const Container = styled.div`
`;

const Block = styled.div`
`;

const CustomisationsList = ({
  currentType,
  translate,
}) => (
  <Container>
    <Flash />
    <Spacer />
    <Block>
      <NormalFont>
        { translate('vendorModalsCustomisationsListMessage') }
      </NormalFont>
    </Block>
    <Spacer />
    <Actions />
    <Spacer />
    <Tabs />
    <Spacer />
    <Block>
      <TitleFont>
        { translate(`vendorModalsCustomisationsList${uppercamelcase(currentType)}Title`) }
      </TitleFont>
    </Block>
    <Spacer size="xs" />
    <Block>
      <NormalFont>
        { translate(`vendorModalsCustomisationsList${uppercamelcase(currentType)}Message`) }
      </NormalFont>
    </Block>
    <Spacer />
    <form id="item_add_ons_list" >
      <List type="itemAddOn" />
    </form>
    <form id="service_add_ons_list" >
      <List type="serviceAddOn" />
    </form>
  </Container>
);

CustomisationsList.propTypes = {
  currentType: string,
  translate: func.isRequired,
};

const mapStateToProps = ({
  $$customisationsStore,
}) => ({
  currentType: $$customisationsStore.get('currentType'),
});

export default connect(mapStateToProps)(withI18n(CustomisationsList));
