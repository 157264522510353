import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import styled from 'styled-components';
import {
  InputField,
} from 'libs/components/Forms';
import {
  SecondaryTitle,
  Spacer,
} from 'libs/support/sharedComponents';

const Container = styled.div`
  position: ${({ isHidden }) => (isHidden ? 'absolute' : 'inherit')};
  opacity: ${({ isHidden }) => (isHidden ? '0' : '1')};
  z-index: ${({ isHidden }) => (isHidden ? '-1' : '1')};
  width: ${({ isHidden }) => (isHidden ? '0' : '100%')};
  height: ${({ isHidden }) => (isHidden ? '0' : '100%')};
  overflow: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
`;

const VendorBillingDetail = ({
  translate,
  vendorBillingDetail,
  billableId,
  billableType,
  isModal,
  isHidden,
}) => {
  const namePrefix = (fieldName) => {
    if (isModal) {
      return fieldName;
    }

    if (billableType === 'Outlet') {
      return `vendor_billing[vendor_attributes][outlets_attributes][][outlet_billing_detail_attributes][${fieldName}]`;
    }

    if (billableType === 'Vendor') {
      return `vendor_billing[vendor_attributes][vendor_billing_detail_attributes][${fieldName}]`;
    }

    return fieldName;
  };

  return (
    <Container isHidden={isHidden}>
      <input
        type="hidden"
        name={namePrefix('billable_type')}
        value={billableType}
      />
      <input
        type="hidden"
        name={namePrefix('billable_id')}
        value={billableId}
      />

      <InputField
        label={translate('billingsLabelsBillingName')}
        name={namePrefix('billing_name')}
        type="text"
        isRequired
        value={vendorBillingDetail.get('billingName')}
      />

      <InputField
        label={translate('billingsLabelsBusinessRegistrationNumber')}
        name={namePrefix('business_registration_number')}
        type="text"
        isRequired
        value={vendorBillingDetail.get('businessRegistrationNumber')}
      />

      <InputField
        label={translate('billingsLabelsTaxRegistrationNumber')}
        name={namePrefix('tax_registration_number')}
        type="text"
        value={vendorBillingDetail.get('taxRegistrationNumber')}
      />

      <Spacer />
      <div>
        <SecondaryTitle>{translate('billingsTitlesBillingAddress')}</SecondaryTitle>
      </div>

      <InputField
        label={translate('billingsLabelsAddress1')}
        name={namePrefix('address1')}
        type="text"
        value={vendorBillingDetail.get('address1')}
      />
      <InputField
        label={translate('billingsLabelsAddress2')}
        name={namePrefix('address2')}
        type="text"
        value={vendorBillingDetail.get('address2')}
      />
      <InputField
        label={translate('billingsLabelsPostcode')}
        name={namePrefix('postcode')}
        type="text"
        value={vendorBillingDetail.get('postcode')}
      />
      <InputField
        label={translate('billingsLabelsCity')}
        name={namePrefix('city')}
        type="text"
        value={vendorBillingDetail.get('city')}
      />

      <Spacer />
      <div>
        <SecondaryTitle>{translate('billingsTitlesPayment')}</SecondaryTitle>
      </div>

      <InputField
        label={translate('billingsLabelsBankName')}
        name={namePrefix('bank_name')}
        type="text"
        isRequired
        value={vendorBillingDetail.get('bankName')}
      />
      <InputField
        label={translate('billingsLabelsSwiftCode')}
        name={namePrefix('swift_code')}
        type="text"
        isRequired
        value={vendorBillingDetail.get('swiftCode')}
      />
      <InputField
        label={translate('billingsLabelsBankCode')}
        name={namePrefix('bank_code')}
        type="text"
        isRequired
        value={vendorBillingDetail.get('bankCode')}
      />
      <InputField
        label={translate('billingsLabelsBankAccountNumber')}
        name={namePrefix('bank_account_number')}
        type="text"
        isRequired
        value={vendorBillingDetail.get('bankAccountNumber')}
      />
      <InputField
        label={translate('billingsLabelsBankAccountHolder')}
        name={namePrefix('bank_account_holder')}
        type="text"
        isRequired
        value={vendorBillingDetail.get('bankAccountHolder')}
      />
    </Container>
  );
};

VendorBillingDetail.propTypes = {
  translate: func.isRequired,
  vendorBillingDetail: object.isRequired,
  billableId: string.isRequired,
  billableType: string.isRequired,
  isModal: bool,
  isHidden: bool,
};

export default withI18n(VendorBillingDetail);
